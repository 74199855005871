import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, CHEMID_LOGIN, CHEMID_LOGOUT, CHEMID_ENTITY } from '../actions'
import { LoginServices } from '../services/Login'
export default (type, params) => {

  if (type === AUTH_LOGIN) {
    return LoginServices.doLogin(params).then((response) => {
      let access_token = response.data.access_token
      localStorage.setItem('access_token', access_token)
      return response.data
    }).catch((error) => {
      return Promise.reject(error)
    })
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('access_token')
    return Promise.resolve()
  }

  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      localStorage.removeItem('access_token')
      return Promise.reject()
    }
    return Promise.resolve()
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('access_token')
      ? Promise.resolve()
      : Promise.reject()
  }

  if (type === CHEMID_LOGIN) {
	return LoginServices.chemidLogin(params).then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
  }

  if (type === CHEMID_LOGOUT) {
	return LoginServices.chemidLogout().then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
  }

  if (type === CHEMID_ENTITY) {
	return LoginServices.chemidEntity().then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
  }

  return Promise.reject('Unknown method')
}
