import React from 'react-dom'
import { Route, Link, Switch } from 'react-router-dom'
import { isTablet, isMobile, isDesktop, isAndroid, isIOS, isWinPhone } from 'react-device-detect'

function CertusAppRedirect () {

  const android = "https://play.google.com/store/apps/details?id=com.sicpa.certus&hl=en_US&gl=US"
  const ios = "https://apps.apple.com/us/app/certus/id1528287500"
  const direct_certus = "https://www.certusdoc.com/verify/"
  let renderContent = () => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = ios
      } else if (isAndroid) {
        window.location.href = android
      }
    } else if (isTablet) {
      if (isIOS) {
        window.location.href = ios
      } else if (isAndroid) {
        window.location.href = android
      }
    } else {
      window.location.href = direct_certus
    }
    return <div></div>
  }
  return renderContent()
}


export default CertusAppRedirect
