import React, { Component } from 'react'
import cn from 'classnames'
import fabric from '../lib/fabric'
import Vial from './autosampler/Vial'
import SampleQueueList from './autosampler/SampleQueueList'
import AcquiringVialStatus from './autosampler/AcquiringVialStatus'
import AutosamplerActions from './autosampler/AutosamplerActions'
import cannidAPI from '../cannidAPI/client'
import submitSoloSequence from '../tasks/submitSoloSequence'
import { Button, Message, Icon, Popup } from 'semantic-ui-react'
import { TOGGLE_SAMPLE_QUEUES, APPEND_NOTIFICATIONS_LOG, CLEAR_NOTIFICATIONS_LOG } from '../actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import socket from '../lib/socket'
import _ from 'lodash'

const mapStateToProps = (state) => {
  return {
    sampleQueues: state.sampleQueues,
    showQueue: state.showQueue,
    machineState: state.machineState,
    queuePaused: state.queuePaused,
    notificationsLog: state.notificationsLog
  }
}

const mapDispatchToProps = (dispatch) => ({
  appendNotificationsLog: (notification) => { dispatch({ type: APPEND_NOTIFICATIONS_LOG, notification: notification }) },
  clearNotificationsLog: () => { dispatch({ type: CLEAR_NOTIFICATIONS_LOG }) },
  toggleSampleQueues: () => { dispatch({ type: TOGGLE_SAMPLE_QUEUES }) },
  togglePause: () => { dispatch({ type: 'TOGGLE_PAUSE' }) },
  pauseQueue: () => { dispatch({ type: 'PAUSE_QUEUE' }) }
})

class Autosampler extends Component {

  constructor(props) {
    super(props)
    this.canvas = undefined
    this.canvasVialRefs = {}
    this.state = {
      currentVialLocation: undefined,
      sample_queues: [],
      currentTask: this.getNextQueueTask(),
      armMoving: false,
      copiedNotification: null,
	  dblClickPrevention: false
    }
    this.mappedVials = {}
  }

  componentDidMount = () => {
    this.props.fetchQueues()
    this.canvas = new fabric.Canvas("autosamplerCanvas", { selection: false })

    for (let i = 0; i < 100; i++) {
      let circleRadius = 20
      let circleDiameter = circleRadius * 2
      let verticalSpacing = 20
      let perColumn = 10
      let borderWidth = 5
      let columnNum = Math.floor(i / perColumn)
      let evenColumnNum = (columnNum + 1) % 2 === 0
      let rowNum = (i % perColumn) + 1
      let vialNumber = (perColumn - rowNum) + (perColumn * columnNum) + 1
      let textColor = '#aaa'
      let borderColor = '#aaa'
      let yOffset = ((circleDiameter + verticalSpacing) * (rowNum - 1)) + circleRadius + borderWidth
      let xOffset = circleRadius + (circleDiameter * columnNum) + borderWidth
      if (!evenColumnNum) {
        yOffset += circleRadius + (verticalSpacing / 2)
      }
      let text = new fabric.Text("", { left: xOffset + 13, top: yOffset + 8, fill: 'white', fontSize: 26, selectable: false, hoverCursor: "default", stroke: 'black', strokeWidth: .25, fontWeight: 'bold' })
      let circle = new Vial({
        left: xOffset,
        top: yOffset,
        radius: circleRadius,
        hoverCursor: "default"
      }, vialNumber, this.mappedVials[vialNumber], text)
      text.on("mouseover", (event) => {
        this.setState({ currentVialLocation: vialNumber })
      })
      text.on("mouseout", (event) => {
        this.setState({ currentVialLocation: undefined })
      })
      circle.on("mouseover", (event) => {
        this.setState({ currentVialLocation: vialNumber })
      })
      circle.on("mouseout", (event) => {
        this.setState({ currentVialLocation: undefined })
      })
      this.canvas.add(circle)
      this.canvas.add(text)
      this.canvasVialRefs[vialNumber] = circle
    }
    this.canvas.renderAll()
    this.registerSocketHooks()
  }

  startAction = (nextTask) => {
    console.log('debouncin --- startAction ---')
    console.log('fed next task', nextTask)
    console.log('current one from state', this.state.currentTask)
    if (nextTask) {
      console.log('startacct pre timeout')
	  this.setState({ currentTask: nextTask, armMoving: true })
      setTimeout(() => {
        console.log('startacct .001 sec times up', nextTask)
        submitSoloSequence(nextTask).subscribe({ next: (x) => console.log(x) })
      }, 10)
    } else {
      console.log('no next task, must have just finished the last one!')
      this.props.pauseQueue()
      this.setState({ currentTask: nextTask, armMoving: false })
    }
  }

  getNextQueueTask = () => {
    let vialToRun
    this.props.sampleQueues.forEach((queue) => {
      if (queue.vials) {
        queue.vials.forEach((vial, index) => {
          if (!vialToRun && !vial.completed) {
            vialToRun = {
              location: vial.location,
              name: queue.sample.name,
              sample_id: queue.sample.id,
              vial_number: index + 1,
              vial_count: queue.vials.length
            }
          }
        })
      } else if (queue.action) {
        if (!vialToRun) {
          vialToRun = { action: queue.action }
        }
      }
    })
    return vialToRun
  }

  appendResults = (cannIdSampleId, contextualizedResult, count = 1) => {
    cannidAPI.post(`/samples/${cannIdSampleId}/append_results`, { result: contextualizedResult }).then((res) => {
      console.log('Successfully appended results to sample, fetching queues.')
      this.props.fetchQueues()
    }).catch((err) => {
      console.log("Error while trying to append results to sample.", err)
      console.log("attempt #", count)

      if (Object.keys(err.response.data).includes('retry') && err.response.data.retry === false) {
        const message = err.response.data.message ? err.response.data.message : `Driver detected result not uploaded. Current results count matches expected acquiring vial count for ID ${cannIdSampleId}. Clear results and manually Import Chemstation Run.`
        this.props.appendNotificationsLog(message)
        this.registerSocketHooks()
      } else if (count >= 60) {
        const message = `60 failed attempts (over 5 minutes) trying to append results to sample for ID ${cannIdSampleId}. This is typical of a lost or inconsistent internet connection. Refresh the browser and 'Resume' to re-run vial, or import the results. Please contact Client Services if help is needed.`
        this.props.appendNotificationsLog(message)
        this.registerSocketHooks()
      } else if (count === 30) {
        const message = `30 failed attempts (over 2 1/2 minutes) trying to append results to sample for ID ${cannIdSampleId}. This is typical of a lost or inconsistent internet connection. The program will continue to try appending the results for 2 1/2 more minutes. Please stand by...`
        this.props.appendNotificationsLog(message)
        this.registerSocketHooks()
        count += 1
        setTimeout(() => this.appendResults(cannIdSampleId, contextualizedResult, count), 5000)
      } else if (count === 6) {
        const message = `6 failed attempts (over 30 seconds) trying to append results to sample for ID ${cannIdSampleId}. This is typical of a lost or inconsistent internet connection. The program will continue to try appending the results for 4 1/2 more minutes. Please stand by...`
        this.props.appendNotificationsLog(message)
        this.registerSocketHooks()
        count += 1
        setTimeout(() => this.appendResults(cannIdSampleId, contextualizedResult, count), 5000)
      } else {
        count += 1
        setTimeout(() => this.appendResults(cannIdSampleId, contextualizedResult, count), 5000)
      }
    })
  }

  startAcquisition = (sampleId, count = 1) => {
    cannidAPI.post(`/samples/${sampleId}/start_acquisition`).then((res) => {
      console.log('Successfully logged a start_acquisition time, fetching queues.')
      this.props.fetchQueues()
    }).catch((err) => {
      console.log("Couldn't post to start acq endpoint", err)
      console.log("attempt #", count)
      if (count >= 6) {
        const message = err.response.data.message ? err.response.data.message : `6 failed attempts (over 30 seconds) trying to post the acquisition start for sample ID ${sampleId}. This is typical of a lost or inconsistent internet connection. The vial will continue to run but the progress bar will not be accurate. Please contact Client Services if help is needed.`
        this.props.appendNotificationsLog(message)
        this.registerSocketHooks()
      } else {
        count += 1
        setTimeout(() => this.startAcquisition(sampleId, count), 5000)
      }
    })
  }

  registerSocketHooks = () => {
    if (window.socketsRegistered) {
      return
    }
    window.socketsRegistered = true

    socket.on('CSHook', (hook) => {
		console.log('Received socket CSHook', hook)
		if (hook.type === "PostDA") {
			const runMeta = hook.results.runMeta || {}
			if (Object.keys(runMeta).length) {
				// check if metadata attributes hook to a sample
				// 'cannid' key is legacy of updated 'sample_description' key so we handle both
				if ((runMeta['cannid'] && runMeta['cannid']['sample_id']) ||
					(runMeta['sample_description'] && runMeta['sample_description']['sample_id'])) {
					const cannIdSampleId = runMeta['cannid'] ? runMeta['cannid']['sample_id'] : runMeta['sample_description']['sample_id']
					let contextualizedResult = {
						csDataFolder: runMeta.data_directory,
						csDataName: runMeta.data_file_name,
						results: hook.results.amounts,
						machineName: localStorage.getItem('machine')
					}

					if (hook.results.invalid) contextualizedResult = {...contextualizedResult, invalid: hook.results.invalid}
					Object.keys(runMeta).forEach((key) => {
						if (key !== 'data_directory' && key !== 'data_file_name') {
							contextualizedResult[key] = runMeta[key]
						}
					})
					this.appendResults(cannIdSampleId, contextualizedResult)
				} else {
					console.log('No ["cannid"]["sample_id"] -or- ["sample_description"]["sample_id"] in your runMeta.', runMeta)
				}
			} else {
				console.log('No ["results"]["runMeta"] received with your hook.')
			}
		}
		socket.emit('issued-confirmation', hook)
    })

	socket.on('AcqHook', (hook) => {
		console.log('Received socket AcqHook', hook)
		if (hook.type === 'StartAcquisition') {
			if (hook.sampleInfo && hook.sampleInfo.sample_id) {
				this.startAcquisition(hook.sampleInfo.sample_id)
			} else {
				console.log('Hook had no sampleInfo.sample_id')
			}
		}
		socket.emit('issued-confirmation', hook)
	})

	socket.on('connect', () => {
		console.log('Socket CONNECTED +=+=+=+')
	})
	socket.on('disconnect', (reason) => {
		console.log('Socket DISONNECTED &%&%&%&%&%&%&&%%&%&%&', reason)
	})
	socket.on('reconnect_attempt', (attemptNumber) => {
		console.log('Socker RECON ATTEMPT _!_!_!_!',  attemptNumber)
	})
	socket.on('reconnect_failed', () => {
		console.log('Socket RECONNECT FailURE _!_!_!_!')
		this.props.disconnectDriver()
	})
  }

  componentDidUpdate(prevProps, prevState) {
	console.log('SOCKET check!::: ', socket.id, 'connected?', socket.connected)
	if (!socket.connected && this.props.pollStatus === "Connected") socket.connect()
    let instrumentActive = this.state.armMoving
    console.log("sss COMPONENT DID PROPS", this.props.machineState, this.props)

    // if driver is now connected, tell the Routes component to update the sidebar toggle
    if (this.props.machineState && this.props.machineState.status && this.props.pollStatus !== "Connected") {
      this.props.setDriverConnected()
    }

    //Disable Buttons while instrumnet is out of a actionable state
    if (prevProps.machineState && this.props.machineState) {
      //if error then nothing else matters
      if (this.props.machineState.status == 'ERROR') {
        instrumentActive = false
      }

      else if (this.props.machineState.status == 'PRERUN' && prevProps.machineState.status == 'PRERUN' && this.props.machineState.sequenceRunning && !prevProps.machineState.sequenceRunning) {
        console.log('1st')
        instrumentActive = true
      }

      else if (this.props.machineState.status == 'PRERUN' && prevProps.machineState.status == 'PRERUN' && !this.props.machineState.sequenceRunning && this.props.queuePaused) {
        instrumentActive = false
        console.log('2nd')
      }

      else if ((this.props.machineState.state == 'RAWDATA' || this.props.machineState.status == 'PRERUN' || this.props.machineState.status == 'POSTRUN') && prevProps.machineState.status == 'RUN') {
        instrumentActive = true
        console.log('3rd')
      }

      else if (this.props.machineState.status == 'INJECTING' && (prevProps.machineState.status == 'PRERUN' || prevProps.machineState.status == 'NOTREADY')) {
        instrumentActive = true
        console.log('4th')
      }

	  else if ((this.props.machineState.state == 'RAWDATA' || this.props.machineState.status == 'PRERUN' || this.props.machineState.status == 'POSTRUN') && this.props.machineState.sequenceRunning) {
        instrumentActive = true
        console.log('5th')
      }

      else if (this.props.machineState.status == 'RUN' && prevProps.machineState.status == 'INJECTING') {
        instrumentActive = false
      }

      // ---------------------- WARMUP method monitoring ----------------------
      // warmup was running but now stopping has begun
      else if (this.props.machineState.method === process.env.REACT_APP_WARMUP_METHOD + '.M' && (this.props.machineState.status == 'NOTREADY' || this.props.machineState.status == 'POSTRUN' || this.props.machineState.status == 'RAWDATA')) {
        instrumentActive = true
      }
      else if (this.props.machineState.method === process.env.REACT_APP_WARMUP_METHOD + '.M' && this.props.machineState.status == 'RUN') {
        instrumentActive = false
      }

      // ---------------------- SLEEP method monitoring ----------------------
      // sleep was running but now stopping has begun
      else if (this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD + '.M' && (this.props.machineState.status == 'POSTRUN' || this.props.machineState.status == 'RAWDATA') && prevProps.machineState.status == 'RUN') {
        instrumentActive = true
      }
      // sleep was stopping but now it's definitely donezo
      else if (this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD + '.M' && this.props.machineState.status == 'NOTREADY' && (prevProps.machineState.status == 'POSTRUN' || prevProps.machineState.status == 'RAWDATA' || prevProps.machineState.status == 'RUN')) {
        instrumentActive = false
      }
      // sleep is running. 
      else if (this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD + '.M' && this.props.machineState.status == 'RUN') {
        instrumentActive = false
      }
      else if (this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD + '.M' && this.props.machineState.sequenceRunning) {
        instrumentActive = true
      }
      console.log("previous status", prevProps.machineState.status)
      console.log("current status", this.props.machineState.status)
      console.log('instru active', this.state.armMoving, '->', instrumentActive)

      // if sleep is running and queue isn't paused, engage the pause so for when it's completed
      if ((this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD + '.M' || this.props.machineState.method === process.env.REACT_APP_WARMUP_METHOD + '.M') &&
          this.props.machineState.sequenceRunning &&
          !this.props.queuePaused) {
        this.props.pauseQueue()
      }
    }

    const nextTask = this.getNextQueueTask()

    this.mappedVials = {}
    if (this.props.sampleQueues) {
      console.log('nextTask', nextTask, 'currentTask', this.state.currentTask, 'not equal', !_.isEqual(nextTask, this.state.currentTask))
      let i = 0
      this.props.sampleQueues.forEach((sample_queue) => {
        if (sample_queue.locations) {
          sample_queue.locations.forEach((vialLocation) => {
            let vial = sample_queue.vials.find((vial) => (parseInt(vial.location) === parseInt(vialLocation)))
            this.mappedVials[vialLocation] = { sample: sample_queue.sample, vial }
          })
        }
      })
      if (this.state.currentTask) {
        if (!_.isEqual(nextTask, this.state.currentTask)) {
          console.log('current task complete, diffent up next', nextTask, this.state.currentTask)
          if (!this.props.queuePaused && this.props.machineState && !this.props.machineState.sequenceRunning) {
            this.startAction(nextTask)
            console.log('starting next task (uno): ', nextTask)
            console.log("COMPONENT DID UPDATE", this.props)
            console.log('STATESIDE', this.state)
          } else if (this.props.queuePaused && this.props.machineState && !this.props.machineState.sequenceRunning) {
            console.log('  at end of vial?')
            if (this.state.armMoving) {
              this.setState({ armMoving: false, currentTask: nextTask })
              console.log("last sample in queue?") // last sample in queue
            } else {
              this.setState({ currentTask: nextTask })
              console.log("new queue mid run?") // new queue mid run
            }
          } else {
            console.log('paused?', this.props.queuePaused, 'running?', this.props.machineState.sequenceRunning)
            if (this.state.armMoving != instrumentActive) { this.setState({ armMoving: instrumentActive }) }
          }
        }
        else {
          console.log("this arm moving?", this.state.armMoving, "gonna be?", instrumentActive)
          if (this.state.armMoving != instrumentActive) { this.setState({ armMoving: instrumentActive }) }
        }
      } else if (nextTask) {
        console.log('setting next task to (dos) ', nextTask)
        if (!this.props.queuePaused && this.props.machineState && !this.props.machineState.sequenceRunning) {
          // this is where auto-start bug is firing
          this.startAction(nextTask)
        }
        else {
          console.log("this arm moving? dos else", this.state.armMoving)
          // nextTask exists but no current one in state
          let currTsk = this.state.currentTask
          if (!this.state.currentTask) {
            currTsk = nextTask
          }

          if (this.state.armMoving != instrumentActive) {
            this.setState({ armMoving: instrumentActive, currentTask: currTsk })
          } else {
            this.setState({ currentTask: currTsk })
          }
        }
      } else {
        console.log('nothing up next')
        if (this.state.armMoving != instrumentActive) { this.setState({ armMoving: instrumentActive }) }
      }
    } else {
      console.log("this arm moving? no sample queues", this.state.armMoving)
      if (this.state.armMoving != instrumentActive) { this.setState({ armMoving: instrumentActive }) }
    }

    // update our visualization (just the tray right now)
    for (let i = 1; i <= 100; i++) {
      this.canvasVialRefs[i].updateData(this.mappedVials[i], i === parseInt(this.state.currentVialLocation))
    }
    this.canvas.renderAll()

    // create an array of injections / actions in order
    let everythingInOrder = []
    let i = 0
    if (this.props.sampleQueues) {
      this.props.sampleQueues.forEach((q) => {
        if (q.locations) {
          q.locations.forEach((location) => {
            everythingInOrder[i] = {
              location: location,
              sample_id: q.sample.id,
              sample_name: q.sample.name
            }
            i++
          })
        } else if (q.action) {
          everythingInOrder[i] = {
            action: q.action
          }
          i++
        }
      })
    }

  }

  startCalibration = () => {
    cannidAPI.post('/sample_queues/start_calibration').then((res) => {
      this.props.fetchQueues()
    })
  }

  startCalCheck = () => {
    cannidAPI.post('/sample_queues/start_cal_check').then((res) => {
      this.props.fetchQueues()
    })
  }

  addSleep = () => {
	if (!this.state.dblClickPrevention) {
		this.setState({ dblClickPrevention: true })
		cannidAPI.post('/sample_queues/add_sleep').then((res) => {
			this.props.fetchQueues()
			setTimeout(this.setState({ dblClickPrevention: false }), 500)
		})
	}
  }

  clearQueue = () => {
    cannidAPI.post('/sample_queues/clear').then((res) => {
      this.props.fetchQueues().then(() => {
        // this.doAction('clearQueue')
      })
    })
  }

  removeSampleFromQueue = (queue) => {
    // if only 2 items in sample queue and last is sleep, and item
    // being deleted is the sample -- then just clear the whole queue
    // since we can't sleep as only thing in queue
    if (this.props.sampleQueues.length === 2 &&
      this.props.sampleQueues[1].action &&
      this.props.sampleQueues[1].action === 'sleep' &&
      !queue.action) {
      this.clearQueue()
    } else {
      cannidAPI.delete('/sample_queues/' + queue.id).then((res) => {
        this.props.fetchQueues()
      })
    }
  }

  goToAddMoreSamples = () => {
    this.props.history.push("/test-result/list")
    this.props.toggleSampleQueues()
  }

  doAction = (action) => {
    const request = new Request(process.env.REACT_APP_DRIVER_HTTP_ROOT + '/chemstation/sequence/control', {
      method: 'POST',
      body: JSON.stringify({ action }),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
    return fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      return response.json()
    }).then((json) => {
      console.log('toggle toggle')
    })
  }

  render() {
    let sampleDom
    if (this.props.sampleQueues) {
      sampleDom = this.props.sampleQueues.map((sample_queue) => {
        return <SampleQueueList key={sample_queue.id}
                  sample_queue={sample_queue}
                  removeSampleFromQueue={this.removeSampleFromQueue}
                  setCurrentVialLocation={(vialLocation) => { this.setState(() => ({ currentVialLocation: vialLocation })) }}
                  currentVialLocation={this.state.currentVialLocation}
                  machineState={this.props.machineState}
                  currentTask={this.state.currentTask}
                  queuePaused={this.props.queuePaused} />
      })
    }

    let acquiringVialStatus, acquisitionTools
    if (this.props.machineState) {
      if (this.props.machineState.sequenceRunning && this.props.machineState.currentVialAcquiring > -1) {
        let acquiringVial = this.props.machineState.currentVialAcquiring
        let sample_queue = this.props.sampleQueues.find((queue) => (queue.locations.indexOf(acquiringVial) > -1))
        if (acquiringVial && sample_queue) {
          acquiringVialStatus = <AcquiringVialStatus sampleQueue={sample_queue} acquiringVialNumber={acquiringVial} />
        }
        acquisitionTools = <div className="acquiringVialContainer">{acquiringVialStatus}</div>
      } else if (this.props.machineState
        && this.props.machineState.method === process.env.REACT_APP_WARMUP_METHOD + '.M'
        && ['PRERUN', 'RUN', 'NOTREADY'].indexOf(this.props.machineState.status) > -1) {
        acquisitionTools = <div className="acquiringVialContainer">Warmup Flush Running</div>
      } else if (this.props.machineState
        && this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD + '.M'
        && (this.props.machineState.status == 'RUNNING' || this.props.machineState.status == 'RUN' || this.props.machineState.status == 'PRERUN')) {
        acquisitionTools = <div className="acquiringVialContainer">Sleep Flush Running</div>
      } else if (this.props.machineState.status === 'ERROR') {
        acquisitionTools = (
          <div className="acquiringVialContainer">
            <Message error>
              <p><strong>ChemStation Error!</strong> View ChemStation for more details.</p>
              <p>Contact <a href="mailto:clientservices@cannid.com">clientservices@cannid.com</a> for help. (Reference {JSON.stringify(this.props.machineState.errors)})</p>
            </Message>
          </div>
        )
      }
    }

    const sleepInQueue = (this.props.sampleQueues.length > 0 &&
      this.props.sampleQueues[this.props.sampleQueues.length - 1].action &&
      this.props.sampleQueues[this.props.sampleQueues.length - 1].action === 'sleep') ? true : false
    const sleepEnabled = (this.props.machineState && this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD + '.M') ? true : false

    return (
      <div className={cn('queueManager', { open: this.props.showQueue })}>
        <div className="acquisitionTools">
          <AutosamplerActions
            sampleQueues={this.props.sampleQueues}
            taskToRun={this.state.currentTask}
            startAction={this.startAction}
            acquisitionTools={acquisitionTools}
            armMoving={this.state.armMoving}
            setArmMoving={(active) => this.setState({ armMoving: active })}
          />
        </div>
        {this.props.notificationsLog.length > 0 &&
          <div className="notificationsLog">
            <Message warning>
              <Message.Header>Notification Log</Message.Header>
              <Message.List>
                {this.props.notificationsLog.map((notification, index) => {
                  return (
                  <Message.Item key={`notification${index}`}>
                    {notification}&nbsp;
                    <Popup content={this.state.copiedNotification === index ? `Log Copied!` : `Copy Log`}
                      size='tiny'
                      trigger={<a style={this.state.copiedNotification === index ? {color:'grey'} : {color:'#4183c4'}} onClick={() => {
                        navigator.clipboard.writeText(notification)
                        this.setState({copiedNotification: index}, () => {setTimeout(() => this.setState({copiedNotification: null}), 5000)})
                      }} ><Icon name='copy' size='small' /></a>} />
                  </Message.Item>)
                })}
              </Message.List>
              <Button icon onClick={() => this.props.clearNotificationsLog()} labelPosition="left" style={{marginTop:'0.5em'}}>
                Clear
                <Icon name='close' />
              </Button>
            </Message>
          </div>
        }
        {this.props.machineState &&
          <div className="queuesHolder">
            <div className="autosamplerTable">
              {sampleDom}
              <div className="handyActions">
                <Button onClick={this.goToAddMoreSamples}
                  disabled={sleepInQueue || sleepEnabled}>+ Add Sample</Button>
                <Button onClick={this.addSleep}
                  disabled={sleepInQueue || sleepEnabled
				  	|| (this.props.sampleQueues && this.props.sampleQueues.length === 0)
					|| this.state.dblClickPrevention}>+ Add Sleep</Button>
                <Button
                  onClick={this.clearQueue}
                  disabled={ // disabled if...
                    // no samples in queue
                    this.props.sampleQueues.length === 0 ||
                    // or in RUN method while actually running
                    (
                      (this.props.machineState.method === process.env.REACT_APP_RUN_METHOD + '.M' || this.props.machineState.method === 'RUN.M') &&
                      (this.props.machineState.status !== 'PRERUN' || this.props.machineState.sequenceRunning)
                    ) ||
                    // or in RUN method while idle but not paused (circumstance of short idle between vials)
                    (
                      (this.props.machineState.method === process.env.REACT_APP_RUN_METHOD + '.M' || this.props.machineState.method === 'RUN.M') &&
                      this.props.machineState.status === 'PRERUN' &&
                      !this.props.machineState.sequenceRunning &&
                      !this.props.queuePaused
                    )
                  }>+ New Queue</Button>
                <Button onClick={this.props.toggleSampleQueues}>Close Queue</Button>
              </div>
            </div>
            <canvas id="autosamplerCanvas" width="500" height="900"></canvas>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Autosampler))
