import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { isMobile } from 'react-device-detect'
import ReactDOMServer from 'react-dom/server'

class PieChartComponent extends React.Component {
	constructor(props) {
		super(props)
	}

	preparePieOptions = (data) => {
		const donutText = data.totals ? (
			<div>
				<strong>
					<span style={{ color: '#2ea702', margin: 0 }}>Total<br />Analytes</span>
					<br />
					{data.totals.percent}%
				</strong>
			</div>
		) : ''

		const options = {
			chart: {
				renderTo: 'container',
				type: 'pie',
				backgroundColor: 'transparent',
				events: {
					load() {
						if (isMobile) {
							const $legend = $('#legend-item-container')
							$.each(data.piechart, (j, data) => {
								$legend.append(`<div class="item"><div class="symbol" style="background-color:${data.color}"></div><div class="serieName">${data.name}</div></div>`)
							})
							const chart = this
							$('#legend-item-container .item').on('click', function (e) {
								const inx = $(this).index()
								const point = chart.series[0].data[inx]
								if (point.visible) {
									$(this).addClass('disabled')
									point.setVisible(false)
								}
								else {
									$(this).removeClass('disabled')
									point.setVisible(true)
								}
							})
						}
					}
				}
			},
			title: {
				text: ReactDOMServer.renderToStaticMarkup(donutText),
				align: 'center',
				verticalAlign: 'middle',
				style: {
					fontSize: '28px'
				},
				floating: true,
				x: 0
			},
			credits: {
				enabled: false
			},
			plotOptions: {
				pie: {
					innerSize: window.innerWidth > 425 ? '60%' : '80%',
					dataLabels: {
						enabled: !isMobile
					}
				}
			},
			tooltip: {
				formatter() {
					const formatStr = `<span style="color:${this.point.color}"></span> ${this.point.name}: <b>${this.point.label} %</b><br />`
					return formatStr
				}
			},
			series: [{ data: this.props.data.piechart }],
			exporting: {
				enabled: false
			}
		}

		return options
	}

	render() {
		return (
			<HighchartsReact
				highcharts={Highcharts}
				options={this.preparePieOptions(this.props.data)}
			/>
		)
	}
}
export default PieChartComponent
