import React, { Component } from 'react'
import { Button, Modal, Checkbox, Message, Icon, Popup } from 'semantic-ui-react'
import cannidAPI from '../../cannidAPI/client'
import PreviousCertus from './PreviousCertus'
import constants from '../../lib/constants'
import permissionCheck from '../../lib/permissionCheck'
import { getOrgCertusTokenBank } from '../../actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class CertusModalCreate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			certus: {},
			modalSuccess: false,
			associatedDisplay: false,
			apiErrors: {},
			strain_name: "",
			verisealCoa: true,
			certusID: undefined,
			expeditedPrevious: false
		}
	}

	clearState = (redirect) => {
		this.setState({certusID: undefined})
		this.props.closeModal()
		if (redirect) {
			this.props.match.path === '/sample/:id/results' ? window.location.reload()
				: this.props.history.push(`/sample/${this.props.sample_id}/results`)
		}
	}

	createSampleCertus = () => {
		// only if it has a COA, veriseal coa toggled true, and user has permissions will we write as a veriseal coa
		let params = {
			id: this.props.sample_id,
			template: (this.props.coa && this.state.verisealCoa
				&& permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL_COA)) ? "coa" : "pdf"
		}

		if (!this.state.expeditedPrevious && this.state.certusID) {
			params['previous'] = this.state.certusID
		}
		else if (this.state.expeditedPrevious && !this.state.certusID) {
			params['expedited'] = true
		}
		else {
			console.error('Previous Certus selection and expedited toggle out of sync', this.state)
		}

		document.body.classList.add('loading-indicator')
		
		if ((this.state.expeditedPrevious && !this.state.certusID)
			|| (!this.state.expeditedPrevious && this.state.certusID)) {
			cannidAPI.post('/sample_certi', params)
			.then((response) => {
				console.log('success', response)
				document.body.classList.remove('loading-indicator')
				this.setState({modalSuccess: true})
				this.props.createSuccess()
				this.props.getOrgCertusTokenBank()
			})
			.catch((err) => {
				console.error('error!', err)
				document.body.classList.remove('loading-indicator')
				const message = `${err}. VERISEAL creation error. Reload the page and try again.`
				this.props.createFailure(message)
				this.props.getOrgCertusTokenBank()
			})
		}
	}

	startLoading = () => {
		this.props.startLoading()
		this.createSampleCertus()
	}

	render() {
		let verisealCoaToggle = ""
		if (this.props.coa && permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL_COA)) {
			// coa sample & user has veriseal coa permissions: show toggle option
			verisealCoaToggle = (
				<div>
					<Checkbox className="certusModalToggle" toggle checked={this.state.verisealCoa}
						label={this.state.verisealCoa ? "VERISEAL Certificate of Analysis" : "VERISEAL Chemical Report"}
						onChange={() => this.setState(prevState => ({verisealCoa: !prevState.verisealCoa}))}/>
					<p style={{marginTop: '1rem'}}>
						Toggle to write the results as a VERISEAL Chemical Report or VERISEAL Certificate of Analysis.&nbsp;
						<strong>Only one type of report can be wrtitten per sample.</strong>
					</p>
				</div>
			)
		}
		else if (this.props.coa && !permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL_COA)) {
			// coa sample but user doens't have veriseal coa permissions: warn them
			verisealCoaToggle = (
				<div style={{marginTop: '1rem'}}>
					<Message warning>
						<Message.Header>This sample has a COA!</Message.Header>
						<p>
							Sample results reported with a COA can be VERISEAL Certificate of Analysis instead of VERISEAL Chemical Report 
							to apply laboratory accreditation to the blockchain alongside the test results.&nbsp;
							<strong>
								Unfortunately, you do not have VERISEAL Certificate of Analysis permissions and only one type of report 
								can be uploaded per sample.&nbsp;
							</strong>
							If you create a VERISEAL Chemical Report you will not be able to create a VERISEAL Certificate of Analysis and 
							secure the COA information for this sample test result. Are you sure you want to proceed?
						</p>
					</Message>
				</div>
			)
		}

		const modal = this.state.modalSuccess ? (
				<Modal className='certusConfirmation'
					size='large'
					open={this.props.modalOpen}
					onClose={() => this.clearState(true)} closeIcon>
					<Modal.Header>CERTUS Success!</Modal.Header>
					<Modal.Content scrolling>
						<div>
							<p>
								{(this.props.coa && this.state.verisealCoa
									&& permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL_COA))
									? 'VERISEAL Certificate of Analysis' : 'VERISEAL Chemical Report'
								}
								&nbsp;successfully written to the 
								CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> KSI Blockchain.
								<br/><strong>Notice: It may take up to a minute for the QR code scan functionality to propogate.</strong>
							</p>
						</div>
					</Modal.Content>
					<Modal.Actions>
						<Button className='confirmButton'
							icon='checkmark'
							labelPosition='right'
							content='OK'
							onClick={() => this.clearState(true)}/>
					</Modal.Actions>
				</Modal>
			) : (
				<Modal className='certusConfirmation'
					size='large'
					open={this.props.modalOpen}
					onClose={() => this.clearState()} closeIcon>
					<Modal.Header>
						Create VERISEAL Chemical Report
						{ this.props.coa
							&& permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL_COA)
							&& ' or VERISEAL Certificate of Analysis'}
					</Modal.Header>
					<Modal.Content scrolling style={this.state.expeditedPrevious ? { overflow: 'initial' } : {}}>
						{ !this.state.expeditedPrevious &&
							<PreviousCertus
								certusType='certi'
								setSelection={ (value) => this.setState({ certusID: value }) }
								current_name={this.props.current_name} />
						}
						{ !this.state.certusID &&
							<div className='certusSelectorTable' style={{marginTop: 0, marginBottom: '1rem', overflow: 'initial'}}>
								<Checkbox toggle
									label={
										<label>
											<strong>
												Create an expedited VERISEAL Certificate of Origin assigned as the Previous VERISEAL
											</strong>
											{' '}
											<Popup
												position='top center'
												content={<>Reminder: This will create an additional VERISEAL and incur the use of a second 
													token from the CERTUS
													<Icon style={{ verticalAlign: 'top', margin: 0 }}
														name='registered outline' size='tiny' /> token bank</>}
												trigger={<Icon name='info circle' />} />
										</label>
									}
									onChange={() => this.setState(prevState => ({
										expeditedPrevious: !prevState.expeditedPrevious, 
										certusID: undefined
									}))} />
							</div>
						}
						{ ((this.state.expeditedPrevious && !this.state.certusID)
							|| (!this.state.expeditedPrevious && this.state.certusID)) &&
							<section>
								{verisealCoaToggle}
								<p style={{marginTop: '1rem'}}>
									Are you sure you want to write this&nbsp;
									{(this.props.coa && this.state.verisealCoa
										&& permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL_COA))
										? 'VERISEAL Certificate of Analysis' : 'VERISEAL Chemical Report'
									}
									&nbsp;to the immutable CERTUS KSI Blockchain?
								</p>
							</section>
						}
					</Modal.Content>
					{ ((this.state.expeditedPrevious && !this.state.certusID)
						|| (!this.state.expeditedPrevious && this.state.certusID)) &&
						<Modal.Actions>
							<Button className='cancelButton' onClick={()=> this.clearState()}>No</Button>
							<Button className='confirmButton'
								icon='checkmark'
								labelPosition='right'
								content='Yes'
								onClick={() => this.startLoading()} />
						</Modal.Actions>
					}
				</Modal>
		)

		return modal
	}
}

const mapStateToProps = (state) => {
	return {
	  current_user: state.current_user,
	}
}

const mapDispatchToProps = (dispatch) => ({
	getOrgCertusTokenBank: () => { dispatch(getOrgCertusTokenBank()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CertusModalCreate))
