import AWS from 'aws-sdk'
import deleteS3Object from './deleteS3Object'

// assume renaming is occuring in the same bucket.
async function renameS3Object({ bucket, accessKeyId, secretAccessKey, region }, oldKey, newKey) {
	const creds = {
		accessKeyId: accessKeyId,
		secretAccessKey: secretAccessKey,
		region: region
	}

	const s3 = new AWS.S3(creds)

	// copy the old key to a new name
	return await s3.copyObject({
		Bucket: bucket,
		Key: newKey,
		CopySource: `${bucket}/${oldKey}`,
		ACL: 'public-read'
	})
	.promise()
	// after copied, delete old
	.then((response) => {
		deleteS3Object({...creds, ...{bucket: bucket}}, oldKey)
		return response
	})
	.catch((error) =>{
		console.error("copy error", error);
		return error
	})
}
export default renameS3Object
