import React, { Component } from 'react'
import { Table, Checkbox, Menu, Input, Icon } from 'semantic-ui-react'
import dateTimeFormatter from '../../lib/dateTimeFormatter'

class CertusSelectorTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selected: {}
		}
	}

	toggleSelected = (e, data) => {
		const selected = {...this.state.selected}
		if (data.checked) {
			document.getElementById('certus'+data.certus_id).classList.add('selected-certus-row')
			const idx = this.props.certusRows.findIndex((certus) => certus.id === data.certus_id)
			selected[data.certus_id] = this.props.certusRows[idx]
		} else {
			document.getElementById('certus'+data.certus_id).classList.remove('selected-certus-row')
			delete selected[data.certus_id]
		}
		this.setState({selected: selected}, () => this.props.updateSelected(Object.values(this.state.selected)))
	}

	render() {
		const today = new Date()

		const rows = this.props.certusRows.map((certus, index) => {
			const expiry_date = Date.parse(certus.expiration_date)

			const status = certus.revoked
				? <Table.Cell negative>Revoked</Table.Cell>	: (today.getTime() > expiry_date)
					? <Table.Cell negative>Expired</Table.Cell>
					: <Table.Cell>Valid</Table.Cell>

			const expirationDate = certus.revoked
				? <Table.Cell negative>Revoked</Table.Cell>	: (today.getTime() > expiry_date)
					? (
						<Table.Cell negative>
							Expired:&nbsp;
							{certus.expiration_date}
						</Table.Cell>
					)
					: <Table.Cell>{certus.expiration_date}</Table.Cell>

			return (
				<Table.Row	id={'certus'+certus.id} key={'certus'+certus.id} className={Object.keys(this.state.selected).includes(certus.id.toString()) ? 'selected-certus-row' : ''}>
					<Table.Cell><Checkbox toggle onChange={(e, data) => this.toggleSelected(e, data)} certus_id={certus.id} checked={Object.keys(this.state.selected).includes(certus.id.toString()) ? true : false} /></Table.Cell>
					<Table.Cell>{certus.project_name}</Table.Cell>
					<Table.Cell>{certus.certus_type.certus_type}</Table.Cell>
					<Table.Cell>{certus.share_key}</Table.Cell>
					{expirationDate}
					<Table.Cell>{dateTimeFormatter(certus.created_at)}</Table.Cell>
					<Table.Cell>{certus.user.name}</Table.Cell>
					{status}
					{this.props.sampleCerti && [
						<Table.Cell key={'batch'+index}>{certus.sample.intake_form.batch_number}</Table.Cell>,
						<Table.Cell key={'weight'+index}>{certus.sample.intake_form.sample_weight}</Table.Cell>,
						<Table.Cell key={'date'+index}>{dateTimeFormatter(certus.sample.created_at)}</Table.Cell>
					]}
				</Table.Row>
			)
		})

		const colSpan = this.props.sampleCerti ? 11 : 8

		return (
			<section className="certusSelectorTable">
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Select <span style={{color: '#db2828'}}>*</span> </Table.HeaderCell>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>VERISEAL</Table.HeaderCell>
							<Table.HeaderCell>TRACER</Table.HeaderCell>
							<Table.HeaderCell>Expiration</Table.HeaderCell>
							<Table.HeaderCell>Created Date</Table.HeaderCell>
							<Table.HeaderCell>Created By</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							{this.props.sampleCerti &&
								[
								<Table.HeaderCell key="batch">Batch Number</Table.HeaderCell>,
								<Table.HeaderCell key="sample_weight">Sample Weight (mg)</Table.HeaderCell>,
								<Table.HeaderCell key="sample_date">Sample Created</Table.HeaderCell>
								]
							}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows}
					</Table.Body>
					{Object.keys(this.props.meta).length > 0 &&
						<Table.Footer>
							<Table.Row colSpan={colSpan} size={window.screen.width < 768 ? 'mini' : 'medium'}>
								<Table.HeaderCell colSpan={colSpan}>
									<Menu floated='right' className={window.screen.width < 768 ? 'menuPagination' : ''} pagination>
										<Menu.Item as='a' >
											<Input label={'Page No'} size='mini'
												value={this.props.meta.currentPageNo}
												name='pageNo' 
												min={'1'}
												max={this.props.meta.lastPageNo.toString()}
												type='number'
												onChange={(e) => { this.props.jumpPage(e.target.value) }}
												onBlur={(e) => this.props.getCertiRecords(e.target.value)} />
										</Menu.Item>
										<Menu.Item as='a' icon disabled={this.props.meta.currentPageNo === 1 ? true : false} onClick={() => {
											this.props.getCertiRecords(this.props.meta.firstPageNo)
											}}>
											<Icon name='angle double left' size={window.screen.width < 768 ? 'small' : 'large'} />
										</Menu.Item>
										<Menu.Item as='a' icon disabled={this.props.meta.currentPageNo === 1 ? true : false} onClick={() => {
											this.props.getCertiRecords(this.props.meta.previousPageNo)
											}}>
											<Icon name='chevron left' size={window.screen.width < 768 ? 'small' : 'large'} />
										</Menu.Item>
										<Menu.Item as='a' icon>
											{this.props.meta.currentPageNo}/ {this.props.meta.lastPageNo}
										</Menu.Item>
										<Menu.Item as='a' icon disabled={this.props.meta.currentPageNo === this.props.meta.lastPageNo} onClick={() => {
											this.props.getCertiRecords(this.props.meta.nextPageNo)
											}}>
											<Icon name='chevron right' size={window.screen.width < 768 ? 'small' : 'large'} />
										</Menu.Item>
										<Menu.Item as='a' icon disabled={this.props.meta.currentPageNo === this.props.meta.lastPageNo} onClick={() => {
											this.props.getCertiRecords(this.props.meta.lastPageNo)
											}}>
											<Icon name='angle double right' size={window.screen.width < 768 ? 'small' : 'large'} />
										</Menu.Item>
									</Menu>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					}
				</Table>
			</section>
		)
	}
}

export default CertusSelectorTable
