import React, { Component } from 'react'
import { Breadcrumb, Form, Button, Popup, Card, Message, Icon, Checkbox, Header, Grid, Input } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { HexColorPicker } from 'react-colorful'

import AppHeader from './AppHeader'
import ErrorMessages from './Errors/ErrorMessages'
import cannidAPI from '../cannidAPI/client'

class CreateReportMethod extends Component {
	constructor(props) {
		super(props)
		this.state = {
			default: false,
			method_name: '',
			analytes: [],
			template: null,
			test_methods: [],
			derived_calculated: {},
			apiErrors: {},
			errors: {},
			clearDerived: {},
			upper_bounds: 50,
			lower_bounds: 1
		}
		this.mounted = false
	}

	componentDidMount() {
		this.mounted = true
		// get all org and global methods, ignoring global methods if overridden
		cannidAPI.get('/methods').then((response) => {
			if (this.mounted) {
				const derived_calculated = {}
				const test_methods = response.data.map((method) => {
					const compiledAnalytes = method.ordered_names.map((name) => {
						let fixedAnalyte
						method.analytes.map((analyte) => {
							if (analyte.analyte === name) {
								fixedAnalyte = {
									analyte: name,
									color: `#${analyte.color}`,
									show: false
								}
							}
						})
						return fixedAnalyte
					})
					if (method.derived_calculated) {
						method.derived_calculated.map((calc) => {
							derived_calculated[calc.name] = { ...calc, ...{ checked: false } }
						})
					}
					return {
						id: method.id,
						default: method.default,
						method_name: method.method_name,
						analytes: compiledAnalytes
					}
				})
				this.setState({ test_methods, derived_calculated, clearDerived: { ...derived_calculated } })
			}
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. Failed to retrieve methods.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	componentWillUnmount() {
		this.mounted = false
	}

	updateForm = (e) => {
		const newState = { errors: {} }
		newState[e.target.name] = e.target.value
		this.setState(newState)
	}

	getErrors = () => {
		const errors = {}
		const re = /^[\w\d-_]+$/
		if (!this.state.method_name) {
			errors.method_name = 'Must include Report Method name.'
		}

		if (this.state.analytes.length <= 0) {
			errors.analytes = 'Report Method must have analytes.'
		}
		else {
			const unique = []
			this.state.analytes.map((analyte) => {
				if (!re.test(analyte.analyte)) {
					errors.analytes = 'Analyte name can only contain letters, numbers, dashes, and underscores.'
				}
				if (!analyte.analyte) {
					errors.analytes = 'All analytes must have a name.'
				}
				if (unique.includes(analyte.analyte)) {
					errors.analytes = 'All analyte names must be unique.'
				}
				else { unique.push(analyte.analyte) }
			})
		}

		if (parseFloat(this.state.lower_bounds) && parseFloat(this.state.upper_bounds)) {
			if (parseFloat(this.state.lower_bounds) > parseFloat(this.state.upper_bounds)) {
				errors.lower_bounds = 'Upper must be greater than lower bounds.'
			}
		}
		else {
			if (parseFloat(this.state.lower_bounds)) {
				errors.lower_bounds = 'A positive number is required.'
			}
			if (parseFloat(this.state.upper_bounds)) {
				errors.upper_bounds = 'A positive number is required.'
			}
		}

		if ((parseFloat(this.state.lower_bounds) > 999999999) || (parseFloat(this.state.lower_bounds) < 0)) {
			errors.lower_bounds = 'Lower bounds must be between 0 and 999,999,999.'
		}

		if ((parseFloat(this.state.upper_bounds) > 999999999) || (parseFloat(this.state.upper_bounds) < 0)) {
			errors.upper_bounds = 'Upper bounds must be 0 to 999,999,999.'
		}

		if (parseFloat(this.state.upper_bounds) === parseFloat(this.state.lower_bounds)) {
			errors.lower_bounds = 'Upper bounds and lower bounds must be different.'
		}

		return errors
	}

	submitMethod = () => {
		if (!Object.keys(this.getErrors()).length) {
			this.setState({ errors: {}, apiErrors: {} })

			const analyteColors = {}
			const orderedAnalytes = this.state.analytes.map((analyte) => {
				analyteColors[analyte.analyte] = analyte.color.replace('#', '').toUpperCase()
				return analyte.analyte
			})
			const derivedCompounds = []
			Object.keys(this.state.derived_calculated).map((derived) => {
				if (this.state.derived_calculated[derived].checked) {
					return derivedCompounds.push(this.state.derived_calculated[derived])
				}
			})

			const methodJson = {
				ordered_analytes: orderedAnalytes,
				analyteColors,
				method: {
					id: 0,
					name: this.state.method_name,
					default: this.state.default
				},
				derived_compounds: derivedCompounds,
				upper_bounds: this.state.upper_bounds,
				lower_bounds: this.state.lower_bounds
			}

			cannidAPI.post('/methods', methodJson).then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText)
				}
				this.props.history.push('/samples/report-methods')
			}).catch((err) => {
				console.error('error!', err)
				const message = `${err}. Failed to create Report Method.`
				this.setState({ apiErrors: message })
			})
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	setTemplate = (e, { name, value }) => {
		if (value === null) {
			this.setState({ analytes: [], template: null, errors: {}, derived_calculated: this.state.clearDerived })
		}
		else {
			this.state.test_methods.map((method) => {
				if (method.id === value) {
					this.setState({ analytes: method.analytes, template: value, errors: {}, derived_calculated: this.state.clearDerived })
				}
			})
		}
	}

	setColor = (index, hex) => {
		const current = [...this.state.analytes]
		current[index].color = hex
		this.setState({ analytes: current, errors: {} })
	}

	addAnalyte = () => {
		const current = [...this.state.analytes]
		current.push({ analyte: `Analyte-${current.length + 1}`, color: '#000000', show: false })
		this.setState({ analytes: current, errors: {} })
	}

	removeAnalyte = (index, name) => {
		const current = [...this.state.analytes]
		current.splice(index, 1)
		if (current[index]) {
			current[index].show = false
		}
		const stateDerived = { ...this.state.derived_calculated }
		Object.keys(stateDerived).map((derived) => {
			if (name === stateDerived[derived].primary_analyte || name === stateDerived[derived].acid_analyte) {
				stateDerived[derived].checked = false
			}
		})
		this.setState({ analytes: current, derived_calculated: stateDerived, errors: {} })
	}

	updateAnalyteName = (e, index) => {
		const stateDerived = { ...this.state.derived_calculated }
		Object.keys(stateDerived).map((derived) => {
			if (this.state.analytes[index].analyte === stateDerived[derived].primary_analyte || this.state.analytes[index].analyte === stateDerived[derived].acid_analyte) {
				stateDerived[derived].checked = false
			}
		})

		const current = [...this.state.analytes]
		current[index].analyte = e.target.value
		this.setState({ analytes: current, derived_calculated: stateDerived, errors: {} })
	}

	moveLeft = (index) => {
		const landing = this.state.analytes[index - 1]
		const takeoff = this.state.analytes[index]
		const current = [...this.state.analytes]
		current[index - 1] = takeoff
		current[index] = landing
		current[index].show = false
		this.setState({ analytes: current, errors: {} })
	}

	moveRight = (index) => {
		const landing = this.state.analytes[index + 1]
		const takeoff = this.state.analytes[index]
		const current = [...this.state.analytes]
		current[index + 1] = takeoff
		current[index] = landing
		current[index].show = false
		this.setState({ analytes: current, errors: {} })
	}

	handlePopup = (index, show) => {
		const current = [...this.state.analytes]
		current[index].show = show
		this.setState({ analytes: current, errors: {} })
	}

	attachCalc = (e, calc) => {
		const current = this.state.analytes.map((a) => a.analyte)
		if (!current.includes(calc.primary_analyte) || !current.includes(calc.acid_analyte)) {
			this.setState({ errors: { analytes: `${calc.primary_analyte} and ${calc.acid_analyte} analytes must be present in the Report Method to attach the Calculated Total Potential of ${calc.name}.` } })
		}
		else {
			calc.checked = !calc.checked
			const stateDerived = { ...this.state.derived_calculated }
			stateDerived[calc.name] = calc
			this.setState({ derived_calculated: stateDerived, errors: {} })
		}
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to="/"><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Link to="/test-result/list"><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Link to="/samples/report-methods"><Breadcrumb.Section>Report Methods</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Breadcrumb.Section>Create</Breadcrumb.Section>
			</Breadcrumb>
		)

		const templateOptions = this.state.test_methods.map((test_method, index) => {
			const text = test_method.default ? <span>{test_method.method_name} <span style={{ color: 'rgba(0,0,0,.4)' }}>(Default)</span></span> : test_method.method_name

			return (
				{
					key: `${test_method.id.toString() + index.toString()}`,
					text,
					value: test_method.id
				}
			)
		})
		const clearOption = this.state.template ? [{
			key: 'templateClear',
			text: <span style={{ color: 'rgba(0,0,0,.4)' }}><Icon name='undo' />Clear</span>,
			value: null
		}] : {}
		const FormFields = (
			<Form>
				<Form.Group className='methodFields'>
					<Form.Input
						label='Report Method Name' value={this.state.method_name}
						name='method_name'
						onChange={this.updateForm}
						type='text' className='required-field'
						placeholder='Report Method Name'
						width={8}
						error={this.state.errors.method_name}
					/>
					<Form.Select
						name='template'
						label={<label>Template <i>(Optional)</i></label>}
						value={this.state.template}
						onChange={this.setTemplate}
						options={this.state.template ? [
							...clearOption,
							...templateOptions] : templateOptions}
						placeholder='Start with a Template'
						width={8}
						clearable
					/>
				</Form.Group>
			</Form>
		)

		const analytesEditor = (
			<Card fluid className='analytesEditor'>
				<Card.Content>
					<Card.Meta>
						Add analytes to build a Report Method:&nbsp;
						<Popup
							content='Add Analyte'
							trigger={(
								<Button
									className='dynamicTableActionButton analytePill' icon='plus'
									title='Add Analyte'
									style={{ backgroundColor: '#21ba45' }}
									onClick={() => this.addAnalyte()}
								/>
							)}
						/>
						<span style={{ color: '#db2828', float: 'right' }}>*</span>
					</Card.Meta>
					<Card.Description>
						{this.state.analytes.map((analyte, anal_index) => (
							<Popup
								key={anal_index}
								content={(
									<div className='createAnalyteContainer'>
										<Form.Input
											value={analyte.analyte}
											onChange={(e) => this.updateAnalyteName(e, anal_index)}
											type='text' className='required-field'
											placeholder='Analyte Name'
										/>
										<HexColorPicker color={analyte.color} onChange={(hex) => this.setColor(anal_index, hex)} />
										<div className='buttonsContainer'>
											<Popup
												content='Move Left' size='tiny' trigger={
													<Button className='gray dynamicTableActionButton moveLeft' icon='arrow left' size='tiny' onClick={() => this.moveLeft(anal_index)} disabled={anal_index < 1} />
												}
											/>
											<Popup
												content='Move Right' size='tiny' trigger={
													<Button className='gray dynamicTableActionButton moveRight' icon='arrow right' size='tiny' onClick={() => this.moveRight(anal_index)} disabled={(anal_index + 1) === this.state.analytes.length} />
												}
											/>
											<Popup
												content={`Remove ${analyte.analyte}`} size='tiny' trigger={
													<Button className='red dynamicTableActionButton removeButton' icon='remove' size='tiny' onClick={() => this.removeAnalyte(anal_index, analyte.analyte)} />
												}
											/>
										</div>
									</div>
								)}
								on='click'
								pinned
								open={this.state.analytes[anal_index].show}
								onClose={() => this.handlePopup(anal_index, false)}
								onOpen={() => this.handlePopup(anal_index, true)}
								trigger={(
									<Button
										className='dynamicTableActionButton analytePill'
										title={`Customize - ${analyte.analyte}`}
										style={{ backgroundColor: analyte.color }}
									>
										{analyte.analyte}
									</Button>
								)}
							/>
						))}
						{this.state.errors.analytes
	&& <Message error>{this.state.errors.analytes}</Message>}
					</Card.Description>
				</Card.Content>
				{this.state.analytes.length > 0
&& (
	<Card.Content extra textAlign='left'>
		<Popup
			content='Clear All'
			trigger={<a onClick={() => this.setState({ analytes: [], template: null, errors: {}, derived_calculated: this.state.clearDerived })}><Icon name='undo' />Clear All</a>}
		/>
	</Card.Content>
)}
			</Card>
		)

		const attachCalculated = (
			<Grid>
				{Object.keys(this.state.derived_calculated).map((compound, index) => {
					const calc = { ...this.state.derived_calculated[compound] }
					return (
						<Grid.Row key={`${compound + index.toString()}`} verticalAlign='bottom' className='attachCalcRow'>
							<Grid.Column width={8}>
								<span><i>{calc.primary_analyte} + ({calc.acid_analyte} * {calc.acid_ratio_conversion_factor})</i></span>
							</Grid.Column>
							<Grid.Column width={4}>
								<strong>{compound}</strong>
							</Grid.Column>
							<Grid.Column width={4}>
								<Checkbox
									toggle
									checked={calc.checked}
									onClick={(e) => this.attachCalc(e, calc)}
								/>
							</Grid.Column>
						</Grid.Row>
					)
				})}
			</Grid>
		)

		const bounds = (
			<Form>
				<Form.Group style={{ justifyContent: 'center' }}>
					<Form.Field
						inline
						control={Input}
						label='Upper Bounds'
						value={this.state.upper_bounds}
						placeholder={this.state.upper_bounds}
						name='upper_bounds'
						onChange={this.updateForm}
						type='number'
						error={this.state.errors.upper_bounds}
					/>
				</Form.Group>
				<Form.Group style={{ justifyContent: 'center' }}>
					<Form.Field
						inline
						control={Input}
						label='Lower Bounds'
						value={this.state.lower_bounds}
						placeholder={this.state.lower_bounds}
						name='lower_bounds'
						onChange={this.updateForm}
						type='number'
						error={this.state.errors.lower_bounds}
					/>
				</Form.Group>
			</Form>
		)

		return (
			<section id='mainContent' className='app light createMethodPage'>
				<AppHeader title={<h1>Create Report Method</h1>} breadcrumb={breadcrumb} />
				<section className='app light'>
					<ErrorMessages errors={this.state.apiErrors} />
				</section>

				<section className='app light'>
					{FormFields}
					{analytesEditor}
					<Grid columns='equal' verticalAlign='middle' stackable>
						{Object.keys(this.state.derived_calculated).length > 0
&& (
	<Grid.Column>
		<Card fluid className='analytesEditor'>
			<Card.Content>
				<Card.Meta>Calculated Total Potential</Card.Meta>
				<Card.Description>
					{attachCalculated}
				</Card.Description>
			</Card.Content>
		</Card>
	</Grid.Column>
)}
						<Grid.Column>
							<Card
								fluid
								style={Object.keys(this.state.derived_calculated).length > 0
									? {} : { maxWidth: '50%', margin: '1em auto' }}
							>
								<Card.Content>
									<Card.Meta>
										Set Method Concentration Range (ug/mL)
									</Card.Meta>
									<Card.Description>
										{bounds}
									</Card.Description>
								</Card.Content>
							</Card>
							<Checkbox
								toggle
								checked={this.state.default}
								onClick={() => this.setState({ default: !this.state.default, errors: {} })}
								label='Set as Default'
							/>
							<Button className='blue submitMethodButton' type='submit' onClick={this.submitMethod}>Save</Button>
						</Grid.Column>
					</Grid>
					<Header as='h5' textAlign='right'>
						<span style={{ color: '#db2828' }}>*</span> Required
					</Header>
				</section>
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

export default connect(
	mapStateToProps
)(withRouter(CreateReportMethod))
