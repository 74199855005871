import React, { Component } from 'react'
import { Breadcrumb, Form, Button, Popup, Message, Icon, Grid, Header, Checkbox } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import cannidAPI from '../../cannidAPI/client'
import ParsedCsvDisplay from './ParsedCsvDisplay'
import dateTimeFormatter from '../../lib/dateTimeFormatter'

class ImportTemplateEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			default: false,
			template: undefined,
			name: '',
			global: false,
			templateType: undefined,
			// template type options setup as select dropdown for future Batch option
			templateTypeOptions: [],
			apiErrors: {},
			errors: {}
		}
	}

	componentDidMount() {
		this.getImportTemplate()
		this.importTemplateTypes()
	}

	getImportTemplate = () => {
		cannidAPI.get(`/import_templates/${this.props.match.params.id}`).then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			return response.data
		})
			.then((template) => {
				this.setState({
					template,
					name: template.name,
					global: template.global,
					templateType: template.import_template_type.id,
					default: template.default
				})
			})
			.catch((err) => {
				console.error('error!', err)
				const message = `${err}. Could not retrieve import template. Reload page.`
				this.setState({ apiErrors: message })
			})
	}

	importTemplateTypes = () => {
		cannidAPI.get('/import_template_types').then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			return response.data
		})
			.then((types) => {
				const options = types.map((type) => ({
					key: type.slug,
					text: type.name,
					value: type.id
				}))
				this.setState({ templateTypeOptions: options })
			})
			.catch((err) => {
				console.error('error!', err)
				const message = `${err}. Could not retrieve template types. Reload page to try again.`
				this.setState({ apiErrors: message })
			})
	}

	updateForm = (e) => {
		this.setState({ [e.target.name]: e.target.value })
	}

	updateFormSelect = (e, { name, value }) => {
		this.setState({ [name]: value })
	}

	getErrors = () => {
		const errors = {}
		if (!this.state.name) {
			errors.name = 'Must include Import Template name.'
		}
		return errors
	}

	submitEdits = () => {
		if (!Object.keys(this.getErrors()).length) {
			this.setState({ errors: {}, apiErrors: {} })

			const updateParams = {
				global: this.state.template.global,
				name: this.state.name,
				import_template_type_id: this.state.templateType,
				default: this.state.default
			}

			cannidAPI.patch(`/import_templates/${this.state.template.id}`, updateParams).then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText)
				}
				return response.data
			})
				.then(() => {
					this.props.history.push('/samples/import/templates')
				})
				.catch((err) => {
					console.error('update error!', err)
					const message = `${err}. Failed to update import template.`
					this.setState({ apiErrors: message })
				})
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/test-result/list'><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/samples/import'><Breadcrumb.Section>Import New Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/samples/import/templates'><Breadcrumb.Section>Templates</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>Edit</Breadcrumb.Section>
			</Breadcrumb>
		)

		const FormFields = this.state.template ? (
			<Form>
				<Form.Group className='templateEditFields'>
					<Form.Select
						search
						selection
						label='Template Type'
						value={this.state.templateType}
						name='templateType'
						onChange={this.updateFormSelect}
						className='required-field'
						placeholder='Template Type'
						error={this.state.errors.templateType}
						options={this.state.templateTypeOptions}
						disabled
					/>
					<Form.Input
						label='Template Name'
						value={this.state.name}
						name='name'
						onChange={this.updateForm}
						type='text'
						className='required-field'
						placeholder='Template Name'
						error={this.state.errors.name}
						maxLength={50}
						disabled={this.state.global}
					/>
				</Form.Group>
			</Form>
		) : ''

		return (
			<section id='mainContent' className='app light editImportTemplatePage'>
				<AppHeader title={<h1>Edit Import Template</h1>} breadcrumb={breadcrumb} />
				<section className='app light'>
					<ErrorMessages errors={this.state.apiErrors} />
				</section>

				<section className='app light'>
					<div className='templateEditForm'>
						{FormFields}
					</div>
					<Header as='h5'>
						<Grid columns={2}>
							{this.state.template &&
								<Grid.Column textAlign='left'>
									Custom: {this.state.template.global ? '❌' : '✅'}
									<br/>
									Created: {dateTimeFormatter(this.state.template.created_at)}
								</Grid.Column>
							}
							<Grid.Column style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
								<span style={{ color: '#db2828' }}>*</span> Required
							</Grid.Column>
						</Grid>
					</Header>
				</section>
				{this.state.template
					&& (
						<section className='app light'>
							<Header as='h3'>Original Mapped Upload</Header>
							<div className='templateOriginal'>
								<ParsedCsvDisplay
									parsed={this.state.template.original}
									sourceFilename={this.state.template.source_filename}
									sourceFile={this.state.template.source_file}
									mappingTemplate={this.state.template}
									batchTemplate={this.state.templateType === 2}
								/>
							</div>
						</section>
					)
				}
				<section className='app light'>
					{!this.state.global
						&& (
							<div>
								<Checkbox
									toggle
									checked={this.state.default}
									onClick={ () => this.setState((prevState) => ({ default: !prevState.default })) }
									label='Set as Default'
									style={{ marginBottom: '1em' }}
								/>
							</div>
						)
					}
					<Button
						className='blue submitMethodButton'
						type='submit'
						onClick={this.submitEdits}
						disabled={this.state.global}
					>
						Update
					</Button>
					{this.state.global
						&& (
							<Popup
								content={(
									<Message attached='bottom' warning>
										Import Template editing only available for &quot;Custom&quot; methods.
									</Message>
								)}
								size='tiny'
								trigger={<Icon name='exclamation triangle' size='large' />}
							/>
						)
					}
				</section>
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

ImportTemplateEdit.propTypes = {
	history: PropTypes.objectOf(PropTypes.shape({
		push: PropTypes.func.isRequired
	})).isRequired,
	match: PropTypes.objectOf(PropTypes.shape({
		params: PropTypes.objectOf(PropTypes.shape({
			id: PropTypes.string.isRequired
		}))
	})).isRequired
}.isRequired

export default connect(
	mapStateToProps
)(withRouter(ImportTemplateEdit))
