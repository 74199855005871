import React, { Component } from 'react'
import { useDispatch } from 'react-redux'
import ilLogo from '../../images/cannid-white-logo.png'
import hamburger from '../../images/hamburger.svg'
import { Link } from 'react-router-dom'

function HamburgerAndLogo(props) {
  const dispatch = useDispatch()
  return (
    <div className="header-hamburgerAndLogo">
      <img src={hamburger} onClick={() => (dispatch({type: "TOGGLE_SIDEBAR_MENU"}))} className="menuIcon" />
      <Link to="/">
        <img src={ilLogo} />
      </Link>
    </div>
  )
}

export default HamburgerAndLogo
