import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import PrepMethodCannIdTile from './PrepMethodCannIdTile'
import PrepMethodEditTile from './PrepMethodEditTile'
import cannidAPI from '../cannidAPI/client'

class PrepMethodsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing_prep_method_id: undefined,
      prep_methods: []
    }
    this.mounted = false
  }

  setEditingMethod = (editing_prep_method_id) => {
    this.setState({editing_prep_method_id})
  }

  cancel = () => {
    this.setEditingMethod(null)
  }

  componentDidMount() {
    this.mounted = true
    cannidAPI.get('/prep_methods').then((res) => {
      // console.log(res.data)
      if (this.mounted) {
        this.setState({prep_methods: res.data})
      }
    }).catch((err) => {
      console.log("couldn't get prep methods:")
      if (this.mounted) {
        console.log(err)
      }
    })
  }

  componentWillUnmount () {
    this.mounted = false
  }

  render() {
    return (
      <div id="prepMethodPage">
        <section id="mainContent" className="app light">
          <section className="app">
            <header>
              <h2>Cann-ID Prep Methods</h2>
            </header>
            <div>
              {this.state.prep_methods.filter(pm => !pm.editable).map(pm => <PrepMethodCannIdTile key={pm.name} prepMethod={pm} />)}
            </div>
          </section>
          {/* <section className="app">
            <header>
              <h2>Custom Prep Methods</h2>
            </header>
            <div>
              {
                this.state.prep_methods.filter(pm => pm.editable).map((pm) => {
                  if (pm.id === this.state.editing_prep_method_id) {
                    return <PrepMethodEditTile cancel={this.cancel} prepMethod={pm} updateEditingMethod={this.updateEditingMethod} saveEditingMethod={this.saveEditingMethod} />
                  } else {
                    return <PrepMethodTile key={pm.name} prepMethod={pm} setEditingMethod={this.setEditingMethod} />
                  }
                })
              }
            </div>
            <div>
            </div>
          </section> */}
        </section>
      </div>
    )
  }
}

export default PrepMethodsPage
