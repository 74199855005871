import axios from '../axios'
import config from '../config'
import constants from '../lib/constants'
export const GraphServices = {
  getAllGraphs,
  getGraphTypes,
  getGraphAttributes,
  getGraphScales,
  createGraph,
  getGraphDataPreview,
  updateGraph,
  getGraphConfigurations,
  getGraphData,
  deleteGraph,
  getPdfReportDownloadUrl,
  sendEmailReportPDF
}
function getAllGraphs() {
  return axios().get(config.api_root + 'analytical-reports?no_pagination=true&sortOrder=DESC&sortColumn=id')
}
function getGraphConfigurations(id) {
  return axios().get(config.api_root + 'analytical-reports/' + id)
}
function getGraphData(id) {
  return axios().get(config.api_root + 'analytical-reports/' + id + '/data')
}
async function getGraphTypes() {
  return await axios().get(config.api_root + 'chart-types?no_pagination=true&sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id')
}
async function getGraphAttributes() {
  return await axios().get(config.api_root + 'chart-attributes?no_pagination=true&sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id')
}
async function getGraphScales() {
  return await axios().get(config.api_root + 'chart-scales?no_pagination=true&sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id')
}
function createGraph(requestData) {
  return axios().post(config.api_root + '/analytical-reports', requestData)
}
function getGraphDataPreview(requestData) {
  return axios().post(config.api_root + '/analytical-reports?preview=true', requestData)
}
function updateGraph(id, requestData) {
  return axios().patch(config.api_root + `/analytical-reports/${id}`, requestData)
}
function deleteGraph(id) {
  return axios().delete('/analytical-reports/' + id)
}
function getPdfReportDownloadUrl(id) {
  return config.api_root + `analytical-reports/${id}/download`
}
function sendEmailReportPDF(requestData, id) {
  return axios().post(config.api_root + `analytical-reports/${id}/share`, requestData)
}
