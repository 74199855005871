import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Table, Button, Popup, Grid, Dropdown, Segment, Icon, Tab, Input, Message, Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import ImportResultsIntakeFields from './ImportResultsIntakeFields'
import ParsedCsvDisplay from './ParsedCsvDisplay'
import ChromeUploader from '../ChromeUploader'
import PreparedSamplesInput from '../PreparedSamplesInput'
import ChromatogramLineChart from '../Charts/ChromatogramLineChart'
import FileDropzone from '../FileDropzone'

function ImportResultsTable(props) {
	const [currentPreview, setPreview] = useState(null)
	const [previewVisible, togglePreview] = useState(false)

	const setPreviewState = (uuid) => {
		if (currentPreview === uuid || uuid === null) {
			setPreview(null)
			togglePreview(false)
		}
		else {
			setPreview(uuid)
			togglePreview(true)
		}
	}

	const removeResult = (index) => {
		setPreviewState(null)
		props.removeResult(index)
	}

	const resultRows = props.results.map((result, resultIndex) => {
		const metadataPreview = (
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Metadata Point</Table.HeaderCell>
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{Object.keys(result.metadata).map((point, index) => {
						const idx = index
						return (
							<Table.Row key={`${idx}${point}`}>
								<Table.Cell>{point}</Table.Cell>
								<Table.Cell>{result.metadata[point]}</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
			</Table>
		)

		const validPreview = (
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Analyte Name</Table.HeaderCell>
						<Table.HeaderCell>Concentration (%)</Table.HeaderCell>
						<Table.HeaderCell>Peak Area</Table.HeaderCell>
						<Table.HeaderCell>Retention Time</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{result.results.map((analyte, index) => {
						const idx = index
						return (
							<Table.Row key={`${idx}${analyte.compound}`}>
								<Table.Cell>{analyte.compound}</Table.Cell>
								<Table.Cell>{analyte.amount}%</Table.Cell>
								<Table.Cell>{analyte.peak_area}</Table.Cell>
								<Table.Cell>{analyte.rt_min}</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
			</Table>
		)

		const invalidPreview = (
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Analyte Name</Table.HeaderCell>
						<Table.HeaderCell>Concentration (%)</Table.HeaderCell>
						<Table.HeaderCell>Peak Area</Table.HeaderCell>
						<Table.HeaderCell>Retention Time</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{result.invalid.map((invalidAnalyte, index) => {
						const analyteDisplay = invalidAnalyte.compound === 'N/A'
							? <span className='ui label orange'>{invalidAnalyte.compound}</span> : invalidAnalyte.compound
						const amountDisplay = invalidAnalyte.amount === 'N/A' || parseFloat(invalidAnalyte.amount) < 0
							? <span className='ui label orange'>{invalidAnalyte.amount}</span> : invalidAnalyte.amount
						const peakDisplay = invalidAnalyte.peak_area === 'N/A'
							? <span className='ui label orange'>{invalidAnalyte.peak_area}</span> : invalidAnalyte.peak_area
						const idx = index
						return (
							<Table.Row key={`${idx}${invalidAnalyte.compound}`}>
								<Table.Cell>{analyteDisplay}</Table.Cell>
								<Table.Cell>{amountDisplay}%</Table.Cell>
								<Table.Cell>{peakDisplay}</Table.Cell>
								<Table.Cell>{invalidAnalyte.rt_min}</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
			</Table>
		)

		const chromeDropdown = (
			<Dropdown
				selection
				compact
				value={result.chromeRtUnits}
				options={[
					{
						text: 'Minutes',
						value: 'Minutes'
					},
					{
						text: 'Seconds',
						value: 'Seconds'
					}
				]}
				onChange={(e, { value }) => props.updateResult(result.uuid, { chromeRtUnits: value })}
			/>
		)

		const chromeDisplay = result.chromeUrl
			? (
				<div>
					<div style={{ marginBottom: '1rem' }}><span>Retention Time Units:</span> {chromeDropdown}</div>
					<ChromatogramLineChart
						openOnLoad
						{
							...{
								sample: {
									import: true,
									results: [result],
									prepared_samples: [props.preparedSamples[resultIndex]],
									intake_form: { strain_name: '' }
								}
							}
						}
					/>
				</div>
			)
			: (
				<ChromeUploader
					result={result}
					updateResult={(info) => { props.updateResult(result.uuid, info) }}
					functionalError={(error) => { props.functionalError(error) }}
				/>
			)

		const attachmentsPresent = (props.attachments[resultIndex] && props.attachments[resultIndex].length > 0)
		const attachmentsDisplay = (
			<div style={attachmentsPresent ? { minHeight: 350 } : {}}>
				{attachmentsPresent &&
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell>Attachment Type</Table.HeaderCell>
								<Table.HeaderCell>Filename</Table.HeaderCell>
								<Table.HeaderCell>Format</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{props.attachments[resultIndex].map((attachment, attachmentIndex) => {
								return (
									<Table.Row key={`${attachment.file.name}${attachmentIndex}`}>
										<Table.Cell>
											<Popup
												content='Remove' size='tiny' trigger={(
													<Button
														className='red dynamicTableActionButton'
														type='submit'
														icon='x'
														size='tiny'
														onClick={() => props.removeAttachment(resultIndex, attachmentIndex)}
													/>
												)}
											/>
										</Table.Cell>
										<Table.Cell>
											<Dropdown
												style={{width: '100%'}}
												selection
												value={attachment.type}
												options={props.attachmentOptions}
												onChange={(e, { value }) => props.updateAttachment(resultIndex,
													attachmentIndex,
													value,
													attachment.file.name
												)}
												onClick={props.clearErrors}
												error={(props.errors.attachments
													&& Object.keys(props.errors.attachments).includes(resultIndex.toString())
													&& props.errors.attachments[resultIndex] === attachmentIndex)}
											/>
										</Table.Cell>
										<Table.Cell>{attachment.file.name}</Table.Cell>
										<Table.Cell>{attachment.file.type}</Table.Cell>
									</Table.Row>
								)
							})}
						</Table.Body>
					</Table>
				}
				<FileDropzone handleFile={(file) => props.addAttachment(resultIndex, file)} />
			</div>
		)

		const chromeTabContent = result.chromeUrl ? '✅' : '❌'
		const panes = [
			{ menuItem: isMobile ? { icon: 'area chart', key: 'chromatogram', content: chromeTabContent }
				: `Chromatogram ${chromeTabContent}`,
			render: () => <Tab.Pane>{chromeDisplay}</Tab.Pane> },
			{ menuItem: isMobile ? { icon: 'list alternate', key: 'metadata' } : 'Metadata',
				render: () => <Tab.Pane>{metadataPreview}</Tab.Pane> },
			{ menuItem: isMobile ? { icon: 'checkmark box', key: 'valid' } : 'Valid Analytes',
				render: () => <Tab.Pane>{validPreview}</Tab.Pane> },
			{ menuItem: isMobile ? { icon: 'times rectangle', key: 'invalid' } : 'Invalid Analytes',
				render: () => <Tab.Pane>{invalidPreview}</Tab.Pane> },
			{ menuItem: isMobile ? { icon: 'file excel', key: 'csv' } : 'CSV',
				// es-lint bug isn't seeing prop validation for parsedCsvs
				render: () => <Tab.Pane><ParsedCsvDisplay parsed={props.singleFile ? props.parsedCsvs[0] : props.parsedCsvs[resultIndex]} /></Tab.Pane> }, // eslint-disable-line react/prop-types
			{ menuItem: isMobile ? { icon: 'attach', key: 'attachments' } : `Attachments (${props.attachments.length ? props.attachments[resultIndex].length : 0})`,
				render: () => <Tab.Pane style={{overflow: 'initial'}}>{attachmentsDisplay}</Tab.Pane> }
		]

		return (
			<Segment key={result.uuid} id={`importPrev${result.uuid}`} className='previewSegment'>
				{props.batchTemplate &&
					<div className='intakeForm>'>
						<Form>
							<ImportResultsIntakeFields
								errors={props.errors}
								index={resultIndex}

								sampleTypes={props.sampleTypes}
								sampleTypeId={props.sampleTypeId[resultIndex]}
								updateSampleType={(value, index) => props.updateSampleType(value, index)}
								intakeForm={props.intakeForm[resultIndex]}
								updateIntakeFormField={(e, index) => props.updateIntakeFormField(e, index)}
								parsedBatch={props.parsedBatch}
								generateBatchNumber={(index) => props.generateBatchNumber(index)}
								resetBatchNumber={(index) => props.resetBatchNumber(index)}
							/>
						</Form>
					</div>
				}
				<Grid stackable>
					<Grid.Column width={14} verticalAlign='middle'>
						<Grid celled='internally' columns='equal' stackable>
							<Grid.Column>
								<Grid.Row className='summaryTop'>
									<strong>
										<Icon name='flask' />
										Vial {resultIndex + 1} of {props.results.length}
									</strong>
								</Grid.Row>
								<Grid.Row className='summaryBottom'>
									<Popup
										content='Remove' size='tiny' trigger={(
											<Button
												className='red dynamicTableActionButton'
												type='submit'
												icon='x'
												size='tiny'
												onClick={() => removeResult(resultIndex)}
											/>
										)}
									/>
									<Popup
										content={previewVisible && currentPreview === result.uuid ? 'Hide Preview' : 'Show Preview'}
										size='tiny'
										trigger={(
											<Button
												className='blue dynamicTableActionButton'
												type='submit' icon={previewVisible && currentPreview === result.uuid ? 'eye slash' : 'eye'}
												size='tiny'
												onClick={() => setPreviewState(result.uuid)}
											/>
										)}
									/>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column>
								<Grid.Row className='summaryTop'>Metadata Points</Grid.Row>
								<Grid.Row className='summaryBottom'>{Object.keys(result.metadata).length}</Grid.Row>
							</Grid.Column>
							<Grid.Column>
								<Grid.Row className='summaryTop'>Valid Analytes</Grid.Row>
								<Grid.Row className='summaryBottom'>
									<span className={result.results.length === 0 ? 'ui label red' : 'ui label green'}>
										{result.results.length} of {result.results.length + result.invalid.length}
									</span>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column>
								<Grid.Row className='summaryTop'>Invalid Analytes</Grid.Row>
								<Grid.Row className='summaryBottom'>
									<span className={result.invalid.length ? 'ui label orange' : ''}>
										{result.invalid.length} of {result.results.length + result.invalid.length}
									</span>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column>
								<Grid.Row className='summaryTop'>Vial Dilution</Grid.Row>
								<Grid.Row className='summaryBottom'>
									<Input
										className='previewVialInput'
										name='dilution_factor'
										placeholder='Dilution'
										type='number'
										value={props.preparedSamples[resultIndex].dilution_factor}
										onChange={(e) => props.updatePreparedSample(resultIndex, {
											[e.target.name]: parseFloat(e.target.value)
										})}
										error={!!((props.errors[resultIndex]
											&& props.errors[resultIndex].dilution_factor))}
									/>
									{(props.errors[resultIndex] && props.errors[resultIndex].dilution_factor)
										&& <Message error>Positive numbers only</Message>
									}
								</Grid.Row>
							</Grid.Column>
							<Grid.Column>
								<Grid.Row className='summaryTop'>Vial Label</Grid.Row>
								<Grid.Row className='summaryBottom'>
									<Input
										className='previewVialInput'
										name='label'
										placeholder='Label'
										value={props.preparedSamples[resultIndex].label}
										onChange={(e) => props.updatePreparedSample(resultIndex, { [e.target.name]: e.target.value })}
									/>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column>
								<Grid.Row className='summaryTop'>Vial Color</Grid.Row>
								<Grid.Row className='summaryBottom'>
									<Dropdown
										selection
										compact
										className='previewVialInput'
										name='vial_type'
										value={props.preparedSamples[resultIndex].vial_type}
										options={[
											{
												text: 'Green',
												value: 'green_1ml'
											},
											{
												text: 'Yellow',
												value: 'yellow_1ml'
											},
											{
												text: 'Blue',
												value: 'blue_1ml'
											},
											{
												text: 'Red',
												value: 'red_1ml'
											}
										]}
										onChange={(e, { name, value }) => props.updatePreparedSample(resultIndex, { [name]: value })}
									/>
								</Grid.Row>
							</Grid.Column>
						</Grid>
					</Grid.Column>
					<Grid.Column width={2}>
						<PreparedSamplesInput
							previousPreppedSamples={[props.preparedSamples[resultIndex]]}
							editable={false}
							disabled
							import
							hideAcquire
						/>
					</Grid.Column>
				</Grid>

				{previewVisible && props.results.length && result.uuid === currentPreview
					&& <Tab panes={panes} style={{ marginTop: '1rem' }} />}
			</Segment>
		)
	})

	return (
		<div>
			{resultRows}
		</div>
	)
}

ImportResultsTable.propTypes = {
	results: PropTypes.array.isRequired,
	preparedSamples: PropTypes.array.isRequired,
	parsedCsvs: PropTypes.array.isRequired,
	functionalError: PropTypes.func.isRequired,
	updateResult: PropTypes.func.isRequired,
	removeResult: PropTypes.func.isRequired,
	updatePreparedSample: PropTypes.func.isRequired
}.isRequired

export default ImportResultsTable
