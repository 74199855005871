import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
	Table, Accordion, Segment, Icon
} from 'semantic-ui-react'

const EQUATIONS_CONFIG = {
	CBC: '% of CBC Total = % of CBC + (% of CBCA x 0.877)',
	CBD: '% of CBD Total = % of CBD + (% of CBDA x 0.877)',
	CBDV: '% of CBDV Total = % of CBDV + (% of CBDVA x 0.867)',
	CBN: '% of CBN Total = % of CBN + (% of CBNA x 0.876)',
	CBG: '% of CBG Total = % of CBG + (% of CBGA x 0.878)',
	THC: '% of THC Total = % of THCD9 + (% of THCA x 0.877)'
}

function compoundPercentIsEmpty(compoundPercent) {
	return compoundPercent === 'N/D' || compoundPercent === '< LOQ'
}

class DerivedCompoundsTotals extends Component {
	constructor(props) {
		super(props)
		this.state = { showDropdown: false }
	}

	// Sort compounds by highest to lowest percent concentration
	getSortedCompoundNames() {
		const derivedCompounds = this.props.derivedCompounds

		return Object.keys(derivedCompounds).sort((compoundA, compoundB) => {
			const percentA = derivedCompounds[compoundA].percent
			const percentB = derivedCompounds[compoundB].percent

			const compoundAEmpty = compoundPercentIsEmpty(percentA)
			const compoundBEmpty = compoundPercentIsEmpty(percentB)

			if (compoundAEmpty && compoundBEmpty) {
				return 0
			}

			if (compoundAEmpty && !compoundBEmpty) {
				return 1
			}

			if (!compoundAEmpty && compoundBEmpty) {
				return -1
			}

			if (!compoundAEmpty && !compoundBEmpty) {
				return parseFloat(percentB) - parseFloat(percentA)
			}

			return 0
		})
	}

	toggleDropdown = () => {
		this.setState(({ showDropdown }) => ({ showDropdown: !showDropdown }))
	}

	buildMainTableContent() {
		return this.getSortedCompoundNames().map((compound) => {
			const compoundPercent = this.props.derivedCompounds[compound].percent
			const unitSymbol = compoundPercentIsEmpty(compoundPercent) ? '' : '%'

			return (
				<Table.Row key={compound}>
					<Table.Cell>{compound}</Table.Cell>
					<Table.Cell>
						{`${compoundPercent}${unitSymbol}`}
					</Table.Cell>
				</Table.Row>
			)
		})
	}

	buildEquationsList() {
		const sortedCompoundNames = this.getSortedCompoundNames()
		const equationElements = sortedCompoundNames.map((compound) => <Segment size='tiny' key={compound}>{EQUATIONS_CONFIG[compound]}</Segment>)

		return <div>{ equationElements }</div>
	}

	render() {
		const { showDropdown } = this.state

		return (
			<Table celled style={{ textAlign: 'center', width: '75%', margin: '0 auto' }}>
				<Table.Header>
					<Table.Row><Table.HeaderCell colSpan='2'>Calculated Total Potential</Table.HeaderCell></Table.Row>
				</Table.Header>
				<Table.Body>
					{this.buildMainTableContent()}
				</Table.Body>
				<Table.Footer>
					<Table.Row>
						<Table.HeaderCell colSpan='2'>
							<Accordion>
								<Accordion.Title active={showDropdown} onClick={this.toggleDropdown}>
									<Icon name='dropdown' />
									Equations Used
								</Accordion.Title>
								<Accordion.Content active={showDropdown}>
									{this.buildEquationsList()}
								</Accordion.Content>
							</Accordion>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
		)
	}
}

DerivedCompoundsTotals.propTypes = {
	derivedCompounds: PropTypes.objectOf(PropTypes.shape({
		percent: PropTypes.string.isRequired
	})).isRequired
}

export default DerivedCompoundsTotals
