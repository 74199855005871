import React, { Component, useState } from 'react'
import { 
  Button, 
  Input } from 'semantic-ui-react'
import { connect } from 'react-redux'

const channelMap = {
  'A1': 'A',
  'A2': 'B',
  'B1': 'C',
  'B2': 'D'
}

function RenderWidget({ bottlePercent, bottleLimit, bottle, numBottles, hideInputs }) {
  let [botlmt, setLmt] = useState(bottleLimit)
  let [botremain, setCurrent] = useState(bottleLimit * bottlePercent * .01)
  const handleChange = () => {
    if( botremain > botlmt ) {
      return false
    }
    if(numBottles > 2 ) {
      bottle = channelMap[bottle]
    }
    const request = new Request(process.env.REACT_APP_DRIVER_HTTP_ROOT + '/machine/bottle', {
      method: 'POST',
      body: JSON.stringify({
        bottle : bottle,
        botremain : botremain,
        size : botlmt
      }),
      headers: new Headers({
        'Content-Type': 'application/json'
      })

    })
    fetch(request)
      .then((response) => {
      })

  }
  let headerOutput
  let bottleColor = "green"
  let renderID = bottle + "lg"
  let textPosition = bottlePercent < 100? "170" : "135"
  if(bottlePercent < 20) {
    bottleColor = "red"
    headerOutput = <div className = "bottleWarning">
        <h5>Warning Bottle {bottle} Low</h5>
      </div>
  } else {
    if(bottlePercent < 50) {
      bottleColor = "yellow"
    }
    headerOutput = <div>
        <h5>Bottle {bottle}</h5>
      </div>

  }

  return (
    <div className={!hideInputs ? "bottleDiv" : "bottleDiv mini"}>
      {headerOutput}
      <div className={!hideInputs ? "botUsage" : "botUsage mini"}>
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 470 470"  width="80px">
         
          <linearGradient id={renderID} x1="0.5" y1="1" x2="0.5" y2="0">
              <stop offset="0%" stopOpacity="1" stopColor= {bottleColor} />
              <stop offset={bottlePercent + "%"} stopOpacity = "1" stopColor = {bottleColor} />
              <stop offset={bottlePercent + "%"} stopOpacity = "0" stopColor = {bottleColor} />
              <stop offset="100%" stopOpacity="0" stopColor = {bottleColor} />
          </linearGradient>
          <path stroke="black" fill={"url(#" + renderID + ")"} strokeWidth="20" d="M287.5,72.768V7.5c0-4.142-3.357-7.5-7.5-7.5h-90c-4.143,0-7.5,3.358-7.5,7.5v65.268c-39.643,19.823-65,60.558-65,105.132   v254.6c0,20.678,16.822,37.5,37.5,37.5h160c20.678,0,37.5-16.822,37.5-37.5V177.9C352.5,133.326,327.143,92.591,287.5,72.768z"/>
          <text className = "heavy" x = {textPosition} y = "310">{bottlePercent + "%"}</text>
        </svg>
        {!hideInputs &&
          <div className = "bottle">
            <Input className = "bottleCurrent" size='mini' type="number" label = {{ basic: true, content : 'Current ml'}}
              onChange = {event => setCurrent(parseInt(event.target.value))}
              labelPosition = 'left'
              value = {botremain}
            />
            <Input className = "bottleLimit" size='mini' type="number" label = {{ basic : true, content : 'Max ml'}}
              onChange = {event => setLmt(parseInt(event.target.value))}
              labelPosition = 'left'
              value = {botlmt}
            />
            <Button className="changeButton"
              onClick = {handleChange}
            >Change {bottle}</Button>
          </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { machineState } = state
  return {machineState}
}


class BottleWidget extends Component {
  render() {
    const machineOnline = (this.props.machineState && Object.keys(this.props.machineState).length > 0 && !["POWEROFF", "disconnect"].includes(this.props.machineState.status))
    let output
    if (machineOnline) {
      output = (
        <div className = "BottleWidget ui padded grid dashboardButtonGroup">
          {!this.props.hideInputs &&
            <div className = "mobilePhaseHeader">
              <h3>Mobile Phase</h3>
            </div>
          }
          {this.props.machineState.bottles.map((value, index) => {
            return (
              <RenderWidget
                bottleLimit = {value.botlmt*1000}
                bottlePercent = {parseInt(value.bot)}
                bottle = {value.channel}
                key = {value.channel}
                numBottles = {this.props.machineState.numBottles}
                hideInputs = {this.props.hideInputs}
              />
            )
          })}
        </div>
      )
    }
    return (
      <div>
        {output}
      </div>
    )
  }
}

export default connect(mapStateToProps)(BottleWidget)
