import React, { Component } from 'react'
import { formatRoute } from 'react-router-named-routes'
import constants from '../../lib/constants'
import { LoginServices } from '../../services/Login'
import decodeJWT from '../../lib/decodeJWT'
import tokenValidity from '../../lib/tokenValidity'
import QrRedirection from '../../lib/QrRedirection'

class SharedQrDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	componentDidMount() {
		if (this.props.match.params.share_key) {
			LoginServices.checkValidQR(this.props.match.params.share_key).then((res) => {
				const decodeTokenResponse = decodeJWT(localStorage.getItem('access_token'))
				const tokenValidityCheck = tokenValidity(decodeTokenResponse.exp)
				if (localStorage.getItem('access_token') && tokenValidityCheck.duration > 0) {
					if (res.data.results !== null) {
						window.location.href = formatRoute(constants.APPLICATION_ROUTE.SAMPLE.SHARE_REPORT.ROUTE, { id: res.data.share_key })
					}
					else if (decodeTokenResponse && decodeTokenResponse.organization && decodeTokenResponse.organization.id && decodeTokenResponse.organization.id === res.data.organization.id) {
						localStorage.setItem('share_key', this.props.match.params.share_key)
						const redirection = QrRedirection(res.data)
						redirection.forceRedirection ? window.location.href = redirection.redirectTo : this.props.history.push(redirection.redirectTo)
					}
					else {
						this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE))
					}
				}
				else if (res.data.results !== null) {
					window.location.href = formatRoute(constants.APPLICATION_ROUTE.SAMPLE.SHARE_REPORT.ROUTE, { id: res.data.share_key })
				}
				else {
					localStorage.setItem('share_key', this.props.match.params.share_key)
					localStorage.setItem('organization_id', res.data.organization.id)
					this.props.history.push(constants.APPLICATION_ROUTE.LOGIN.ROUTE)
				}
			}).catch((error) => {
				this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.PAGE_NOT_FOUND.ROUTE, {}))
			})
		}
	}

	render() {
		return (
			<></>
		)
	}
}
export default SharedQrDetails
