import React, { Component } from 'react'
import { Button, Form, Grid, Header, Segment, Popup, Icon } from 'semantic-ui-react'
import ToastMessage from '../Message'
import { userServices } from '../../services/User'
import constants from '../../lib/constants'
import decodeJWT from '../../lib/decodeJWT'
import ErrorMessages from '../Errors/ErrorMessages'
import passwordStrength from '../../lib/passwordStrength'
import PasswordStrengthMeter from '../../lib/PasswordStrengthMeter'
import trimWhiteSpace from '../../lib/trim'
import passwordValidity from '../../lib/passwordValidity'
import { formatRoute } from 'react-router-named-routes'
class InviteUser extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      firstName: '',
      lastName: '',
      role: '',
      roleName: '',
      organization: '',
      organizationName: '',
      email: '',
      phone: '',
      altEmail: '',
      newPassword: '',
      confirmPassword: '',
      formErrors: {
        firstNameError: false,
        lastNameError: false,
        roleError: false,
        emailError: false,
        altEmailError: false,
        phoneError: false,
        organizationError: false,
        newPasswordError: false,
        confirmPasswordError: false
      },
      userCreated: null,
      errorMesage: '',
      isTokenValid: false,
      showFields: false,
      apiErrors: {}
    }
  }
  componentDidMount() {
    let token = this.props.match.params.token
    let decodeToken = decodeJWT(token)
    let currentTime = Math.floor(Date.now() / 1000)
    if (decodeToken.email && currentTime <= decodeToken.exp) {
      this.setState({
        role: decodeToken.role.id,
        roleName: decodeToken.role.name,
        organization: decodeToken.organization.id,
        organizationName: decodeToken.organization.name,
        email: decodeToken.email,
        isTokenValid: true
      })
    }
    else {
      this.setState({ userCreated: false, isTokenValid: false, errorMesage: constants.USER_SIGN_UP.LINK_EXPIRED, apiErrors: { message: [constants.USER_SIGN_UP.LINK_EXPIRED] } })
    }
  }
  handleInputChange = (e) => {
    let target = e.target
    const newState = {}
    newState[target.name] = target.value
    this.setState(newState, () => {
      this.isfieldValid(target.name)
    })
  }
  checkEmail = (email) => {
    let re = constants.REG_EX.EMAIL_REG_EX
    return !re.test(String(email).toLowerCase())
  }
  checkPhone = (phone) => {
    if (phone.length === 0) {
      return false
    }
    return !phone.match(constants.REG_EX.PHONE_REG_EX)
  }
  checkAltEmail = (altEmail) => {
    if (altEmail.length === 0)
      return false
    if (this.state.email === this.state.altEmail)
      return true
    let re = constants.REG_EX.EMAIL_REG_EX
    return !re.test(String(altEmail).toLowerCase())
  }
  checkPassword = () => {
    if (!this.state.newPassword.match(constants.REG_EX.PASSWORD_REG_EX)) {
      if (this.state.newPassword.length < constants.PASSWORD.MIN_LENGTH) {
        this.setState({ errorMessage: constants.CHANGE_PASSWORD.PASSWORD_STRENGTH })
      }
      else
        this.setState({ errorMessage: constants.CHANGE_PASSWORD.PASSWORD_STRENGTH })
      return true
    }
    else {
      this.setState({ errorMessage: '' })
      return false
    }
  }
  checkConfirmPassword = () => {
    const { confirmPassword, newPassword } = this.state
    if (confirmPassword.length > 0 && confirmPassword === newPassword) {
      return false
    }
    else return true
  }
  isfieldValid = (field) => {
    let tempFormError = { ...this.state.formErrors }
    if (this.state[field] === '') {
      if (field !== 'altEmail')
        tempFormError[`${field}Error`] = true
    }
    else {
      tempFormError[`${field}Error`] = false
    }
    this.setState({
      formErrors: { ...tempFormError }
    }, () => { this.passwordFormValidation() })
  }
  validateForm = () => {
    let tempFormError = { ...this.state.formErrors }
    if (!this.state.firstName) {
      tempFormError.firstNameError = true
    }
    if (!this.state.lastName) {
      tempFormError.lastNameError = true
    }
    if (!this.state.role) {
      tempFormError.roleError = true
    }
    if (!this.state.organization) {
      tempFormError.organizationError = true
    }
    if (this.checkEmail(this.state.email)) {
      tempFormError.emailError = true
    }
    if (this.checkPhone(this.state.phone)) {
      tempFormError.phoneError = true
    }
    if (this.checkAltEmail(this.state.altEmail)) {
      tempFormError.altEmailError = true
    }
    if (this.checkPassword(this.state.newPassword)) {
      tempFormError.newPasswordError = true
    }
    if (this.checkConfirmPassword(this.state.confirmPassword)) {
      tempFormError.confirmPasswordError = true
    }
    this.setState({
      formErrors: {
        ...tempFormError
      }
    })
    for (let key in tempFormError) {
      if (tempFormError[key] === true)
        return false
    }
    return true
  }
  passwordFormValidation = () => {
    let tempFormError = { ...this.state.formErrors }
    let passwordErrors = passwordValidity(this.state.newPassword, this.state.confirmPassword)
    tempFormError['confirmPasswordError'] = passwordErrors.confirmPassword
    tempFormError['newPasswordError'] = passwordErrors.password
    if (tempFormError['newPasswordError']) {
      this.setState({ errorMessage: constants.CHANGE_PASSWORD.PASSWORD_STRENGTH })
    }
    this.setState({
      formErrors: { ...tempFormError }
    })
  }
  onSubmit = () => {
    let formIsValid = this.validateForm()
    if (!formIsValid) {
      return false
    }
    let requestBody = {
      user: {
        name: trimWhiteSpace(this.state.firstName + ' ' + this.state.lastName),
        email: trimWhiteSpace(this.state.email),
        role_id: trimWhiteSpace(this.state.role),
        contact_number: trimWhiteSpace(this.state.phone),
        alternate_email_id: trimWhiteSpace(this.state.altEmail),
        token: trimWhiteSpace(this.props.match.params.token),
        password: this.state.newPassword
      }
    }
    userServices.signupUser(requestBody).then((res) => {
      this.setState({ userCreated: true, errorMesage: constants.USER_SIGN_UP.SUCCESS, apiErrors: {} })
      setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})), 1000)
    }).catch((e) => {
      this.setState({ userCreated: false, errorMesage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} })
    })
  }
  render() {
    let title = constants.USER_SIGN_UP.HEADER_TITLE
    let passwordStrengthCalc = passwordStrength(this.state.newPassword)
    return (
      <Grid id="newUserForm" textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Header as='h2' className='headerColor' textAlign='center'>
            {title}
          </Header>
          {
            this.state.userCreated === true && <ToastMessage title='Success' description={this.state.errorMesage} />
          }
          <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
          {this.state.isTokenValid &&
            <Form>
              <Segment stacked className='segmentPadding'>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                    <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="First Name" value={this.state.firstName}
                      name="firstName" onChange={this.handleInputChange} type="text" className='required-field'
                      placeholder="First Name" error={this.state.formErrors.firstNameError} />
                    {this.state.formErrors.firstNameError && <p className="error">Please fill the first name</p>}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                    <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="Last Name" name="lastName" value={this.state.lastName}
                      onChange={this.handleInputChange} type="text" className='required-field'
                      placeholder="Last Name" error={this.state.formErrors.lastNameError} />
                    {this.state.formErrors.lastNameError && <p className="error">Please fill the last Name</p>}
                  </Grid.Column>
                  {this.state.showFields &&
                    <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                      <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="Email" value={this.state.email}
                        name="email" onChange={this.handleInputChange} type="text"
                        placeholder="E-mail"
                        className='required-field' error={this.state.formErrors.emailError} readOnly={true} />
                      {this.state.formErrors.emailError && <p className="error">Please enter a valid email address</p>}
                    </Grid.Column>
                  }
                  {this.state.showFields &&
                    <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                      <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="Role" value={this.state.roleName}
                        name="roleName" onChange={this.handleInputChange} type="text"
                        placeholder="Role"
                        className='required-field' error={this.state.formErrors.roleError} readOnly={true} />
                      {this.state.formErrors.roleError && <p className="error">Please select the role</p>}
                    </Grid.Column>
                  }
                  {this.state.showFields &&
                    <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                      <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="Organization" value={this.state.organizationName}
                        name="organizationName" onChange={this.handleInputChange} type="text"
                        placeholder="Organization"
                        className='required-field' error={this.state.formErrors.organizationError} readOnly={true} />
                      {this.state.formErrors.organizationError && <p className="error">Please select the organization</p>}
                    </Grid.Column>
                  }
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                    <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="Alternate Email" value={this.state.altEmail}
                      name="altEmail" onChange={this.handleInputChange} type="text"
                      placeholder="Alternate E-mail" error={this.state.formErrors.altEmailError} />
                    {this.state.formErrors.altEmailError && <p className="error">Please fill the valid alternate email</p>}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} >
                    <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="Phone" value={this.state.phone}
                      name="phone" onChange={this.handleInputChange}
                      placeholder="Phone" error={this.state.formErrors.phoneError} />
                    {this.state.formErrors.phoneError && <p className="error">Please fill the valid contact number</p>}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} >
                    <Grid centered>
                      <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                        <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label={<React.Fragment><label>Password <Popup header={constants.CHANGE_PASSWORD.PASSWORD_CRITERIA_TITLE} content={constants.CHANGE_PASSWORD.PASSWORD_STRENGTH} trigger={<Icon name='info circle' color="grey" />} /></label></React.Fragment>} value={this.state.newPassword}
                          name="newPassword" onChange={this.handleInputChange}
                          placeholder="Password" error={this.state.formErrors.newPasswordError} className='required-field' type="password" />
                      </Grid.Column>
                      {
                        passwordStrengthCalc >= 0 &&
                        <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} >
                          <PasswordStrengthMeter score={passwordStrengthCalc} />
                        </Grid.Column>
                      }
                      {
                        this.state.formErrors.newPasswordError && this.state.newPassword.length === 0 && <p className="error">{constants.FORM.MANDATORY_FIELD}</p>
                      }
                    </Grid>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} >
                    <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} label="Confirm Password" value={this.state.confirmPassword}
                      name="confirmPassword" onChange={this.handleInputChange}
                      placeholder="Confirm Password" error={this.state.formErrors.confirmPasswordError} className='required-field' type="password" />
                    {
                      this.state.formErrors.confirmPasswordError && this.state.confirmPassword.length > 0 && <p className="error">{constants.CHANGE_PASSWORD.PASSWORD_MATCH}</p>
                    }
                    {
                      this.state.formErrors.confirmPasswordError && this.state.confirmPassword.length === 0 && <p className="error">{constants.FORM.MANDATORY_FIELD}</p>
                    }
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
                    <Button className='confirmButton' type="submit"
                      onClick={() => this.onSubmit()}>{constants.USER_SIGN_UP.SIGN_UP_BUTTON_TITLE}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Form>
          }
        </Grid.Column>
      </Grid>
    )
  }
}
export default InviteUser
