import React, { Component } from 'react'
import { Breadcrumb, Image, Label, List, Grid, Icon, Menu, Popup} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import cannidAPI from '../../cannidAPI/client'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import certusLogoGreen from '../../images/certus_transparent.png'
import QrCodeDownload from './QrCodeDownload'

const QRCode = require('qrcode.react')

class CertificateOfOriginCertus extends Component {
	constructor(props) {
		super(props)
		this.state = {
			certus: {},
			h1: 'VERISEAL Certificate of Origin',
			halfDetailsIndex: 0,
			firstHalfDetails: [],
			secondHalfDetails: [],
			attachments: [],
			apiErrors: {}
		}
		this.mounted = false
	}

	componentDidMount() {
		this.mounted = true
		// get individual transportation certus
		cannidAPI.get(`/certificate_of_origin/${this.props.match.params.id}`).then((response) => {
			if (this.mounted) {
				const certus = response.data
				const regex = /^\((\d+)*\) /

				const detailKeys = Object.keys(certus.details).filter(k => !k.match(regex) && k != 'TRACER').sort()
				const halfDetailsIndex = Math.ceil(detailKeys.length / 2)
				const firstHalfDetails = detailKeys.slice(0, halfDetailsIndex)
				const secondHalfDetails = detailKeys.slice(halfDetailsIndex)

				this.setState({
					certus,
					h1: certus.project_name,
					halfDetailsIndex: halfDetailsIndex,
					firstHalfDetails: firstHalfDetails,
					secondHalfDetails: secondHalfDetails,
					attachments: Object.keys(certus.details).filter(k => k.match(regex)).sort()
				})
			}
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. Failed to retrieve certus.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	componentWillUnmount() {
		this.mounted = false
	}

	getAWSItem(data) {
		return window.open(data)
	}

	render() {		
		const today = new Date()
		const projectExpiry = this.state.certus ? this.state.certus.revoked
			? <span style={{color:"red"}}>Revoked</span> : (new Date(this.state.certus.expiration_date) < today.getTime())
				? <span style={{color:"red"}}>{this.state.certus.expiration_date}</span> : this.state.certus.expiration_date : ''
		const certusStatus = this.state.certus ? this.state.certus.revoked
			? <span style={{color:"red"}}>Revoked</span> : (new Date(this.state.certus.expiration_date) < today.getTime())
				? <span style={{color:"red"}}>Expired</span> : <span>Valid</span> : ""

		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/certus'><Breadcrumb.Section><div style={{ display: 'flex' }}>CERTUS<Icon name='registered outline' size='tiny' /></div></Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>VERISEAL Certificate Of Origin: {this.state.h1}</Breadcrumb.Section>
			</Breadcrumb>
		)

		return (
			<section id='mainContent' className='app light certusList'>
				<ErrorMessages errors={this.state.apiErrors} />
				<AppHeader title={<h1>{this.state.h1}</h1>} breadcrumb={breadcrumb} />

				{this.state.certus.id && (
					<section className='certusListHeader'>
						<Grid stackable verticalAlign='middle'>
							<Grid.Row columns={2}>
								<Grid.Column>
									<Grid columns={2} stackable verticalAlign='middle' className='descriptorContainer'>
										<Grid.Column>
											<div className='descriptorGrid'>
												<Image className='certusLogo' src={certusLogoGreen} alt='Certus Logo' />
												<h3 style={{ display: 'flex', marginTop: 0, marginLeft: '1rem' }}>VERISEAL<br />Certificate Of Origin</h3>
											</div>
										</Grid.Column>
										<Grid.Column>
											<div className='descriptorGrid'>
												<a className='certusScannerLink' href={process.env.REACT_APP_CERTUS_SCANNER_LINK}>
													<QRCode value={process.env.REACT_APP_CERTUS_SCANNER_LINK} size={75} />
													<Label color='green'>CERTUS<Icon style={{ position: 'relative', bottom: '.35rem' }} name='registered outline' size='tiny' />QR Scanner</Label>
												</a>
											</div>
										</Grid.Column>
									</Grid>
									<List className='detailsList'>
										<List.Item style={{ display: 'flex' }}>
											<strong style={{ display: 'flex' }}>TRACER:</strong>
											&nbsp;{this.state.certus.share_key}
										</List.Item>
										<List.Item><strong>Expiration Date:</strong> {projectExpiry}</List.Item>
										<List.Item><strong>Status:</strong> {certusStatus}</List.Item>
										<List.Item><strong>Issued:</strong> {dateTimeFormatter(this.state.certus.created_at)}</List.Item>
										<List.Item><strong>Created By:</strong> {this.state.certus.user.name}</List.Item>
									</List>
								</Grid.Column>
								<Grid.Column>
									<Grid columns={2} stackable verticalAlign='middle'>
										{' '}
										<Grid.Column>
											<QRCode value={this.state.certus.qr[0]} size={200} />
											<div>
												<span><small style={{ backgroundColor: 'black', color: 'white', paddingTop: '.4em', paddingBottom: '.2em', letterSpacing: '.7em', position: 'relative', fontSize: '1.03em', fontWeight: 600 }}>
													<img width={19} height={18} style={{ marginRight: '4px', marginLeft: '4px' }} src='https://cdn.chemid.com/images/chemid-logo-image.png' alt='chemid.logo' />
													{this.state.certus.share_key}
												</small>
												</span>
												<p>
													<small>
														Result protected with CERTUS
														<Icon style={{ position: 'relative', bottom: '.35rem' }} name='registered outline' size='tiny' /> 
														KSI Blockchain
													</small>
												</p>
												<Popup
													content='Copied TRACER!'
													on='click'
													pinned
													trigger={
														<Menu className='sampleActionsMenu' compact={true} stackable>
															<Popup
																content='Copy TRACER'
																trigger={
																	<Menu.Item
																		onClick={() => { navigator.clipboard.writeText(this.state.certus.share_key) }}>
																		<Icon name='copy' style={{margin: 0}} />
																	</Menu.Item>
																}
															/>
														</Menu>
													}
												/>				
											</div>
										</Grid.Column>
										<Grid.Column>
											<QrCodeDownload tracer={this.state.certus.share_key} originCertus />
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<Grid textAlign='center'>
							<h3>VERISEAL Contents</h3>
							<Grid.Row verticalAlign='top'columns={this.state.attachments.length > 0 ? 3 : 2}>
								<Grid.Column>
									<List className='detailsList' style={{ margin: '2%' }}>
										{this.state.firstHalfDetails.map(detail => <List.Item key={detail}><strong>{detail}:</strong> {this.state.certus.details[detail]}</List.Item>)}
									</List>
								</Grid.Column>
								<Grid.Column>
									<List className='detailsList' style={{ margin: '2%' }}>
										{this.state.secondHalfDetails.map(detail => <List.Item key={detail}><strong>{detail}:</strong> {this.state.certus.details[detail]}</List.Item>)}
									</List>
								</Grid.Column>
								{this.state.attachments.length > 0 &&
									<Grid.Column>
										<List className='detailsList' style={{ margin: '2%' }}>
											{this.state.attachments.map(detail => {
												const urlSplit = this.state.certus.details[detail].split('/')
												return (
													<List.Item key={detail}>
														<strong>{detail.replace('@url-image-url', '').replace('@url-file-url', '')}:</strong>
														&nbsp;
														<a href={this.state.certus.details[detail]} target='_blank'>
															{urlSplit[urlSplit.length - 1]}
														</a>
													</List.Item>
												)
											})}
										</List>
									</Grid.Column>
								}
							</Grid.Row>
						</Grid>
					</section>
				)}
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

export default connect(
	mapStateToProps
)(withRouter(CertificateOfOriginCertus))
