import { interpolateRainbow } from 'd3-scale-chromatic'
import {get} from 'lodash'
import constants from '../lib/constants'
class SampleProcessor {
  constructor(sample) {
    this.sample = sample
  }

  isOverconcentration() {
    const data = Object.values(this.sample.compounds)
    let over = false
    data.forEach((compound) => {
      if (compound.percent >= 97.5) {
        over = true
      }
    })
    return over
  }

  hasResults() {
    const data = Object.values(this.sample.compounds)
    let results = false
    data.forEach((compound) => {
      if (compound.amount != null) {
        results = true
      }
    })
    return results
  }

  runCount() {
    if (this.sample.results) {
      return this.sample.results.length
    } else {
      return 0
    }
  }

  preparedSampleCount() {
    if (this.sample.prepared_samples && this.sample.prepared_samples.length > 0) {
      let acquiringSamples = this.sample.prepared_samples.filter((ps) => (ps.acquire))
      return acquiringSamples.length
    } else {
      return 0
    }
  }

  finished() {
    if (this.sample.prepared_samples && this.sample.prepared_samples.length > 0) {
      return this.runCount() >= this.preparedSampleCount()
    } else if (this.sample.sample_type && this.sample.sample_type.required_injections) {
      return this.runCount() >= this.sample.sample_type.required_injections
    } else {
      return this.runCount() >= 2
    }
  }

  started() {
    return this.runCount() >= 1
  }

  getDominantValue(label) {
    let value = null
    if (this.sample.derived_compounds[label]) {
      value = this.sample.derived_compounds[label]['percent']
    }
    else if (label === null) {
      value = null
    }

    return { label, value }
  }
}

export default SampleProcessor
