import AWS from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid'

async function ImageCopyAWS({ bucket, accessKeyId, secretAccessKey }, url, id) {
	const creds = {
		accessKeyId: accessKeyId,
		secretAccessKey: secretAccessKey,
		region: 'us-east-2'
	}

	const s3 = new AWS.S3(creds)
	const noProto = url.replace('https://', '').replace('http://', '')
	const sourceBucket = noProto.split('.s3.')[0]
	const sourceKey = noProto.split('.amazonaws.com').pop()
	const ext = sourceKey.split('.').pop()
	const newKey = `${id}/${uuidv4()}.${ext}`

	return await s3.copyObject({
		Bucket: bucket,
		Key: newKey,
		CopySource: `${sourceBucket}${sourceKey}`,
		ACL: 'public-read'
	})
	.promise()
	.then(() => {
		return newKey
	})
	.catch((error) =>{
		console.error("copy error", error);
		return { error: error }
	})
}
export default ImageCopyAWS
