import React from 'react'
import {
	Button, Header, Form, Popup, Icon
} from 'semantic-ui-react'

function ImportResultsIntakeFields(props) {
	return (
		<>
			<Form.Group widths={5}>
				<Form.Select
					search selection clearable label='Material Type' value={props.sampleTypeId}
					name='sampleTypeId' onChange={(e, {name, value}) => props.updateSampleType(value, props.index)}
					className='required-field inputter'
					options={props.sampleTypes.filter((sampleType) => sampleType)}
					placeholder='Material Type' error={props.errors[props.index] && props.errors[props.index].material_type}
				/>
				<Form.Input
					label='Strain/Sample Name' value={props.intakeForm.strain_name}
					name='strain_name' onChange={(e) => props.updateIntakeFormField(e, props.index)} type='text' className='required-field inputter'
					placeholder='Strain Name / Sample Name' error={props.errors[props.index] && props.errors[props.index].strain_name} maxLength={50}
				/>
				<Form.Input
					label='Sample Weight (mg)' value={props.intakeForm.sample_weight}
					name='sample_weight' onChange={(e) => props.updateIntakeFormField(e, props.index)} type='text' className='inputter'
					placeholder='Sample Weight (mg)' error={props.errors[props.index] && props.errors[props.index].sample_weight}
				/>
			</Form.Group>
			<Form.Group widths={5}>
				<Form.Input
					label='Batch Number' value={props.intakeForm.batch_number}
					name='batch_number' onChange={(e) => props.updateIntakeFormField(e, props.index)} type='text' className='required-field inputter'
					placeholder='Batch Number' error={props.errors[props.index] && props.errors[props.index].batch_number}
					action>
					<input />
					<Popup
						content='Generate Unique Batch Number' size='tiny' trigger={(
							<Button
								onClick={() => props.generateBatchNumber(props.index)}
								style={{ paddingLeft: '1em', paddingRight: '.5em' }}>
								<Icon name='lightning' />
							</Button>
						)}
					/>
					{/* <Popup
						content='Reset Batch Number to Default' size='tiny' trigger={(
							<Button
								onClick={() => props.resetBatchNumber(props.index)}
								disabled={props.parsedBatch === props.intakeForm.batch_number}
								style={{ paddingLeft: '1em', paddingRight: '.5em' }}>
								<Icon name='undo' />
							</Button>
						)}
					/> */}
				</Form.Input>
				<Form.Input
					label='Reporting Limit (LOQ, %)' value={props.intakeForm.loq}
					name='loq' onChange={(e) => props.updateIntakeFormField(e, props.index)} type='text' className='required-field inputter'
					placeholder='Reporting Limit (LOQ as a percentage)' error={props.errors[props.index] && props.errors[props.index].loq}
				/>
				<Form.TextArea
					label='Import Notes'
					value={props.intakeForm.import_notes}
					name='import_notes'
					onChange={(e) => props.updateIntakeFormField(e, props.index)}
					type='text'
					className='import-notes inputter'
					placeholder='Additional notes...'
					error={props.errors[props.index] && props.errors[props.index].import_notes}
				/>
			</Form.Group>
			<Header as='h5' textAlign='right' style={{ marginTop: '0', paddingLeft: '.5em', paddingRight: '.5em' }}>
				<span style={{ color: '#db2828' }}>*</span> Required
			</Header>
		</>
	)
}

ImportResultsIntakeFields.propType = {

}.isRequired

export default ImportResultsIntakeFields