import React from 'react'
import _ from 'lodash'
import { Input, Dropdown, Grid } from 'semantic-ui-react'
const SearchComponent = (props) => (
  <Grid>
    <Grid.Column mobile={16} only='mobile'>
      <Input
        name={'search'}
        labelPosition='right'
        placeholder='Search'
        onChange={props.onSearchChange}
        onKeyUp={props.handleSearchResult && _.debounce(props.handleSearchResult, 1000)}
        value={props.searchValue}
        fluid
      />
    </Grid.Column>
    <Grid.Column mobile={16} only='mobile' className={'pt0 pb0'} >
      <Dropdown
        value={props.selectedSearchDropdown}
        onChange={props.getSelectedDropdown}
        options={props.options}
        fluid className={'label pt1em pb1em'} />
    </Grid.Column>
    <Grid.Column computer={16} largeScreen={16} only='tablet'>
      <Input
        label={<Dropdown
          value={props.selectedSearchDropdown}
          onChange={props.getSelectedDropdown}
          options={props.options} />
        }
        name={'search'}
        labelPosition='right'
        placeholder='Search'
        onChange={props.onSearchChange}
        onKeyUp={props.handleSearchResult && _.debounce(props.handleSearchResult, 1000)}
        value={props.searchValue}
      />
    </Grid.Column>
  </Grid>
)
export default SearchComponent
