import axios from '../axios'
import axiosNoToken from '../axiosNoToken'
import axiosExternal from '../axiosExternal'
import config from '../config'
import cannidAPI from '../cannidAPI/client'
import constants from '../lib/constants'
import urlFriendly from '../lib/urlFriendly'

export const SampleTestServices = {
  getBulkCsvResultsDownloadUrl,
  getSampleTestResultDownloadUrl,
  getPdfReportDownloadUrl,
  getSampleTests,
  createSample,
  deleteSample,
  clearSampleResult,
  updateSample,
  getSample,
  getSampleResult,
  createSampleAppendResults,
  getAllSampleTests,
  getSampleShare,
  sendEmailReportLink,
  getAllSampleTypes,
  getQrDetailsByShareKey,
  updateGeoLocation,
  getAllTestResults,
  getTopSamples,
  getTopMachineSamples,
  update_dominant_analyte,
  updateCoaData,
  sendSampleToChemid
}
function getBulkCsvResultsDownloadUrl(exclude_sample_types = [], include_sample_types = [], queryParams) {
  let query = 'samples?format=csv&download=true'
  if (exclude_sample_types.length > 0)
    query += '&exclude_sample_types=' + JSON.stringify(exclude_sample_types)
  if (include_sample_types.length > 0)
    query += '&include_sample_types=' + JSON.stringify(include_sample_types)
  if (queryParams) query += `&${queryParams}`
  return process.env.REACT_APP_API_ROOT + "/" + query
}
function getSampleTestResultDownloadUrl(sampleId) {
  return process.env.REACT_APP_API_ROOT + "/" + `samples/${sampleId}/csv?download=true&no_pagination=true`
}
function getPdfReportDownloadUrl(sampleId, template = "/report_pdf") {
  return process.env.REACT_APP_API_ROOT + "/" + `samples/${sampleId}/report/download` + template
}
function getSampleTests(exclude_sample_types = [], include_sample_types = []) {
  let query = 'samples'
  if (exclude_sample_types.length > 0)
    query += '?exclude_sample_types=' + JSON.stringify(exclude_sample_types)
  if (include_sample_types.length > 0)
    query += '?include_sample_types=' + JSON.stringify(include_sample_types)
  return process.env.REACT_APP_API_ROOT + "/" + query
}
function updateSample(requestData, id) {
  return cannidAPI.patch('/samples/' + id, requestData)
}
function getSample(id) {
  return cannidAPI.get('/samples/' + id)
}
function createSample(requestData) {
  return cannidAPI.post('/samples', requestData)
}
function deleteSample(id) {
  return cannidAPI.delete('/samples/' + id)
}
function clearSampleResult(id) {
  return cannidAPI.patch('/samples/' + id + '/erase_result')
}
function getSampleResult(id) {
  return cannidAPI.get('/samples/' + id + '/results?no_pagination=true')
}
function createSampleAppendResults(requestData, id) {
  return cannidAPI.post('/samples/' + id + '/append_results', requestData)
}
function getAllSampleTests() {
  return cannidAPI.get('/samples?no_pagination=true')
}
function getSampleShare(share_key) {
  return axiosNoToken().get(process.env.REACT_APP_API_ROOT + '/share/samples/' + share_key)
}
function sendEmailReportLink(requestData, id) {
  return cannidAPI.post('/samples/' + id + '/report/share-report', requestData)
}
function getAllSampleTypes() {
  return cannidAPI.get('/sample-types?no_pagination=true&sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id')
}
function updateGeoLocation(requestData, id) {
  return cannidAPI.patch('/samples/' + id + '/location', requestData)
}
function getQrDetailsByShareKey(id) {
  return cannidAPI.get('/samples/' + id + '/details')
}
function getAllTestResults(id) {
  return process.env.REACT_APP_API_ROOT + '/organizations/' + id + '/samples?result=true'
}
function getTopSamples(exclude_sample_types = [], include_sample_types = []) {
  let query = 'samples?startIndex=0&endIndex=10&sortOrder=DESC&sortColumn=id'
  if (exclude_sample_types.length > 0)
    query += '&exclude_sample_types=' + JSON.stringify(exclude_sample_types)
  if (include_sample_types.length > 0)
    query += '&include_sample_types=' + JSON.stringify(include_sample_types)
  return cannidAPI.get("/" + query)
}
function getTopMachineSamples(machine_name, exclude_sample_types = [], include_sample_types = []) {
  let query = 'samples?startIndex=0&endIndex=10&sortOrder=DESC&sortColumn=id'
  query += '&machine_name=' + JSON.stringify(machine_name)
  if (exclude_sample_types.length > 0)
    query += '&exclude_sample_types=' + JSON.stringify(exclude_sample_types)
  if (include_sample_types.length > 0)
    query += '&include_sample_types=' + JSON.stringify(include_sample_types)
  return cannidAPI.get("/" + query)
}
function update_dominant_analyte(requestData, id) {
  return cannidAPI.patch('/samples/' + id + '/dominant_analyte', requestData)
}
function updateCoaData(requestData, id) {
  return cannidAPI.patch('/samples/' + id, requestData)
}
function sendSampleToChemid(entityId, sampleId) {
	return getSample(sampleId).then(res => {
		const sample = res.data
		sample.intake_form.notes = sample.intake_form.notes ? `${sample.intake_form.notes} [CannID Transferred ${new Date().toLocaleString()}]` : `[CannID Transferred ${new Date().toLocaleString()}]`
		const filename = urlFriendly(`cannid-transfer__${sample.intake_form.strain_name}__${sample.id}.json`)
		const requestData = { refiner: 'cannidR1', fileContent: sample, fileMeta: { name: filename, type: 'application/json' } }
		return axiosExternal({ withCredentials: true }).post(process.env.REACT_APP_CHEMID_API_ROOT + `/refiner/entity/${entityId}/samples`, requestData)
	}).catch(err => err)
}
