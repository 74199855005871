import PropTypes from 'prop-types'

function ConditionalRender({ condition, children }) {
	if (condition) {
		return children
	}

	return null
}

ConditionalRender.propTypes = {
	condition: PropTypes.any,	// eslint-disable-line react/forbid-prop-types
	children: PropTypes.node.isRequired
}

ConditionalRender.defaultProps = {
	condition: false
}

export default ConditionalRender
