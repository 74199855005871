import permissionCheck from './permissionCheck'
const checkDynamicTableActions = (tableHeader, entity) => {
  let actionItemTotalObject = tableHeader.filter((data) => data.id === 'action_item')
  if (actionItemTotalObject.length > 0 && actionItemTotalObject[0]) {
    let actionItems = (actionItemTotalObject[0]).actionItems ? (actionItemTotalObject[0]).actionItems : []
    let actionsAllowed = false
    actionItems.map((val) => {
      if (permissionCheck(entity, val.action) === true) {
        actionsAllowed = true
      }
      return actionsAllowed
    })
    return actionsAllowed
  }
  return true
}

export default checkDynamicTableActions
