import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Message, Button } from 'semantic-ui-react'
import dateTimeFormatter from '../../lib/dateTimeFormatter'

function ClearModal({ onClose, onConfirm, onDownloadCOA, sample }) {
	let coaWarning = null
	let testedAtDate = null

	if (sample.coa) {
		coaWarning = (
			<p>
				<strong>
					Clearing these results will delete the current COA document. To save a copy before deletion,&nbsp;
					<Button primary basic compact size='small' icon='file text' content='click here' onClick={() => { onClose(); onDownloadCOA() }} />
					to download a copy for your records.
				</strong>
			</p>
		)
	}

	if (sample.tested_at) {
		const testedAtString = dateTimeFormatter(sample.tested_at)
		if (testedAtString) {
			testedAtDate = testedAtString
		}
	}
	return (
		<Modal size='large' open onClose={onClose} closeIcon centered>
			<Modal.Header>Clear Test Result</Modal.Header>
			<Modal.Content id='clearResultsConfirmModal' scrolling>
				<Message warning><strong>WARNING: This is a permanent action which cannot be reversed! Clearing sample data removes all data associated with a Cann-ID result and cannot be undone.</strong></Message>
				<p>
					Clearing sample data will remove all associated test result information from the Cann-ID database so that a sample can be reanalyzed, or so updated results can be imported for that sample.
				</p>
				{coaWarning}
				<p>
					<strong>Are you sure you want to clear sample data for this result?</strong>&nbsp;
					<br />
					<i>
						{sample.intake_form.batch_number} - {sample.intake_form.strain_name}: {sample.intake_form.sample_weight} mg
						{testedAtDate}
					</i>
				</p>
			</Modal.Content>
			<Modal.Actions>
				<Button className='cancelButton' onClick={onClose}>No</Button>
				<Button className='confirmButton' icon='checkmark' labelPosition='right' content='Yes' onClick={onConfirm} />
			</Modal.Actions>
		</Modal>
	)
}

ClearModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onDownloadCOA: PropTypes.func.isRequired,
	sample: PropTypes.shape({
		coa: PropTypes.bool,
		tested_at: PropTypes.string,
		intake_form: PropTypes.shape({
			batch_number: PropTypes.string,
			strain_name: PropTypes.string,
			sample_weight: PropTypes.string
		})
	}).isRequired
}

export default ClearModal
