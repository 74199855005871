import React, { Component } from 'react'
import PrepVial from './PrepVial'

class PreparedSample extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="preppedSample">
        <PrepVial vial={this.props.vial} />
        <div className="dilutionFactor">
          {this.props.vial.dilution_factor}
        </div>
        <div className="vialLabel">
          {this.props.vial.label}
        </div>
		{!this.props.hideAcquire &&
			<div className="acquireSelector">
				{this.props.vial.acquire ? "✅" : "❌"}
			</div>
  		}
      </div>
    )
  }
}

export default PreparedSample
