import React from 'react'
import { Route, Redirect, } from 'react-router-dom'
import permissionCheck from '../../lib/permissionCheck'
import constants from '../../lib/constants'

const AclRoute = ({ component, path, entity, action, auth }) => {
  return (
    <Route exact path={path} render={(props) => (
      (localStorage.getItem('access_token')) ?
        (auth === false ?
          (<Redirect to={constants.APPLICATION_ROUTE.HOME.ROUTE} />) :
          (permissionCheck(entity, action) === true ?
            component({ ...props }) :
            <Redirect to={{ pathname: constants.APPLICATION_ROUTE.PAGE_NOT_FOUND.ROUTE, state: { from: props.location, hasAuthorizationError: true } }} />)) :
        (auth === false ?
          component({ ...props }) : <Redirect to={constants.APPLICATION_ROUTE.HOME.ROUTE} />)
    )} />
  )
}
export default AclRoute
