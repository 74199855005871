import React, { Component } from 'react'
import { get } from 'lodash'
import {
	Breadcrumb,
	Button,
	Form,
	Grid,
	Radio
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import ToastMessage from '../Message'
import AppHeader from '../AppHeader'
import { userServices } from '../../services/User'
import constants from '../../lib/constants'
import passwordStrength from '../../lib/passwordStrength'
import PasswordStrengthMeter from '../../lib/PasswordStrengthMeter'
import trimWhiteSpace from '../../lib/trim'
import ErrorMessages from '../Errors/ErrorMessages'
import Timer from '../../lib/Timer'

const gridOptions = [
	{ key: '1', text: 'On-site', value: 'onsite' },
	{ key: '2', text: 'Remote', value: 'remote' }
]
class CreateUser extends Component {
	constructor(props, context) {
		super(props, context)
		this.state = {
			firstName: '',
			lastName: '',
			role: '',
			email: '',
			phone: '',
			altEmail: '',
			password: '',
			confirmPassword: '',
			rolesList: [],
			formErrors: {
				firstNameError: false,
				lastNameError: false,
				roleError: false,
				emailError: false,
				altEmailError: false,
				phoneError: false,
				passwordError: false,
				confirmPasswordError: false
			},
			userCreated: null,
			errorMessage: '',
			user_creation_radio: (this.props.match.params.id) ? 'onsite' : 'remote',
			remoteUser: {
				email: '',
				role: ''
			},
			remoteUserFormErrors: {
				emailError: false,
				roleError: false
			},
			apiErrors: {},
			userInvited: null,
			time: constants.USER.CREATE.REINVITE_TIME_INTERVAL_IN_SECONDS
		}
	}

	componentDidMount() {
		userServices.getRoles().then((res) => {
			const roleList = get(res, 'data.data', false) || []
			const rolesArray = roleList.filter((data) => data.name.toLowerCase() !== constants.USER_ROLE.SUPER_ADMIN_SLUG).map((role) => (
				{
					key: role.id,
					text: role.name,
					value: role.id
				}
			))
			this.setState({ rolesList: rolesArray })
		}).catch((e) => {
		})
		const id = this.props.match.params.id
		if (id) {
			userServices.getUser(id).then((res) => {
				const userDetails = get(res, 'data', false) || {}
				const name = userDetails.name.split(' ')
				this.setState({
					firstName: name[0],
					lastName: name[1],
					role: userDetails.role.id,
					email: userDetails.email,
					phone: userDetails.contact_number || '',
					altEmail: userDetails.alternate_email_id || ''
				})
			}).catch((e) => {
				this.setState({
					apiErrors: (e.data) ? e.data : {}
				})
			})
		}
	}

	handleInputChange = (e) => {
		const target = e.target
		let newState = {}
		if (this.state.userInvited) {
			clearInterval(this.timer)
			this.setState({ time: constants.USER.CREATE.REINVITE_TIME_INTERVAL_IN_SECONDS, userInvited: false })
		}
		if (this.state.user_creation_radio === 'onsite') {
			newState[target.name] = target.value
			this.setState(newState, () => {
				this.isfieldValid(target.name)
			})
		}
		else {
			newState = { ...this.state.remoteUser }
			newState[target.name] = target.value
			this.setState({ remoteUser: { ...newState } }, () => { this.isRemoteFieldValid(target.name) })
		}
	}

	handleSelectChange = (e, { name, value }) => {
		if (this.state.userInvited) {
			clearInterval(this.timer)
			this.setState({ time: constants.USER.CREATE.REINVITE_TIME_INTERVAL_IN_SECONDS, userInvited: false })
		}
		if (this.state.user_creation_radio === 'onsite') this.setState({ role: value }, () => { this.isfieldValid(name) })
		else {
			const newState = { ...this.state.remoteUser }
			newState.role = value
			this.setState({ remoteUser: { ...newState } }, () => { this.isRemoteFieldValid(name) })
		}
	}

	checkEmail = (email) => {
		const re = constants.REG_EX.EMAIL_REG_EX
		return !re.test(String(email).toLowerCase())
	}

	checkPhone = (phone) => {
		if (phone.length === 0) {
			return false
		}
		return !phone.match(constants.REG_EX.PHONE_REG_EX)
	}

	checkAltEmail = (altEmail) => {
		if (altEmail.length === 0) return false
		if (this.state.email === this.state.altEmail) return true
		const re = constants.REG_EX.EMAIL_REG_EX
		return !re.test(String(altEmail).toLowerCase())
	}

	checkPassword = () => {
		if (!this.state.password.match(constants.REG_EX.PASSWORD_REG_EX)) {
			if (this.state.password.length < constants.PASSWORD.MIN_LENGTH) {
				this.setState({ errorMessage: constants.RESET_PASSWORD.PASSWORD_STRENGTH })
			}
			else this.setState({ errorMessage: constants.RESET_PASSWORD.PASSWORD_STRENGTH })
			return true
		}

		this.setState({ errorMessage: '' })
		return false
	}

	checkConfirmPassword = () => {
		const { confirmPassword, password } = this.state
		if (confirmPassword.length >= constants.PASSWORD.MIN_LENGTH && confirmPassword === password) {
			return false
		}
		return true
	}

	isfieldValid = (field) => {
		const tempFormError = { ...this.state.formErrors }
		if (this.state[field] === '') {
			tempFormError[`${field}Error`] = true
		}
		else {
			tempFormError[`${field}Error`] = false
		}
		this.setState({
			formErrors: { ...tempFormError }
		})
	}

	isRemoteFieldValid = (field) => {
		const tempFormError = { ...this.state.remoteUserFormErrors }
		if (this.state.remoteUser[field] === '') {
			tempFormError[`${field}Error`] = true
		}
		else {
			tempFormError[`${field}Error`] = false
		}
		this.setState({
			remoteUserFormErrors: { ...tempFormError }
		})
	}

	validateForm = () => {
		const id = this.props.match.params.id
		const tempFormError = { ...this.state.formErrors }
		if (!this.state.firstName) {
			tempFormError.firstNameError = true
		}
		if (!this.state.lastName) {
			tempFormError.lastNameError = true
		}
		if (!this.state.role) {
			tempFormError.roleError = true
		}
		if (this.checkEmail(this.state.email)) {
			tempFormError.emailError = true
		}
		if (this.checkPhone(this.state.phone)) {
			tempFormError.phoneError = true
		}
		if (this.checkAltEmail(this.state.altEmail)) {
			tempFormError.altEmailError = true
		}
		if (this.checkPassword(this.state.password) && !id) {
			tempFormError.passwordError = true
		}
		if (this.checkConfirmPassword(this.state.confirmPassword) && !id) {
			tempFormError.confirmPasswordError = true
		}
		this.setState({
			formErrors: {
				...tempFormError
			}
		})
		for (const key in tempFormError) {
			if (tempFormError[key] === true) return false
		}
		return true
	}

	onSubmit = () => {
		const formIsValid = this.validateForm()
		if (!formIsValid) {
			return false
		}
		const requestBody = {
			user: {
				name: trimWhiteSpace(`${this.state.firstName} ${this.state.lastName}`),
				email: trimWhiteSpace(this.state.email),
				role_id: trimWhiteSpace(this.state.role),
				contact_number: trimWhiteSpace(this.state.phone),
				alternate_email_id: trimWhiteSpace(this.state.altEmail),
				password: trimWhiteSpace(this.state.password)
			}
		}
		if (this.props.match.params.id) {
			userServices.updateUser(this.props.match.params.id, requestBody).then((res) => {
				setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.USER.LIST.ROUTE, {})), 1000)
				this.setState({ userCreated: true, errorMesage: constants.USER.EDIT.SUCCESS, apiErrors: {} })
			}).catch((e) => {
				this.setState({ userCreated: false, errorMesage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} })
			})
		}
		else {
			userServices.createUser(requestBody).then((res) => {
				setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.USER.LIST.ROUTE, {})), 1000)
				this.setState({ userCreated: true, errorMesage: constants.USER.CREATE.SUCCESS })
			}).catch((e) => {
				this.setState({ userCreated: false, errorMesage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} })
			})
		}
	}

	updateRadioType = (e, { value }) => {
		this.setState({ user_creation_radio: value })
	}

	isRemoteUserValidate = () => {
		const tempFormError = { ...this.state.remoteUserFormErrors }
		if (!this.state.remoteUser.role) {
			tempFormError.roleError = true
		}
		if (this.checkEmail(this.state.remoteUser.email)) {
			tempFormError.emailError = true
		}
		this.setState({
			remoteUserFormErrors: {
				...tempFormError
			}
		})
		for (const key in tempFormError) {
			if (tempFormError[key] === true) return false
		}
		return true
	}

	intervalTimer = () => {
		this.setState({ time: this.state.time - 1 }, () => {
			if (this.state.time < 1) {
				clearInterval(this.timer)
			}
		})
	}

	setTimer = () => {
		this.setState({ time: constants.USER.CREATE.REINVITE_TIME_INTERVAL_IN_SECONDS }, () => {
			clearInterval(this.timer)
			this.timer = setInterval(this.intervalTimer, 1000)
		})
	}

	onRemoteSubmit = () => {
		let formIsValid = false
		formIsValid = this.isRemoteUserValidate()
		if (!formIsValid) {
			return false
		}
		const requestData = {
			email: this.state.remoteUser.email,
			role_id: this.state.remoteUser.role
		}
		userServices.createRemoteUser(requestData).then((res) => {
			this.setTimer()
			this.setState({ userInvited: true, errorMesage: constants.USER.CREATE.INVITATION_SUCCESS, apiErrors: {} })
		}).catch((e) => {
			this.setState({ errorMesage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} })
		})
	}

	remoteUser = () => (
		<Form>
			<Grid centered>
				<Grid.Column mobile={16} tablet={12} largeScreen={6} computer={6}>
					<Grid>
						<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
							<Form.Input
								maxLength={constants.FIELD_LENGTH.MAX_64} label='Email' value={this.state.remoteUser.email}
								name='email' onChange={this.handleInputChange} type='text'
								placeholder='E-mail'
								className='required-field' error={this.state.remoteUserFormErrors.emailError}
							/>
							{this.state.remoteUserFormErrors.emailError && <p className='error'>{constants.USER.CREATE.INVALID_EMAIL}</p>}
						</Grid.Column>
						<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
							<Form.Select
								label='Role' value={this.state.remoteUser.role}
								options={this.state.rolesList}
								name='role' onChange={this.handleSelectChange}
								placeholder='Role' className='required-field' error={this.state.remoteUserFormErrors.roleError}
							/>
							{this.state.remoteUserFormErrors.roleError && <p className='error'>{constants.USER.CREATE.ROLE_MANDATORY_FIELD}</p>}
						</Grid.Column>
					</Grid>
					<Grid centered>
						<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
							<Button
								className='confirmButton' type='submit' disabled={this.state.userInvited && this.state.time > 0}
								onClick={() => this.onRemoteSubmit()}
							>{(this.state.userInvited) ? constants.USER.CREATE.CREATE_REMOTE_REINVITE_TITLE : constants.USER.CREATE.CREATE_REMOTE_TITLE}
							</Button>
							{this.state.userInvited && this.state.time > 0 && <><br /><Timer time={this.state.time} /></>}
						</Grid.Column>
					</Grid>
				</Grid.Column>
			</Grid>
		</Form>
	)

	onSiteUser = () => {
		const passwordStrengthCalc = passwordStrength(this.state.password)
		return (
			<Form>
				<Grid centered>
					<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
						<Form.Input
							maxLength={constants.FIELD_LENGTH.MAX_64} label='First Name' value={this.state.firstName}
							name='firstName' onChange={this.handleInputChange} type='text' className='required-field'
							placeholder='First Name' error={this.state.formErrors.firstNameError}
						/>
						{this.state.formErrors.firstNameError && <p className='error'>Please fill the first name</p>}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
						<Form.Input
							maxLength={constants.FIELD_LENGTH.MAX_64} label='Last Name' name='lastName' value={this.state.lastName}
							onChange={this.handleInputChange} type='text' className='required-field'
							placeholder='Last Name' error={this.state.formErrors.lastNameError}
						/>
						{this.state.formErrors.lastNameError && <p className='error'>Please fill the last Name</p>}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
						<Form.Input
							maxLength={constants.FIELD_LENGTH.MAX_64} label='Email' value={this.state.email}
							name='email' onChange={this.handleInputChange} type='text'
							placeholder='E-mail'
							className='required-field' error={this.state.formErrors.emailError} readOnly
						/>
						{this.state.formErrors.emailError && <p className='error'>Please enter a valid email address</p>}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
						<Form.Input
							maxLength={constants.FIELD_LENGTH.MAX_64} label='Alternate Email' value={this.state.altEmail}
							name='altEmail' onChange={this.handleInputChange} type='email'
							placeholder='Alternate E-mail' error={this.state.formErrors.altEmailError}
						/>
						{this.state.formErrors.altEmailError && <p className='error'>Please provide the alternate email</p>}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
						<Form.Input
							maxLength={constants.FIELD_LENGTH.MAX_64} label='Phone' value={this.state.phone}
							name='phone' onChange={this.handleInputChange}
							placeholder='Phone' error={this.state.formErrors.phoneError}
						/>
						{this.state.formErrors.phoneError && <p className='error'>Please provide the contact number</p>}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
						<Form.Select
							label='Role' value={this.state.role}
							options={this.state.rolesList}
							name='role' onChange={this.handleSelectChange}
							placeholder='Role' className='required-field' error={this.state.formErrors.roleError}
						/>
						{this.state.formErrors.roleError && <p className='error'>Please select the role</p>}
					</Grid.Column>
					{!this.props.match.params.id
            && (
            	<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
            		<Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} className='required-field' label='Password' placeholder='Password' type='password' value={this.state.password} onChange={this.handleInputChange} name='password' ref={this.passwordRef} error={this.state.formErrors.passwordError} />
            		<PasswordStrengthMeter score={passwordStrengthCalc} />
            		{this.state.errorMessage.length !== 0 && <p className='error'>{this.state.errorMessage}</p>}
	</Grid.Column>
            )}
					{!this.props.match.params.id
            && (
            	<Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
            		<Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} className='required-field' label='Confirm Password' placeholder='Password' type='password' value={this.state.confirmPassword} onChange={this.handleInputChange} name='confirmPassword' ref={this.passwordRef} error={this.state.formErrors.confirmPasswordError} />
            		{this.state.formErrors.confirmPasswordError && <p className='error'>{constants.RESET_PASSWORD.PASSWORD_MATCH}</p>}
	</Grid.Column>
            )}
				</Grid>
				<Grid centered>
					<Grid.Column mobile={16} tablet={8} largeScreen={4} computer={4} textAlign='center'>
						<Button
							className='confirmButton' type='submit'
							onClick={() => this.onSubmit()}
						>{!this.props.match.params.id ? constants.USER.CREATE.CREATE_BUTTON_TITLE : constants.USER.EDIT.EDIT_BUTTON_TITLE}
						</Button>
					</Grid.Column>
				</Grid>
			</Form>
		)
	}

	render() {
		let breadcrumb
		breadcrumb = (
			<Breadcrumb>
				<Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to={formatRoute(constants.APPLICATION_ROUTE.USER.LIST.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.USER.LIST.NAME}</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to={this.props.match.params.id ? formatRoute(constants.APPLICATION_ROUTE.USER.EDIT.ROUTE, { id: this.props.match.params.id }) : formatRoute(constants.APPLICATION_ROUTE.USER.CREATE.ROUTE, {})}><Breadcrumb.Section>{this.props.match.params.id ? constants.APPLICATION_ROUTE.USER.EDIT.NAME : constants.APPLICATION_ROUTE.USER.CREATE.NAME}</Breadcrumb.Section></Link>
			</Breadcrumb>
		)
		const title = !this.props.match.params.id ? constants.USER.CREATE.HEADER_TITLE : constants.USER.EDIT.HEADER_TITLE
		return (
			<div>
				<section id='mainContent' className='app light'>
					<AppHeader title={<h1>{title}</h1>} breadcrumb={breadcrumb} />
					<section className='app light'>
						<div className='form screen'>
							{
								this.state.userCreated === true && !this.props.match.params.id && <ToastMessage title='Success' description={this.state.errorMesage} />
							}
							{
								this.state.userCreated === true && this.props.match.params.id && <ToastMessage title='Success' description={this.state.errorMesage} />
							}
							{
								this.state.userInvited === true && <ToastMessage title='Success' description={this.state.errorMesage} />
							}
							<ErrorMessages errors={this.state.apiErrors} />
							<Form>
								{!this.state.user_creation_radio
                  && (
                  	<Grid centered>
                  		<Form.Field className='required-field'>
                  			<label>User Type</label>
	</Form.Field>
                  		{gridOptions.map((input, index) => (
		<Form.Field key={input.key}>
	<Radio
	label={input.text}
	name='user_creation_radio'
	value={input.value}
	checked={this.state.user_creation_radio === input.value}
	onChange={this.updateRadioType}
	key={input.key}
                  				/>
                  			</Form.Field>
                  		))}
	</Grid>
                  )}
							</Form>
							{this.state.user_creation_radio === 'onsite' ? this.onSiteUser() : this.remoteUser()}
						</div>
					</section>
				</section>
			</div>
		)
	}
}
export default CreateUser
