import React, { Component } from 'react'
import { Button, Icon, Progress } from 'semantic-ui-react'

function AutosamplerPauseButton(props) {

  let pauseButton

  // error state
  if (props.machineState && props.machineState.status === "ERROR") {
    // pauseButton = (<Button icon labelPosition="left"  disabled>
    //   <Icon name="pause" size="large" />
    //   Error!
    // </Button>)
    pauseButton = undefined
  }

  // warmup running
  else if (props.machineState
    && props.machineState.method === process.env.REACT_APP_WARMUP_METHOD+'.M'
    && ['PRERUN', 'RUN', 'NOTREADY'].indexOf(props.machineState.status) > -1) {
    pauseButton = (<Button icon labelPosition="left"  disabled>
        <Icon loading name="spinner" size="large" />
        Warming
      </Button>
    )
  }

  // sleeping, instrument on
  else if (props.machineState
    && props.machineState.status == 'NOTREADY'
    && props.machineState.method === process.env.REACT_APP_SLEEP_METHOD+'.M' ){
    pauseButton = (<Button icon labelPosition="left" disabled>
        <Icon name="pause" size="large" />
        Sleeping
      </Button>
    )
  }

  // sleep running
  else if (props.machineState && props.machineState.method === process.env.REACT_APP_SLEEP_METHOD+'.M') {
    pauseButton = (<Button icon labelPosition="left" disabled>
        <Icon loading name="spinner" size="large"/>
        Going to Sleep
      </Button>
    )

  }

  // run method enabled and machine running
  else if (props.machineState && props.machineState.method === process.env.REACT_APP_RUN_METHOD+'.M' && props.machineState.sequenceRunning) {
    if (props.queuePaused) {
      pauseButton = (<Button icon labelPosition="left" disabled>
          <Icon loading name="spinner" size="large" />
          Pausing after vial complete
        </Button>
      )
    } else { //Sequence Running 
      pauseButton = (<Button icon labelPosition="left" onClick={() => {props.doAction('pause')}} >
          <Icon name="pause" size="large" />
          Pause Queue
        </Button>
      )
    }
  }

  // run method enabled but machine not running
  else if (props.machineState && (props.machineState.method === process.env.REACT_APP_RUN_METHOD+'.M' || props.machineState.method === 'RUN.M')) {
    pauseButton = (<Button icon labelPosition="left" disabled>
        <Icon name="pause" size="large" />
        Idle / Paused
      </Button>
    )
  }

  // catch-all for safety. 
  // shouldn't be possible if logic above is correct and chemstation enabled cannid method
  else {
    pauseButton = (<Button icon labelPosition="left"  disabled>
      <Icon name="pause" size="large" />
      Pause-ish?
    </Button>)
  }

  return pauseButton ? (
      <div className="autosamplerPauseButton actionButton">
        {pauseButton}
      </div>
    ) : ""
}

export default AutosamplerPauseButton
