import React  from 'react'
import { 
  Breadcrumb, 
  Button, 
  Icon, 
  Modal, 
  Table, 
  Grid 
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import {get} from 'lodash'
import AppHeader from "../AppHeader"
import DynamicTableComponent from "../DynamicTableComponent"
import { FormFieldServices } from "../../services/FormFields"
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import constants from '../../lib/constants'
import Acl from '../ACL/Acl'
import { formatRoute } from 'react-router-named-routes'
import checkDynamicTableActions from '../../lib/checkDynamicTableActions'
const tableHeader = [
  { id: 'name', title: 'Name', sortable: true },
  { id: 'created_at', title: 'Date Created', sortable: true },
  { id: 'updated_at', title: 'Date Updated', sortable: true },
  {
    id: 'action_item',
    title: 'Actions',
    sortable: false,
    actionItems: [
      { title: constants.FORM_FIELD.LIST.EDIT_FORM_FIELD, color: 'black', style: { marginRight: '10px' }, icon: 'edit', action: constants.ACL.FORM_FIELD_RESOURCE.ACTIONS.EDIT },
      { title: constants.FORM_FIELD.LIST.REMOVE, style: { marginRight: '10px' }, color: 'red', icon: 'remove', action: constants.ACL.FORM_FIELD_RESOURCE.ACTIONS.DELETE }]
  }
];
const options = [
  { key: 'name', text: 'Name', value: 'name' }
]
class ListFormFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      removeFormFieldFlag: false,
      selectedSearchDropdown: 'name',
      formFieldId: '',
    }
  }
  resize = () => this.forceUpdate()
  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  sortByColumn = (tableData) => {
    this.setState({ rowData: tableData })
  }
  rowAction = (action, data, e) => {
    e.stopPropagation()
    if (action === constants.FORM_FIELD.LIST.EDIT_FORM_FIELD)
      this.props.history.push("/form-fields/" + data.id + "/edit")
    if (action === constants.FORM_FIELD.LIST.REMOVE) {
      this.setState({ removeFormFieldFlag: true, formFieldId: data.id })
    }
  }
  removeFormField = (id) => {
    FormFieldServices.deleteFormFields(id).then(res => {
      if (res && res.status === 202) {
        let updatedFormField = [...this.state.rowData].filter((data) => data.id !== id);
        this.setState({ removeFormFieldFlag: false, rowData: updatedFormField, count: this.state.count - 1 }, () => window.location.reload())
      }
    })
  }
  getTableData = (data) => {
    let arrayList = get(data, 'data.data', false ) || [];
    let count = get(data, 'data.meta.total_count', false ) || 0;
    const tableData = arrayList.map((data) => {
      return {
        id: data.id,
        name: data.name ? data.name : 'NA',
        created_at: dateTimeFormatter(data.created_at),
        updated_at: dateTimeFormatter(data.updated_at)
      }
    });
    this.setState({ rowData: tableData, count: count, initialCount: count, initialData: tableData })
  }
  getFilteredData = (data) => {
    let arrayList = get(data, 'data.data', false ) || [];
    let count = get(data, 'data.meta.total_count', false ) || 0;
    const tableData = arrayList.map((data) => {
      return {
        id: data.id,
        name: data.name ? data.name : 'NA',
        created_at: dateTimeFormatter(data.created_at),
        updated_at: dateTimeFormatter(data.updated_at)
      }
    });
    this.setState({ rowData: tableData, count: count })
  }
  getSelectedDropdown = (e, { value }) => {
    this.setState({
      selectedSearchDropdown: value,
      rowData: this.state.initialData,
      count: this.state.initialCount
    })
  }
  closeModal = () => {
    this.setState({ removeFormFieldFlag: false })
  }
  render() {
    let breadcrumb;
    breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to="/form-fields"><Breadcrumb.Section>{constants.FORM_FIELD.LIST.HEADER_TITLE}</Breadcrumb.Section></Link>
      </Breadcrumb>
    )
    let showActions = checkDynamicTableActions(tableHeader, constants.ACL.SAMPLE_RESOURCE.TITLE)
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{constants.FORM_FIELD.LIST.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <DynamicTableComponent
              entity={constants.ACL.FORM_FIELD_RESOURCE.TITLE}
              action={constants.ACL.FORM_FIELD_RESOURCE.ACTIONS.LIST}
              tableHeader={tableHeader}
              options={options}
              baseUrl={FormFieldServices.getFormFieldsDataGrid()}
              rowData={this.state.rowData}
              initialData={this.state.initialData}
              selectedSearchDropdown={this.state.selectedSearchDropdown}
              getFilteredData={this.getFilteredData}
              getSelectedDropdown={this.getSelectedDropdown}
              colSpan={tableHeader.length - (showActions ? 0 : 1)}
              sortByColumn={this.sortByColumn}
              count={this.state.count}
              getData={this.getTableData}
              actionItem={true}
              rowAction={this.rowAction}
              showActions={showActions}
            >
              <Table.Header fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan={tableHeader.length - (showActions ? 0 : 1)}>
                    <Grid centered>
                      <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
                        {constants.FORM_FIELD.LIST.TOTAL_TITLE}: {this.state.count}
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8} className='dataTableHeaderButtons'>
                        <Acl entity={constants.ACL.FORM_FIELD_RESOURCE.TITLE} action={constants.ACL.FORM_FIELD_RESOURCE.ACTIONS.CREATE}>
                          <Button floated={window.screen.width < 768 ? 'none' : 'right'} icon labelPosition='left' className='blue' size='small'
                            onClick={() => {
                              this.props.history.push("/form-fields/create")
                            }}>
                            <Icon name='add' />{constants.FORM_FIELD.LIST.ADD_FORM_FIELD_BUTTON}
                          </Button>
                        </Acl>
                      </Grid.Column>
                    </Grid>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </DynamicTableComponent>
            <Modal size='mini' open={this.state.removeFormFieldFlag} onClose={() => { this.closeModal() }} closeIcon>
              <Modal.Header>{constants.FORM_FIELD.DELETE.HEADER_TITLE}</Modal.Header>
              <Modal.Content>
                <p>{constants.FORM_FIELD.DELETE.MODAL_CONTENT}</p>
              </Modal.Content>
              <Modal.Actions>
                <Button className='red' onClick={() => this.closeModal()}>No</Button>
                <Button className='blue' icon='checkmark' labelPosition='right' content='Yes' onClick={() => this.removeFormField(this.state.formFieldId)}></Button>
              </Modal.Actions>
            </Modal>
          </section>
        </section>
      </div>
    );
  }
}
export default ListFormFields
