import React  from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import constants from '../../lib/constants'
import { Grid } from 'semantic-ui-react'
import dateFormatter from '../../lib/dateFormatter'
class ComparisionLineChartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
  }
  componentDidMount() {
  }
  prepareCompareLineChartOptions = (data, config) => {
    let unit = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? ' %' : ''
    let unitLabel = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.TEXT : constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.TEXT
    let dates = Object.keys(data)
    let seriesNames = []
    let categories = []
    let finalSeries = []
    let series
    try {
      dates.map((dateItem, i) => {
        let dateItems = data[dateItem]
        dateItems.map((indDateItem) => {
          series = Object.keys(indDateItem.data)
          for (let j = 0; j < series.length; j++) {
            if (i === 0) {
              seriesNames = { ...seriesNames, [series[j]]: seriesNames[series[j]] ? seriesNames[series[j]] : [] }
            }
            seriesNames[series[j]].push(indDateItem.data[series[j]])
          }
          categories.push(dateFormatter(dateItem) + '_' + indDateItem.name)
          return null
        })
        return null
      })
      for (let j = 0; j < series.length; j++) {
        finalSeries.push({ name: series[j], data: seriesNames[series[j]] })
      }
    } catch (error) {

    }
    let options = {
      chart: {
        type: 'line',
        backgroundColor: 'transparent'
      },
      title: false,
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: unitLabel
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toFixed(2) + unit
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          let formatStr = '<span>' + this.x + '</span><br><span style="color:' + this.point.color + '">●</span> ' + this.point.series.name + ': <b>' + this.point.y.toFixed(2) + unit + '</b><br/>'
          return formatStr
        }
      },
      series: finalSeries
    }
    return options
  }
  render() {
    return (
      <React.Fragment>
        {!this.state.error &&
          <HighchartsReact
            highcharts={Highcharts}
            options={this.prepareCompareLineChartOptions(this.props.data, this.props.config)}
          />
        }
        {this.state.error &&
          <Grid centered>
            <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12} textAlign='center'>
              <div id="runStatus">
                {constants.ERROR.SOMETHING_WENT_WRONG}
              </div>
            </Grid.Column>
          </Grid>
        }
      </React.Fragment>
    )
  }
}
export default ComparisionLineChartComponent
