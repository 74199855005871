import React from 'react'
import PropTypes from 'prop-types'
import { Table, Menu, Input, Icon } from 'semantic-ui-react'

function QCTableFooter({ currentPage, totalPages, onPageChange }) {
	const onFirstPage = currentPage === 1
	const onLastPage = currentPage === totalPages

	const onPageNumberChange = (event) => {
		const pageNumber = parseInt(event.target.value, 10)
		if (!(pageNumber > totalPages) && !Number.isNaN(pageNumber)) {
			onPageChange(pageNumber)
		}
	}

	return (
		<Table.Footer>
			<Table.Row>
				<Table.HeaderCell colSpan='5'>
					<Menu pagination>
						<Menu.Item as='a'>
							<Input label='Page No' size='mini' type='number' value={currentPage} min='1' max={totalPages} onChange={onPageNumberChange} />
						</Menu.Item>
						<Menu.Item as='a' icon disabled={onFirstPage} onClick={() => onPageChange(1)}>
							<Icon name='angle double left' />
						</Menu.Item>
						<Menu.Item as='a' icon disabled={onFirstPage} onClick={() => onPageChange(currentPage - 1)}>
							<Icon name='chevron left' />
						</Menu.Item>
						<Menu.Item as='a' icon>{`${currentPage} / ${totalPages}`}</Menu.Item>
						<Menu.Item as='a' icon disabled={onLastPage} onClick={() => onPageChange(currentPage + 1)}>
							<Icon name='chevron right' />
						</Menu.Item>
						<Menu.Item as='a' icon disabled={onLastPage} onClick={() => onPageChange(totalPages)}>
							<Icon name='angle double right' />
						</Menu.Item>
					</Menu>
				</Table.HeaderCell>
			</Table.Row>
		</Table.Footer>
	)
}

QCTableFooter.propTypes = {
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired
}

export default QCTableFooter
