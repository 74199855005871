import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { unregister } from './registerServiceWorker'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import HttpsRedirect from 'react-https-redirect'

const store = configureStore()

ReactDOM.render(
	<HttpsRedirect>
		<Provider store={store}>
			<App />
		</Provider>
	</HttpsRedirect>, document.getElementById('root'))
unregister()
