import React, { Component } from 'react'
import { Breadcrumb, Button, Popup, Card, Icon } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import ModalComponent from '../ModalComponent'
import cannidAPI from '../../cannidAPI/client'
import permissionCheck from '../../lib/permissionCheck'
import constants from '../../lib/constants'
import deleteS3Object from '../../lib/deleteS3Object'
import dateTimeFormatter from '../../lib/dateTimeFormatter'

class ImportTemplates extends Component {
	constructor(props) {
		super(props)
		this.state = {
			templates: [],
			apiErrors: {},
			confirmOpen: false,
			confirmingTemplate: null
		}
	}

	componentDidMount() {
		this.getImportTemplates()
	}

	getImportTemplates = () => {
		cannidAPI.get('/import_templates').then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			return response.data
		})
			.then((templates) => {
				this.setState({ templates })
			})
			.catch((err) => {
				console.error('error!', err)
				const message = `${err}. Could not retrieve import templates. Reload page.`
				this.setState({ ...this.clearForm, apiErrors: message })
			})
	}

	deleteTemplate = (template) => {
		document.body.classList.add('loading-indicator')
		// since this is a list view page, only permit delete functionality if explicitly granted
		if (permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.DELETE)) {
			this.setState({ confirmOpen: false, confirmingTemplate: null, apiErrors: {} }, () => {
				cannidAPI.delete(`/import_templates/${template.id}`).then(() => {
					if (template.source_file) {
						const key = template.source_file.split('/').splice(3).join('/')
						deleteS3Object({
							bucket: process.env.REACT_APP_AWS_CHROME_BUCKET,
							accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
							secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_ID,
							region: process.env.REACT_APP_AWS_REGION
						}, key)
					}
					setTimeout(() => {
						document.body.classList.remove('loading-indicator')
						this.props.history.go(0)
					}, 500)
				})
					.catch((err) => {
						console.error('delete template error!', err)
						let message = `${err}. Failed to delete import template.`
						if (err.response.data.message) {
							message = `${message} ${err.response.data.message}`
						}
						document.body.classList.remove('loading-indicator')
						this.setState({ apiErrors: message })
					})
			})
		}
		else {
			document.body.classList.remove('loading-indicator')
			this.setState({ apiErrors: 'User not permitted to delete import templates.' })
		}
	}

	editTemplate = (templateId) => {
		this.props.history.push(`/samples/import/templates/${templateId}/edit`)
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/test-result/list'><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/samples/import'><Breadcrumb.Section>Import New Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>Templates</Breadcrumb.Section>
			</Breadcrumb>
		)

		const templatesDisplay = this.state.templates.map((template) => (
			<Card fluid key={template.id} className='methodDisplay'>
				<Card.Content>
					<Card.Header textAlign='left'>
						{template.name}{template.default && <span className='meta' style={{ marginLeft: '5px' }}>(Default)</span>}
						{!template.global
								&& (
									<Popup
										content='Delete Import Template' size='tiny' trigger={(
											<Button
												floated='right' className='red dynamicTableActionButton' icon='remove' size='tiny'
												onClick={() => this.setState({ confirmOpen: true, confirmingTemplate: template, apiErrors: {} })}
											/>
										)}
									/>
								)}
						<Popup
							content='Edit Import Template' size='tiny' trigger={(
								<Button
									floated='right' className='blue dynamicTableActionButton' icon='edit' size='tiny'
									onClick={() => this.editTemplate(template.id)}
								/>
							)}
						/>
					</Card.Header>
					<Card.Meta textAlign='left'>
						Custom: {template.global ? '❌' : '✅'}
						&nbsp;|&nbsp;
						{template.import_template_type.name}
						&nbsp;|&nbsp;
						Created: {dateTimeFormatter(template.created_at)}
					</Card.Meta>
					<Card.Description>
						{/* {analytes} */}
					</Card.Description>
				</Card.Content>
			</Card>
		))

		return (
			// adjust methodsListPage class
			<section id='mainContent' className='app light methodsListPage'>
				<AppHeader title={<h1>Manage Import Templates</h1>} breadcrumb={breadcrumb} />
				<section className='app light'>
					<ErrorMessages errors={this.state.apiErrors} />
				</section>

				<section className='app light'>
					<Card.Group>
						{templatesDisplay}
					</Card.Group>
					{permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.CREATE)
						&& (
							<div style={{ marginTop: '15px' }}>
								<Button
									icon labelPosition='left' className='blue'
									onClick={() => {
										this.props.history.push('/samples/import/map')
									}}
								>
									<Icon name='plus' />Create
								</Button>
							</div>
						)}
				</section>

				<ModalComponent
					action
					size='large'
					openModal={this.state.confirmOpen}
					header='Delete Import Template'
					content={`Are you sure you want to delete the ${this.state.confirmingTemplate
						? this.state.confirmingTemplate.name : ''} import template?`}
					onClose={() => this.setState({ confirmOpen: false, confirmingTemplate: null })}
					handleAction={() => this.deleteTemplate(this.state.confirmingTemplate)}
				/>

			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

ImportTemplates.propTypes = {
	history: PropTypes.objectOf(PropTypes.shape({
		push: PropTypes.func.isRequired,
		go: PropTypes.func.isRequired
	})).isRequired
}.isRequired

export default connect(
	mapStateToProps
)(withRouter(ImportTemplates))
