import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Grid } from 'semantic-ui-react'
import constants from '../../lib/constants'
import dataToggle from '../../lib/dataToggle'

class ScatterChartComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			error: false
		}
	}

	componentDidMount() {
	}

	prepareScatterChartOptions = (data, config) => {
		const unit = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? ' %' : ''
		const unitLabel = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.TEXT : constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.TEXT
		const categories = []
		const parsedObject = []
		try {
			if (this.props.config.switchAxes) data = dataToggle(data)
			Object.keys(data).forEach((date) => {
				categories.push(date)
			})
			const analytes = []
			for (const date in data) {
				analytes.push(data[date])
			}
			const analyte = Object.keys(analytes[0])
			const n = analyte.length
			const dataRows = []
			for (let i = 0; i < n; i++) {
				if (analyte[i]) {
					const dataRow = analytes.map((value) => value[analyte[i]])
					const dataRowFinal = { [analyte[i]]: dataRow }
					dataRows.push(dataRowFinal)
				}
			}
			for (const key in dataRows) {
				const dataRowKeys = Object.keys(dataRows[key])
				const dataRowValues = Object.values(dataRows[key])
				parsedObject.push({ name: dataRowKeys[0], data: dataRowValues[0] })
			}
		}
		catch (error) {

		}
		const options = {
			chart: {
				type: 'scatter',
				backgroundColor: 'transparent'
			},
			title: false,
			xAxis: {
				title: false,
				categories
			},
			yAxis: {
				title: {
					text: unitLabel
				}
			},
			plotOptions: {
				column: {
					dataLabels: {
						enabled: true,
						formatter() {
							return this.y.toFixed(2) + unit
						}
					}
				}
			},
			tooltip: {
				formatter() {
					const formatStr = `<span>${this.x}</span><br><span style="color:${this.point.color}">●</span> ${this.point.series.name}: <b>${this.point.y.toFixed(2)}${unit}</b><br/>`
					return formatStr
				}
			},
			series: parsedObject
		}
		return options
	}

	render() {
		return (
			<>
				{!this.state.error
          && (
	<HighchartsReact
	highcharts={Highcharts}
	options={this.prepareScatterChartOptions(this.props.data, this.props.config)}
          	/>
          )}
				{this.state.error
          && (
	<Grid centered>
	<Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12} textAlign='center'>
	<div id='runStatus'>
	{constants.ERROR.SOMETHING_WENT_WRONG}
          			</div>
          		</Grid.Column>
          	</Grid>
          )}
			</>
		)
	}
}
export default ScatterChartComponent
