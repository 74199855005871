import constants from './constants'
import { formatRoute } from 'react-router-named-routes'
import permissionCheck from './permissionCheck'
const QrRedirection = (data) => {
  let redirectTo = ''
  let removeShareKey = true
  let forceRedirection = false
  if (data.batch_id) {
    if (data.latitude !== '' && data.latitude !== null && data.longitude !== '' && data.longitude !== null) {
      if (data.results !== null) {
        if (permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.SHOW_REPORT) === true)
          redirectTo = formatRoute(constants.APPLICATION_ROUTE.SAMPLE.REPORT.ROUTE, { id: data.id })
        else {
          redirectTo = formatRoute(constants.APPLICATION_ROUTE.SAMPLE.SHARE_REPORT.ROUTE, { id: data.share_key })
          forceRedirection = true
        }
      }
      else {
        if (permissionCheck(constants.ACL.BATCH_TEST_RESOURCE.TITLE, constants.ACL.BATCH_TEST_RESOURCE.ACTIONS.EDIT) === true)
          redirectTo = formatRoute(constants.APPLICATION_ROUTE.BATCH_TEST.EDIT.ROUTE, { id: data.id })
        else
          redirectTo = formatRoute(constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE)
      }
    }
    else {
      if (permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.CREATE) === true) {
        redirectTo = formatRoute(constants.APPLICATION_ROUTE.SCAN_QR_CODE.ROUTE, { id: data.id })
        removeShareKey = false
      }
      else
        redirectTo = formatRoute(constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE)
    }
  }
  else {
    if (data.results !== null) {
      if (permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.SHOW_REPORT) === true)
        redirectTo = formatRoute(constants.APPLICATION_ROUTE.SAMPLE.REPORT.ROUTE, { id: data.id })
      else {
        redirectTo = formatRoute(constants.APPLICATION_ROUTE.SAMPLE.SHARE_REPORT.ROUTE, { id: data.share_key })
        forceRedirection = true
      }
    }
    else {
      if (permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.EDIT) === true)
        redirectTo = formatRoute(constants.APPLICATION_ROUTE.SAMPLE.EDIT.ROUTE, { id: data.id })
      else
        redirectTo = formatRoute(constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE)
    }
  }
  if (localStorage.getItem('share_key') && removeShareKey) {
    localStorage.removeItem('share_key')
  }
  if (localStorage.removeItem('organization_id')) {
    localStorage.removeItem('organization_id')
  }
  return { redirectTo, forceRedirection }
}

export default QrRedirection
