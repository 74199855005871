import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Button, Icon, List, Modal, Checkbox, Form, Segment, Message } from 'semantic-ui-react'
import AppHeader from './AppHeader'
import decodeJWT from '../lib/decodeJWT'
import ToastMessage from './Message'
import { Link } from 'react-router-dom'
import { LogoutServices } from '../services/Logout'
import ErrorMessages from './Errors/ErrorMessages'
import constants from '../lib/constants'
import Acl from '../components/ACL/Acl'
import { formatRoute } from 'react-router-named-routes'

import { CHEMID_LOGIN, CHEMID_LOGOUT, CHEMID_ENTITY } from '../actions'
import authProvider from '../lib/authProvider'
import ConditionalRender from './ConditionalRender'
import permissionCheck from '../lib/permissionCheck'
import trimWhiteSpace from '../lib/trim'
import { setChemidUser, setChemidEntity, logoutChemid } from '../actions'

class Settings extends Component {
	constructor(props) {
		super(props)
		this.chemidFormDefault = {
			loading: false,
			email: '',
			password: '',
			emailError: false,
			passwordError: false,
			loginError: false,
			entityOptions: []
		}
		this.state = {
			isDevice: false,
			error: '',
			success: false,
			checked: localStorage.getItem('betaFeatures') === "true" ? true : false,
			...this.chemidFormDefault
		}
		this.mounted = false
	}
	componentDidMount() {
		this.mounted = true
		if (this.props.chemidUser) this.getEntities()
	}
	componentWillUnmount() {
		this.mounted = false
	}
	handleClose = () => {
		this.setState({ isDevice: false })
	}
	navOpen = () => {
		this.setState({ isDevice: true })
	}
	logoutFromAll = () => {
		let token = decodeJWT(localStorage.getItem('access_token'))
		LogoutServices.logoutFromAll(token.session_id).then((res) => {
			this.handleClose()
			this.setState({ success: true })
		}).catch((e) => {
			this.setState({ error: (e.data) ? e.data : [] })
			this.handleClose()
		})
	}

	checkEmail = (email) => {
		let re = constants.REG_EX.EMAIL_REG_EX
		return re.test(trimWhiteSpace(String(email)).toLowerCase())
	}
	update = (e) => {
		const newState = { loginError: false }
		let target = e.target
		newState[target.name] = target.value
		this.setState(newState, () => {
			if (target.name === 'email') this.setState({ emailError: false })
			if (target.name === 'password') this.setState({ passwordError: false })
		})
	}
	getEntities = () => {
		this.setState({ loading: true, loginError: false })
		authProvider(CHEMID_ENTITY).then((res) => {
			if (res.error) this.setState({ loading: false, loginError: true })
			let entity = null
			if (res.length > 0 && !this.props.chemidEntity) {
				entity = res[0]
				this.props.setChemidEntity(entity)
			}
			else if (this.props.chemidEntity) entity = this.props.chemidEntity
			if (this.mounted) this.setState({ loading: false, loginError: false, chemidEntity: entity, entityOptions: res })
		}).catch((error) => {
			this.setState({ loading: false, loginError: true })
		})
	}
	login = () => {
		const validEmail = this.checkEmail(this.state.email)
		const validPassword = !!this.state.password
		this.setState({ loading: true, emailError: !validEmail, passwordError: !validPassword, loginError: false })
		if (!validEmail || !validPassword) return
		
		let configJson = {
			email: trimWhiteSpace(this.state.email),
			password: this.state.password
		}
		authProvider(CHEMID_LOGIN, configJson).then((res) => {
			if (res.error) this.setState({ loading: false, loginError: true })
			const { email, firstName, lastName } = res
			const user = { email, firstName, lastName }
			this.props.setChemidUser(user)
			this.getEntities()	
		}).catch((error) => {
			this.setState({ loading: false, loginError: true })
		})

	}
	logout = () => {
		this.props.logoutChemid()
		this.setState(this.chemidFormDefault)
		authProvider(CHEMID_LOGOUT)
	}
	buildChemidLogin = () => {
		return this.props.chemidUser ? (
			<div>
				<Form>
					<p><strong>Email:</strong> {this.props.chemidUser.email}</p>
					<p><strong>Name:</strong> {this.props.chemidUser.firstName} {this.props.chemidUser.lastName}</p>
					<Form.Select
						label='Entity'
						options={this.state.entityOptions.map((x) => ({ key: x.id, text: x.name, value: x.id }))}
						value={this.props.chemidEntity ? this.props.chemidEntity.id : null}
						placeholder='Entity'
						onChange={(event, data) => {
							const entity = this.state.entityOptions.filter(x => x.id === data.value)
							this.props.setChemidEntity(entity[0])
							this.setState({ chemidEntity: entity[0] })
						}}
						disabled={this.state.entityOptions.length <= 1}
					/>
				</Form>
				{this.state.loginError && <Message error style={{display:'block',maxWidth:'50%',margin:'0 auto',marginTop: '1rem'}}>Login Failed. Please try again.</Message>}
				<Button className='Margin' style={{marginTop: '1rem'}} type='submit' onClick={this.logout}>ChemID Logout</Button>
				{!this.props.chemidEntity && <Message warning style={{display:'block',maxWidth:'50%',margin:'0 auto',marginTop: '1rem'}}>Notice: Successfully logged into the ChemID account but it does not belong to any Entities. Users cannot send data to ChemID without an Entity.</Message>}
			</div>
		) : (
			<div>
				<Form>
					<Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label='Email' placeholder='Email' className='required-field' type='text' name="email" value={this.state.email} onChange={this.update} ref={this.emailRef} error={this.state.emailError} />
					{this.state.emailError && <Message error style={{display:'block',maxWidth:'50%',margin:'0 auto'}}>Invalid email address</Message>}
					<Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label='Password' placeholder='Password' type='password' error={this.state.passwordError} value={this.state.password} className='required-field' onChange={this.update} name="password" ref={this.passwordRef} />
					{this.state.passwordError && <Message error style={{display:'block',maxWidth:'50%',margin:'0 auto'}}>Invalid password</Message>}
				</Form>
				{this.state.loginError && <Message error style={{display:'block',maxWidth:'50%',margin:'0 auto',marginTop: '1rem'}}>Login Failed. Please try again.</Message>}
				<Button className='userButton Margin' style={{marginTop: '1rem'}} type='submit' onClick={this.login}>ChemID Sign In</Button>
			</div>
		)
	}
	render() {
		const breadcrumb = (
		<Breadcrumb>
			<Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
			<Breadcrumb.Divider icon="right angle" />
			<Breadcrumb.Section active>Settings</Breadcrumb.Section>
		</Breadcrumb>
		)
		const canCoa = permissionCheck(constants.APPLICATION_ROUTE.SAMPLE.POPULATE_COA.TITLE, constants.APPLICATION_ROUTE.SAMPLE.POPULATE_COA.ACTIONS.UPDATE_COA)
		return (
			<div>
				<section id="mainContent" className="app light">
					<AppHeader title={<h1>Home</h1>} breadcrumb={breadcrumb} />
					<section className="app light">
						<div className="contentBlock listBlock">
							{this.state.error && <ErrorMessages errors={this.state.error}></ErrorMessages>}
							{this.state.success ? <ToastMessage title='Success' description={constants.LOGOUT.OTHER_DEVICE.CONFIRM.SUCCESS} /> : null}
							<ul>
								<li><Acl entity={constants.ACL.USER_RESOURCE.TITLE} action={constants.ACL.USER_RESOURCE.ACTIONS.MY_ACCOUNT}>
								<Link to={formatRoute(constants.APPLICATION_ROUTE.ACCOUNT.ROUTE, {})}><Icon name="wrench" />{constants.APPLICATION_ROUTE.ACCOUNT.NAME}</Link>
								</Acl></li>
								<li><Acl entity={constants.ACL.USER_RESOURCE.TITLE} action={constants.ACL.USER_RESOURCE.ACTIONS.CHANGE_PASSWORD}><List className={'logout_other_devices'}><List.Item onClick={() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.USER.CHANGE_PASSWORD.ROUTE, {}))}><Icon name="privacy" id="settingsIcon" />{constants.APPLICATION_MENU.CHANGE_PASSWORD}</List.Item></List></Acl></li>
								<li><Acl entity={constants.ACL.USER_RESOURCE.TITLE} action={constants.ACL.USER_RESOURCE.ACTIONS.LOGOUT_OTHER_DEVICES}><List className={'logout_other_devices'}><List.Item onClick={() => this.navOpen()}><Icon name="power off" id="settingsIcon" />{constants.SETTINGS.LOG_OUT_FROM_OTHER_DEVICES_BUTTON_TITLE}</List.Item></List></Acl></li>
							</ul>
							<div className="betaFeatures">
								<em>enable beta features</em>
								<Checkbox toggle checked={this.state.checked} onClick={() => this.setState({checked: !this.state.checked}, localStorage.setItem('betaFeatures', !this.state.checked))} />
							</div>
						</div>
					</section>
					<ConditionalRender condition={canCoa}>
						<section className="app light">
							<div className="contentBlock listBlock">
								<Segment>
									<h3>
										<img width={19} height={18} style={{ marginRight: '4px', marginLeft: '4px' }} src='https://cdn.chemid.com/images/chemid-logo-image.png' alt='chemid.logo' />
										ChemID Login
									</h3>
									{this.buildChemidLogin()}
								</Segment>
							</div>
						</section>
					</ConditionalRender>
				</section>				
				<Modal size='mini' open={this.state.isDevice} onClose={() => { this.handleClose() }} closeIcon>
					<Modal.Header>{constants.LOGOUT.OTHER_DEVICE.CONFIRM.HEADER_TITLE}</Modal.Header>
					<Modal.Content>
						<p>{constants.LOGOUT.OTHER_DEVICE.CONFIRM.MODAL_CONTENT}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button className='cancelButton' onClick={() => this.handleClose()}>No</Button>
						<Button className='confirmButton' icon='checkmark' labelPosition='right' content='Yes' onClick={this.logoutFromAll}></Button>
					</Modal.Actions>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	chemidUser: state.chemidUser,
	chemidEntity: state.chemidEntity
})
const mapDispatchToProps = (dispatch) => ({
	setChemidUser: (chemidUser) => { dispatch(setChemidUser(chemidUser)) },
	setChemidEntity: (chemidEntity) => { dispatch(setChemidEntity(chemidEntity)) },
	logoutChemid: () => { dispatch(logoutChemid()) }
})

export default connect(
	mapStateToProps, mapDispatchToProps
)(Settings)
