import React, { Component } from 'react'
import { Breadcrumb, Button, Popup, Card, Icon } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppHeader from './AppHeader'
import ErrorMessages from './Errors/ErrorMessages'
import ModalComponent from './ModalComponent'
import cannidAPI from '../cannidAPI/client'
import permissionCheck from '../lib/permissionCheck'
import constants from '../lib/constants'

class ReportMethods extends Component {
	constructor(props) {
		super(props)
		this.state = {
			methods: [],
			apiErrors: {},
			confirmOpen: false,
			confirmingMethod: null
		}
		this.mounted = false
	}

	componentDidMount() {
		this.mounted = true
		// get all org and global methods, ignoring global methods if overridden
		cannidAPI.get('/methods').then((response) => {
			if (this.mounted) {
				this.setState({ methods: response.data })
			}
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. Failed to retrieve methods.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	componentWillUnmount() {
		this.mounted = false
	}

	deleteMethod = (method) => {
		// since this is a list view page, only permit delete functionality if explicitly granted
		if (permissionCheck(constants.ACL.REPORT_METHOD_RESOURCE.TITLE, constants.ACL.REPORT_METHOD_RESOURCE.ACTIONS.DELETE)) {
			this.setState({ confirmOpen: false, confirmingMethod: null, apiErrors: {} }, () => {
				cannidAPI.delete(`/methods/${method.id}`).then(() => {
					this.props.history.go(0)
				}).catch((err) => {
					console.error('error!', err)
					let message = `${err}. Failed to delete report method.`
					if (err.response.data.message) {
						message = `${message} ${err.response.data.message}`
					}
					this.setState({ apiErrors: message })
				})
			})
		}
		else {
			this.setState({ apiErrors: 'User not permitted to delete report methods.' })
		}
	}

	editMethod = (methodId) => {
		this.props.history.push(`/samples/report-methods/${methodId}/edit`)
	}

  render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to="/"><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Link to="/test-result/list"><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Breadcrumb.Section>Report Methods</Breadcrumb.Section>
			</Breadcrumb>
		)

		const methodsDisplay = this.state.methods.map((details, meth_index) => {
			const analytes = details.analytes.map((analyte, anal_index) => {
				const analyteColor = `#${analyte.color}`
				return (
					<Popup
						key={anal_index} content={analyte.analyte} size='tiny' trigger={(
							<div
								className='ui button dynamicTableActionButton analytePill'
								style={{ backgroundColor: analyteColor }}
							>
								{analyte.analyte}
							</div>
						)}
					/>
				)
			})

			const deleteButton = (details.global || details.override_method_id) ? '' : (
				<Popup
					content='Delete Report Method' size='tiny' trigger={(
						<Button
							floated='right' className='red dynamicTableActionButton' icon='remove' size='tiny'
							onClick={() => this.setState({ confirmOpen: true, confirmingMethod: details, apiErrors: {} })}
						/>
					)}
				/>
			)

			return (
				<Card fluid key={meth_index} className='methodDisplay'>
					<Card.Content>
						<Card.Header textAlign='left'>
							{details.method_name}
							{deleteButton}
							{details.default && <span className='meta' style={{ marginLeft: '5px' }}>(Default)</span>}
							<Popup
								content='Edit Report Method' size='tiny' trigger={(
									<Button
										floated='right' className='blue dynamicTableActionButton' icon='edit' size='tiny'
										onClick={() => this.editMethod(details.id)}
									/>
								)}
							/>
						</Card.Header>
						<Card.Meta textAlign='left'>
							Custom: {(details.global || details.override_method_id) ? '❌' : '✅'}
							&nbsp;|&nbsp;
							Calculated Totals: {details.derived_compounds ? '✅' : '❌'}
						</Card.Meta>
						<Card.Meta textAlign='left'>
							Upper Bounds: {details.upper_bounds} &nbsp;|&nbsp; Lower Bounds: {details.lower_bounds}
						</Card.Meta>
						<Card.Description>
							{analytes}
						</Card.Description>
					</Card.Content>
				</Card>
			)
		})

		return (
			<section id='mainContent' className='app light methodsListPage'>
				<AppHeader title={<h1>Report Methods</h1>} breadcrumb={breadcrumb} />
				<section className='app light'>
					<ErrorMessages errors={this.state.apiErrors} />
				</section>

				<section className='app light'>
					<Card.Group>
						{methodsDisplay}
					</Card.Group>
					{permissionCheck(constants.ACL.REPORT_METHOD_RESOURCE.TITLE, constants.ACL.REPORT_METHOD_RESOURCE.ACTIONS.CREATE)
&& (
	<div style={{ marginTop: '15px' }}>
		<Button
			icon labelPosition='left' className='blue'
			onClick={() => {
				this.props.history.push('/samples/report-methods/create')
			}}
		>
			<Icon name='plus' />Create
		</Button>
	</div>
)}
				</section>

				<ModalComponent
					action
					size='large'
					openModal={this.state.confirmOpen}
					header='Delete Report Method'
					content='Are you sure you want to delete this Report Method?'
					onClose={() => this.setState({ confirmOpen: false, confirmingMethod: null })}
					handleAction={() => this.deleteMethod(this.state.confirmingMethod)}
				/>

			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

ReportMethods.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired
	}).isRequired
}

export default connect(
	mapStateToProps
)(withRouter(ReportMethods))
