import React, { Component } from 'react'
import { Breadcrumb, Image, Label, Table, Menu, Grid, Input, Icon, Header, List } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import cannidAPI from '../../cannidAPI/client'
import CertusActionButtons from './CertusActionButtons'
import constants from '../../lib/constants'
import permissionCheck from '../../lib/permissionCheck'
import getParameterByName from '../../lib/getParams'
import certusLogoGreen from '../../images/certus_transparent.png'
import certusLogoGray from '../../images/certus_transparent_grayed.png'
import CertusTokenBank from './CertusTokenBank'

class CertusList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			meta: {},
			certi: [],
			search: '',
			pageJumper: '1',
			jumping: false,
			apiErrors: {}
		}
		this.mounted = false
	}

	componentDidMount() {
		const pageUrl = getParameterByName('page', decodeURI(this.props.location.search))
		this.getCertiRecords(pageUrl || 1)
	}

	componentWillUnmount() {
		this.mounted = false
	}

	getCertiRecords = (page, search) => {
		this.mounted = true
		// get list of certi records, all types
		let url = `/certi?page=${page}`
		if (search) url += `&search=${search.toUpperCase()}`
		cannidAPI.get(url).then((response) => {
			if (this.mounted) {
				this.setState({
					certi: response.data.data,
					meta: response.data.data.length > 0 ? response.data.meta : {},
					pageJumper: response.data.meta.currentPageNo.toString()
				})
			}
		}).catch((err) => {
			console.log('error!', err)
			const message = `${err}. Failed to retrieve certi.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	jumpPage = (e) => {
		if (this.state.meta.currentPageNo.toString() !== this.state.pageJumper) {
			const pageNo = (parseInt(this.state.pageJumper) < this.state.meta.firstPageNo) || this.state.pageJumper === ''
				? 1 : (parseInt(this.state.pageJumper) > this.state.meta.lastPageNo)
					? this.state.meta.lastPageNo : this.state.pageJumper
			this.setState({ jumping: false })
			this.props.history.push(`${this.props.history.location.pathname}?page=${pageNo}`)
			this.getCertiRecords(pageNo, this.state.search)
		}
	}

	changePage = (pageNum) => {
		this.props.history.push(`${this.props.history.location.pathname}?page=${pageNum}`)
		this.getCertiRecords(pageNum, this.state.search)
	}

	updateSearch = (event) => {
		event.preventDefault()
		// 3 char minimum in search
		if (event.target.value.length >= 3) {
			this.getCertiRecords(1, event.target.value)
			this.setState({ pageJumper: '1', search: event.target.value })
		}
		else {
			this.getCertiRecords(1)
			this.setState({ pageJumper: '1', search: '' })
		}
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section><div style={{ display: 'flex' }}>CERTUS<Icon name='registered outline' size='tiny' /></div></Breadcrumb.Section>
			</Breadcrumb>
		)

		const logo = this.state.certi.length > 0 ? <Image className='certusLogo' src={certusLogoGreen} alt='Certus Logo' /> : <Image className='certusLogo' src={certusLogoGray} alt='Certus Logo' />
		const emptyTokenBank = this.props.certusBankInfo && this.props.certusBankInfo.quantity == 0
		const title = (
			<div className='titleGrid'>
				{logo}<h1 style={{ display: 'flex', marginTop: 0 }}>&nbsp;CERTUS<Icon style={{ marginTop: '.5rem' }} name='registered outline' size='tiny' /></h1>
			</div>
		)
		
		const rows = this.state.certi.map((certus, index) => {
			if (certus != null) {
				let link
				switch (certus.certus_type.id) {
				case 6: // certificate of origin
					link = `/certus/certificate/${certus.id}`
					break
				// case 5 is 'kitemark' certus, not currently used
				case 4: // transportation
					link = `/certus/transportation/${certus.id}`
					break
				case 3: // project
					link = `/certus/project/${certus.id}`
					break
				default: // case 1 & 2, chemical report & coa
					link = `/sample/${certus.sample.id}/results`
				}

				// Test for expired
				const expiry_date = Date.parse(certus.expiration_date)
				const today = new Date()

				const expiration = certus.revoked
					? <Table.Cell negative>Revoked</Table.Cell>
					: (today.getTime() > expiry_date)
						? <Table.Cell negative>Expired:  {certus.expiration_date}</Table.Cell>
						: <Table.Cell> {certus.expiration_date} </Table.Cell>

				// Show buttons or revoke
				const buttons = certus.revoked
					? <Table.Cell negative>Revoked </Table.Cell>
					: <Table.Cell><CertusActionButtons certus={certus} /></Table.Cell>

				return (
					<Table.Row className='certusListRow' key={`certus${index}`} onClick={() => this.props.history.push(link)}>
						<Table.Cell>{certus.project_name}</Table.Cell>
						<Table.Cell>{certus.certus_type.certus_type}</Table.Cell>
						<Table.Cell>{certus.share_key}</Table.Cell>
						{expiration}
						<Table.Cell>{certus.created_at}</Table.Cell>
						<Table.Cell>{certus.user.name}</Table.Cell>
						{buttons}
					</Table.Row>
				)
			}
		})

		return (
			<section id='mainContent' className='app light certusList'>
				<ErrorMessages errors={this.state.apiErrors} />
				<AppHeader breadcrumb={breadcrumb} />
				<Grid stackable columns={3}>
					<Grid.Row>
						<Grid.Column />
						<Grid.Column>
							<AppHeader title={title} />
						</Grid.Column>
						{ permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.PROJECT)
							? <Grid.Column><CertusTokenBank /></Grid.Column> : <Grid.Column /> }
					</Grid.Row>
				</Grid>
				{(permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.PROJECT) || permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.TRANSPORTATION) || permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.CERTIFICATE)) && (
					<section className='certusListHeader'>
						<Grid>
							<Grid.Row>
								<Grid.Column>
									<Menu className='sampleActionsMenu' compact stackable>
										{permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.CERTIFICATE) && (
											<Menu.Item
												disabled={emptyTokenBank}
												name='Create Certificate of Origin'
												onClick={() => this.props.history.push('/certus/certificate/create')}
											/>
										)}
										{permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.PROJECT) && (
											<Menu.Item
												disabled={emptyTokenBank}
												name='Create Project'
												onClick={() => this.props.history.push('/certus/project/create')}
											/>
										)}
										{permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.TRANSPORTATION) && (
											<Menu.Item
												disabled={emptyTokenBank}
												name='Create Transportation Manifest'
												onClick={() => this.props.history.push('/certus/transportation/create')}
											/>
										)}
									</Menu>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</section>
				)}
				<div style={{marginTop: '15px'}}>
					<Input icon='search' type='text' minLength='3' id='previousSearchInput'
						onChange={this.updateSearch}
						label='Search'
						placeholder='3 Character Minimum...'
						style={{minWidth: '40%'}} />
				</div>
				<section className='certusSelectorTable'>
					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell>VERISEAL</Table.HeaderCell>
								<Table.HeaderCell>TRACER</Table.HeaderCell>
								<Table.HeaderCell>Expiration</Table.HeaderCell>
								<Table.HeaderCell>Created Date</Table.HeaderCell>
								<Table.HeaderCell>Created By</Table.HeaderCell>
								<Table.HeaderCell>CERTUS<Icon style={{ verticalAlign: 'top' }} name='registered outline' size='tiny' /> Actions</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{this.state.certi.length > 0 ? rows : (
								<Table.Row>
									<Table.Cell colSpan={7} style={{ padding: '2rem' }}>
										<p>No results found. Please alter your search.</p>
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
						{Object.keys(this.state.meta).length > 0 && (
							<Table.Footer>
								<Table.Row colSpan={6} size={window.screen.width < 768 ? 'mini' : 'medium'}>
									<Table.HeaderCell colSpan={7}>
										<Menu floated='right' className={window.screen.width < 768 ? 'menuPagination' : ''} pagination>
											<Menu.Item as='a'>
												<Input label='Page No' size='mini' value={this.state.pageJumper} name='pageNo' min='1' max={this.state.meta.lastPageNo.toString()} type='number' onChange={(e) => { this.setState({ pageJumper: e.target.value, jumping: true }) }} onBlur={(e) => this.jumpPage(e)} />
											</Menu.Item>
											<Menu.Item
												as='a' icon disabled={!this.state.meta.previousPageNo} onClick={() => {
													this.changePage(this.state.meta.firstPageNo)
												}}
											>
												<Icon name='angle double left' size={window.screen.width < 768 ? 'small' : 'large'} />
											</Menu.Item>
											<Menu.Item
												as='a' icon disabled={!this.state.meta.previousPageNo} onClick={() => {
													this.changePage(this.state.meta.previousPageNo)
												}}
											>
												<Icon name='chevron left' size={window.screen.width < 768 ? 'small' : 'large'} />
											</Menu.Item>
											<Menu.Item as='a' icon>
												{this.state.meta.currentPageNo}/ {this.state.meta.lastPageNo}
											</Menu.Item>
											<Menu.Item
												as='a' icon disabled={!this.state.meta.nextPageNo} onClick={() => {
													this.changePage(this.state.meta.nextPageNo)
												}}
											>
												<Icon name='chevron right' size={window.screen.width < 768 ? 'small' : 'large'} />
											</Menu.Item>
											<Menu.Item
												as='a' icon disabled={!this.state.meta.nextPageNo} onClick={() => {
													this.changePage(this.state.meta.lastPageNo)
												}}
											>
												<Icon name='angle double right' size={window.screen.width < 768 ? 'small' : 'large'} />
											</Menu.Item>
										</Menu>
									</Table.HeaderCell>
								</Table.Row>
							</Table.Footer>
						)}
					</Table>
				</section>
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user,
	certusBankInfo: state.certusBankInfo
})

export default connect(
	mapStateToProps
)(withRouter(CertusList))
