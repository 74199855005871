import React  from 'react'
import LineChartComponent from './LineChartComponent'
import PieChartComponent from './PieChartComponent'
import BarChartComponent from './BarChartComponent'
import ScatterChartComponent from './ScatterChartComponent'
import constants from '../../lib/constants'
import ComparisionLineChartComponent from './ComparisionLineChartComponent'
import ComparisionScatterChartComponent from './ComparisionScatterChartComponent'
import ComparisionBarChartComponent from './ComparisionBarChartComponent'
import ComparisionPieChartComponent from './ComparisionPieChartComponent'
class ChartComponent extends React.Component {
  componentDidMount() {

  }
  getChart = () => {
    let chart
    switch (this.props.type) {
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.LINE:
        chart = (this.props.config.is_compare_report ? <ComparisionLineChartComponent data={this.props.data} config={this.props.config} /> : <LineChartComponent data={this.props.data} config={this.props.config} />)
        break
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.PIE:
        chart = (this.props.config.is_compare_report ? <ComparisionPieChartComponent data={this.props.data} config={this.props.config} /> : <PieChartComponent data={this.props.data} config={this.props.config} />)
        break
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.BAR:
        chart = (this.props.config.is_compare_report ? <ComparisionBarChartComponent data={this.props.data} config={this.props.config} /> : <BarChartComponent data={this.props.data} config={this.props.config} />)
        break
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.SCATTER:
        chart = (this.props.config.is_compare_report ? <ComparisionScatterChartComponent data={this.props.data} config={this.props.config} /> : <ScatterChartComponent data={this.props.data} config={this.props.config} />)
        break
      default:
        break
    }
    return chart
  }
  render() {
    return (
      <div>
        {this.getChart()}
      </div>
    )
  }
}
export default ChartComponent
