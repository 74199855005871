import React  from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class BarChartComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  prepareBarOptions = (data) => {
    const labels = this.props.elution_order.filter((analyte) => Object.keys(data).includes(analyte))
    const bars = labels.map((analyte) => {
        const analyteBar = data[analyte]
        return {y: analyteBar.y, color: analyteBar.color, name: analyte, label: analyteBar.label}
    })

    let options = {
      chart: {
      type: 'column',
      backgroundColor: 'transparent',
      },
      title: false,
      xAxis: {
        gridLineWidth: 1,
        categories: labels,
        crosshair: true
      },
      yAxis: {
        allowDecimals: true,
        floor: 0,
        title: {
            text: 'mg/g'
        }
      },
      tooltip: {
        formatter: function () {
          let formatStr = '<span style="color:' + this.point.color + '"></span> ' + this.point.name + ': <b>' + this.point.label + ' </b><br />'
          return formatStr
        }
      },
      series: [{
        data: bars
      }],
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      }
    }
    return options
  }

  render(){
    return (
        <HighchartsReact
        highcharts={Highcharts}
        options={this.prepareBarOptions(this.props.barchart)}
        />
    )
  }
}
export default BarChartComponent
