import React, { Component } from 'react'
import permissionCheck from '../../lib/permissionCheck'

class Acl extends Component {
	restrict = (children) => React.Children.map(children, (child) => this.filterByType(child.type.name ? child.type.name : child.type.displayName, child))

	filterByType = (componentName, component) => {
		switch (componentName) {
		case 'TableComponent':
		case 'Popup':
		case 'DownloadExcel':
		case 'List':
		case 'Link':
		case 'li':
		case 'Grid':
		case 'GridColumn':
		case 'Button': {
			const access = this.checkAccess()
			if (!access[this.props.action]) {
				return <></>
			}
			return component
		}
		default: {
			return component
		}
		}
	}

	getMergedAccess = () => {
		const access = {
			[this.props.action]: false
		}
		if (!this.props.entity || !this.props.action) {
			return access
		}
		access[this.props.action] = permissionCheck(this.props.entity, this.props.action)
		return access
	}

	checkAccess = () => {
		const access = this.getMergedAccess()
		return access
	}

	render() {
		const { children } = this.props
		const proppedChildren = this.restrict(children)
		return (
			<>
				{proppedChildren}
			</>
		)
	}
}
export default Acl
