import React, { useState } from 'react'
import { Button, Card, Icon, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PaymentForm from './PaymentForm'

const certusTokenBank = (props) => {
	const [juri, setJuri] = useState(<><p>No Jurisdiction Found</p> Please submit a <a href={`${process.env.REACT_APP_SELF_REFERENCE_ROOT}/support`}> support ticket.</a></>)
	const [open, setOpen] = useState(false)

	let tokenColor = 'black'
	if (props.certusBankInfo) {
		if (props.certusBankInfo.quantity === 0) {
			tokenColor = 'red'
		}
		else if (props.certusBankInfo.quantity <= 5) {
			tokenColor = 'orange'
		}

	}

	const creditCardButton = (
		<div>
			<Card.Content extra>
				<Button
					as='div'
					labelPosition='right'
					onClick={() => setOpen(true)}
				>
					{ props.certusBankInfo && props.certusBankInfo.card
						? (
							<>
								<Label basic color='orange' size='medium'>
									<Icon name='credit card outline' />
									Edit
								</Label>
								<Label basic color='orange' pointing='left' size='medium'>
									{props.certusBankInfo.card}
								</Label>
							</>
						)
						: (
							<>
								<Label basic color='green'>
									<Icon name='credit card outline' />
								</Label>
								<Label basic color='green' pointing='left'>
									Enter Card Information
								</Label>
							</>
						)}
				</Button>
			</Card.Content>
		</div>
	)
	return (
		<Card.Group style={{justifyContent: 'center'}}>
			<Card>
				<Card.Content>
					<Card.Header>
						<div style={{ display: 'flex', marginTop: 0, justifyContent: 'center' }}> CERTUS <Icon style={{ marginTop: '.2rem' }} size='tiny' name='registered outline' /> Token Bank </div>
					</Card.Header>
					{props.certusBankInfo && props.certusBankInfo.juri
						? (
							<>
								<Card.Meta> {props.certusBankInfo.juri}, {props.certusBankInfo.region} </Card.Meta>
								<Card.Description>
									<div style={{ paddingBottom: '10px' }}><strong>Quantity: </strong><span style={{ color: tokenColor }}>{ props.certusBankInfo && props.certusBankInfo.quantity } </span></div>
								</Card.Description>
							</>
						)
						:						(
							<div>{juri}</div>
						)}
					{props.certusBankInfo && props.certusBankInfo.juri && creditCardButton}
				</Card.Content>
				<PaymentForm
					{...props}
					open={open}
					closeModal={() => setOpen(false)}
				/>
			</Card>
		</Card.Group>
	)
}

const mapStateToProps = (state) => ({
	user: state.current_user,
	certusBankInfo: state.certusBankInfo
})

export default connect(
	mapStateToProps
)(withRouter(certusTokenBank))
