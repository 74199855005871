import React, { Component } from 'react'
import { Icon, Progress } from 'semantic-ui-react'
import momentWithZone from 'moment-timezone'

class AcquiringVialStatus extends Component {
  constructor(props) {
    super(props)
    this.sample = props.sampleQueue.sample
    // example acquisition_started_at format from API -- 2022-02-25 04:05:44 UTC
    this.acquisition_start_time = (this.sample && this.sample.acquisition_started_at) ? this.sample.acquisition_started_at.replace(" UTC", "Z").replace(" ", "T") : new Date()
    let now = momentWithZone()
    this.state = {
      elapsedSeconds: now.diff(this.acquisition_start_time, 'seconds')
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      let now = momentWithZone()
      this.setState((prevState, props) => {
        const differ = (props.sampleQueue.sample && props.sampleQueue.sample.acquisition_started_at) ? props.sampleQueue.sample.acquisition_started_at.replace(" UTC", "Z").replace(" ", "T") : this.acquisition_start_time
        return {
          elapsedSeconds: now.diff(differ, 'seconds')
        }
      })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    let vialCount = this.props.sampleQueue.locations.indexOf(this.props.acquiringVialNumber) + 1
    let minutes = Math.floor(this.state.elapsedSeconds / 60)
	let seconds = this.state.elapsedSeconds - minutes * 60
	minutes = `${minutes}`.length === 1 ? `0${minutes}` : `${minutes}`
	seconds = `${seconds}`.length === 1 ? `0${seconds}` : `${seconds}`
    return (
      <div className="acquiringVialStatus">
        <span className="sampleName">{this.props.sampleQueue.sample.name}: Vial {vialCount} of {this.props.sampleQueue.locations.length}</span>
        <div>Duration {minutes}:{seconds}</div>
      </div>
    )
  }
}

export default AcquiringVialStatus
