import React from 'react'
import { Progress } from 'semantic-ui-react'
import constants from './constants'
function PasswordStrengthMeter(props) {
  let percent = 0
  let color = 'red'
  let label = constants.PASSWORD_STRENGTH_METER.WEAK
  switch (props.score) {
    case 0:
      percent = 10
      color = 'red'
      label = constants.PASSWORD_STRENGTH_METER.WEAK
      break;
    case 1:
      percent = 10
      color = 'red'
      label = constants.PASSWORD_STRENGTH_METER.WEAK
      break;
    case 2:
      percent = 55
      color = 'yellow'
      label = constants.PASSWORD_STRENGTH_METER.FAIR
      break;
    case 3:
      percent = 75
      color = 'orange'
      label = constants.PASSWORD_STRENGTH_METER.GOOD
      break;
    case 4:
      percent = 100
      color = 'green'
      label = constants.PASSWORD_STRENGTH_METER.STRONG
      break;
    default:
      break;
  }
  return (props.score >= 0) ? (<Progress percent={percent} color={color} size='tiny' > {label}</Progress>) : null
}

export default PasswordStrengthMeter
