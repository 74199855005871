import React, { Component } from 'react'
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Menu,
  Segment,
  Table,
  Input,
  Popup,
  Checkbox,
  Grid
} from 'semantic-ui-react'
import SearchComponent from './SearchComponent'
import { DynamicTableServices } from '../services/DynamicTable'
import Acl from './ACL/Acl'
import ErrorMessages from './Errors/ErrorMessages'
import { withRouter } from 'react-router'
import getParameterByName from '../lib/getParams'
import CertusModalCreate from './Certus/CertusModalCreate.js'
import CertusModalQr from './Certus/CertusModalQr.js'
import CertusTokenBank from './Certus/CertusTokenBank'
import { ReactComponent as CertusLogoSvg } from '../images/certus_check.svg'
import permissionCheck from '../lib/permissionCheck'
import constants from '../lib/constants'
class TableComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageNo: 1,
      pageSize: 10,
      sortColumn: 'id',
      sortOrder: 'DESC',
      searchIn: null,
      searchData: null,
      loading: true,
      searchValue: '',
      dynamicTableError: false,
      apiErrors: {},
      currentPageNo: 0,
      selectedSearchDropdown: this.props.selectedSearchDropdown,
      path: this.props.location.pathname,
      // VVV below are used to control certu modals
      modalOpen: false,
      modalSampleId: null,
      modalStrainName: null,
      certusId: null,
      coa: null,
      eggDisplay: false
    }
  }
  resize = () => this.forceUpdate()
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    let queryParams = decodeURI(this.props.location.search)
    this.dispatchParams(queryParams)
  }

  componentDidUpdate (prevProps, prevState) {
    // if queue was closed then refresh test results
    if (prevProps.showQueue && !this.props.showQueue) {
      this.getTableData()
    }
  }

  dispatchParams = (queryParams) => {
    let page = getParameterByName('page', queryParams)
    let sortOrder = getParameterByName('sortOrder', queryParams)
    let sortColumn = getParameterByName('sortColumn', queryParams)
    this.setState({ pageNo: page ? page : 1, sortColumn: sortColumn ? sortColumn : 'id', sortOrder: sortOrder ? sortOrder : 'DESC' }
      , () => this.getTableData())
  }
  pagePrevious = () => {
    this.setState((prevState) => ({
      pageNo: parseInt(prevState.pageNo) - 1
    }), () => {
      this.getTableData()
      this.getUrl()
    })
  }
  pageNext = () => {
    this.setState((prevState) => ({
      pageNo: parseInt(prevState.pageNo) + 1
    }), () => {
      this.getTableData()
      this.getUrl()
    })
  }
  getUrl = () => {
    const { pageNo, sortColumn, sortOrder } = this.state
    let queryList = []
    pageNo !== 1 && queryList.push('page=' + pageNo)
    sortColumn !== 'id' && queryList.push('sortColumn=' + sortColumn)
    sortColumn !== 'id' && queryList.push('sortOrder=' + sortOrder)
    let queryString = ''
    for (let i = 0; i < queryList.length; i++) {
      queryString += (i !== 0 ? '&' : '') + queryList[i]
    }
    this.props.history.push(this.state.path + '?' + queryString)
  }
  handleSearchChange = (text) => {
    let searchValue = text
    this.setState((prevState) => ({
      pageNo: (prevState.searchValue === text) ? parseInt(prevState.pageNo) : 1,
      searchValue: searchValue
    }))
  }
  handleSearchResult = () => {
    let startIndex = (this.state.pageNo * this.state.pageSize) - this.state.pageSize
    let endIndex = startIndex + this.state.pageSize
    let queryParams = (this.props.baseUrl.indexOf("?") < 0 ? '?' : '&') + 'startIndex=' + startIndex + '&endIndex=' + endIndex
    if (this.state.searchValue !== '' && this.state.searchValue !== null) {
      queryParams = queryParams + '&searchIn=' + this.state.selectedSearchDropdown + '&searchData=' + this.state.searchValue
    }
    if (this.state.sortColumn !== null) {
      queryParams = queryParams + '&sortOrder=' + this.state.sortOrder + '&sortColumn=' + this.state.sortColumn
    }
    setTimeout(DynamicTableServices.getDynamicTableData(this.props.baseUrl + queryParams).then((data) => {
      this.setState({ dynamicTableError: false, apiErrors: {}, currentPageNo: this.state.pageNo })
      this.props.getFilteredData(data)
	  if (this.props.propagateQuery) this.props.propagateQuery(queryParams.substring(1))
    }).catch((e) => {
      this.setState({ dynamicTableError: true, apiErrors: (e.data) ? e.data : {} })
      this.props.getFilteredData([])
    })
      , 1000)
  }
  getTableData = () => {
    let startIndex = (this.state.pageNo * this.state.pageSize) - this.state.pageSize
    let endIndex = startIndex + this.state.pageSize
    let queryParams = (this.props.baseUrl.indexOf("?") < 0 ? '?' : '&') + 'startIndex=' + startIndex + '&endIndex=' + endIndex
    if (this.state.sortColumn !== null) {
      queryParams = queryParams + '&sortOrder=' + this.state.sortOrder + '&sortColumn=' + this.state.sortColumn
    }
    if (this.state.searchValue && this.state.searchValue.length !== 0) {
      queryParams = queryParams + '&searchIn=' + this.state.selectedSearchDropdown +
        '&searchData=' + this.state.searchValue
    }
    DynamicTableServices.getDynamicTableData(this.props.baseUrl + queryParams).then((data) => {
      this.setState({ loading: false, dynamicTableError: false, apiErrors: {}, currentPageNo: this.state.pageNo })
      this.props.getData(data)
	  if (this.props.propagateQuery) this.props.propagateQuery(queryParams.substring(1))
    }).catch((error) => {
      this.setState({ dynamicTableError: true, apiErrors: (error.data) ? error.data : {} })
      this.props.getFilteredData([])
    })
  }
  handleSort = (clickedColumn) => {
    let startIndex = (this.state.pageNo * this.state.pageSize) - this.state.pageSize
    let endIndex = startIndex + this.state.pageSize
    this.setState({
      sortOrder: this.state.sortOrder === 'DESC' ? 'ASC' : 'DESC',
      sortColumn: clickedColumn
    }, () => {
      this.getUrl()
      let queryParams = (this.props.baseUrl.indexOf("?") < 0 ? '?' : '&') + 'startIndex=' + startIndex + '&endIndex=' + endIndex + '&sortOrder=' + this.state.sortOrder + '&sortColumn=' + this.state.sortColumn

      if (this.state.searchValue && this.state.searchValue.length !== 0) {
        queryParams = queryParams + '&searchIn=' + this.state.selectedSearchDropdown +
          '&searchData=' + this.state.searchValue
      }
      DynamicTableServices.getDynamicTableData(this.props.baseUrl + queryParams).then((data) => {
        this.setState({ loading: false, dynamicTableError: false, apiErrors: {}, currentPageNo: this.state.pageNo })
        this.props.getData(data)
		if (this.props.propagateQuery) this.props.propagateQuery(queryParams.substring(1))
      }).catch((error) => {
        this.setState({ dynamicTableError: true, apiErrors: (error.data) ? error.data : {} })
        this.props.getFilteredData([])
      })
    })
  }
  handleLastPage = () => {
    this.setState({ pageNo: this.totalPages() }, () => {
      this.getUrl()
      this.getTableData()
    })
  }
  totalPages = () => {
    if (this.props.count === 0) {
      return 1
    }
    return parseInt(Math.ceil(this.props.count / this.state.pageSize))
  }
  orderDirection = (direction) => {
    if (direction === 'ASC')
      return 'ascending'
    else
      return 'descending'
  }
  handlePage = (e) => {
    this.setState({ pageNo: e.target.value })
  }
  onInputPageChange = () => {
    if (this.state.pageNo >= this.totalPages()) {
      this.setState({ pageNo: this.totalPages() }, () => {
        this.getTableData()
        this.getUrl()
      })
    }
    else if (this.state.pageNo < 1) {
      this.setState({ pageNo: 1 }, () => {
        this.getTableData()
        this.getUrl()
      })
    }
    else {
      this.getTableData()
      this.getUrl()
    }
  }
  handleFirstPage = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getUrl()
      this.getTableData()
    })
  }
  entireRowAction = (row, e) => {
    if (this.props.entireRowAction && this.props.entireRowAction.title) {
      this.setState({
        clickedRow: row.id
      }, () => {
        if (e.target.tagName === "TD")
          this.props.rowAction(this.props.entireRowAction.title, row, e)
      })
    }
  }
  calculateColSpan = () => {
    return parseInt(this.props.tableHeader.length) - (this.props.showActions === true ? 0 : 1) - (this.props.isCheckBoxRequired === false ? 1 : 0)
  }
  handleSearchDropDownOptions = (e, { value }) => {
    let selectedSearchDropdown = value
    this.setState((prevState) => ({
      pageNo: (prevState.selectedSearchDropdown === value) ? parseInt(prevState.pageNo) : 1,
      selectedSearchDropdown: selectedSearchDropdown
    }), () => { this.handleSearchResult() })
  }
  render() {

    const rowData = this.props.rowData
    const { sortColumn, sortOrder } = this.state
    let isSelectedAll = true
    let isDisabledAll = true
    let disableCount = 0
    if (this.props.isCheckBoxRequired && this.props.isCheckBoxRequired === true && this.props.selectedCheckBoxes) {
      this.props.rowData.map((eachRow) => {
        if (eachRow.isResult === false) {
          isDisabledAll = false
          let index = this.props.selectedCheckBoxes.findIndex(x => x.id === eachRow.id)
          if (index < 0) {
            isSelectedAll = false
          }
        }
        else {
          disableCount = disableCount + 1
        }
        return null
      })
      if (disableCount === this.props.rowData.length)
        isSelectedAll = false
    }

    const modal = this.state.certusId ? (
      	<CertusModalQr
	  		sample_id={this.state.modalSampleId}
			strain_name={this.state.modalStrainName}
			modalOpen={this.state.modalOpen}
			closeModal={()=> this.setState({modalOpen: false, modalSampleId: null, modalStrainName: null, certusId: null, coa: null})}
			createFailure={(message) => this.setState({apiErrors: message, modalOpen: false, loading: false})}
		/>
    ) : (
		<CertusModalCreate
			coa={this.state.coa}
			sample_id={this.state.modalSampleId}
			current_name={this.state.modalStrainName}
			modalOpen={this.state.modalOpen}
			closeModal={()=> this.setState({modalOpen:false})}
			startLoading={()=> this.setState({modalOpen:false, loading:true})}
			createSuccess={() => this.setState({modalOpen:true, loading: false})}
			createFailure={(message) => this.setState({apiErrors: message, modalOpen: false, loading: false})}
		/>
    )

    return (
      <Grid className='dynamicTable'>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
          <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
          {modal}
          <Grid divided='vertically'>
          <Grid.Row columns={3}>
          <Grid.Column>
          </Grid.Column>
            <Grid.Column>
              <SearchComponent
              options={this.props.options}
              handleSearchResult={this.handleSearchResult}
              onSearchChange={(e) => this.handleSearchChange(e.target.value)}
              getSelectedDropdown={this.handleSearchDropDownOptions}
              selectedSearchDropdown={this.state.selectedSearchDropdown}
              searchValue={this.state.searchValue}
            />
            </Grid.Column>
            <Grid.Column>
             {this.props.certusTokenBankPanel && permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.PROJECT) && <CertusTokenBank /> }
            </Grid.Column>
          </Grid.Row>
          </Grid>
          {this.props.loading ?
            <Segment>
              <Dimmer active={this.state.loading} inverted>
                <Loader inverted content='Loading' size='medium' />
              </Dimmer>
            </Segment>
            :
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                <Table sortable celled fixed selectable>
                  {
                    this.props.actionItem && this.props.children
                  }
                  <Table.Header>
                    <Table.Row>
                      {
                        this.props.tableHeader.map((val) => (
                          (val.id !== 'action_item' || (val.id === 'action_item' && this.props.showActions === true)) ?
                            <React.Fragment key={val.id}>
                              {(val.type !== 'checkbox' && <Table.HeaderCell
                                sorted={sortColumn === val.id && val.sortable ? this.orderDirection(sortOrder) : null}
                                onClick={() => val.sortable && this.handleSort(val.id)}
                              >{val.title}</Table.HeaderCell>)}
                            </React.Fragment> : null
                        ))
                      }
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      rowData.map((row, index) =>
                        <Table.Row key={index}>
                          {this.props.tableHeader.map((val) =>
                            (val.id !== 'action_item' ?
                              ((val.type !== 'checkbox') || (val.type === 'checkbox' && this.props.isCheckBoxRequired)) ?
                                <Table.Cell key={val.id} onClick={(e) => { this.entireRowAction(row, e) }}>
                                  {val.import_flag && row['import'] }
                                  {(val.type !== 'checkbox') && row[val.id]}
                                  {(val.type === 'checkbox' && this.props.isCheckBoxRequired) && <Checkbox disabled={(row.isResult) ? row.isResult : false} key={row.id} checked={(this.props.selectedCheckBoxes.findIndex(x => x.id === row.id) >= 0 ? true : false)} onChange={(e, data) => { this.props.checkBoxHandler(e, data, row) }} />}
                                </Table.Cell> : null
                              :
                              (this.props.showActions === true ?
                                <Table.Cell key={val.id + "action"}>
                                  {
                                    val.actionItems &&
                                    val.actionItems.map((data, index) => {
                                      const inQueue = this.props.idsInQueue ? this.props.idsInQueue.indexOf(parseInt(row.id)) >= 0 : false
                                      if (data.action === "add_to_queue") {
                                        const notQueueable = inQueue || row.finished
                                        const sleepInQueue = this.props.idsInQueue.indexOf("sleep") >= 0
                                        const sleepEnabled = (this.props.machineState && this.props.machineState.method === process.env.REACT_APP_SLEEP_METHOD+'.M') ? true : false
                                        if (sleepInQueue && !inQueue && !notQueueable) {
                                          return (
                                            <Popup key={row.id + data.action} content="Remove sleep from the sample queue to add more samples." size='tiny' position='top right' trigger={
                                              <span>
                                                <Button icon="bed" color="orange" onClick={(e) => {
                                                  this.props.rowAction(data.title, row, e)
                                                }} className={'dynamicTableActionButton ' + ((data.class) ? row[data.class] : '')}
                                                  disabled={true}
                                                />
                                              </span>
                                            } />
                                          )
                                        } else if (sleepEnabled && !inQueue && !notQueueable) {
                                          return (
                                            <Popup key={row.id + data.action} content="Cannot add samples to the sample queue while instrument is sleeping." size='tiny' position='top right' trigger={
                                              <span>
                                                <Button icon="bed" color="orange" onClick={(e) => {
                                                  this.props.rowAction(data.title, row, e)
                                                }} className={'dynamicTableActionButton ' + ((data.class) ? row[data.class] : '')}
                                                  disabled={true}
                                                />
                                              </span>
                                            } />
                                          )
                                        } else {
                                          let iconName = notQueueable ? 'check' : 'plus'
                                          return (
                                            <Popup key={row.id + data.action} content={data.title} size='tiny' position='top right' trigger={
                                              <Button icon={iconName} color={data.color} onClick={(e) => {
                                                this.props.rowAction(data.title, row, e)
                                              }} className={'dynamicTableActionButton ' + ((data.class) ? row[data.class] : '')}
                                                disabled={notQueueable}
                                              />
                                            } />
                                          )
                                        }
                                      } else if (data.action === "certus") {
                                        let certus = row.certus_id
                                        let popUpTest =
                                          certus ?
                                          <span style={{display:'flex'}}>VERISEAL Associations</span> :
                                            this.props.certusTokenBank && this.props.certusTokenBank.quantity != 0 ?
												<span style={{display:'flex'}}>Create VERISEAL</span> :
												<span style={{display:'flex'}}>
													No CERTUS<Icon name='registered outline' size='tiny'/>Tokens Available
												</span>
                                        return (
                                          <Popup key={row.id + data.action} content={popUpTest} size='tiny' position='top right' trigger={
                                            <Button
                                            className={`dynamicTableActionButton certusLogoActionButton ${certus ? 'associated green' : 'create'}`}
                                            onClick={(e) => {  (!certus && this.props.certusTokenBank && this.props.certusTokenBank.quantity == 0) ? null :
                                              this.setState({modalOpen: true, modalSampleId: row.id, modalStrainName: row.strain_name, certusId: row.certus_id, coa: row.coa})}}
                                            disabled={!row.finished}>
                                              <CertusLogoSvg alt="Certus Logo" />
                                            </Button>
                                          }/>
                                        )
                                      } else {
                                        return (
                                          <Acl key={row.id + data.action + index} entity={this.props.entity} action={data.action}>
                                            <Popup content={data.title} size='tiny' position='top right' trigger={
												<Button icon={data.icon} color={data.color}
													onClick={(e) => {
														this.props.rowAction(data.title, row, e)
													}}
													className={'dynamicTableActionButton ' + ((data.class) ? row[data.class] : '')}
													// disabled logic specific to action being 'destroy' (delete) is dependent upon no disableClass present as defined in SampleTests component
													disabled={(data.disableClass) ? (row[data.disableClass] ? row[data.disableClass] : false) : (data.action === 'destroy' ? !!(inQueue || row.certus_id) : false)}
												/>
                                            } />
                                          </Acl>
                                        )
                                      }
                                    })
                                  }
                                </Table.Cell> : null)
                            )
                          )}
                        </Table.Row>
                      )
                    }
                    {
                      (!rowData || rowData.length === 0) &&
                      <Table.Row colSpan={this.calculateColSpan()} textAlign='center'>
                        <Table.Cell colSpan={this.calculateColSpan()}>No Records Found</Table.Cell>
                      </Table.Row>
                    }
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row colSpan={this.calculateColSpan()} size={window.screen.width < 768 ? 'mini' : 'medium'}>
                      <Table.HeaderCell colSpan={this.calculateColSpan()}>
                        {this.state.pageNo === this.totalPages() &&
                          <Menu float='left' style={{background:'none',border:'none',boxShadow:'none'}}
                            onClick={() => {this.setState({eggDisplay: true})}}>
                            <Menu.Item>
                              <Icon name='spy' size='large' style={this.state.eggDisplay ? {margin:0,cursor:'pointer'} : {opacity:0,cursor:'pointer'}}
                                onClick={() => {if (this.state.eggDisplay) {window.open('https://youtu.be/EcF2LOaLgA0')}}} />
                            </Menu.Item>
                          </Menu>
                        }
                        <Menu float='right' className={window.screen.width < 768 ? 'menuPagination' : ''} pagination>
                          <Menu.Item as='a' >
                            <Input label={'Page No'} size='mini' value={this.state.pageNo} name='pageNo' min={'1'} max={this.totalPages().toString()} type='number' onChange={(e) => { this.handlePage(e) }} onBlur={(e) => { setTimeout(() => { this.onInputPageChange() }, 0) }} />
                          </Menu.Item>
                          <Menu.Item as='a' icon disabled={this.state.pageNo === 1 ? true : false} onClick={() => {
                            this.handleFirstPage()
                          }}>
                            <Icon name='angle double left' size={window.screen.width < 768 ? 'small' : 'large'} />
                          </Menu.Item>
                          <Menu.Item as='a' icon disabled={this.state.pageNo === 1 ? true : false} onClick={() => {
                            this.pagePrevious()
                          }}>
                            <Icon name='chevron left' size={window.screen.width < 768 ? 'small' : 'large'} />
                          </Menu.Item>
                          <Menu.Item as='a' icon>
                            {this.state.currentPageNo}/ {this.totalPages()}
                          </Menu.Item>
                          <Menu.Item as='a' icon disabled={this.state.pageNo === this.totalPages()} onClick={() => {
                            this.pageNext()
                          }}>
                            <Icon name='chevron right' size={window.screen.width < 768 ? 'small' : 'large'} />
                          </Menu.Item>
                          <Menu.Item as='a' icon disabled={this.state.pageNo === this.totalPages()} onClick={() => {
                            this.handleLastPage()
                          }}>
                            <Icon name='angle double right' size={window.screen.width < 768 ? 'small' : 'large'} />
                          </Menu.Item>
                        </Menu>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Grid.Column>
            </Grid>
          }
        </Grid.Column>
      </Grid >
    )
  }
}

export default withRouter(TableComponent)
