import moment from 'moment'
let intakeNumber = () => {
  let mmt = moment()
  let mmtMidnight = mmt.clone().startOf('day')
  let diffSeconds = mmt.diff(mmtMidnight, 'seconds')
  let date = mmt.format('MM-DD-YY')
  return `Cann.ID-${date}/${diffSeconds}-TechId`
}

export default intakeNumber
