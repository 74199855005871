import React, { Component } from 'react'
import _ from 'lodash'
import { Icon, Loader, Dimmer, Segment, Table } from 'semantic-ui-react'
class StickyTableComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageNo: 1,
      pageSize: 10,
      column: null,
      direction: null,
    }
  }
  pagination = (rowData) => {
    const listData = [...rowData]
    return listData
  }
  pagePrevious = () => {
    this.setState((prevState) => ({
      pageNo: prevState.pageNo - 1
    }))
  }
  pageNext = () => {
    this.setState((prevState) => ({
      pageNo: prevState.pageNo + 1
    }))
  }
  handleSort = (clickedColumn) => {
    const { column, direction } = this.state
    if (column !== clickedColumn) {
      this.props.sortByColumn(_.sortBy(this.props.rowData, [clickedColumn]))
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
      })
      return
    }
    this.setState({
      data: this.props.sortByColumn(this.props.rowData.reverse()),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }
  totalPages = () => {
    return Math.ceil(this.props.count / this.state.pageSize)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchValue !== this.props.searchValue)
      this.setState({ pageNo: 1 })
  }

  buildTableHeader() {
	const { column, direction } = this.state
	const headerCells = this.props.tableHeader.map((cellData, index) => (
		<Table.HeaderCell
			sorted={column === cellData.id && cellData.sortable === 'Yes' ? direction : null}
			onClick={() => this.handleSort(cellData.id)}
			key={index} >
				{cellData.title}
			</Table.HeaderCell>
	))

	let headerTitle = null

	if (this.props.headerTitle) {
		headerTitle = (
			<Table.Row>
				<Table.HeaderCell colSpan={16} textAlign={'center'}>
					{this.props.headerTitle}
				</Table.HeaderCell>
			</Table.Row>
		)
	}

	return (
		<Table.Header fullWidth>
			{headerTitle}
			<Table.Row>
				{headerCells}
			</Table.Row>
		</Table.Header>
	)
  }
  render() {
    const rowData = this.pagination(this.props.rowData)

    return (
      <div className='table-wrapper'>
        {this.props.loading ?
          <Segment>
            <Dimmer active={this.props.loading} inverted>
              <Loader inverted content='Loading' size='medium' />
            </Dimmer>
          </Segment>
          :
          <Table sortable celled unstackable selectable compact={this.props.compact} >
			{this.buildTableHeader()}
            <Table.Body>
              {
                rowData.map((row, index) =>
                  <Table.Row key={index}>
                    {this.props.tableHeader.map((val, indexCol) =>
                      (val.id !== 'action_item' ?
                        <Table.Cell key={`${index}-${indexCol}`} className={(val.class) ? row[val.class] : ''} >{row[val.id]}</Table.Cell>
                        :
                        <Table.Cell key={`${index}-${indexCol}`}>
                          {
                            val.actionItems &&
                            val.actionItems.map((data) => {
                              return (
                                <Icon size='large' name={data.icon} color={data.color} onClick={() => { this.props.rowAction(data.title, row) }} title={data.title} />
                              )
                            })}
                        </Table.Cell>
                      )
                    )}
                  </Table.Row>
                )
              }
              {this.props.totals &&
                <Table.Row className='totals-row' key='totals'>
                  <Table.Cell><strong>Total</strong></Table.Cell>
                  <Table.Cell><strong>{this.props.totals.percent}%</strong></Table.Cell>
                  <Table.Cell><strong>{this.props.totals.sample_amount} mg/g</strong></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              }
            </Table.Body>
          </Table>
        }
      </div>
    )
  }
}

export default StickyTableComponent
