import React, { Component } from 'react'
import { Button, Icon, Progress } from 'semantic-ui-react'
import { connect } from 'react-redux'
import AutosamplerPlayButton from './AutosamplerPlayButton'
import AutosamplerPauseButton from './AutosamplerPauseButton'
import AutosamplerStopButton from './AutosamplerStopButton'
import csdriverAPI from '../../csdriverAPI/client'
import BottleWidget from '../BottleWidget'
import submitSoloSequence from '../../tasks/submitSoloSequence'

const mapStateToProps = (state) => {
  return {
    machineState: state.machineState,
    queuePaused: state.queuePaused
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    togglePause: () => {dispatch({type: 'TOGGLE_PAUSE'})},
    pauseQueue: () => {dispatch({type: 'PAUSE_QUEUE'})},
    resumeQueue: () => {dispatch({type: 'RESUME_QUEUE'})}
  }
}

class AutosamplerActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      awaitingAction: null,
      label: null,
	  dblClickPrevention: false
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.machineState) {

      if (this.props.machineState.status === 'ERROR' && this.state.awaitingAction && this.state.awaitingAction !== 'preprun') {
        // if error then show buttons!
        this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
		this.props.pauseQueue()
      }
      else if (this.state.awaitingAction === 'start' && this.props.machineState.status === 'RUN') {
        this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
		this.props.resumeQueue()
      }
      else if ((this.state.awaitingAction === 'warmup' || this.state.awaitingAction === 'sleep')
          && this.props.machineState.status === 'RUN') {
        this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
		this.props.pauseQueue()
      }
      else if (this.state.awaitingAction === 'stopMethod' && this.state.label === 'warming' && this.props.machineState.status === 'PRERUN') {
        this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
		this.props.pauseQueue()
      }
      else if (this.state.awaitingAction === 'stopMethod' && this.state.label === 'sleeping' && this.props.machineState.status === 'NOTREADY') {
        this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
		this.props.pauseQueue()
      }
      else if (this.state.awaitingAction === 'abort' && this.props.machineState.status === 'PRERUN') {
        this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
		this.props.pauseQueue()
      }
      else if (this.state.awaitingAction === 'preprun' && this.props.machineState.status === 'PRERUN') {
        this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
		this.props.pauseQueue()
      }
    }
  }

  doAction = (action, label = null) => {
	if (!this.state.dblClickPrevention) {
		this.setState({ dblClickPrevention: true })
		if (action === "start") {
			console.log('start')
			this.setState({awaitingAction: action, label: null})
			this.props.startAction(this.props.taskToRun)
		} else if (action === "pause") {
			console.log('togglePause')
			this.props.togglePause()
			setTimeout(this.setState({ dblClickPrevention: false }), 500)
		} else {
		
			this.setState({awaitingAction: action, label: label})

			csdriverAPI.post('/chemstation/sequence/control', {action}).then((res) => {
				console.log('action success', action, res)
			}).catch((err) => {
				console.log(`failed to do action: ${action}`, err)
				this.setState({awaitingAction: null, label: null, dblClickPrevention: false})
			})
		}
	}
  }

  render() {      
    const actions = (this.state.awaitingAction || this.props.armMoving) ? (
      <div className="actionButton">
        <Button icon labelPosition="left"  disabled>
          <Icon loading name="spinner" size="large" />
          Instrument Busy
        </Button>
      </div>
    ) : (
      <div>
        <AutosamplerPlayButton machineState={this.props.machineState}
            doAction={this.doAction}
            queuePaused={this.props.queuePaused}
            sampleQueues={this.props.sampleQueues}
            taskToRun={this.props.taskToRun} />
        <AutosamplerPauseButton machineState={this.props.machineState} doAction={this.doAction} queuePaused={this.props.queuePaused} />
        <AutosamplerStopButton machineState={this.props.machineState} doAction={this.doAction} />
        {this.props.acquisitionTools}
      </div>
    )

    if (!this.props.machineState) {
      return (
        <div className="queueHeaderSection">
          No machine connection detected.
        </div>
      )
    }
    return (
      <div className="queueHeaderSection">
        <div className="autosamplerActions">
          {actions}
        </div>
        <div className="miniBottles">
          <BottleWidget machineState={this.props.machineState} hideInputs={true} />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutosamplerActions)
