import axios from '../axios'
import moment from 'moment'
import decodeJWT from '../../src/lib/decodeJWT'
import handleDefaultValue from '../../src/lib/handleDefaultValue'

export const CsvDownload = {
  downloadData,
}

function downloadData(url, fileName, date=moment(new Date()).format('MM_DD_YY')) {
  let decodedResponse = decodeJWT(localStorage.getItem('access_token'))
  let organizationName = handleDefaultValue(decodedResponse.organization.name)
  return axios().get(url, { headers: { common: { Accept: 'text/csv' } } }).then((response) => {
    if (navigator.userAgent.match('CriOS')) {
      var reader = new FileReader()
      var out = new Blob([response.data])
      reader.onload = function (e) {
        window.location.href = reader.result
      }
      reader.readAsDataURL(out)
    }
    else {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${date + '_' + (handleDefaultValue(fileName)).replace(/ /g, '_') + '_' + organizationName.replace(/ /g, '_')}.csv`)
      document.body.appendChild(link)
      link.click()
    }
  })
  .catch((error) => console.error('CSV Download Error!', error))
}
