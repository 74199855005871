import React, { Component } from 'react'
import { formatRoute } from 'react-router-named-routes'
import { withRouter } from 'react-router-dom'
import { Segment, Button, Grid, Popup, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import StickyTableComponent from '../StickyTableComponent'
import constants from '../../lib/constants'

const tableHeaderStandard = [
	{ id: 'name', title: 'Cannabinoid', sortable: 'Yes' },
	{ id: 'percent', title: 'Result (%)', sortable: 'Yes' },
	{ id: 'mg', title: 'Result (mg/g)', sortable: 'Yes' },
	{ id: 'loq', title: 'LOQ (%)', sortable: 'Yes' },
	{ id: 'last_percentage', title: 'Last (%)', sortable: 'Yes' },
	{ id: 'last_mg', title: 'Last (mg/g)', sortable: 'Yes' },
	{ id: 'diff', title: '% Diff', sortable: 'Yes', class: 'percentClass' }
]
const tableHeaderNonStandard = [
	{ id: 'name', title: 'Cannabinoid', sortable: 'Yes' },
	{ id: 'percent', title: 'Result (%)', sortable: 'Yes' },
	{ id: 'mg', title: 'Result (mg/g)', sortable: 'Yes' },
	{ id: 'loq', title: 'LOQ (%)', sortable: 'Yes' }
]
class ReportResultsTable extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableData: [],
			count: 0,
			loading: true,
			selectedSearchDropdown: 'name',
			serachvalue: '',
			sampleFlag: false,
			showComparision: false,
			upper_bounds: this.props.sample.test_method.upper_bounds,
			lower_bounds: this.props.sample.test_method.lower_bounds
		}
	}

	componentDidMount () {
		const main_array = []

		const addTrailingZeros = (num, totalLength) => {
			let left = String(num).split('.')[0]
			let right = String(num).split('.')[1].padEnd(totalLength, '0')
			return left + '.' + right
		}

		if (this.props.sample.compounds && Object.keys(this.props.sample.compounds).length) {
			// leave clause for legacy 'compliance' sample_type. this keeps the table integrity of those historical tests.
			// moving forward, we just use the test method's elution order
			const table_analytes = (this.props.sample.sample_type && this.props.sample.sample_type.slug.indexOf('compliance') > -1) ? ['THCA', 'THCD9'] : this.props.sample.elution_order
			const compound_unbounded = this.props.sample.compounds_unbounded
			table_analytes.map((key) => {
				const compound = this.props.sample.compounds[key]
				let previous_compound
				if (this.props.sample.previous_standard && this.props.sample.previous_standard.compounds) {
					previous_compound = this.props.sample.previous_standard.compounds[key]
				}
				if (compound.loq && previous_compound) {
					let percentClass = ''
					const diff = parseFloat((compound.percent - previous_compound.percent).toFixed(2))
					if (diff > 0) {
						percentClass = 'positive'
					}
					else if (diff < 0) {
						percentClass = 'negative'
					}
					const loqValue = this.props.sample.loq.toString()
					const newObject = {
						name: key,
						percent: `${compound.percent}%`,
						mg: compound.sample_amount,
						loq: `${loqValue}%`,
						last_percentage: previous_compound.percent,
						last_mg: previous_compound.percent,
						diff,
						percentClass
					}
					main_array.push(newObject)
				}
				else if (compound.sample_amount && compound.loq === true) {
					const loqValue = this.props.sample.loq.toString()
					const newObject = {
						name: key,
						percent: `${compound.percent}%`,
						mg: compound.sample_amount,
						loq: `${loqValue}%`,
						last_percentage: 'No Data',
						last_mg: 'No Data',
						diff: 'No Data',
						percentClass: ''
					}
					main_array.push(newObject)
				}
				else if (compound.sample_amount && compound.loq !== true) {
					const loqValue = this.props.sample.loq.toString()
					const newObject = {
						name: key,
						percent: '< LOQ',
						mg: '< LOQ',
						loq: `${loqValue}%`,
						last_percentage: 'No Data',
						last_mg: 'No Data',
						diff: 'No Data',
						percentClass: ''
					}
					main_array.push(newObject)
				}
				else if (compound_unbounded[key]) {
					const loqValue = this.props.sample.loq.toString()
					let unbounded_percent = addTrailingZeros(Number((compound_unbounded[key].percent).toFixed(4)),4)
					let unbounded_amount = addTrailingZeros(Number((compound_unbounded[key].percent * 10 ).toFixed(4)),4)
					const newObject = {
						name: key,
						percent: (
							<>
								<Popup
									flowing
									trigger={<Icon circular color='yellow' name='exclamation' />}
									content={<p>Value {compound_unbounded[key].amount} is over Upper Bounds: {this.state.upper_bounds} <br /> Over Bounds result: { unbounded_percent }</p>}
									size='mini'
								/>Over Bounds
							</>
						),
						mg:(
							<>
								<Popup
									flowing
									trigger={<Icon circular color='yellow' name='exclamation' />}
									content={<p>Value {compound_unbounded[key].amount} is over Upper Bounds: {this.state.upper_bounds} <br /> Over Bounds result: { unbounded_amount }</p>}
									size='mini'
								/>Over Bounds
							</>
						),
						loq: `${loqValue}%`,
						last_percentage: 'OVER BOUNDS',
						last_mg: 'OVER BOUNDS',
						diff: 'OVER BOUNDS',
						percentClass: ''
					}
					main_array.push(newObject)
				}
				else {
					const loqValue = this.props.sample.loq.toString()
					const newObject = {
						name: key,
						percent: 'N/D',
						mg: 'N/D',
						loq: `${loqValue}%`,
						last_percentage: 'N/D',
						last_mg: 'N/D',
						diff: 'N/D',
						percentClass: ''
					}
					main_array.push(newObject)
				}
				return null
			})
			this.setState({
				tableData: main_array,
				count: main_array.length,
				loading: false,
				initialData: main_array
			})
		}
	}

	sortByColumn = (tableData) => {
		this.setState({ tableData })
	}

	rowAction = (action, data, e) => {
		e.stopPropagation()
		if (action === 'Edit') this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.EDIT.ROUTE, { id: data.id }))
		if (action === 'Remove') {
			this.setState({ sampleFlag: true, sampleId: data.id })
		}
	}

	onSearchChange = (e) => {
		let searchvalue = e.target.value
		this.setState({ searchvalue })
		if (searchvalue.length === 0) {
			this.setState((prevState) => {
				const tableData = prevState.initialData
				const count = prevState.initialData.length
				return { tableData,
					count }
			})
		}
		const selectedDropdown = this.state.selectedSearchDropdown
		const data = [...this.state.tableData]
		const arrayList = []
		if (searchvalue.toString().length > 0) {
			searchvalue = searchvalue.toLowerCase()
			for (let i = 0; i < data.length; i++) {
				const x = data[i]
				if (x[selectedDropdown].toString().toLowerCase().indexOf(searchvalue) >= 0) {
					arrayList.push(data[i])
				}
			}
			this.setState({ tableData: arrayList, count: arrayList.length })
		}
	}

	getSelectedDropdown = ({ value }) => {
		this.setState((prevState) => {
			const tableData = prevState.initialData
			return { selectedSearchDropdown: value, searchvalue: '', tableData }
		})
	}

	closeModal = () => {
		this.setState({ sampleFlag: false })
	}

	render() {
		return (
			<Grid>
				{this.props.sample.sample_type && this.props.sample.sample_type.slug && this.props.sample.sample_type.slug === constants.SAMPLE_TYPE.STANDARD && (
					<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='right'>
						<Popup
							content={this.state.showComparision ? 'Close Comparisions' : 'Open Comparisions'} size='tiny' trigger={(
								<Button
									className='blue' icon={this.state.showComparision ? 'minus' : 'add'} labelPosition='left' content='Comparisions' onClick={() => {
										this.setState((prevState) => {
											const showComparision = !prevState.showComparision
											return { showComparision }
										})
									}}
								/>
							)}
						/>
					</Grid.Column>
				)}
				<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
					<StickyTableComponent
						tableHeader={(this.props.sample.sample_type && this.props.sample.sample_type.slug && this.props.sample.sample_type.slug === constants.SAMPLE_TYPE.STANDARD && this.state.showComparision) ? tableHeaderStandard : tableHeaderNonStandard}
						rowData={this.state.tableData}
						colSpan={6}
						actionItem
						sortByColumn={this.sortByColumn}
						count={this.state.count}
						loading={this.state.loading}
						rowAction={this.rowAction}
						countTitle='Total Sample Tests'
						searchValue={this.state.searchvalue}
						totals={this.props.sample.totals}
					/>
					<Segment.Group size='tiny'>
						<Segment>Method Concentration Range - Upper Bounds: {this.state.upper_bounds} ug/mL,  Lower Bounds: {this.state.lower_bounds} ug/mL</Segment>
						<Segment>Moisture Content = 100 x [(As-Harvested Weight - Dry Weight) / As-Harvested Weight]</Segment>
						<Segment>N/D = Non Detectable</Segment>
						<Segment>LOQ = Limits of Quantitation</Segment>
					</Segment.Group>
				</Grid.Column>
			</Grid>
		)
	}
}

ReportResultsTable.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	sample: PropTypes.shape({
		compounds: PropTypes.object,
		compounds_unbounded: PropTypes.object,
		elution_order: PropTypes.arrayOf(PropTypes.string),
		loq: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		totals: PropTypes.objectOf(PropTypes.string),
		previous_standard: PropTypes.shape({
			compounds: PropTypes.shape({
				percent: PropTypes.number
			})
		}),
		sample_type: PropTypes.shape({
			slug: PropTypes.string.isRequired
		})
	}).isRequired
}

export default withRouter(ReportResultsTable)
