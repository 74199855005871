import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'
const getAxiosInstance = (options) => {
  const instance = axios.create({
    ...options
  })
  instance.interceptors.request.use(reqConfig => {
    document.body.classList.add('loading-indicator')
    return reqConfig
  },
    err => Promise.reject(err),
  )
  instance.interceptors.response.use(response => {
    document.body.classList.remove('loading-indicator')
    return response
  }, err => {
    document.body.classList.remove('loading-indicator')
    return Promise.reject(err.response)
  },
  )
  return instance
}

export default getAxiosInstance
