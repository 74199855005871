import React, { Component } from 'react'
import { connect } from 'react-redux'
import QueueToggle from '../autosampler/QueueToggle'
import HeaderUser from './User'
import HamburgerAndLogo from './HamburgerAndLogo'

const mapStateToProps = (state) => {
  return {
    machineState: state.machineState,
	chemidUser: state.chemidUser
  }
}
class AppHeader extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const machineOnline = this.props.machineState && Object.keys(this.props.machineState).length > 0
    return (
      <header id="appHeader">
        <HamburgerAndLogo handleShowClick={this.props.handleShowClick} />
        {machineOnline && <QueueToggle />}
        <HeaderUser chemidUser={this.props.chemidUser} />
      </header>
    )
  }
}

export default connect(mapStateToProps)(AppHeader)
