import React, { Component } from 'react'
import { Form, Button, Message, Grid, Header, Segment } from 'semantic-ui-react'
import authProvider from '../lib/authProvider'
import { connect } from 'react-redux'
import { AUTH_LOGIN, getUser } from '../actions'
import { LoginServices } from '../services/Login'
import constants from '../lib/constants'
import trimWhiteSpace from '../lib/trim'
import { SampleTestServices } from '../services/SampleTest'
import QrRedirection from '../lib/QrRedirection'
import { formatRoute } from 'react-router-named-routes'
import decodeJWT from '../lib/decodeJWT'
const mapStateToProps = (state) => ({
  access_token: state.access_token
})
const mapDispatchToProps = (dispatch) => ({
  getUser: (token) => { dispatch(getUser(token)) }
})
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      organization: (localStorage.getItem('organization_id') ? parseInt(localStorage.getItem('organization_id')) : ''),
      loading: !!localStorage.getItem('access_token'),
      invalid: false,
      isSuperAdmin: false,
      isEmailVerified: false,
      isEmailValid: true,
      organizationError: false,
      passwordError: false,
      invalidLoginMessage: constants.LOGIN.PASSWORD_ERROR
    }
  }
  checkEmail = (email) => {
    let re = constants.REG_EX.EMAIL_REG_EX
    return re.test(trimWhiteSpace(String(email)).toLowerCase())
  }
  loginUserWithOutOrg = () => {
    if (this.checkEmail(this.state.email)) {
      if (this.state.password === '')
        this.setState({ passwordError: true })
      else {
        let configJson = {
          username: trimWhiteSpace(this.state.email),
          password: this.state.password,
          organization_id: (this.state.organization === '') ? null : this.state.organization
        }
        try {
          this.setState({ loading: true })
          authProvider(AUTH_LOGIN, configJson).then((res) => {
            let access_token = res.access_token
            let decodeTokenResponse = decodeJWT(access_token)
            if (decodeTokenResponse && decodeTokenResponse.actual_role && decodeTokenResponse.actual_role.slug) {
              if (decodeTokenResponse.actual_role.slug === constants.USER_ROLE.SUPER_ADMIN_SLUG) {
                if (this.state.organization === '') {
                  this.setState({ isSuperAdmin: true, isEmailVerified: true, isEmailValid: true, loading: false, invalid: false }, () => {
                    // if superadmin belongs to Ionization Labs org return all other orgs, otherwise only return the organizations monitored by the officer org
                    decodeTokenResponse.organization.id === 2 ? this.getOrganisations(access_token) : this.getOfficerOrganizations(access_token, decodeTokenResponse.organization.id)
                  })
                }
                else {
                  this.setState({ isSuperAdmin: false, isEmailVerified: true, isEmailValid: true, loading: false, invalid: false })
                  this.props.getUser(localStorage.getItem('access_token'))
                  this.redirectToHome()
                }
              }
              else {
                this.setState({ isSuperAdmin: false, isEmailVerified: true, isEmailValid: true, loading: false, invalid: false })
                this.props.getUser(localStorage.getItem('access_token'))
                this.redirectToHome()
              }
            }
          }).catch((error) => {
            this.setState({
              invalidLoginMessage: (error && error.data && error.data.global && error.data.global.length > 0) ? error.data.global.join(", ") : constants.LOGIN.PASSWORD_ERROR
            })
            this.setState({ loading: false, invalid: true, isEmailValid: false })
          })
        } catch (e) {
        }
      }
    }
    else {
      this.setState({ isEmailValid: false })
    }
  }
  getOrganisations = (access_token) => {
    LoginServices.getOrganisations(access_token).then((res) => {
      let list = res.data.data
      let organizations = list.map((data) => {
        return (
          {
            text: data.name,
            key: data.id,
            value: data.id
          }
        )
      })
      this.setState({ organizations: organizations })
    })
  }
  getOfficerOrganizations = (access_token, org_id) => {
    LoginServices.getOfficerOrganizations(access_token, org_id).then((res) => {
      let list = res.data
      let organizations = list.map((data) => {
        return (
          {
            text: data.private_organization.name,
            key: data.private_organization.id,
            value: data.private_organization.id
          }
        )
      })
      this.setState({ organizations: organizations })
    })
  }
  loginUserWithOrg = () => {
    let isFormValid = true
    if (this.state.organization === '' && this.state.isSuperAdmin) {
      this.setState({ organizationError: true })
      isFormValid = false
    }
    if (this.state.password === '') {
      this.setState({ passwordError: true })
      isFormValid = false
    }
    if (!isFormValid)
      return

    try {
      let configJson = {
        username: trimWhiteSpace(this.state.email),
        password: this.state.password
      }
      if (this.state.isSuperAdmin) {
        configJson = {
          username: trimWhiteSpace(this.state.email),
          password: this.state.password,
          organization_id: trimWhiteSpace(this.state.organization)
        }
      }
      this.setState({ loading: true })

      authProvider(AUTH_LOGIN, configJson).then(() => {
        this.props.getUser(localStorage.getItem('access_token'))
        setTimeout(() => {
          this.redirectToHome()
        }, 0)
      }).catch((error) => {
        this.setState({ loading: false, invalid: true, invalidLoginMessage: (error.data && error.data.global && error.data.global.length > 0) ? error.data.global.join(", ") : constants.LOGIN.PASSWORD_ERROR })
      })
    } catch (e) {
    }
  }
  redirectToHome = () => {
    if (localStorage.getItem('share_key')) {
      SampleTestServices.getQrDetailsByShareKey(localStorage.getItem('share_key')).then((response) => {
        let decodeTokenResponse = decodeJWT(localStorage.getItem('access_token'))
        if (decodeTokenResponse && decodeTokenResponse.organization && decodeTokenResponse.organization.id && decodeTokenResponse.organization.id === response.data.organization.id) {
          let redirection = QrRedirection(response.data)
          redirection.forceRedirection ? window.location.href = redirection.redirectTo : this.props.history.push(redirection.redirectTo)
        }
        else {
          this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE))
        }
      }).catch((error) => {
        localStorage.removeItem('share_key')
        localStorage.removeItem('organization_id')
        this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ACCESS_FORBIDDEN.ROUTE))
      })
    }
    else
      window.location.href = formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})
  }
  update = (e) => {
    const newState = {}
    let target = e.target
    newState[target.name] = target.value
    this.setState(newState, () => {
      if (target.name === 'password')
        this.setState({ passwordError: false })
    })
  }
  handleSelectChange = (e, { name, value }) => {
    this.setState({ organization: value }, () => this.setState({ organizationError: false }))
  }
  componentDidMount = () => {
    if (localStorage.getItem('access_token'))
      this.props.history.push("/")
  }
  render() {
    let message
    if (this.state.invalid) {
      message = (
        <Message negative>
          <p>{this.state.invalidLoginMessage}</p>
        </Message>
      )
    }
    if (!this.state.isEmailValid) {
      message = (
        <Message negative>
          <p>{this.state.invalidLoginMessage}</p>
        </Message>
      )
    }

    return (
      <div id="loadingLoginPage">
        {!this.state.loading &&
          <Grid id='loginForm' textAlign='center' verticalAlign='middle'>
            <Grid.Column>
              <Header as='h2' className='headerColor' textAlign='center'>
                LOGIN
              </Header>
              <Form>
                <Segment stacked className='segmentPadding'>
                  {message}
                  {!this.state.isEmailVerified &&
                    <React.Fragment>
                      <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label='Email' placeholder='Email' className='required-field' type='text' name="email" value={this.state.email} onChange={this.update} ref={this.emailRef} error={!this.state.isEmailValid} />
                      <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label='Password' placeholder='Password' type='password' error={this.state.passwordError} value={this.state.password} className='required-field' onChange={this.update} name="password" ref={this.passwordRef} />
                    </React.Fragment>
                  }
                  {!this.state.isEmailVerified &&
                    <React.Fragment>
                      <Button className='userButton Margin' type='submit' onClick={this.loginUserWithOutOrg}>Sign In</Button>
                      <Button className='userButton' type='button' onClick={() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE, {}))}>{constants.APPLICATION_ROUTE.FORGOT_PASSWORD.NAME}</Button>
                    </React.Fragment>
                  }
                  {this.state.isEmailVerified &&
                    <React.Fragment>
                      {
                        this.state.isSuperAdmin &&
                        <Form.Select label="Organization" value={this.state.organization}
                          options={this.state.organizations}
                          name="organization" onChange={this.handleSelectChange}
                          placeholder="Organization" className='required-field' error={this.state.organizationError} disabled={localStorage.getItem('organization_id') ? true : false} />
                      }
                      <Button className='userButton' type='submit' onClick={this.loginUserWithOrg}>Sign In</Button>
                    </React.Fragment>}
                </Segment>
              </Form>
            </Grid.Column>
          </Grid>
        }
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
