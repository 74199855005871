import React, { Component } from 'react'
import { Button, Modal, Checkbox, Message, Icon, Divider, Form, Grid, Segment,  Input } from 'semantic-ui-react'
import cannidAPI from '../../cannidAPI/client'
import DatePicker from 'react-datepicker'
import { withRouter } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'

class CertusModalActions extends Component {
  constructor(props){
    super(props)
    let Auth = this.props.certus.details["Authentication Type"] || this.props.certus.details["Authenication Type"] ? this.props.certus.details["Authentication Type"] || this.props.certus.details["Authenication Type"] : this.props.certus.details.authentication_type || this.props.certus.details.authenication_type
    this.defaultState = {
      certus: null,
      modalSuccess: false,
      apiErrors: {},
      vericert: true,
      modalActionType: this.props.buttonClicked,
      newExpirationDate: null,
      confirmDisabled: true,
      revokeOne: false,
      revokeTwo: false,
      expirationDate: null,
      actionError: null,
      certusDocId: Auth
    }
    this.state = this.defaultState
  }
  componentDidMount(){
    let currentExpirationDate = Date(this.props.certus.expiration_date)
    this.setState({expirationDate: currentExpirationDate})
  }
  componentDidUpdate (prevProps, prevState) {
    if (document.getElementsByName('filterRunsDate')[0]) {
      document.getElementsByName('filterRunsDate')[0].placeholder='Filter Date'
      document.getElementsByName('filterRunsDate')[0].autocomplete='off'
    }
    if (this.state.revokeOne && this.state.revokeTwo && prevState.revokeTwo == false)
      this.setState({confirmDisabled: false})
    else if (!(this.state.revokeOne) && prevState.revokeOne && this.state.revokeTwo)
      this.setState({confirmDisabled: true, revokeTwo: false})
    else if (this.state.revokeOne && prevState.revokeTwo && !(this.state.revokeTwo))
      this.setState({confirmDisabled: true})
    else if (!(this.state.revokeOne) && prevState.revokeOne)
      this.setState({confirmDisabled: true})
  }

  cancelModal = (e) => {
   this.setState(this.defaultState)
   this.props.closeModal()
  }

  reload = (e) => {
    e.stopPropagation();
    this.props.closeModal()
  }

  expireNow = () => {
    let now = new Date()
    this.setState({newExpirationDate: now})
  }

  closeSuccessModal = () =>{
    this.props.closeModal()
    window.location.reload()
  }

  failModal = () =>{
    window.location.reload()
  }

  render(){
    // let expirationDate = Date(this.props.certus.expiration_date)
    let modaltest
    let action = this.props.buttonClicked
    const display = () => {
      switch(this.props.buttonClicked){
        case 'Expire':
          return modaltest = expire
        break;
        case 'Renew':
          return modaltest = renew
        break;
        case 'Revoke':
          return modaltest = revoke
        break;
      }
    }

    const apiCall = () => {
      this.props.closeModal()
      let params = {
        certus_batch_id: this.props.certus.batch_id,
        expiry_reason: "",
        expiry_date: this.state.newExpirationDate,
        renew_reason: "",
        revoke_reason: "",
        certusDocId: this.props.certus.details["Authentication Type"] || this.props.certus.details["Authenication Type"] ? this.props.certus.details["Authentication Type"] || this.props.certus.details["Authenication Type"] : this.props.certus.details.authentication_type || this.props.certus.details.authenication_type
 
      }
      this.props.closeModal()
      document.body.classList.add('loading-indicator')

      switch(this.props.buttonClicked){
        case 'Expire':
          cannidAPI.post('/certi/expire/' + params.certus_batch_id, params).then((response) => {
            console.log('success', response)
             if (response.status < 200 || response.status >= 300) {
                throw new Error(response && response.statusText)
              }
            document.body.classList.remove('loading-indicator')
            this.setState({modalSuccess: true})
            this.props.openModal()
          }).catch((err) => {
			console.log('error!', err)
			this.setState({actionError: `This VERISEAL could not be expired on the CERTUS<span style='line-height: .8rem; font-size: .7rem; vertical-align: top;'>&#174;</span> KSI Blockchain, please submit a <a href=${process.env.REACT_APP_SELF_REFERENCE_ROOT + "/support" }>support ticket</a>.`, apiErrors: err, confirmDisabled: true })
			document.body.classList.remove('loading-indicator')
			this.props.openModal()
          })
        break;

        case 'Renew':
          cannidAPI.post('/certi/renew/' + params.certus_batch_id, params).then((response) => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error(response && response.statusText)
            }
            document.body.classList.remove('loading-indicator')
            this.setState({modalSuccess: true})
            this.props.openModal()
          }).catch((err) => {
			console.log('error!', err)
			this.setState({actionError: `This VERISEAL could not be renewed on the CERTUS<span style='line-height: .8rem; font-size: .7rem; vertical-align: top;'>&#174;</span> KSI Blockchain, please submit a <a href=${process.env.REACT_APP_SELF_REFERENCE_ROOT + "/support" }>support ticket</a>.`, apiErrors: err, confirmDisabled: true })
			document.body.classList.remove('loading-indicator')
			this.props.openModal()
          })
        break;

        case 'Revoke':
          cannidAPI.post('/certi/revoke/' + params.certus_batch_id, params).then((response) => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error(response && response.statusText)
            }
            document.body.classList.remove('loading-indicator')
            this.setState({modalSuccess: true})
            this.props.openModal()
          }).catch((err) => {
          console.log('error!', err)
          this.setState({actionError: `This VERISEAL could not be revoked on the CERTUS<span style='line-height: .8rem; font-size: .7rem; vertical-align: top;'>&#174;</span> KSI Blockchain, please submit a <a href=${process.env.REACT_APP_SELF_REFERENCE_ROOT + "/support" }>support ticket</a>.`, apiErrors: err, confirmDisabled: true })
          document.body.classList.remove('loading-indicator')
          this.props.openModal()
          })
        break;
      }
    }
      const errorMessage = (
        <Grid>
          <Grid.Row centered>
            <p><strong>Error!</strong> <span dangerouslySetInnerHTML={{__html: this.state.actionError}}></span> </p>
          </Grid.Row>
        </Grid>)

      const expire = this.state.actionError ?
      errorMessage :
      this.state.modalSuccess ? (
        <Grid>
          <Grid.Row centered>
            <p><strong>Success!</strong> The new expiration date is {this.state.newExpirationDate && this.state.newExpirationDate.toDateString()}.</p>
          </Grid.Row>
        </Grid>
      ) : (
        <Grid columns={2} divided>
          <Grid.Row centered>
            {this.state.newExpirationDate ?
            <p>The new expiration date will be <b>{this.state.newExpirationDate.toDateString()}</b>. Click OK to confirm.</p> :
            <p>The current expiration date is <b>{this.props.certus.expiration_date}</b>. Pick a new expiration date from in the future below or expire now.</p>}
          </Grid.Row>
          <Grid.Row textAlign='center'>
            <Grid.Column>
              <div style={{float:'right'}}>
                <DatePicker
                name='filterRunsDate'
                selected={this.state.newExpirationDate ? this.state.newExpirationDate : this.expirationDate}
                minDate={Date.now()}
                startDate={Date.now()}
                onSelect={ (e) => {console.log("select",e),
                this.setState({newExpirationDate: e, confirmDisabled: false})}} //when day is clicked
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <div style={{float:'left'}}>
                <Button className='confirmButton'
                  content='Expire Now'
                  onClick={ (e) => { this.expireNow(),this.setState({confirmDisabled: false})}}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )

      const renew = this.state.actionError ?
      errorMessage :
      this.state.modalSuccess ? (
        <Grid>
          <Grid.Row centered>
            <p>
				<strong>Success!</strong> This VERISEAL as been renewed on the 
				CERTUS<span style={{lineHeight: '.8rem', fontSize: '.7rem', verticalAlign: 'top'}}>&#174;</span> KSI Blockchain and the 
				expiration date is {this.state.newExpirationDate && this.state.newExpirationDate.toDateString()}.
			</p>
          </Grid.Row>
        </Grid>
      ) : (
        <Grid columns={2} divided>
          <Grid.Row centered>
            {this.state.newExpirationDate ?
            <p>The new expiration date will be <b>{this.state.newExpirationDate.toDateString()}</b>. Click OK to confirm.</p> :
            <p>Please select a new expiration date below.</p>}
          </Grid.Row>
          <Grid.Row>
            <div style={{margin:'0 auto'}}>
              <DatePicker
                name='filterRunsDate'
                selected={this.state.newExpirationDate ? this.state.newExpirationDate : this.expirationDate}
                minDate={Date.now()}
                startDate={Date.now()}
                onSelect={ (e) => {this.setState({newExpirationDate: e, confirmDisabled: false})}} //when day is clicked
              />
            </div>
          </Grid.Row>
        </Grid>
      )

      const revoke = this.state.actionError ?
      errorMessage :
      this.state.modalSuccess ? (
        <Grid>
          <Grid.Row centered>
            <p>
				<strong>Success!</strong> This VERISEAL as been permanently revoked on the 
				CERTUS<span style={{lineHeight: '.8rem', fontSize: '.7rem', verticalAlign: 'top'}}>&#174;</span> KSI Blockchain.
			</p>
          </Grid.Row>
        </Grid>
      ) : (
        <Grid columns={2} divided>
          <Grid.Row centered>
            <Message warning>
				<strong>
					WARNING: This action is not reversible! The results will permanently be marked as invalid through the authenticating 
					VERISEAL QR Code.
				</strong>
			</Message>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div style={{float:'right'}}>
                <Checkbox className="certusModalToggle" toggle onChange={() => {this.setState({revokeOne: !this.state.revokeOne})}} />
              </div>
            </Grid.Column>
            <Grid.Column>
              {this.state.revokeOne &&
                <div style={{float:'left'}}>
                  <Checkbox className="certusModalToggle" disabled={!(this.state.revokeOne)} toggle onChange={() => {this.setState({revokeTwo: !this.state.revokeTwo})}}/>
                </div>
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <p>Toggle both switches to confirm you want to proceed with this action.</p>
          </Grid.Row>
        </Grid>
      )
    return (
      <Modal
        onClick={(e) => {e.stopPropagation()}}
        className='certusConfirmation'
        size='large'
        open={this.props.modalOpen}
        onClose={(e) => {this.reload(e), this.cancelModal()}} closeIcon>
        <Modal.Header>
          {action}: {this.props.certus.project_name}
        </Modal.Header>
        <Modal.Content>
          {display()}
        </Modal.Content>
        <Modal.Actions>
			{ this.state.actionError
				? <Button className='cancelButton' onClick={(e) => {this.reload(e), this.cancelModal()}}>Close</Button>
				: <Button className='confirmButton'
					disabled={this.state.confirmDisabled}
					icon='checkmark'
					labelPosition='right'
					content='OK'
					onClick={(e) => {this.state.modalSuccess ? this.closeSuccessModal(e) : apiCall()}} />
			}
        </Modal.Actions>
      </Modal>
    )
  }
}


export default withRouter(CertusModalActions)
