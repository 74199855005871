import React, { Component } from 'react'
import { 
  Button, 
  Form, 
  Grid, 
  Radio, 
  Modal, 
  Header 
} from 'semantic-ui-react'
import constants from '../../lib/constants'
import trimWhiteSpace from '../../lib/trim'
const gridOptions = [
  { key: 'text', text: 'Text', value: 'textbox' },
  { key: 'number', text: 'Number', value: 'number' },
  { key: 'check_box', text: 'Checkbox', value: 'checkbox' },
  { key: 'drop_down', text: 'Dropdown', value: 'dropdown' },
]
class CreateFormField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formFieldCreated: null,
      formFieldUpdated: null,
      fieldValidation: false,
      templateValue: [''],
      radioType: 'textbox',
      fieldName: '',
      formError: {
        fieldNameError: false,
        templateValueError: false,
      },
      errorMessage: '',
      apiErrors: {}
    }
  }
  componentDidMount() {
    if (this.props.data.name)
      this.setState({ fieldName: this.props.data.name, radioType: this.props.data.type, fieldValidation: this.props.data.validations[0].is_required, templateValue: (this.props.data.values.length === 0) ? [''] : this.props.data.values, apiErrors: {} })
  }
  handleNameChange = (e) => {
    this.setState({ fieldName: e.target.value })
    if (e.target.value !== '' && this.state.formError.fieldNameError === true) {
      this.setState({ formError: { ...this.state.formError, fieldNameError: false } })
    }
  }
  handleChange = (event, i) => {
    let values = [...this.state.templateValue]
    values[i] = event.target.value
    this.setState({ templateValue: values }, () => {
      let arrayList = this.state.templateValue.filter((value) => { return value !== '' })
      if (arrayList.length > 0) {
        this.setState({ formError: { ...this.state.formError, templateValueError: false } })
      }
    })
  }
  addClick = () => {
    this.setState(prevState => ({ templateValue: ['', ...prevState.templateValue] }))
  }
  removeClick = (i) => {
    let values = [...this.state.templateValue]
    values.splice(i, 1)
    this.setState({ templateValue: values })
  }
  validateForm = () => {
    let formError = false
    let error = { ...this.state.formError }
    if (this.state.fieldName.length === 0) {
      error = { ...error, fieldNameError: true }
      this.setState({ formError: error })
      formError = true
    }
    else {
      error = { ...error, fieldNameError: false }
      this.setState({ formError: error })
    }
    if (this.state.radioType === 'dropdown') {
      let arrayList = this.state.templateValue.filter((value) => { return value !== '' })
      if (arrayList.length === 0) {
        error = { ...error, templateValueError: true }
        this.setState({ formError: error })
        formError = true
      }
      else {
        error = { ...error, templateValueError: false }
        this.setState({ formError: error })
      }
    }
    return formError
  }
  addFormField = (e) => {
    let formIsNotValid = this.validateForm()
    if (formIsNotValid) {
      return false
    }
    let requestBody = {
      "id": this.props.type === 'add' ? null : this.props.data.id,
      "name": trimWhiteSpace(this.state.fieldName),
      "type": trimWhiteSpace(this.state.radioType),
      "values": this.state.radioType === 'dropdown' ? this.state.templateValue.filter((value) => trimWhiteSpace(value) !== '') : [],
      "validations": [
        {
          "is_required": this.state.fieldValidation
        }
      ]
    }
    this.props.handleSave(e, requestBody, this.props.type, this.props.index)
  }
  handleCheckBox = (e, { name, value, checked }) => {
    this.setState({ fieldValidation: checked })
  }
  handleRadioType = (e, { value }) => {
    this.setState({ radioType: value })
  }
  render() {
    return (
      <Modal size='small' open={true} onClose={(e) => { this.props.closeModal(e) }
      } closeIcon>
        <Header icon='add' content={this.props.type === 'add' ? constants.FORM_FIELD.CREATE.HEADER_TITLE : constants.FORM_FIELD.EDIT.HEADER_TITLE} />
        <Modal.Content style={{ textAlign: 'center' }}>
          <Form>
            <Grid centered>
              <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label="Name"
                  name="fieldName" onChange={this.handleNameChange} type="text"
                  value={this.state.fieldName}
                  placeholder="Name" error={this.state.formError.fieldNameError} className="required-field" />
                {this.state.formError.fieldNameError && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
              </Grid.Column>
            </Grid>
            <Grid centered>
              <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                <Form.Field style={{ fontWeight: 'bold', color: 'black' }}>Type</Form.Field>
                <Grid style={{ marginTop: '3px' }}>
                  {gridOptions.map((input, index) =>
                    <Form.Field key={input.key}>
                      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                        <Radio
                          label={input.text}
                          name='radioType'
                          value={input.value}
                          checked={this.state.radioType === input.value}
                          onChange={this.handleRadioType}
                          key={input.key}
                          style={{ float: 'left' }}
                        />
                      </Grid.Column>
                    </Form.Field>)
                  }
                </Grid>
              </Grid.Column>
            </Grid>
            {this.state.radioType === 'dropdown' &&
              <React.Fragment>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                    <Form.Field style={{ fontWeight: 'bold', color: 'black' }}>
                      Values
                    </Form.Field>
                    <Grid>
                      {this.state.templateValue.map((input, i) =>
                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                          <Grid >
                            <Grid.Column mobile={12} tablet={12} computer={14} largeScreen={14}>
                              <Form.Input
                                name='template'
                                onChange={(e) => this.handleChange(e, i)}
                                value={input}
                                style={{ float: 'left' }}
                                error={this.state.formError.templateValueError}
                              /> {this.state.formError.templateValueError && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
                            </Grid.Column>
                            <Grid.Column mobile={4} tablet={4} computer={2} largeScreen={2}>
                              {
                                i !== 0 && <Button circular color='red' onClick={(e) => { e.preventDefault(); this.removeClick(i) }} icon='remove' title='Remove' />
                              }
                              {
                                i === 0 && <Button circular color='blue' onClick={(e) => { e.preventDefault(); this.addClick() }} icon='add' title='Add' />
                              }
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      )}
                    </Grid>
                  </Grid.Column>
                </Grid>
              </React.Fragment>}
            <Grid centered>
              <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                <Form.Field style={{ fontWeight: 'bold', color: 'black' }}>
                  Validation
                </Form.Field>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
                    <Form.Checkbox label='Required' checked={this.state.fieldValidation} name='fieldValidation' value={this.state.fieldValidation} onClick={this.handleCheckBox} />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
            <Grid centered>
              <Grid.Column mobile={16} tablet={8} largeScreen={4} computer={4} textAlign='center'>
                <Button className='blue' type="submit" onClick={(e) => (this.addFormField(e))} >{this.props.type === 'edit' ? constants.FORM_FIELD.EDIT.EDIT_BUTTON_TITLE : constants.FORM_FIELD.CREATE.CREATE_BUTTON_TITLE}</Button>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

export default CreateFormField
