import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Countdown from 'react-countdown-now'
import { Button, Grid, Row, Modal, Message, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import moment from 'moment'
import SystemStatus from './SystemStatus'
import ResultsWidget from './ResultsWidget'
import BottleWidget from './BottleWidget'
import SampleProcessor from '../lib/SampleProcessor'
import constants from '../lib/constants'
import { userServices } from '../services/User'
import { SampleTestServices } from '../services/SampleTest'
import permissionCheck from '../lib/permissionCheck'
import { startTimer, TOGGLE_SAMPLE_QUEUES } from '../actions'
import axios from '../axios'
import cannidAPI from '../cannidAPI/client'

const mapStateToProps = (state) => ({
	current_user: state.current_user,
	machineState: state.machineState,
	timer: state.timer
})

const mapDispatchToProps = (dispatch) => ({
	startTimer: (timer) => {
		dispatch(startTimer(timer))
	},
	toggleSampleQueues: () => {
		dispatch({ type: TOGGLE_SAMPLE_QUEUES })
	}
})

class Home extends Component {
	constructor(props) {
		super(props)
		this.state = {
			top_samples: [],
			top_calibration_samples: [],
			meta: {},
			releases: []
		}
		this.mounted = false
	}

	componentDidMount() {
		if (!localStorage.getItem('access_token')) {
			this.props.history.push(constants.APPLICATION_ROUTE.LOGIN.ROUTE)
		}
		this.getTopSamples()

		this.mounted = true
		cannidAPI.get('/releases').then((res) => {
			if (this.mounted) {
				this.setState({ releases: res.data }, () => {
					setTimeout(() => {
						if (this.mounted) {
							this.setState({ releases: [] })
						}
					}, 30000)
				})
			}
		}).catch((err) => {
			console.error('couldn\'t get releases because of this error:')
			if (this.mounted) {
				console.error(err)
			}
		})
	}

	componentWillUnmount() {
		this.mounted = false
	}

	getTopSamples = () => {
		const machine = localStorage.getItem('machine')
		if (machine) {
			SampleTestServices.getTopMachineSamples(machine, [constants.SAMPLE_TYPE.CALIBRATION], []).then((result) => {
				this.setState({
					top_samples: result.data.data,
					meta: result.data.meta
				})
			}).catch((error) => {
			})
			SampleTestServices.getTopMachineSamples(machine, [], [constants.SAMPLE_TYPE.CALIBRATION]).then((result) => {
				this.setState({
					top_calibration_samples: result.data.data
				})
			}).catch((error) => {
			})
		}
		else {
			SampleTestServices.getTopSamples([constants.SAMPLE_TYPE.CALIBRATION], []).then((result) => {
				this.setState({
					top_samples: result.data.data,
					meta: result.data.meta
				})
			}).catch((error) => {
			})
			SampleTestServices.getTopSamples([], [constants.SAMPLE_TYPE.CALIBRATION]).then((result) => {
				this.setState({
					top_calibration_samples: result.data.data
				})
			}).catch((error) => {
			})
		}
	}

	createTimer = (duration, message) => {
		this.props.startTimer({
			seconds: 0,
			message,
			duration
		})
	}

	startCalibration = () => {
		cannidAPI.post('/sample_queues/start_calibration').then((res) => {
			this.props.fetchQueues().then(() => {
				this.props.toggleSampleQueues()
			})
		})
	}

	updateActionState = () => {

	}

	wakePopup = (e) => {
		cannidAPI.get('/actions/wake')
			.then((res) => {
				fetch(`${process.env.REACT_APP_DRIVER_HTTP_ROOT}/machine/warmup`, { method: 'POST' })
					.then((res) => {
					})
					.catch((res) => {
						console.error('cs could not warm up', res.body)
					})
			})
			.catch((e) => (console.error('oh no we, couldnt warm up', e)))
	}

	sleepPopup = (e) => {
		cannidAPI.get('/actions/sleep')
			.then((res) => {
				this.updateActionState()
			})
			.catch((e) => (console.error('oh no we, couldnt go to sleep', e)))
	}

	closeModal = () => {
		this.setState({
			modalOpen: false,
			modalTitle: false,
			modalText: false,
			redirectVar: false,
			countDown: false
		})
	}

	isMachineOnline = () => {
		const machineState = this.props.machineState
		const machineStateExists = !!(machineState && Object.keys(machineState).length > 0)

		if (machineStateExists === false) {
			return false
		}

		const OFF_STATUSES = ['POWEROFF', 'disconnect']
		return !OFF_STATUSES.includes(machineState.status)
	}

	buildImportButtons = () => {
		const onViewResultsClick = () => { this.props.history.push(constants.APPLICATION_ROUTE.SAMPLE.DASHBOARD.ROUTE) }
		const viewResultsBtnDisabled = !(permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.LIST))
		const viewResultsButton = (
			<Button disabled={viewResultsBtnDisabled} onClick={onViewResultsClick} fluid size='small' className='dashboardButton green'>
				{constants.APPLICATION_ROUTE.SAMPLE.DASHBOARD.NAME}
			</Button>
		)

		const newSampleButton = (
			<Button
				onClick={() => { this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.CREATE.ROUTE, {})) }}
				fluid size='small' className='dashboardButton blue'
			>
				{constants.APPLICATION_ROUTE.SAMPLE.CREATE.NAME}
			</Button>
		)

		const onQCBtnClick = () => { this.props.history.push(constants.APPLICATION_ROUTE.QC.LIST.ROUTE) }
		const qcButton = (
			<Button onClick={onQCBtnClick} fluid size='small' className='dashboardButton green'>Quality Control Results</Button>
		)

		const importButton = (
			<Button
				onClick={() => { this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.IMPORT_CSV.ROUTE)) }}
				fluid size='small' className='dashboardButton blue'
			>
				{constants.APPLICATION_ROUTE.SAMPLE.IMPORT_CSV.NAME}
			</Button>
		)

		const reportButton = (
			<Button
				onClick={() => { this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.REPORT_METHOD.LIST.ROUTE)) }}
				fluid size='small' className='dashboardButton blue'
			>
				{constants.APPLICATION_ROUTE.REPORT_METHOD.LIST.NAME}
			</Button>
		)

		const certusButton = (
			<Button
				onClick={() => { this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.CERTUS.LIST.ROUTE)) }}
				fluid size='small' className='dashboardButton blue'
			>
				<div style={{ display: 'flex', justifyContent: 'center' }}>CERTUS<Icon name='registered outline' size='tiny' /></div>
			</Button>
		)

		const isCertusUser = permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL)

		return (
			<Grid stackable centered padded className='dashboardButtonGroup' textAlign='center'>
				<Grid.Row columns={isCertusUser ? 6 : 5}>
					<Grid.Column>
						{viewResultsButton}
					</Grid.Column>
					<Grid.Column>
						{qcButton}
					</Grid.Column>
					<Grid.Column>
						{newSampleButton}
					</Grid.Column>
					<Grid.Column>
						{importButton}
					</Grid.Column>

					<Grid.Column>
						{reportButton}
					</Grid.Column>

					{isCertusUser
          && (
          	<Grid.Column>
          		{certusButton}
	</Grid.Column>
          )}
				</Grid.Row>
			</Grid>
		)
	}

	buildHomeButtons = () => {
		const machineOnline = this.isMachineOnline()

		const viewResultsBtnDisabled = !(permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.LIST))
		const onViewResultsClick = () => { this.props.history.push(constants.APPLICATION_ROUTE.SAMPLE.DASHBOARD.ROUTE) }
		const viewResultsButton = (
			<Button disabled={viewResultsBtnDisabled} onClick={onViewResultsClick} fluid size='large' className='dashboardButton green'>
				{constants.APPLICATION_ROUTE.SAMPLE.DASHBOARD.NAME}
			</Button>
		)

		const newSampleButton = (
			<Button
				onClick={() => { this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.CREATE.ROUTE, {})) }}
				fluid size='large' className='dashboardButton blue'
			>
				{constants.APPLICATION_ROUTE.SAMPLE.CREATE.NAME}
			</Button>
		)

		const onQCBtnClick = () => { this.props.history.push(constants.APPLICATION_ROUTE.QC.LIST.ROUTE) }
		const qcButton = (
			<Button onClick={onQCBtnClick} fluid size='large' className='dashboardButton green'>Quality Control Results</Button>
		)

		let systemStatusSection = null
		if (machineOnline === true) {
			systemStatusSection = (
				<SystemStatus machineState={this.props.machineState} />
			)
		}

		const modal = (
			<Modal size='mini' open={this.state.modalOpen} onClose={this.closeModal} closeIcon>
				<Modal.Header>{this.state.modalTitle}</Modal.Header>
				<Modal.Content>
					<p>{this.state.modalText}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button className='cancelButton' onClick={this.closeModal}>Cancel</Button>
					<Button className='calibrationButton' icon='checkmark' content={this.state.countDown} labelPosition='right' onClick={() => { this.state.redirectVar() }} />
				</Modal.Actions>
			</Modal>
		)

		return (
			<Grid doubling padded columns={2} className='dashboardButtonGroup' textAlign='center'>
				<Grid.Row>
					<Grid.Column width={16}>
						{viewResultsButton}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						{qcButton}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						{newSampleButton}
					</Grid.Column>
				</Grid.Row>
				{machineOnline === true
&& (
	<Grid.Row>
		<Grid.Column width={16}>
			{systemStatusSection}
		</Grid.Column>
	</Grid.Row>
)}
				{modal}
			</Grid>
		)
	}

	render() {
		const machineOnline = this.isMachineOnline()
		if (machineOnline && ['POWEROFF', 'disconnect'].includes(this.props.machineState.status)) {
			const closeModal = () => {
				document.getElementById('modal').style.display = 'none'
			}
			ReactDOM.render(
				<div>
					<h1>Connection Lost</h1>
					<h2 style={{ maxWidth: '80%', margin: '0 auto' }}>Please ensure ChemStation (online) is running, check the instrument is powered on, and the LAN cable is secured both in the instrument and in the laptop.</h2>
					<Button className='cancelButton' onClick={closeModal}>
						Close
					</Button>
				</div>,
				document.getElementById('modal')
			)
			document.getElementById('modal').style.display = 'block'
		}
		else if (document.getElementById('modal')) {
			document.getElementById('modal').style.display = 'none'
		}

		const homePageButtonGroupSizing = {
			largeScreen: 10,
			computer: 10
		}

		if (!machineOnline) {
			homePageButtonGroupSizing.largeScreen = 6
			homePageButtonGroupSizing.computer = 6
		}

		return (
			<div>
				<section id='mainContent'>
					{(this.state.releases.length > 0 && moment(this.state.releases[0].release_date).isAfter(moment().subtract(1, 'weeks')))
&& (
	<section className='app'>
		<Message warning>
			<Message.Header>New version {this.state.releases[0].version} released!</Message.Header>
			<p>Visit the <Link to='/support/changelog'>Changelog</Link> to review the release notes and see what is new.</p>
		</Message>
	</section>
)}
					<section className='app dark split'>
						{machineOnline
            && (
            	<Grid stackable columns={2} className='dashboard'>
            		<Grid.Row>
            			<Grid.Column {...homePageButtonGroupSizing}>
            				{this.buildHomeButtons()}
	</Grid.Column>
            			<Grid.Column floated='right' largeScreen={6} computer={6}>
            				<BottleWidget machineState={this.props.machineState} hideInputs={false} />
	</Grid.Column>
	</Grid.Row>
	</Grid>
            )}
						{!machineOnline && this.buildImportButtons()}
					</section>
					<section className='app dark split'>
						<div className='widgets'>
							<div className='widgetLeft'>
								<div className='widget'>
									<ResultsWidget samples={this.state.top_samples} />
								</div>
							</div>
						</div>
					</section>
				</section>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
