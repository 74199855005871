import axios from '../axios'
import { SampleTestServices } from './SampleTest'

function pdfDownload(shareKey, fileName, template) {
	return axios().get(SampleTestServices.getPdfReportDownloadUrl(shareKey, template), { responseType: 'blob' }).then((response) => {
		if (response.status === 200) {
			if (navigator.userAgent.match('CriOS')) {
				const reader = new FileReader()
				const out = new Blob([response.data], { type: 'application/pdf' })

				reader.onload = () => { window.location.href = reader.result }
				reader.readAsDataURL(out)
			}
			else {
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')

				link.href = url
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				link.click()
			}
		}
	})
}

export default pdfDownload
