import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'

function QueueToggle(props) {
  const dispatch = useDispatch()
  return (
    <div className="autosamplerWidget" onClick={() => dispatch({type: "TOGGLE_SAMPLE_QUEUES"})}>
      <Button icon labelPosition="left">
        <span>
          {useSelector(state => state.sampleQueues.filter((q) => (q.sample)).length)}
          &nbsp;Samples in Queue&nbsp;
          {useSelector(state => state.notificationsLog.length) > 0 &&
            <Icon name="exclamation triangle" size="large" />
          }
        </span>
        <Icon name="numbered list" size="large" />
      </Button>
    </div>
  )
}

export default QueueToggle
