import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import axios from 'axios'
import ConditionalRender from '../ConditionalRender'
import Acl from '../ACL/Acl'
import constants from '../../lib/constants'
import isQC from '../../lib/isQC'
import SampleProcessor from '../../lib/SampleProcessor'
import { showChromes } from '../../lib/pathEncoderS3'
import permissionCheck from '../../lib/permissionCheck'
import { SampleTestServices } from '../../services/SampleTest'

const ACL = constants.ACL

const OFF_MACHINE_STATUSES = ['POWEROFF', 'disconnect']

const mapStateToProps = (state) => ({
	machineState: state.machineState,
	sampleQueues: state.sampleQueues,
	user: state.current_user,
	chemidUser: state.chemidUser,
	chemidEntity: state.chemidEntity
})

function ActionsMenu({ machineState, sampleQueues, user, chemidUser, chemidEntity, sample, onAddSampleToQueue, onShare, onDownloadCSV, onDownloadJSON, onDownloadPDF, onDownloadCOA, onDownloadChromes, onDownloadVialZip, onPrintReport, onClearSample, chemidTransferResponse }) {
	const sampleFinished = new SampleProcessor(sample).finished()
	const machineOnline = machineState && machineState.status && !OFF_MACHINE_STATUSES.includes(machineState.status)
	const sampleInQueue = sampleQueues.some((queueSample) => (queueSample && queueSample.sample && queueSample.sample.id === sample.id))

	let runTestItem = null
	let downloadDropdown = null
	let printDropdown = null

	if (machineOnline) {
		if (sampleFinished) {
			runTestItem = <Menu.Item name='Create New Test' icon='play' href={constants.APPLICATION_ROUTE.SAMPLE.CREATE.ROUTE} />
		}
		else {
			const machineIsSleeping = machineState.method === `${process.env.REACT_APP_SLEEP_METHOD}.M`

			runTestItem = <Menu.Item name='Add To Queue' icon='plus' onClick={onAddSampleToQueue} disabled={sampleInQueue || machineIsSleeping} />
		}
	}

	if (sampleFinished) {
		const userIsSuperAdmin = user.actual_role.slug === constants.USER_ROLE.SUPER_ADMIN_SLUG
		const userIsGeneralAdmin = constants.USER_ROLE.ADMIN_ROLE_SLUGS.includes(user.role.slug)
		let zipDropdownItems = null
		let sourceFileItems = []
		let attachmentDropdownItems = []

		if (userIsGeneralAdmin) {
			zipDropdownItems = sample.results.map((result, index) => {
				const key = result.dataZipUrl || result.uuid || result.chromeUrl || result.csDataName || `${index}Zipper`

				if (result.dataZipUrl) {
					return <Dropdown.Item key={key} text={`Raw Data`} description={`Vial ${index + 1}`} icon='zip' onClick={() => onDownloadVialZip(result.dataZipUrl, index + 1)} />
				}
			})
		}

		const attachmentIconMap = {
			pdf: 'file pdf',
			zip: 'zip',
			csv: 'file excel',
			gif: 'file image',
			jpg: 'file image',
			jpeg:'file image',
			png: 'file image',
			webp: 'file image'
		}

		sample.results.forEach((result, resultIndex) => {
			if (result.importedSource) {
				const urlSplit = result.importedSource.split('.')
				const ext = urlSplit[urlSplit.length - 1]

				sourceFileItems.push(
					<Dropdown.Item
						key={result.importedSource}
						text='Source File'
						icon={ attachmentIconMap[ext] || attachmentIconMap[ext.toUpperCase()] || 'file' }
						description={`Vial ${resultIndex + 1}`}
						onClick={() => {
							const url = result.importedSource
							const sampleName = (sample && sample.intake_form.strain_name)
								? sample.intake_form.strain_name : ''
							axios({ url, method: 'GET', responseType: 'blob' })
							.then((response) => {
								if (response.status >= 200 && response.status <= 299) {
									const content = window.URL.createObjectURL(new Blob([response.data]))
									const link = document.createElement('a')
									link.href = content
									link.setAttribute('download', result.metadata['Imported Filename'] || `${sampleName}_${resultIndex + 1}_Source`)
									document.body.appendChild(link)
									link.click()
								}
								else {
									console.error(`Access error downloading attachment. (Reference ${response.status} ${response.statusText})`)
								}
							})
							.catch((error) => {
								console.error(`Network error downloading attachment. (Reference ${error})`)
							})
						}}
					/>
				)
			}

			if (result.attachments) {
				attachmentDropdownItems.push(
					<Dropdown.Header
						key={`attyVial${resultIndex}`}
						icon='flask'
						content={`Vial ${resultIndex + 1} Attachments`}
						style={{ background: 'lightgray', padding: '5px 1em', textAlign: 'center', margin: 0 }}
					/>
				)

				result.attachments.forEach((atty, attyIndex) => {
					const nameSplit = atty.name.split('.')
					const ext = nameSplit[nameSplit.length - 1]

					attachmentDropdownItems.push(
						<Dropdown.Item
							key={atty.url}
							text={`${atty.name}`}
							icon={ attachmentIconMap[ext] || attachmentIconMap[ext.toUpperCase()] || 'file' }
							onClick={() => {
								const url = atty.url
								const sampleName = (sample && sample.intake_form.strain_name)
									? sample.intake_form.strain_name : ''
								axios({ url, method: 'GET', responseType: 'blob' })
								.then((response) => {
									if (response.status >= 200 && response.status <= 299) {
										const content = window.URL.createObjectURL(new Blob([response.data]))
										const link = document.createElement('a')
										link.href = content
										link.setAttribute('download', `${sampleName}_${attyIndex + 1}_${atty.name}`)
										document.body.appendChild(link)
										link.click()
									}
									else {
										console.error(`Access error downloading attachment. (Reference ${response.status} ${response.statusText})`)
									}
								})
								.catch((error) => {
									console.error(`Network error downloading attachment. (Reference ${error})`)
								})
							}}
						/>
					)
				})
			}
		})

		downloadDropdown = (
			<Acl entity={ACL.SAMPLE_REPORT_RESOURCE.TITLE} action={ACL.SAMPLE_REPORT_RESOURCE.ACTIONS.DOWNLOAD_REPORT}>
				<Dropdown item trigger={<span><Icon name='download' /> Download</span>}>
					<Dropdown.Menu style={{ minWidth: 170}}>
						<Dropdown.Item text='CSV' icon='file excel' onClick={onDownloadCSV} />
						<Dropdown.Item text='JSON' icon='js' onClick={onDownloadJSON} />
						<Dropdown.Item text='Results' icon='file pdf' onClick={onDownloadPDF} />
						<ConditionalRender condition={sample.coa && userIsSuperAdmin}>
							<Dropdown.Item text='COA' icon='file text' onClick={onDownloadCOA} />
						</ConditionalRender>
						<ConditionalRender condition={userIsGeneralAdmin && showChromes(sample.results)}>
							<Dropdown.Item text='Chromatograms' icon='zip' onClick={onDownloadChromes} />
						</ConditionalRender>
						{zipDropdownItems}
						{sourceFileItems}
						{attachmentDropdownItems}
					</Dropdown.Menu>
				</Dropdown>
			</Acl>
		)

		printDropdown = (
			<Acl entity={constants.ACL.SAMPLE_REPORT_RESOURCE.TITLE} action={constants.ACL.SAMPLE_REPORT_RESOURCE.ACTIONS.DOWNLOAD_REPORT}>
				<Dropdown item trigger={<span><Icon name='print' /> Print</span>}>
					<Dropdown.Menu>
						<Dropdown.Item text='Results' icon='file pdf' onClick={onPrintReport} />
						<ConditionalRender condition={sample.coa && userIsSuperAdmin}>
							<Dropdown.Item text='COA' icon='file text' onClick={() => onPrintReport(true)} />
						</ConditionalRender>
					</Dropdown.Menu>
				</Dropdown>
			</Acl>
		)
	}

	const sampleHasResults = sample.results && sample.results.length > 0
	const canEditCOA = permissionCheck(constants.APPLICATION_ROUTE.SAMPLE.POPULATE_COA.TITLE, constants.APPLICATION_ROUTE.SAMPLE.POPULATE_COA.ACTIONS.UPDATE_COA)
	const canChemidCopy = chemidUser && chemidEntity && canEditCOA && sampleHasResults && !isQC.sample(sample) && sample.coa

	return (
		<Menu className='sampleActionsMenu' compact stackable>
			<Acl entity={ACL.SAMPLE_REPORT_RESOURCE.TITLE} action={ACL.SAMPLE_REPORT_RESOURCE.ACTIONS.RUN_TEST}>
				{runTestItem}
			</Acl>
			<ConditionalRender condition={sampleFinished}>
				<Acl entity={ACL.SAMPLE_REPORT_RESOURCE.TITLE} action={ACL.SAMPLE_REPORT_RESOURCE.ACTIONS.SHARE_REPORT}>
					<Menu.Item name='Share' icon='linkify' onClick={onShare} />
				</Acl>
			</ConditionalRender>
			{downloadDropdown}
			{printDropdown}
			<Acl entity={ACL.SAMPLE_RESOURCE.TITLE} action={ACL.SAMPLE_RESOURCE.ACTIONS.DELETE}>
				<Dropdown item trigger={<span><Icon name='wrench' /> Manage</span>}>
					<Dropdown.Menu>
						<Dropdown.Item text='Edit' icon='edit' disabled={!!(sampleInQueue || sampleHasResults)} href={formatRoute(constants.APPLICATION_ROUTE.SAMPLE.EDIT.ROUTE, { id: sample.id })} />
						<Dropdown.Item text='Clear' icon='erase' disabled={!!(sampleInQueue || sample.import || sample.certus.sample_certus || !sampleHasResults)} onClick={onClearSample} />
						<ConditionalRender condition={machineOnline}>
							<Dropdown.Item text='Import Chemstation Run' icon='upload' disabled={!!(sampleFinished || sampleInQueue)} href={`/sample/${sample.id}/import`} />
						</ConditionalRender>
						<ConditionalRender condition={!isQC.sample(sample) && canEditCOA}>
							<Dropdown.Item
								text={constants.APPLICATION_ROUTE.SAMPLE.POPULATE_COA.NAME}
								icon='certificate'
								disabled={!sampleFinished}
								href={formatRoute(constants.APPLICATION_ROUTE.SAMPLE.POPULATE_COA.ROUTE, { id: sample.id })}
							/>
						</ConditionalRender>
						<ConditionalRender condition={canChemidCopy}>
							<Dropdown.Item
								text='Send to ChemID'
								icon='send'
								disabled={!sampleFinished}
								onClick={() => SampleTestServices.sendSampleToChemid(chemidEntity.id, sample.id).then(res => chemidTransferResponse(res))}
							/>
						</ConditionalRender>
					</Dropdown.Menu>
				</Dropdown>
			</Acl>
		</Menu>
	)
}

ActionsMenu.propTypes = {
	machineState: PropTypes.shape({
		status: PropTypes.string,
		method: PropTypes.string
	}),
	sampleQueues: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number
	})).isRequired,
	user: PropTypes.shape({
		actual_role: PropTypes.shape({ slug: PropTypes.string }),
		role: PropTypes.shape({ slug: PropTypes.string })
	}).isRequired,
	sample: PropTypes.shape({
		id: PropTypes.number,
		coa: PropTypes.bool,
		import: PropTypes.bool,
		sample_type: PropTypes.shape({ slug: PropTypes.string }),
		results: PropTypes.arrayOf(PropTypes.shape({
			uuid: PropTypes.string,
			dataZipUrl: PropTypes.string
		})),
		certus: PropTypes.shape({
			sample_certus: PropTypes.object // eslint-disable-line react/forbid-prop-types
		})
	}).isRequired,
	onAddSampleToQueue: PropTypes.func.isRequired,
	onShare: PropTypes.func.isRequired,
	onDownloadCSV: PropTypes.func.isRequired,
	onDownloadJSON: PropTypes.func.isRequired,
	onDownloadPDF: PropTypes.func.isRequired,
	onDownloadCOA: PropTypes.func.isRequired,
	onDownloadChromes: PropTypes.func.isRequired,
	onDownloadVialZip: PropTypes.func.isRequired,
	onPrintReport: PropTypes.func.isRequired,
	onClearSample: PropTypes.func.isRequired,
	chemidTransferResponse: PropTypes.func.isRequired
}

ActionsMenu.defaultProps = {
	machineState: null
}

export default connect(mapStateToProps)(ActionsMenu)
