import React from 'react'
import { Icon, Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'

function ImportDisclosureModal(props) {
	// requires props
	// active: state boolean for toggling open/close
	// closer: function for setting active state false on close
	return (
		<Modal
			className='importDisclosure'
			size='large'
			open={props.active}
			onClose={() => props.closer()}
			closeIcon
		>
			<Modal.Header><Icon name='info circle' color='green' />Imported Sample</Modal.Header>
			<Modal.Content scrolling>
				The data for this sample was uploaded and sourced from outside the standard Cann.ID ecosystem.
				The Cann.ID Data Importer was used to upload the data in a .CSV format that could have been altered without the user&#39;s knowledge.
				Potentially, data records may have been in a format that CannID does not recognize or was missing required data and HAS NOT
				been included in the web report.
				Contact Cann.ID at <a href='mailto:tech@ionizationlabs.com' style={{ cursor: 'pointer' }}>tech@ionizationlabs.com</a> if you
				have questions about the Cann.ID Data Importer.
			</Modal.Content>
		</Modal>
	)
}

ImportDisclosureModal.propTypes = {
	active: PropTypes.bool.isRequired,
	closer: PropTypes.func.isRequired
}.isRequired

export default ImportDisclosureModal
