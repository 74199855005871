import zxcvbn from 'zxcvbn'
import constants from './constants'
const passwordStrength = (password) => {
  let score = 0
  if (password.length > 0) {
    if (password.match(constants.REG_EX.PASSWORD_REG_EX) && password.length >= constants.PASSWORD.MIN_LENGTH) {
      score = zxcvbn(password).score
    }
  }
  else {
    score = -1
  }

  return score
}

export default passwordStrength
