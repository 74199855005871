import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import vialTypes from '../lib/vialTypes'
import PrepVial from './PrepVial'
import FancyVolume from './FancyVolume'

class TreeFollow extends Component {
	constructor(props) {
		super(props)
		this.state = { vialRef: null }
		this.setVialRef = (element) => {
			this.setState((prevState, props) => ({
				vialRef: element
			}))
		}
	}

	componentDidUpdate() {
		this.props.renderCallback && this.props.renderCallback()
	}

	render() {
		const children = this.props.allNodes.filter((node) => (node.draw_from_prep_step_id === this.props.currentNode.id))
		const childrenSeeds = children.map((child) => (
			<TreeFollow
				key={child.id}
				currentNode={child}
				allNodes={this.props.allNodes}
				depth={this.props.depth + 1}
				parentRef={this.state.vialRef}
				idPrefix={this.props.idPrefix}
				editingVialId={this.props.editingVialId}
				editing={this.props.editingVialId === child.id}
				nodeClicked={this.props.nodeClicked}
			/>
		))
		let aliquotInfo
		return (
			<div id={`prepMethod-${this.props.idPrefix}-vial-${this.props.currentNode.id}`} className={`vial nodeDepth-${this.props.depth} ${this.props.editing ? 'editing' : ''}`} ref={this.setVialRef}>
				{aliquotInfo}
				<div className='description' onClick={this.props.nodeClicked ? () => { this.props.nodeClicked(this.props.currentNode.id) } : null}>
					<PrepVial vial={this.props.currentNode}>
						<div className='aliquotInfo'>
							<div className='depositVolume'>
								<FancyVolume ul={this.props.currentNode.deposit_volume_ul} />
							</div>
							<div className='transitionText'>
								➢
							</div>
							<div className='fillVolume'>
								<FancyVolume ul={this.props.currentNode.fill_volume_ul} />
							</div>
						</div>
					</PrepVial>
				</div>
				{childrenSeeds}
			</div>
		)
	}
}

export default TreeFollow
