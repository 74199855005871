import React  from 'react'
import { Breadcrumb, Button, Icon, Table, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import AppHeader from '../AppHeader'
import DynamicTableComponent from '../DynamicTableComponent'
import { FormTemplateService } from '../../services/FormTemplates'
import ModalComponent from '../ModalComponent'
import ListFormFieldsByTemplate from '../FormTemplates/ListFormFieldsByTemplate'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import constants from '../../lib/constants'
import Acl from '../ACL/Acl'
import { formatRoute } from 'react-router-named-routes'
import checkDynamicTableActions from '../../lib/checkDynamicTableActions'
import permissionCheck from '../../lib/permissionCheck'
const tableHeader = [
  { id: 'name', title: 'Name', sortable: true },
  { id: 'formFieldCount', title: 'Form field Count', sortable: false },
  { id: 'created_at', title: 'Date Created', sortable: true },
  { id: 'updated_at', title: 'Date Updated', sortable: true },
  {
    id: 'action_item',
    title: 'Actions',
    sortable: false,
    actionItems: [
      { title: constants.FORM_TEMPLATE.LIST.EDIT_FORM_TEMPLATE, color: 'black', style: { marginRight: '10px' }, icon: 'edit', action: constants.ACL.FORM_TEMPLATE_RESOURCE.ACTIONS.EDIT },
      { title: constants.FORM_TEMPLATE.LIST.VIEW_FORM_TEMPLATE, style: { marginRight: '10px' }, color: 'yellow', icon: 'eye', action: constants.ACL.FORM_TEMPLATE_RESOURCE.ACTIONS.VIEW_FORM_TEMPLATE },
      { title: constants.FORM_TEMPLATE.LIST.REMOVE, style: { marginRight: '10px' }, color: 'red', icon: 'remove', action: constants.ACL.FORM_TEMPLATE_RESOURCE.ACTIONS.DELETE }
    ]
  }
]
const options = [
  { key: 'name', text: 'Name', value: 'name' }
]
const entireRowAction = { title: constants.FORM_TEMPLATE.LIST.LIST_FORM_FIELDS, action: constants.ACL.FORM_TEMPLATE_RESOURCE.ACTIONS.LIST_FORM_FIELDS }
class ListFormTemplates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      removeFormTemplate: false,
      selectedSearchDropdown: 'name',
      formTemplate: '',
      formTemplateId: '',
      form_errors: {
        formTemplateError: false
      },
      formTemplateCreated: '',
      descriptionDetails: false,
      descriptionContent: ''
    }
  }
  resize = () => this.forceUpdate()
  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  sortByColumn = (tableData) => {
    this.setState({ tableData: tableData })
  }
  rowAction = (action, data, e) => {
    e.stopPropagation()
    this.setState({ templateName: data.name })
    if (action === constants.FORM_TEMPLATE.LIST.EDIT_FORM_TEMPLATE) {
      this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.EDIT.ROUTE, { id: data.id }))
    }
    if (action === constants.FORM_TEMPLATE.LIST.REMOVE) {
      this.setState({ removeFormTemplate: true, formTemplateId: data.id })
    }
    if (action === constants.FORM_TEMPLATE.LIST.VIEW_FORM_TEMPLATE) {
      this.setState({ descriptionDetails: true, descriptionContent: data.description })
    }
    if (action === constants.FORM_TEMPLATE.LIST.LIST_FORM_FIELDS) {
      if (permissionCheck(constants.ACL.FORM_TEMPLATE_RESOURCE.TITLE, constants.ACL.FORM_TEMPLATE_RESOURCE.ACTIONS.LIST_FORM_FIELDS))
        this.setState({ viewFormFields: true, formTemplateId: data.id })
    }
  }
  removeFormTemplate = (id) => {
    FormTemplateService.deleteFormTemplate(id).then(res => {
      if (res && res.status === 202) {
        let updatedFormTemplate = [...this.state.tableData].filter((data) => data.id !== id)
        this.setState({ removeFormTemplate: false, tableData: updatedFormTemplate, count: this.state.count - 1 }, () => window.location.reload())
      }
    }).catch((error) => {
    })
  }
  openFormTemplateModal = (id, data) => {
    if (id === '')
      this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.CREATE.ROUTE, {}))
    else
      this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.EDIT.ROUTE, { id: id }))
  }
  getTableData = (res) => {
    let response = ( res && res.data && res.data.data ) || []
    let count = ( res && res.data.meta && res.data.meta.total_count ) || 0
    const tableData = response.map((data) => {
      return {
        id: data.id,
        name: data.name,
        description: data.description,
        formFieldCount: data.no_of_form_fields,
        created_at: dateTimeFormatter(data.created_at),
        updated_at: dateTimeFormatter(data.updated_at)
      }
    })
    this.setState({
      tableData: tableData,
      count: count,
      initialCount: count,
      initialData: tableData,
    })
  }
  getFilteredData = (data) => {
    let arrayList = ( data && data.data && data.data.data ) || []
    let count = ( data && data.data && data.data.meta && data.data.meta.total_count ) || 0
    const tableData = arrayList.map((data) => {
      return {
        id: data.id,
        name: data.name,
        description: data.description,
        formFieldCount: data.no_of_form_fields,
        created_at: dateTimeFormatter(data.created_at),
        updated_at: dateTimeFormatter(data.updated_at)
      }
    })
    this.setState({ tableData: tableData, count: count })
  }
  getSelectedDropdown = (e, { value }) => {
    this.setState({
      selectedSearchDropdown: value,
      rowData: this.state.initialData,
      count: this.state.initialData.length
    })
  }
  closeModal = () => {
    this.setState({ descriptionDetails: false, removeFormTemplate: false, viewFormFields: false, descriptionContent: '' })
  }
  render() {
    let breadcrumb
    breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.LIST.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.FORM_TEMPLATE.LIST.NAME}</Breadcrumb.Section></Link>
      </Breadcrumb>
    )
    let showActions = checkDynamicTableActions(tableHeader, constants.ACL.SAMPLE_RESOURCE.TITLE)
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{constants.FORM_TEMPLATE.LIST.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <DynamicTableComponent
              entity={constants.ACL.FORM_TEMPLATE_RESOURCE.TITLE}
              action={constants.ACL.FORM_TEMPLATE_RESOURCE.ACTIONS.LIST}
              tableHeader={tableHeader}
              options={options}
              baseUrl={FormTemplateService.getFormTemplates()}
              rowData={this.state.tableData}
              initialData={this.state.initialData}
              selectedSearchDropdown={this.state.selectedSearchDropdown}
              getFilteredData={this.getFilteredData}
              getSelectedDropdown={this.getSelectedDropdown}
              colSpan={tableHeader.length - (showActions ? 0 : 1)}
              sortByColumn={this.sortByColumn}
              count={this.state.count}
              getData={this.getTableData}
              actionItem={true}
              rowAction={this.rowAction}
              entireRowAction={entireRowAction}
              showActions={showActions}
            >
              <Table.Header fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan={tableHeader.length - (showActions ? 0 : 1)}>
                    <Grid centered>
                      <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
                        {constants.FORM_TEMPLATE.LIST.TOTAL_TITLE}: {this.state.count}
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8} className='dataTableHeaderButtons'>
                        <Acl entity={constants.ACL.FORM_TEMPLATE_RESOURCE.TITLE} action={constants.ACL.FORM_TEMPLATE_RESOURCE.ACTIONS.CREATE}>
                          <Button floated={window.screen.width < 768 ? 'none' : 'right'} icon labelPosition='left' className='blue' size='small'
                            onClick={() => {
                              this.openFormTemplateModal('', [])
                            }}>
                            <Icon name='add' />{constants.FORM_TEMPLATE.LIST.ADD_FORM_TEMPLATE_BUTTON}
                          </Button>
                        </Acl>
                      </Grid.Column>
                    </Grid>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </DynamicTableComponent>
            <ModalComponent
              size='mini'
              action={true}
              openModal={this.state.removeFormTemplate}
              onClose={this.closeModal}
              header={constants.FORM_TEMPLATE.DELETE.HEADER_TITLE}
              content={constants.FORM_TEMPLATE.DELETE.MODAL_CONTENT}
              handleAction={this.removeFormTemplate}
              id={this.state.formTemplateId}
            />
            <ModalComponent
              size='small'
              action={false}
              openModal={this.state.descriptionDetails}
              onClose={this.closeModal}
              header={constants.FORM_TEMPLATE.LIST.VIEW_FORM_TEMPLATE}
              content={this.state.descriptionContent === '' ? constants.FORM_TEMPLATE.LIST.NO_DESCRIPION_FOUND : this.state.descriptionContent}
            />
            <ModalComponent
              size='large'
              action={false}
              header={`Form Fields for ${this.state.templateName}`}
              openModal={this.state.viewFormFields}
              onClose={this.closeModal}
              content={<ListFormFieldsByTemplate history={this.props.history} id={this.state.formTemplateId} onClose={this.closeModal} />}
            />
          </section>
        </section>
      </div>
    )
  }
}
export default ListFormTemplates
