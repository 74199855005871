import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import QCTableHeader from './QCTableHeader'
import QCTableFooter from './QCTableFooter'
import QCTableRow from './QCTableRow'
import samplePropType from './samplePropType'

function QCResultsTable({
	resultsData, sampleQueue, onHeaderCellClick, onRowClick, onRowActionClick, onPageChange, pageSize, currentPage, sortProperty, sortOrder
}) {
	const tableRows = resultsData.data.map(
		(sampleResult) => {
			const inQueue = sampleQueue.some((queueItem) => {
				if (queueItem.sample && queueItem.sample.id && queueItem.sample.id === sampleResult.id) {
					return true
				}

				return false
			})
			return (
				<QCTableRow
					key={sampleResult.id}
					sampleResult={sampleResult}
					inQueue={inQueue}
					onRowClick={onRowClick}
					onRowActionClick={onRowActionClick}
				/>
			)
		}
	)

	const totalPages = Math.ceil(resultsData.meta.total_count / pageSize)

	return (
		<Table sortable celled fixed selectable>
			<QCTableHeader onHeaderCellClick={onHeaderCellClick} sortProperty={sortProperty} sortOrder={sortOrder} />
			<Table.Body>
				{tableRows}
			</Table.Body>
			<QCTableFooter currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
		</Table>
	)
}

QCResultsTable.propTypes = {
	resultsData: PropTypes.shape({
		data: PropTypes.arrayOf(samplePropType).isRequired,
		meta: PropTypes.shape({
			total_count: PropTypes.number.isRequired
		}).isRequired
	}).isRequired,
	sampleQueue: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired
	})).isRequired,
	onHeaderCellClick: PropTypes.func.isRequired,
	onRowClick: PropTypes.func.isRequired,
	onRowActionClick: PropTypes.func.isRequired,
	onPageChange: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	sortProperty: PropTypes.string.isRequired,
	sortOrder: PropTypes.string.isRequired
}

export default QCResultsTable
