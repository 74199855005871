import React, { Component } from 'react'
import {
  Icon,
  Menu,
  Grid,
  Popup,
  Button
} from 'semantic-ui-react'
import { LoginServices } from '../services/Login'
import decodeJWT from '../lib/decodeJWT'
class SharedHeader extends Component {
  logout = () => {
    let token = decodeJWT(localStorage.getItem('access_token'))
    LoginServices.logout(token.session_id).then((res) => {
      localStorage.removeItem('access_token')
      window.location.href = '/'
    }).catch((error) => {
    })
  }
  goToHomePage = () => {
    window.location.href = '/'
  }
  login = () => {
    window.location.href = '/'
  }
  checkStatus = () => {
    return !Object.keys(decodeJWT(localStorage.getItem('access_token'))).length
  }
  render() {
    return (
      <header id="dashHeader">
        <div className="">
          <div className="third ilLogo">
            <div>
              <Grid>
                <Grid.Column textAlign='center' only='mobile' mobile={16}>
                  <Menu compact inverted pointing secondary floated='right'>
                    {!this.checkStatus() &&
                      <Menu.Item id="shareHeaderIcon" onClick={() => this.goToHomePage()}>
                        <Icon name='home' className="sideBarIcon" />
                      </Menu.Item>
                    }
                    <Menu.Item id="shareHeaderIcon" onClick={!this.checkStatus() ? this.logout : this.login}>
                      <Icon name={!this.checkStatus() ? 'power off' : 'unlock'}  className="sideBarIcon"></Icon>
                    </Menu.Item>
                  </Menu>
                </Grid.Column>
                <Grid.Column tablet={16} largeScreen={16} computer={16} textAlign='right' only='computer'>
                  <Menu inverted pointing secondary size='large' floated='right'>
                    {!this.checkStatus() && <Menu.Item id="sharedHeaderMenu" onClick={() => this.goToHomePage()}>
                      <Button className='systemButton logoutDesktop'>Home</Button>
                    </Menu.Item>}
                    <Menu.Item id="sharedHeaderMenu" >
                      <Popup content={!this.checkStatus() ? 'Logout' : 'Login'} size='tiny' trigger={<Button className='systemButton logoutDesktop' onClick={() => { !this.checkStatus() ? this.logout() : this.login() }}>{!this.checkStatus() ? 'Logout' : 'Login'}</Button>} />
                    </Menu.Item>
                  </Menu>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default SharedHeader
