import React from 'react'
import ilLogoWhite from '../images/cannid-white-icon.png'
import constants from '../lib/constants'

function Footer() {
	return (
		<footer id='main'>
			<img id='footerLogo' src={ilLogoWhite} alt='ion labs' />
			<span>
				Powered by CannID v
				{process.env.REACT_APP_VERSION}
				{' '}
				|
				<a className='footerLink' target='_blank' rel='noopener noreferrer' href={constants.FOOTER.IONIZATION_LABS_LINK}>Ionization Labs</a>
				{' '}
				| Copyright
				{' '}
				{new Date().getFullYear()}
			</span>
		</footer>
	)
}

export default Footer
