import axios from 'axios'

const client = axios.create({
  baseURL: process.env.REACT_APP_DRIVER_HTTP_ROOT + '/',
  mode: 'no-cors',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
  }
})

export default client
