import React, { Component } from 'react'
import { Breadcrumb, Table, Tab, Popup, Button, Icon, Message, Grid, Segment } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import iconv from 'iconv-lite'
import axios from 'axios'
import * as csv from 'csvtojson'

import AppHeader from './AppHeader'
import ErrorMessages from './Errors/ErrorMessages'
import cannidAPI from '../cannidAPI/client'
import { showChromes } from '../lib/pathEncoderS3'
import standardizeParsedCsv from '../lib/standardizeParsedCsv'
import ParsedCsvDisplay from './Import/ParsedCsvDisplay'
import PreparedSamplesInput from './PreparedSamplesInput'
import AnalyticsChromeComponent from './Charts/AnalyticsChromeComponent'
import constants from '../lib/constants'
import isQC from '../lib/isQC'

class RawImport extends Component {
	constructor(props) {
		super(props)
		this.state = {
		sample: undefined,
		result: {},
		preparedSample: undefined,
		index: (parseInt(this.props.match.params.index) - 1),
		copied: '',
		parsedCsv: undefined,
		importedTemplate: undefined,
		apiErrors: {}
		}
		this.mounted = false
	}

	componentDidMount () {
		this.mounted = true
		cannidAPI.get(`/samples/${this.props.match.params.id}`).then((response) => {
			if (this.mounted) {
				const vialResult = response.data.results[this.state.index]
				const acquiredPreps = response.data.prepared_samples.filter((ps) => ps.acquire)
				this.setState({sample: response.data, result: vialResult, preparedSample: acquiredPreps[this.state.index]})

				// if there is an importedSource metadata, let's grab the original for display
				if (vialResult.importedSource) {
					fetch(vialResult.importedSource)
					.then(res => {
						if (res.status >= 200 && res.status <= 299) {
							return res
						} else {
							throw Error(res.statusText)
						}
					})
					.then(res => res.arrayBuffer())
					.then(arrayBuffer => {
						const buff = Buffer.from(arrayBuffer)
						// test if newlines exist to determine encoding utf8 or utf16
						const utf16 = iconv.decode(buff, 'ucs2').toString()
						if (utf16.split('\r\n').length === 1) {
							const utf8 = iconv.decode(buff, 'utf8').toString()
							return utf8
						} else {
							return utf16
						}
					})
					.then(text => {
						csv({ noheader: true }).fromString(text).then((row) => {
							const standardized = standardizeParsedCsv(row)
							this.setState({ parsedCsv: standardized })
						})

						if (vialResult.importedTemplate) { this.getImportedTemplate(vialResult.importedTemplate) }
					})
					.catch(err => {
						const message = `${err}. Failed to imported source for sample.`
						this.setState({ apiErrors: message })
					})
				}
			}
		})
		.catch((err) => {
			console.log('error!', err)
			const message = `${err}. Failed to retrieve sample.`
			if (this.mounted) {
				this.setState({apiErrors: message})
			}
		})
	}

	componentWillUnmount () {
		this.mounted = false
	}

	getImportedTemplate = (template) => {
		cannidAPI.get(`/import_templates/${template.id}`).then((response) => {
			this.setState({ importedTemplate: response.data })
		})
		.catch((err) => {
			console.log('error!', err)
			const message = `${err}. Failed to retrieve imported template. ${JSON.stringify(template)}`
			this.setState({apiErrors: message})
		})
	}

	metadataValueType = (value) => {
		switch (typeof value) {
			case 'string':
				return value
			case 'object':
				return JSON.stringify(value)
			case 'undefined':
				return 'N/A'
			default:
				return value.toString()
		}
	}

	metadataItem = (point, value, index) => {
		return (
			<Table.Row key={`${index}${point}`}>
				<Table.Cell>{point}</Table.Cell>
				<Table.Cell style={{wordBreak: 'break-all'}}>{this.metadataValueType(value)}</Table.Cell>
				<Table.Cell>
				<Popup content={this.state.copied === point ? `${point} Copied!` : `Copy ${point}`} size='tiny'
					position='top right'
					trigger={
					<Button icon circular color={this.state.copied === point ? 'grey' : 'blue'} style={{margin: '0'}}
						onClick={() => {
						navigator.clipboard.writeText(this.metadataValueType(value))
						this.setState({copied: point}, () => {setTimeout(() => this.setState({copied: ''}), 5000)})
						}}>
						<Icon name='copy' size='small' style={{width: '1.25em'}}/>
					</Button>} />
				</Table.Cell>
			</Table.Row>
		)
	}

	buildBreadcrumb = () => {
		let listLinkRoute = constants.APPLICATION_ROUTE.SAMPLE.LIST.ROUTE
		let listLinkName = constants.APPLICATION_ROUTE.SAMPLE.LIST.NAME

		if (this.state.sample && isQC.sample(this.state.sample)) {
			listLinkRoute = constants.APPLICATION_ROUTE.QC.LIST.ROUTE
			listLinkName = constants.APPLICATION_ROUTE.QC.LIST.NAME
		}

		return (
			<div>
				<Popup content='Back' size='tiny' trigger={
				<Button icon circular color='blue' style={{marginRight: '8px', marginBottom: '8px'}}
					onClick={() => this.props.history.push(`/sample/${this.props.match.params.id}/results`)}>
					<Icon name='arrow alternate circle left outline' size='large'/>
				</Button>} />
				<Breadcrumb>
					<Link to="/"><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
					<Breadcrumb.Divider icon="right angle" />
					<Link to={listLinkRoute}><Breadcrumb.Section>{listLinkName}</Breadcrumb.Section></Link>
					<Breadcrumb.Divider icon="right angle" />
					<Link to={`/sample/${this.props.match.params.id}/results`}><Breadcrumb.Section>{this.state.sample ? this.state.sample.intake_form.strain_name : 'Results'}</Breadcrumb.Section></Link>
					<Breadcrumb.Divider icon="right angle" />
					<Breadcrumb.Section>Raw Data: Vial {this.props.match.params.index}</Breadcrumb.Section> 
				</Breadcrumb>
			</div>
		)
	}

	render() {
		
		let summary = ''
		let vialName = ''
		let tabs = ''

		if (this.state.sample && this.state.result) {
			let metaCount = 0
			const metadata = (
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Metadata Point</Table.HeaderCell>
							<Table.HeaderCell>Value</Table.HeaderCell>
							<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{Object.keys(this.state.result).map((point, index) => {
							if (!['results', 'invalid', 'metadata', 'attachments'].includes(point)) {
								metaCount += 1
								return this.metadataItem(point, this.state.result[point], index)
							}
							else if (point === 'metadata') {
								return Object.keys(this.state.result.metadata).map((nestedPoint, nestedIndex) => {
									metaCount += 1
									return this.metadataItem(nestedPoint, this.state.result.metadata[nestedPoint], nestedIndex)
								})
							}
						})}
					</Table.Body>
				</Table>
			)

			const analytes = (this.state.result.results && this.state.result.results.length > 0) ? (
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Amount (ug/mL)</Table.HeaderCell>
							<Table.HeaderCell>Concentration (%)</Table.HeaderCell>
							<Table.HeaderCell>Peak Area</Table.HeaderCell>
							<Table.HeaderCell>Peak Retention Time</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{this.state.result.results.map((analyte, index) => {
							const ugMl = analyte.amount_ug_ml ? analyte.amount_ug_ml : ''
							return (
								<Table.Row key={`${index}${analyte.compound}`}>
									<Table.Cell>{analyte.compound}</Table.Cell>
									<Table.Cell>{this.state.sample.import ? ugMl : analyte.amount}</Table.Cell>
									<Table.Cell>{this.state.sample.import ? analyte.amount : ''}</Table.Cell>
									<Table.Cell>{analyte.peak_area}</Table.Cell>
									<Table.Cell>{analyte.peak_retention_time}</Table.Cell>
								</Table.Row>
							)
						})}
					</Table.Body>
				</Table>
			) : <Message warning>No valid analytes found.</Message>

			const invalid = (
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Amount (ug/mL)</Table.HeaderCell>
							<Table.HeaderCell>Concentration (%)</Table.HeaderCell>
							<Table.HeaderCell>Peak Area</Table.HeaderCell>
							<Table.HeaderCell>Peak Retention Time</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{this.state.result.invalid && this.state.result.invalid.map((invalidAnalyte, index) => {
							const analyteDisplay = invalidAnalyte.compound === 'N/A'
								? <span className='ui label orange'>{invalidAnalyte.compound}</span> : invalidAnalyte.compound
							const amountDisplay = invalidAnalyte.amount === 'N/A' || parseFloat(invalidAnalyte.amount) < 0
								? <span className='ui label orange'>{invalidAnalyte.amount}</span> : invalidAnalyte.amount
							const peakDisplay = invalidAnalyte.peak_area === 'N/A'
								? <span className='ui label orange'>{invalidAnalyte.peak_area}</span> : invalidAnalyte.peak_area
							const ugMl = invalidAnalyte.amount_ug_ml ? invalidAnalyte.amount_ug_ml : ''
							return (
								<Table.Row key={`${index}${invalidAnalyte.compound}`}>
									<Table.Cell>{analyteDisplay}</Table.Cell>
									<Table.Cell>{this.state.sample.import ? ugMl : amountDisplay}</Table.Cell>
									<Table.Cell>{this.state.sample.import ? amountDisplay : ''}</Table.Cell>
									<Table.Cell>{peakDisplay}</Table.Cell>
									<Table.Cell>{invalidAnalyte.peak_retention_time}</Table.Cell>
								</Table.Row>
							)
						})}
					</Table.Body>
				</Table>
			)

			const preppedVial = this.state.sample.prepared_samples.filter((prep) => {return prep.acquire === true})[this.state.index]
			const chromeProp = {sample: {results: [this.state.result], intake_form: this.state.sample.intake_form, prepared_samples: [preppedVial]}}
			const chrome = showChromes(this.state.sample.results) ?
				<AnalyticsChromeComponent openOnLoad {...chromeProp} /> : <Message warning>No Chromatogram uploaded.</Message>

			vialName = `${preppedVial["label"] || _.capitalize(preppedVial["vial_type"].split("_")[0]) + ' Vial'}`

			let resultCount = this.state.result.results.length
			if (this.state.result.invalid) { resultCount += this.state.result.invalid.length }
			summary = (
				<Segment>
					<Grid stackable>
						<Grid.Column width={2} verticalAlign='middle'>
							<strong>
								<Icon name='flask' />
								Vial {this.state.index + 1} of {this.state.sample.results.length}
							</strong>
						</Grid.Column>
						<Grid.Column width={12} verticalAlign='middle'>
							<Grid celled='internally' columns='equal' stackable>
								<Grid.Column>
									<Grid.Row className='summaryTop'>Metadata Points</Grid.Row>
									<Grid.Row className='summaryBottom'>{metaCount}</Grid.Row>
								</Grid.Column>
								<Grid.Column>
									<Grid.Row className='summaryTop'>Valid Analytes</Grid.Row>
									<Grid.Row className='summaryBottom'>
										<span className={this.state.result.results.length === 0 ? 'ui label red' : 'ui label green'}>
											{this.state.result.results.length} of {resultCount}
										</span>
									</Grid.Row>
								</Grid.Column>
								<Grid.Column>
									<Grid.Row className='summaryTop'>Invalid Analytes</Grid.Row>
									<Grid.Row className='summaryBottom'>
										<span className={(this.state.result.invalid && this.state.result.invalid.length) ? 'ui label orange' : ''}>
											{(this.state.result.invalid && this.state.result.invalid.length)
												? this.state.result.invalid.length : 0} of {resultCount}
										</span>
									</Grid.Row>
								</Grid.Column>
								<Grid.Column>
									<Grid.Row className='summaryTop'>Vial Dilution</Grid.Row>
									<Grid.Row className='summaryBottom'>
										{this.state.preparedSample.dilution_factor
											? this.state.preparedSample.dilution_factor
											: <span className='ui label orange'>N/A</span>}
									</Grid.Row>
								</Grid.Column>
								<Grid.Column>
									<Grid.Row className='summaryTop'>Vial Label</Grid.Row>
									<Grid.Row className='summaryBottom'>
										{this.state.preparedSample.label
											? this.state.preparedSample.label
											: <span className='ui label orange'>N/A</span>}
									</Grid.Row>
								</Grid.Column>
								<Grid.Column>
									<Grid.Row className='summaryTop'>Vial Color</Grid.Row>
									<Grid.Row className='summaryBottom'>
										{_.capitalize(preppedVial["vial_type"].split("_")[0])}
									</Grid.Row>
								</Grid.Column>
							</Grid>
						</Grid.Column>
						<Grid.Column width={2}>
							<PreparedSamplesInput
								previousPreppedSamples={[this.state.preparedSample]}
								editable={false}
								disabled
								import
								hideAcquire
							/>
						</Grid.Column>
					</Grid>
				</Segment>
			)

			let attachments
			if (this.state.result.attachments && this.state.result.attachments.length > 0) {
				attachments = (
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>#</Table.HeaderCell>
								<Table.HeaderCell>Attachment Type</Table.HeaderCell>
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell>File Type</Table.HeaderCell>
								<Table.HeaderCell>Size (Bytes)</Table.HeaderCell>
								<Table.HeaderCell textAlign='center'>Download</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{this.state.result.attachments.map((atty, index) => {
								return (
									<Table.Row key={`${index}${atty.name}`}>
										<Table.Cell>{index + 1}</Table.Cell>
										<Table.Cell>{atty.attachmentType}</Table.Cell>
										<Table.Cell>{atty.name}</Table.Cell>
										<Table.Cell>{atty.fileType}</Table.Cell>
										<Table.Cell>{atty.size.toLocaleString('en-US')}</Table.Cell>
										<Table.Cell textAlign='center'>
											<Popup content={`Download #${index + 1}: ${atty.name}`} size='tiny'
												position='top right'
												trigger={
												<Button icon circular color='blue' style={{margin: '0'}}
													onClick={() => {
														const url = atty.url
														const sampleName = (this.state.sample && this.state.sample.intake_form.strain_name)
															? this.state.sample.intake_form.strain_name : ''
														axios({ url, method: 'GET', responseType: 'blob' })
														.then((response) => {
															if (response.status >= 200 && response.status <= 299) {
																const content = window.URL.createObjectURL(new Blob([response.data]))
																const link = document.createElement('a')
																link.href = content
																link.setAttribute('download', `${sampleName}_${index + 1}_${atty.name}`)
																document.body.appendChild(link)
																link.click()
															}
															else {
																this.setState({
																	apiErrors: `Access error downloading attachment. (Reference ${response.status} ${response.statusText})`
																})
															}
														})
														.catch((error) => {
															this.setState({ apiErrors: `Network error downloading attachment. (Reference ${error})` })
														})
													}}>
													<Icon name='download' size='small' style={{width: '1.25em'}}/>
												</Button>} />
										</Table.Cell>
									</Table.Row>
								)
							})}
						</Table.Body>
					</Table>
				)
			}

			const chromeTabContent = showChromes(this.state.sample.results) ? '✅' : '❌'
			const panes = [
				{ menuItem: isMobile ? { icon: 'area chart', key: 'chromatogram', content: chromeTabContent }
					: `Chromatogram ${chromeTabContent}`,
					render: () => <Tab.Pane>{chrome}</Tab.Pane> },
				{ menuItem: isMobile ? { icon: 'list alternate', key: 'metadata' } : 'Metadata',
					render: () => <Tab.Pane>{metadata}</Tab.Pane> },
				{ menuItem: isMobile ? { icon: 'checkmark box', key: 'valid' } : 'Valid Analytes',
					render: () => <Tab.Pane>{analytes}</Tab.Pane> }
			]

			if (this.state.result.invalid && this.state.result.invalid.length > 0) {
				panes.push({
					menuItem: isMobile ? { icon: 'times rectangle', key: 'invalid' } : 'Invalid Analytes',
					render: () => <Tab.Pane>{invalid}</Tab.Pane>
				})
			}

			if (this.state.parsedCsv) {
				panes.push({
					menuItem: isMobile ? { icon: 'file excel', key: 'csv' } : 'CSV',
					render: () => (
						<Tab.Pane>
							{this.state.importedTemplate &&
								<Message info>
									Imported using <span style={{textDecoration: 'underline'}}>
										{this.state.importedTemplate.import_template_type.name}
									</span> mapping template: <strong>{this.state.importedTemplate.name}</strong>
								</Message>
							}
							<ParsedCsvDisplay parsed={this.state.parsedCsv}
								sourceFilename={this.state.result.metadata['Imported Filename']
									? this.state.result.metadata['Imported Filename'] : 'Original Imported File'}
								sourceFile={this.state.parsedCsv ? this.state.result.importedSource : null}
								// mappingTemplate={this.state.importedTemplate} // currently turning off the mapping toggle since it requires handling the metadata bottom offset based on analyte list
							/>
						</Tab.Pane>)
				})
			}

			if (attachments) {
				panes.push({
					menuItem: isMobile ? { icon: 'attach', key: 'attachments' }
						: `Attachments (${this.state.result.attachments.length})`,
					render: () => <Tab.Pane>{attachments}</Tab.Pane>
				})
			}

			tabs = <Tab panes={panes} />
    	}

		return (
			<section id="mainContent" className="app light rawDataPage">
				<AppHeader title={<h1>Raw Data: Vial {this.props.match.params.index}</h1>} breadcrumb={this.buildBreadcrumb()} />
				<section className="app light">
					<h2>{this.state.sample && this.state.sample.intake_form.strain_name}{(this.state.sample && this.state.sample.intake_form.strain_name && vialName !== '') ? ' | ' : ''}{vialName}</h2>
					<ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
				</section>

				<section className="app light tabbedDisplay">
					{summary}
					{tabs}
				</section>
			</section>
		)
  	}
}

export default connect()(withRouter(RawImport))
