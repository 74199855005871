import PropTypes from 'prop-types'

const intakeFormPropType = PropTypes.shape({
	sample_weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	batch_number: PropTypes.string,
	strain_name: PropTypes.string
})

export default PropTypes.shape({
	id: PropTypes.number.isRequired,
	created_at: PropTypes.string.isRequired,
	intake_form: intakeFormPropType
})
