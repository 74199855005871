import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../lib/constants'
import StickyTableComponent from '../StickyTableComponent'

const TABLE_HEADER = {
	[constants.SAMPLE_TYPE.STANDARD]: [
		{ id: 'compound', title: 'Compound' },
		{ id: '10 ug/ml', title: '10 ug/ml' },
	],
	[constants.SAMPLE_TYPE.CALIBRATION]: [
		{ id: 'compound', title: 'Compound' },
		{ id: '1 ug/ml', title: '1 ug/ml' },
		{ id: '5 ug/ml', title: '5 ug/ml' },
		{ id: '10 ug/ml', title: '10 ug/ml' },
		{ id: '25 ug/ml', title: '25 ug/ml' },
		{ id: '50 ug/ml', title: '50 ug/ml' },
	]
}

function QCReportResultsTable({ sample }) {
	const percentRecoveryTableData = []
	const percentErrorTableData = []

	const qcCompounds = sample.qc_compounds
	const compoundKeys = Object.keys(qcCompounds)

	compoundKeys.forEach((compoundKey) => {
		const compoundDataSet = qcCompounds[compoundKey]

		const recoveryRowData = { compound: compoundKey }
		const errorRowData = { compound: compoundKey }

		compoundDataSet.forEach((compoundVialData) => {
			const vialName = compoundVialData.vial_name
			recoveryRowData[vialName] = `${compoundVialData.percent_recovery} %`
			errorRowData[vialName] = `${compoundVialData.percent_error} %`
		})

		percentRecoveryTableData.push(recoveryRowData)
		percentErrorTableData.push(errorRowData)
	})

	const header = TABLE_HEADER[sample.sample_type.slug]

	return (
		<>
			<StickyTableComponent compact tableHeader={header} headerTitle='Percent Recovery'rowData={percentRecoveryTableData} />
			<br />
			<StickyTableComponent compact tableHeader={header} headerTitle='Percent Error' rowData={percentErrorTableData} />
		</>
	)
}

QCReportResultsTable.propTypes = {
	sample: PropTypes.shape({
		qc_compounds: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
			vial_name: PropTypes.string,
			prepared_amount: PropTypes.number,
			mesured_amount: PropTypes.number,
			percent_recovery: PropTypes.number,
			percent_error: PropTypes.number
		})))
	}).isRequired
}

export default QCReportResultsTable
