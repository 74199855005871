import React, { Component } from 'react'
import { Breadcrumb, Form, Button, Popup, Card, Message, Icon, Checkbox, Header, Grid, Input } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { HexColorPicker } from 'react-colorful'
import PropTypes from 'prop-types'
import AppHeader from './AppHeader'
import ErrorMessages from './Errors/ErrorMessages'
import cannidAPI from '../cannidAPI/client'

class EditReportMethod extends Component {
	constructor(props) {
		super(props)
		this.state = {
			default: false,
			method: null,
			method_name: '',
			analyteColors: {},
			derived_calculated: {},
			apiErrors: {},
			errors: {}
		}
		this.mounted = false
	}

	componentDidMount() {
		this.mounted = true
		cannidAPI.get(`/methods/${this.props.match.params.id}`).then((response) => {
			const rebuiltAnalytes = {}
			response.data.analytes.forEach((analyte) => {
				rebuiltAnalytes[analyte.analyte] = `#${analyte.color}`
			})
			const derived_calculated = []
			if (response.data.derived_calculated) {
				response.data.derived_calculated.map((calc) => {
					derived_calculated[calc.name] = { ...calc, ...{ checked: false } }
				})
			}

			if (this.mounted) {
				this.setState({
					default: response.data.default,
					method: response.data,
					method_name: response.data.method_name,
					analyteColors: rebuiltAnalytes,
					derived_calculated,
					lower_bounds: response.data.lower_bounds,
					upper_bounds: response.data.upper_bounds
				})
			}
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. Failed to retrieve report method.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	componentWillUnmount() {
		this.mounted = false
	}

	updateForm = (e) => {
		const newState = {}
		newState[e.target.name] = e.target.value
		this.setState(newState)
	}

	getErrors = () => {
		const errors = {}
		if (!this.state.method_name) {
			errors.method_name = 'Must include Report Method name.'
		}

		if (parseFloat(this.state.lower_bounds) && parseFloat(this.state.upper_bounds)) {

			if (parseFloat(this.state.lower_bounds) > parseFloat(this.state.upper_bounds)) {
				errors.lower_bounds = 'Upper must be greater than lower bounds.'
			}
		}
		else {
			if (parseFloat(this.state.lower_bounds)) {
				errors.lower_bounds = 'A positive number is required.'
			}
			if (parseFloat(this.state.upper_bounds)) {
				errors.upper_bounds = 'A positive number is required.'
			}
		}

		if ((parseFloat(this.state.lower_bounds) > 999999999) || (parseFloat(this.state.lower_bounds) < 0)) {
			errors.lower_bounds = 'Lower bounds must be between 0 and 999,999,999.'
		}

		if ((parseFloat(this.state.upper_bounds) > 999999999) || (parseFloat(this.state.upper_bounds) < 0)) {
			errors.upper_bounds = 'Upper bounds must be 0 to 999,999,999.'
		}

		if (parseFloat(this.state.upper_bounds) === parseFloat(this.state.lower_bounds)) {
			errors.lower_bounds = 'Upper bounds and lower bounds must be different.'
		}

		return errors
	}

	submitEdits = () => {
		if (!Object.keys(this.getErrors()).length) {
			this.setState({ errors: {}, apiErrors: {} })

			// find only the updated colors for sending to api
			const updatedColors = {}
			this.state.method.analytes.forEach((analyte) => {
				const newColor = this.state.analyteColors[analyte.analyte].replace('#', '').toUpperCase()
				if (analyte.color !== newColor) updatedColors[analyte.analyte] = newColor
			})

			const updateParams = {
				id: this.state.method.id,
				global: this.state.method.global,
				analyteColors: updatedColors,
				default: this.state.default,
				name: this.state.method_name,
				lower_bounds: this.state.lower_bounds,
				upper_bounds: this.state.upper_bounds
			}

			cannidAPI.patch(`/methods/${this.state.method.id}/update`, updateParams).then(() => {
				this.props.history.push('/samples/report-methods')
			}).catch((err) => {
				console.error('error!', err)
				const message = `${err}. Failed to update report method.`
				this.setState({ apiErrors: message })
			})
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	resetDefaultColors = () => {
		if (!Object.keys(this.getErrors()).length) {
			this.setState({ errors: {}, apiErrors: {} })

			cannidAPI.patch(`/methods/${this.state.method.id}/reset-colors`).then(() => {
				this.props.history.go(0)
			}).catch((err) => {
				console.error('error!', err)
				const message = `${err}. Failed to update report method.`
				this.setState({ apiErrors: message })
			})
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	setColor = (analyte, hex) => {
		this.setState((prevState) => {
			const currentColors = prevState.analyteColors
			currentColors[analyte] = hex
			return { analyteColors: currentColors }
		})
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to="/"><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Link to="/test-result/list"><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Link to="/samples/report-methods"><Breadcrumb.Section>Report Methods</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon="right angle" />
				<Breadcrumb.Section>Edit</Breadcrumb.Section>
			</Breadcrumb>
		)

		const FormFields = this.state.method ? (
			<Form>
				<Form.Group className='methodFields'>
					<Form.Input
						label='Report Method Name' value={this.state.method_name}
						name='method_name'
						onChange={this.updateForm}
						type='text' className='required-field'
						placeholder='Report Method Name'
						width={8}
						error={this.state.errors.method_name}
						disabled={(this.state.method.global || this.state.method.override_method_id != null)}
					/>
					{(this.state.method.global || this.state.method.override_method_id != null)
&& (
	<Popup
		content={<Message attached='bottom' warning>Report Method name editing only available for &apos;Custom&apos; methods.</Message>}
		size='tiny'
		trigger={<Icon name='exclamation triangle' size='large' />}
	/>
)}
				</Form.Group>

			</Form>
		) : ''

		const analytesEditor = this.state.method
			? (
				<Card fluid className='analytesEditor'>
					<Card.Content>
						<Card.Meta>Click an analyte to change report color</Card.Meta>
						<Card.Description>
							{this.state.method.analytes.map((analyte, anal_index) => (
								<Popup
									key={anal_index}
									content={<HexColorPicker color={this.state.analyteColors[analyte.analyte]} onChange={(hex) => this.setColor(analyte.analyte, hex)} />}
									on='click'
									pinned
									trigger={(
										<Button
											className='dynamicTableActionButton analytePill'
											title={`Edit color - ${analyte.analyte}`}
											style={{ backgroundColor: this.state.analyteColors[analyte.analyte] }}
										>
											{analyte.analyte}
										</Button>
									)}
								/>
							)) }
						</Card.Description>
					</Card.Content>
					{(this.state.method && !this.state.method.global)
&& (
	<Card.Content extra textAlign='left'>
		<Popup
			content='Reset Default Colors'
			trigger={<a onClick={this.resetDefaultColors}><Icon name='undo' />Reset Default Colors</a>}
		/>
	</Card.Content>
)}
				</Card>
			)
			: ''

		const attachCalculated = (
			<Grid>
				{Object.keys(this.state.derived_calculated).map((compound, index) => {
					const calc = { ...this.state.derived_calculated[compound] }
					return (
						<Grid.Row key={`${compound + index.toString()}`} verticalAlign='bottom' className='attachCalcRow'>
							<Grid.Column width={8}>
								<span><i>{calc.primary_analyte} + ({calc.acid_analyte} * {calc.acid_ratio_conversion_factor})</i></span>
							</Grid.Column>
							<Grid.Column width={4}>
								<strong>{compound}</strong>
							</Grid.Column>
							<Grid.Column width={4}>
								<Checkbox
									toggle
									checked
									disabled
								/>
							</Grid.Column>
						</Grid.Row>
					)
				})}
			</Grid>
		)

		const bounds = this.state.method ? (
			<Form>
				<Form.Group style={{ justifyContent: 'center' }}>
					<Form.Field
						inline
						control={Input}
						label='Upper Bounds'
						value={this.state.upper_bounds}
						placeholder={this.state.upper_bounds}
						name='upper_bounds'
						onChange={this.updateForm}
						type='number'
						error={this.state.errors.upper_bounds}
					/>
				</Form.Group>
				<Form.Group style={{ justifyContent: 'center' }}>
					<Form.Field
						inline
						control={Input}
						label='Lower Bounds'
						value={this.state.lower_bounds}
						placeholder={this.state.lower_bounds}
						name='lower_bounds'
						onChange={this.updateForm}
						type='number'
						error={this.state.errors.lower_bounds}
					/>
				</Form.Group>
			</Form>
		) : ''

		return (
			<section id='mainContent' className='app light editMethodPage'>
				<AppHeader title={<h1>Edit Report Method</h1>} breadcrumb={breadcrumb} />
				<section className='app light'>
					<ErrorMessages errors={this.state.apiErrors} />
				</section>

				<section className='app light'>
					{FormFields}
					{analytesEditor}
					<Grid columns='equal' verticalAlign='middle' stackable>
						{Object.keys(this.state.derived_calculated).length > 0
&& (
	<Grid.Column>
		<Card fluid className='analytesEditor'>
			<Card.Content>
				<Card.Meta>
					Calculated Total Potential
					<Popup
						content={<Message attached='bottom' warning>Editing the Calculated Total Potential of analytes is not available.</Message>}
						size='tiny'
						trigger={<span style={{ float: 'right', color: 'rgba(0,0,0,0.87)' }}><Icon name='exclamation triangle' size='large' /></span>}
					/>
				</Card.Meta>
				<Card.Description>
					{attachCalculated}
				</Card.Description>
			</Card.Content>
		</Card>
	</Grid.Column>
)}
						<Grid.Column>
							<Card
								fluid
								style={Object.keys(this.state.derived_calculated).length > 0
									? {} : { maxWidth: '50%', margin: '1em auto' }}
							>
								<Card.Content>
									<Card.Meta>
										Set Method Concentration Range (ug/mL)
									</Card.Meta>
									<Card.Description>
										{bounds}
									</Card.Description>
								</Card.Content>
							</Card>
							{(this.state.method && !this.state.method.global && this.state.method.override_method_id === null)
								&& (
									<Checkbox
										toggle
										checked={this.state.default}
										onClick={() => {
											this.setState((prevState) => {
												const currentDefault = prevState.default
												return { default: !currentDefault }
											})
										}}
										label='Set as Default'
									/>
								)
							}
							{this.state.method
								&& <Button className='blue submitMethodButton' type='submit' onClick={this.submitEdits}>Update</Button>
							}
						</Grid.Column>
					</Grid>

					<Header as='h5' textAlign='right'>
						<span style={{ color: '#db2828' }}>*</span> Required
					</Header>
				</section>

			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

EditReportMethod.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,

	}).isRequired,
	match: PropTypes.object.isRequired
}

export default connect(
	mapStateToProps
)(withRouter(EditReportMethod))
