import React, { Component } from 'react'
import { Breadcrumb, Form, Button, Grid, Icon, Message } from 'semantic-ui-react'
import constants from '../lib/constants'
import {get} from 'lodash'
import AppHeader from './AppHeader'
import { Link } from 'react-router-dom'
import trimWhiteSpace from '../lib/trim'
import ToastMessage from '../components/Message'
import ImageZoom from 'react-medium-image-zoom'
import ErrorMessages from '../components/Errors/ErrorMessages'
import { LoginServices } from '../services/Login'
import { connect } from 'react-redux'
const mapStateToProps = (state) => {
  return {
    current_user: state.current_user,
    machineState: state.machineState
  }
}
const mapDispatchToProps = (dispatch) => ({
})
class Account extends Component {
  constructor(props) {
    super(props)
    this.upload = React.createRef()
    this.state = {
      organisation_name: '',
      image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
      uploadFlag: false,
      email: '',
      address: '',
      emailError: false,
      phone: '',
      phoneError: false,
      apiErrors: {},
      imageError: false,
      preFilledLogo: false,
      disabled: true,
      id: undefined
    }
  }
  componentDidMount() {
    LoginServices.getAccountInfo().then((res) => {
        let org = res.data.organization
        this.setState({
          organisation_name: org.name,
          email: org.email,
          phone: org.contact_no,
          address: org.address,
          image: org.logo ? org.logo : constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
          uploadFlag: org.logo ? true : false,
          preFilledLogo: org.logo ? true : false,
          id: org.id
        })
      }).catch((e) => {
        console.log(e)
        this.setState({ errorMesage: constants.ERROR.SOMETHING_WENT_WRONG,
         apiErrors: (e.data) ? e.data : {} })
      })
  }

  handleFileChange = () =>{
    this.upload.current.files
  }
  handleBtnClick =(e)=>{
      this.upload.current.click()
  }
  uploadImage = (image) => {
    try {
      let reader = new FileReader()
      if (image[0].size < constants.ACCOUNT.MAX_FILE_UPLOAD_SIZE) {
        reader.onload = (e) => {
          this.setState({
            image: [e.target.result],
            uploadFlag: true,
            imageError: false
          })
        }
        reader.readAsDataURL(image[0])
      } else {
        this.setState({ imageError: true })
      }
    }
    catch (e) {
      console.log('error in uploaing image:', e)
    }
  }
  checkEmail = (email) => {
    var re = constants.REG_EX.EMAIL_REG_EX
    return !re.test(String(email).toLowerCase())
  }
  validateForm = () => {
    let error = false
    if (this.checkEmail(this.state.email) && this.state.email !== '' && this.state.email !== null) {
      this.setState({ emailError: true })
      error = true
    }
    if (this.checkPhone(this.state.phone)) {
      this.setState({ phoneError: true })
      error = true
    }
    if (this.state.imageError) {
      error = true
    }
    return error
  }
  removeImage = () => {
    if (this.state.preFilledLogo) {
      this.setState({ preFilledLogo: false })
    }
    this.setState({ image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND, uploadFlag: false })
    this.upload.current.value = "" //Wild West way to clear the dom of the image
  }
  checkPhone = (phone) => {
    if (phone === null || phone.length === 0) {
      return false
    }
    return !phone.match(constants.REG_EX.PHONE_REG_EX)
  }
  update = (e) => {
    let target = e.target
    this.setState({ [target.name]: target.value }, () => { this.fieldValid(target.name) })
  }
  fieldValid = (field) => {
    if (this.state[field] !== '') {
      this.setState({
        [`${field}Error`]: false
      })
    }
  }
  handleSubmit = () => {
    if (!this.validateForm()) {
      let requestJson = {}
      requestJson = {
        organization: {
          logo: this.state.uploadFlag ? this.state.image[0] : null
        }
      }
      if (this.state.preFilledLogo) {
        requestJson = {
          organization: {
            address: this.state.address,
          }
        }
      }
      if (this.orgId) {
        LoginServices.updateOrganisation(this.orgId, requestJson).then((res) => {
          this.setState({ organisationUpdated: true, errorMesage: constants.USER.EDIT.SUCCESS, apiErrors: {} })
        }).catch((e) => {
          this.setState({ organisationUpdated: false, errorMesage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} })
        })
      }
    }
  }
  render() {
    const breadcrumb = (
      <Breadcrumb>
        <Link to="/"><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to="/settings"><Breadcrumb.Section>Settings</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>Account</Breadcrumb.Section>
      </Breadcrumb>
    )
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{constants.ACCOUNT.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <div className="form screen">
              {this.state.organisationUpdated &&
                <ToastMessage title='Success' description={constants.ACCOUNT.SUCCESS} />}
              <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
              <Form>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12}>
                    <Grid>
                      <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                        <Grid>
                          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                            <Form.Field>
                              <label>Company Name: {this.state.organisation_name}</label>
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                            <Form.TextArea disabled={this.state.disabled} label="Address" value={this.state.address} name="address" onChange={this.update} type="text" placeholder="Address" />
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                            <Form.Field>
                              <Form.Input disabled={this.state.disabled} maxLength={constants.FIELD_LENGTH.MAX_64} label="Email" value={this.state.email} name="email" onChange={this.update} type="text" placeholder="Email" />
                            </Form.Field>
                            {this.state.emailError && <p className="error">please provide the valid email Id </p>}
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                            <Form.Field>
                              <Form.Input disabled={this.state.disabled} maxLength={constants.FIELD_LENGTH.MAX_64} label="Contact" value={this.state.phone} name="phone" onChange={this.update} type="text" placeholder="Phone" />
                            </Form.Field>
                            {this.state.phoneError && <p className="error">please provide the valid phone number</p>}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8} textAlign='center'>
                        <Form.Field>
                          <label>Logo</label>
                        </Form.Field>
                        <Grid centered id='myAccountLogo'>
                         <ImageZoom
                            image={
                              {
                                src: this.state.image,
                                className: 'img',
                              }
                            }
                            zoomImage={
                              {
                                src: this.state.image,
                              }
                            }
                          />
                        </Grid>
                        <Grid centered>
                          <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
                            <input
                              type="file"
                              accept="image/*"
                              ref={this.upload}
                              id="myAccountLogoForm"
                              onChange={() => this.uploadImage(this.upload.current.files)}
                              disabled={this.state.disabled}
                            />
                            {this.state.imageError && <p className="error">{constants.ACCOUNT.IMAGE_LIMIT}</p>}
                            {/*<Button icon labelPosition='left' primary={true} negative={false} size='small' disabled={this.state.disabled}
                              onClick={() => this.upload.current.click()}>
                              <Icon name={'arrow alternate circle up'} />{'Upload'}
                            </Button>
                            <Button icon labelPosition='left' primary={false} negative={true} size='small' disabled={this.state.disabled}
                              onClick={() => this.removeImage()}>
                              <Icon name={'trash'} />{'Remove'}
                            </Button>*/}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
                <Grid centered>
                  <Grid.Row columns={2}>
                  {/* <Grid.Column width={2}>
                    <Button icon labelPosition='left' primary size='small'
                    disabled={this.state.disabled}
                      onClick={() => {
                        this.handleSubmit()
                      }}>
                      <Icon name='check' />Save
                    </Button>
                  </Grid.Column> */}

                  <Grid.Column width={16} textAlign="center">
                    <Message info compact>
                      <p>For security these settings are locked</p>
                      <p>To make changes please submit a <a href={process.env.REACT_APP_SELF_REFERENCE_ROOT + "/support"}>support ticket.</a></p>
                    </Message>
                  </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </div>
          </section>
        </section>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
