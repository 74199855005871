import { Observable } from 'rxjs'
import csdriverAPI from '../csdriverAPI/client'
import { delay, tap, mergeMap, repeat } from 'rxjs/operators'

// example:
// const sampleAndVial = {
//   location: 2,
//   name: "dave",
//   sample_id: 34
// }

const submitSoloSequence = (sampleAndVial) => {
  return new Observable(function subscribe(subscriber) {
    csdriverAPI.post('/', sampleAndVial).then((res) => {
      subscriber.next({message: "Sample submitted."})
    }).catch((err) => {
      subscriber.error(err)
    })
  })
}

export default submitSoloSequence
