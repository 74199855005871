import React from 'react'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'
import cannidAPI from '../../cannidAPI/client'

class Changelog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			releases: []
		}
		this.mounted = false
	}

	componentDidMount() {
		this.mounted = true
		cannidAPI.get('/releases').then((res) => {
			if (this.mounted) {
				this.setState({ releases: res.data })
			}
		}).catch((err) => {
			console.error('Unable to obtain change log releases due to error\n', err)
		})
	}

	componentWillUnmount() {
		this.mounted = false
	}

	buildReleasesList() {
		const { releases } = this.state
		return releases.map((release, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<figure key={`v${release.version}_${index}`}>
				<header>
					<h2>{`v${release.version}`}</h2>
					{'    '}
					<span>{`Released ${moment(release.release_date).fromNow()}`}</span>
				</header>
				<article className='markdown'>
					<ReactMarkdown>{release.notes}</ReactMarkdown>
				</article>
			</figure>
		))
	}

	render() {
		const { releases } = this.state
		let pageContent

		if (releases.length === 0) {
			pageContent = <h1 className='base-case'>No releases in database.</h1>
		}
		else {
			pageContent = this.buildReleasesList()
		}

		return (
			<div id='changelog-page'>
				<section id='releases'>
					{pageContent}
				</section>
			</div>
		)
	}
}

export default Changelog
