import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Breadcrumb, Grid, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppHeader from '../AppHeader'
import constants from '../../lib/constants'
import SubmitTicket from './SubmitTicket'
import Ordering from './Ordering'
import Changelog from './Changelog'

const contentConfig = constants.SUPPORT
const routeConfig = constants.APPLICATION_ROUTE.SUPPORT

const buttonMenuConfig = [
	{ ...contentConfig.TICKET_BUTTON, link: routeConfig.TICKET.ROUTE },
	{ ...contentConfig.ORDER_BUTTON, link: routeConfig.ORDERING.ROUTE },
	{ ...contentConfig.CHANGE_LOG_BUTTON, link: routeConfig.CHANGE_LOG.ROUTE },
	{ ...contentConfig.KNOWLEDGE_BASE_BUTTON, link: constants.KNOWLEDGE_BASE_LINK, externalLink: true }
]

function getActiveComponent(pathname, user = { organization: {} }) {
	const formProps = { fullName: user.name, email: user.email, organizationName: user.organization.name }
	if (pathname === routeConfig.TICKET.ROUTE) {
		return <SubmitTicket {...formProps} />
	}
	if (pathname === routeConfig.ORDERING.ROUTE) {
		return <Ordering {...formProps} />
	}
	if (pathname === routeConfig.CHANGE_LOG.ROUTE) {
		return <Changelog />
	}

	return null
}

function SupportBreadcrumb() {
	return (
		<Breadcrumb>
			<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
			<Breadcrumb.Divider icon='right angle' />
			<Breadcrumb.Section>Support</Breadcrumb.Section>
		</Breadcrumb>
	)
}

function SupportPage({ history, current_user }) {	// eslint-disable-line camelcase
	const { pathname } = history.location
	const titleComponent = <h1>{contentConfig.TITLE}</h1>
	const btnStyle = { fontWeight: 'bold', borderRadius: '.28571429rem' } // to account for CSS conflict issues

	const buttons = buttonMenuConfig.map((btnConfig) => {
		const isActive = pathname === btnConfig.link
		const onBtnClick = () => {
			if (btnConfig.externalLink === true) {
				window.open(btnConfig.link, '_blank')
			}
			else {
				history.push(btnConfig.link)
			}
		}

		return (
			<Grid.Column key={btnConfig.link} computer={buttonMenuConfig.length} mobile={16}>
				<Button content={btnConfig.CONTENT} icon={btnConfig.ICON} fluid primary basic={!isActive} size='large' onClick={onBtnClick} style={btnStyle} />
			</Grid.Column>
		)
	})

	return (
		<section id='mainContent' className='app light'>
			<AppHeader title={titleComponent} breadcrumb={SupportBreadcrumb()} />
			<br />
			<Grid centered padded>
				{buttons}
			</Grid>
			<br />
			{getActiveComponent(pathname, current_user)}
		</section>
	)
}

SupportPage.propTypes = {
	current_user: PropTypes.shape({
		name: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		organization: PropTypes.shape({
			name: PropTypes.string.isRequired
		})
	}),
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}

SupportPage.defaultProps = {
	current_user: undefined
}

function mapStateToProps(state) {
	return { current_user: state.current_user }
}

export default withRouter(connect(mapStateToProps)(SupportPage))
