import React, { Component } from 'react'
import { Breadcrumb, Button, Table, Icon, Grid, Form, Label, Modal, Loader, Dimmer } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import {get} from 'lodash'
import AppHeader from '../AppHeader'
import { userServices } from '../../services/User'
import DynamicTableComponent from '../DynamicTableComponent'
import DownloadExcel from '../Download/DownloadExcel'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import ModalComponent from '../ModalComponent'
import constants from '../../lib/constants'
import ErrorMessages from '../Errors/ErrorMessages'
import { connect } from 'react-redux'
import Acl from '../ACL/Acl'
import { formatRoute } from 'react-router-named-routes'
import checkDynamicTableActions from '../../lib/checkDynamicTableActions'
import permissionCheck from '../../lib/permissionCheck'
const tableHeader = [
  { id: 'name', title: 'Name', sortable: true },
  { id: 'email', title: 'Email', sortable: true },
  { id: 'role', title: 'Role', sortable: false },
  { id: 'created_at', title: 'Date Created', sortable: true },
  { id: 'updated_at', title: 'Date Updated', sortable: true },
  {
    id: 'action_item', title: 'Actions', sortable: false, actionItems: [
      { title: constants.USER.LIST.EDIT_USER, color: 'orange', style: { marginRight: '10px' }, icon: 'edit', action: constants.ACL.USER_RESOURCE.ACTIONS.EDIT, disableClass: 'isCurrentUser' },
      { title: constants.USER.LIST.REMOVE_USER, style: { marginRight: '10px' }, color: 'red', icon: 'remove', action: constants.ACL.USER_RESOURCE.ACTIONS.DELETE, disableClass: 'isCurrentUser' },
      { title: constants.USER.LIST.STATUS, style: { marginRight: '10px' }, icon: 'power off', class: 'userActiveClass', action: constants.ACL.USER_RESOURCE.ACTIONS.STATUS, disableClass: 'isCurrentUser' }
    ]
  }
]
const options = [
  { key: 'name', text: 'Name', value: 'name' },
  { key: 'email', text: 'Email', value: 'email' },
  { key: 'role', text: 'Role', value: 'role' }
]
const mapStateToProps = (state) => {
  return {
    current_user: state.current_user,
    machineState: state.machineState
  }
}
const mapDispatchToProps = (dispatch) => ({
})
const entireRowAction = { title: constants.USER.LIST.VIEW_USER, action: constants.ACL.USER_RESOURCE.ACTIONS.VIEW_USER }
class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      count: 0,
      selectedSearchDropdown: 'name',
      userFlag: false,
      userId: '',
      userActivityFlag: false,
      isUserFlag: false,
      isUserRemove: false,
      isUserActive: false,
      isUserDeletable: true,
      isUserDeletableFlag: false,
      usersList: [],
      userSelected: '',
      userSelectedFlag: false,
      isMigrate: false,
      migrateLoading: false,
      apiErrors: {}
    }
  }
  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload)
  }

  beforeunload = (e) => {
    if (this.state.migrateLoading) {
      e.preventDefault()
      e.returnValue = true
    }
  }
  getTableData = (res) => {
    let response = get(res, 'data.data', false) || []
    let count = get(res, 'data.meta.total_count', false) || 0
    const tableData = response.map((data) => {
      return {
        id: data.id,
        name: data.name,
        email: data.email,
        role: data.role.name,
        role_slug: data.role.slug,
        created_at: dateTimeFormatter(data.created_at),
        updated_at: dateTimeFormatter(data.updated_at),
        is_active: data.is_active,
        userActiveClass: (data.is_active) ? 'green' : 'red',
        isCurrentUser: (this.props.current_user.id === data.id) ? true : false
      }
    })
    this.setState({
      tableData: tableData,
      count: count,
      initialCount: count,
      initialData: tableData,
    })
  }
  getFilteredData = (data) => {
    let arrayList = get(data, 'data.data', false ) || []
    let count = get(data, 'data.meta.total_count', false ) || 0
    const tableData = arrayList.map((data) => {
      return {
        id: data.id,
        name: data.name,
        email: data.email,
        role: data.role.name,
        created_at: dateTimeFormatter(data.created_at),
        updated_at: dateTimeFormatter(data.updated_at),
        is_active: data.is_active,
        userActiveClass: (data.is_active) ? 'green' : 'red',
        isCurrentUser: (this.props.current_user.id === data.id) ? true : false
      }
    })
    this.setState({ tableData: tableData, count: count })
  }
  sortByColumn = (tableData) => {
    this.setState({ tableData: tableData })
  }
  rowAction = (action, data, e) => {
    e.stopPropagation()
    switch (action) {
      case constants.USER.LIST.EDIT_USER:
        this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.USER.EDIT.ROUTE, { id: data.id }))
        break
      case constants.USER.LIST.REMOVE_USER:
        this.setState({ userFlag: true, userId: data.id })
        this.isDeleteable(data)
        break
      case constants.USER.LIST.STATUS:
        this.setState({ userActivityFlag: true, userId: data.id, activity: data.is_active })
        break
      case constants.USER.LIST.VIEW_USER:
        if (permissionCheck(constants.ACL.USER_RESOURCE.TITLE, constants.ACL.USER_RESOURCE.ACTIONS.VIEW_USER)) {
          this.setState({ isUserDetails: true })
          this.viewUserDetails(data.id)
        }
        break
      default:
    }
  }
  removeUser = (id, callBack) => {
    userServices.deleteUser(id).then(res => {
      let tableData = [...this.state.tableData].filter((data) => data.id !== id)
      this.setState({ userFlag: false, tableData: tableData, count: this.state.count - 1, apiErrors: {} }, () => window.location.reload())
    }).catch((error) => {
      this.setState({
        userFlag: false, isUserRemove: false, apiErrors: (error.data) ? error.data : {}
      })
      this.setState({ isUserRemove: true })
    })
  }
  handleActivity = (id, flag) => {
    let responseJson = {
      "user": {
        "is_active": flag === true ? flag : !this.state.activity
      }
    }
    userServices.userActivity(id, responseJson).then((res) => {
      let tableData = [...this.state.tableData].map((data) => data.id === id ? { ...data, is_active: !this.state.activity, userActiveClass: (data.is_active === false) ? 'green' : 'red' } : data)
      this.setState({ userActivityFlag: false, tableData: tableData, isUserActive: false, apiErrors: {} })
    }).catch((e) => {
      this.setState({ isUserActive: true, userActivityFlag: false, apiErrors: (e.data) ? e.data : {} })
    })
  }
  handleSelectChange = (e, { name, value }) => {
    this.setState({ userSelected: value, userSelectedFlag: false })
  }
  isDeleteable = (user) => {
    userServices.isUserDeletable(user.id).then((res) => {
      let arrayObject = {}
      arrayObject = { ...res.data.meta }
      this.setState({ isUserDeletableFlag: false, userRelatedData: arrayObject })
      for (let key in arrayObject) {
        if (arrayObject[key] > 0) {
          this.setState({ isUserDeletable: false }, () => {
            userServices.getTotalUsers().then((res) => {
              let arrayList = res.data.data.filter((data) => {
                if (data.id !== user.id) {
                  if ((user.role_slug === data.role.slug) ||
                    ((user.role_slug === constants.USER_ROLE.ORGANIZATION_TESTER_SLUG) && (constants.USER_ROLE.ADMIN_ROLE_SLUGS.includes(data.role.slug))) ||
                    ((user.role_slug === constants.USER_ROLE.ORGANIZATION_VIEWER_SLUG) && (constants.USER_ROLE.ADMIN_ROLE_SLUGS.includes(data.role.slug) || data.role.slug === constants.USER_ROLE.ORGANIZATION_TESTER_SLUG))) {
                    return data
                  }
                }
                return null
              }
              ).map((data) => {
                return (
                  {
                    key: data.id,
                    text: data.name + '(' + data.role.name + ')',
                    value: data.id
                  })
              })
              this.setState({ usersList: arrayList })
            })
          })
          return
        }
      }
      this.setState({ isUserDeletable: true, apiErrors: {} })
    }).catch((e) => {
      this.setState({ isUserDeletableFlag: true, apiErrors: (e.data) ? e.data : {} })
    })
  }
  closeModal = () => {
    this.setState({ userFlag: false, userActivityFlag: false, isUserDetails: false })
  }
  getSelectedDropdown = (e, { value }) => {
    this.setState({ selectedSearchDropdown: value, tableData: this.state.initialData, count: this.state.initialCount })
  }
  viewUserDetails = (id) => {
    this.setState({
      isUserFlag: false,
      userName: '',
      userRole: '',
      userEmail: '',
      userPhone: '',
      userAltEmail: '',
      created_at: '',
      updated_at: '',
      userStatus: '',
      userOrganization: '',
      apiErrors: {}
    })
    userServices.getUser(id).then((res) => {
      let userDetails = get(res, 'data', false ) || {}
      this.setState({
        isUserFlag: false,
        userName: userDetails.name,
        userRole: userDetails.role.name,
        userEmail: userDetails.email,
        userPhone: userDetails.contact_number || 'NA',
        userAltEmail: userDetails.alternate_email_id || 'NA',
        created_at: dateTimeFormatter(userDetails.created_at),
        updated_at: dateTimeFormatter(userDetails.updated_at),
        userStatus: userDetails.is_active ? 'Active' : 'Inactive',
        userOrganization: ( userDetails.organization && userDetails.organization.name )|| 'NA',
        apiErrors: {}
      })
    }).catch((e) => {
      this.setState({ isUserFlag: true, apiErrors: (e.data) ? e.data : {} })
    })
  }
  migrateAndRemoveUser = async (id) => {
    if (this.state.userSelected === '') {
      this.setState({ userSelectedFlag: true })
      return false
    }
    await this.handleActivity(id, true)
    if (!this.state.isUserActive && !this.state.isMigrate)
      await this.handleMigrate(id)
    if (!this.state.isUserActive && !this.state.isMigrate)
      await this.removeUser(id, () => {
        this.setState({ migrateLoading: true })
      })
  }
  handleMigrate = (id) => {
    let responseJson = {
      "target_user": this.state.userSelected
    }
    userServices.userMigrate(id, responseJson).then((res) => {
      this.setState({ isMigrate: false, apiErrors: {} })
    }).catch((e) => {
      this.setState({ isMigrate: true, apiErrors: (e.data) ? e.data : {} })
    })
  }
  userRelatedDetails = () => {
    const userRelatedData = { ...this.state.userRelatedData }
    return (
      <Form>
        <Grid>
          <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
            <Label className='user-Details'>Number of Batches:</Label>{userRelatedData.batch_count}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
            <Label className='user-Details'>Number of Batch-Tests:</Label>{userRelatedData.batch_test_count}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
            <Label className='user-Details'>Number of Samples:</Label>{userRelatedData.sample_count}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8} >
            <Label className='user-Details'>Number of Form Templates:</Label>{userRelatedData.form_template_count}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8} >
            <Label className='user-Details'>Number of Analytical Reports: </Label>{userRelatedData.analytical_report_count ? userRelatedData.analytical_report_count : 0}
          </Grid.Column>
          {!this.state.isUserDeletable && <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
            <Grid>
              <Grid.Column mobile={16} tablet={16} largeScreen={8} computer={8}>
                <Form.Select label='Migarte To ' value={this.state.userSelected}
                  options={this.state.usersList}
                  name="userSelected" onChange={this.handleSelectChange}
                  placeholder="Select" className='required-field' error={''} />
                {this.state.userSelectedFlag && <p className='error'>Please select the user</p>}
              </Grid.Column>
            </Grid>
          </Grid.Column>}
        </Grid>
      </Form>
    )
  }
  render() {
    let breadcrumb
    breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={formatRoute(constants.APPLICATION_ROUTE.USER.LIST.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.USER.LIST.NAME}</Breadcrumb.Section></Link>
      </Breadcrumb>
    )
    let showActions = checkDynamicTableActions(tableHeader, constants.ACL.SAMPLE_RESOURCE.TITLE)
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{constants.USER.LIST.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
            <DynamicTableComponent
              entity={constants.ACL.USER_RESOURCE.TITLE}
              action={constants.ACL.USER_RESOURCE.ACTIONS.LIST}
              tableHeader={tableHeader}
              options={options}
              baseUrl={userServices.getAllUsers()}
              rowData={this.state.tableData}
              initialData={this.state.initialData}
              selectedSearchDropdown={this.state.selectedSearchDropdown}
              getFilteredData={this.getFilteredData}
              getSelectedDropdown={this.getSelectedDropdown}
              colSpan={tableHeader.length - (showActions ? 0 : 1)}
              sortByColumn={this.sortByColumn}
              count={this.state.count}
              getData={this.getTableData}
              actionItem={true}
              countTitle={constants.USER.LIST.TOTAL_TITLE}
              rowAction={this.rowAction}
              entireRowAction={entireRowAction}
              showActions={showActions}
            >
              <Table.Header fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan={tableHeader.length - (showActions ? 0 : 1)}>
                    <Grid centered>
                      <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
                        {constants.USER.LIST.TOTAL_TITLE}: {this.state.count}
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8} className='dataTableHeaderButtons'>
                        <Acl entity={constants.ACL.USER_RESOURCE.TITLE} action={constants.ACL.USER_RESOURCE.ACTIONS.CREATE}>
                          <Button floated='right' icon labelPosition='left' className='confirmButton' size='small' onClick={() => { this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.USER.CREATE.ROUTE, {})) }}>
                            <Icon name='user' />{constants.USER.LIST.ADD_USER_BUTTON}
                          </Button>
                        </Acl>
                      </Grid.Column>
                    </Grid>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </DynamicTableComponent>
          </section>
        </section>
        <Modal size='small' open={this.state.userFlag} onClose={() => { this.closeModal() }} closeIcon>
          <Modal.Header>{this.state.isUserDeletable ? constants.USER.LIST.DELETE_BUTTON_TITLE : constants.USER.LIST.DELETE_AND_MIGRATE_BUTTON_TITLE}</Modal.Header>
          <Modal.Content>
            {this.state.migrateLoading ? <Dimmer active={this.state.migrateLoading} inverted>
              <Loader inverted content='Loading' size='medium'>Please do not close the browser </Loader>
            </Dimmer> : this.userRelatedDetails()}
          </Modal.Content>
          <Modal.Actions>
            <Button className='cancelButton' onClick={() => this.closeModal()}>No</Button>
            <Button className='confirmButton' icon='checkmark' disabled={!this.state.isUserDeletable && this.state.userSelected === ''} labelPosition='right' content={this.state.isUserDeletable ? constants.USER.LIST.DELETE_BUTTON_TITLE : constants.USER.LIST.DELETE_AND_MIGRATE_BUTTON_TITLE}
              onClick={() => {
                this.state.isUserDeletable ? this.removeUser(this.state.userId) : this.setState({ migrateLoading: true }, () => {
                  this.migrateAndRemoveUser(this.state.userId)
                })
              }}></Button>
          </Modal.Actions>
        </Modal>
        <ModalComponent
          size='mini'
          action={true}
          openModal={this.state.userActivityFlag}
          onClose={this.closeModal}
          header={`${this.state.activity ? constants.USER.LIST.DEACTIVATE_CONFIRMATION_HEADER : constants.USER.LIST.ACTIVATE_CONFIRMATION_HEADER}`}
          content={`${this.state.activity ? constants.USER.LIST.DEACTIVATE_CONFIRMATION_CONTENT : constants.USER.LIST.ACTIVATE_CONFIRMATION_CONTENT}`}
          handleAction={this.handleActivity}
          id={this.state.userId}
        />
        <ModalComponent
          size='large'
          action={false}
          openModal={this.state.isUserDetails}
          onClose={this.closeModal}
          header={constants.USER.LIST.VIEW_USER}
          content={
            this.state.isUserFlag ?
              constants.ERROR.SOMETHING_WENT_WRONG :
              <Form>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Label className='user-Details'>Name:</Label>{this.state.userName}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Label className='user-Details'>Role:</Label>{this.state.userRole}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Label className='user-Details'>Email:</Label>{this.state.userEmail}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Label className='user-Details'>Phone:</Label>{this.state.userPhone}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6} >
                    <Label className='user-Details'>AltEmail:</Label>{this.state.userAltEmail}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Label className='user-Details'>Created At:</Label>{this.state.created_at}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Label className='user-Details'>Updated At:</Label>{this.state.updated_at}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} largeScreen={6} computer={6}>
                    <Label className='user-Details'>Status:</Label>{this.state.userStatus}
                  </Grid.Column>
                </Grid>
              </Form>
          }
        />
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersList)
