import React, { Component } from 'react'
import { Grid, Label, Icon, Button, Form } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import dateFormatterUtcString from '../lib/dateFormatterUtcString'
import dateTimeFormatter from '../lib/dateTimeFormatter'
import handleDefaultValue from '../lib/handleDefaultValue'
import trimWhiteSpace from '../lib/trim'
import constants from '../lib/constants'
import ImageUploadAWS from '../lib/ImageUploadAWS'
import ImageDropzone from '../lib/ImageDropzone'
import ImportDisclosureModal from './Import/ImportDisclosureModal'
import PreparedSamplesInput from './PreparedSamplesInput'
import cannidAPI from '../cannidAPI/client'

class SampleInfo extends Component {
	constructor(props) {
		super(props)
		this.upload = React.createRef()
		this.awsConf = {
			bucket: process.env.REACT_APP_COA_IMAGES_BUCKET,
			accessKeyId: process.env.REACT_APP_COA_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_COA_SECRET_ACCESS_KEY
		}
		this.awsLink = `https://${process.env.REACT_APP_COA_IMAGES_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`
		const sampleImagePresent = (this.props.sample && this.props.sample.intake_form && this.props.sample.intake_form.sample_image_url)
			? true : false
		this.state = {
			preEditNotes: (this.props.sample && this.props.sample.intake_form && trimWhiteSpace(this.props.sample.intake_form.post_notes))
				? trimWhiteSpace(this.props.sample.intake_form.post_notes) : '',
			active: false,
			prepMethodName: '',
			sharePage: this.props.match.path.includes('share/report'),
			pressed: false,
			post_notes: (this.props.sample && this.props.sample.intake_form && trimWhiteSpace(this.props.sample.intake_form.post_notes))
				? trimWhiteSpace(this.props.sample.intake_form.post_notes) : '',
			image: sampleImagePresent ? `${this.props.sample.intake_form.sample_image_url}`
				: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND, // preload if already exists
			imageError: undefined,
			uploadFlag: sampleImagePresent ? true : false,
			errors:{}
		}
	}

	componentDidMount() {
		if (this.props.sample.prep_method_id) {
			cannidAPI.get(`/prep_methods/${this.props.sample.prep_method_id}`).then((res) => {
				this.setState({ prepMethodName: res.data.name })
			}).catch((err) => {
				console.error('couldn\'t get prep methods:', err)
			})
		}
		else {
			this.setState({ prepMethodName: 'Custom' })
		}
	}

	linkify = (text) => {
		const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
		const urlized = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`)
		return `<span>${urlized}</span>`
	}

	createPostNote(e) {
		this.setState({ post_notes: e.target.value })
	}

	savePostNote(e) {
		let theNotes = trimWhiteSpace(e)
		if (trimWhiteSpace(e) || (this.state.preEditNotes && !trimWhiteSpace(e))) {
			theNotes = `${e} [${dateFormatterUtcString()}]`
		}

		const payload = {
			...this.props.sample,
			sample_type_id: this.props.sample.sample_type.id,
			update_intake_attribute: {
				key: 'post_notes',
				value: theNotes
			}
		}

		cannidAPI.put(`samples/${this.props.sample.id}`, payload).then((res) => {
			res.status == 200 ? window.location.reload() : console.error('Error occured while updating additional notes')
		}).catch((err) => {
			console.error(err)
		})
	}

	uploadImage = async (image) => {
		try {
			let reader = new FileReader()

			if (image[0].size < constants.ACCOUNT.MAX_FILE_UPLOAD_SIZE) {
				if (image[0].name.match(/.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
					// const filename = image[0].name
					const awsUrl = await ImageUploadAWS(this.awsConf, image[0], this.props.sample.id)
					if (awsUrl) {
						const payload = {
							...this.props.sample,
							sample_type_id: this.props.sample.sample_type.id,
							update_intake_attribute: {
								key: 'sample_image_url',
								value: `${this.awsLink}/${awsUrl}`
							}
						}

						cannidAPI.put(`samples/${this.props.sample.id}`, payload).then((res) => {
							if (res.status >= 200 && res.status <= 299) {
								reader.onload = (e) => {
									this.setState({
										image: `${this.awsLink}/${awsUrl}`,
										uploadFlag: true,
										imageError: undefined
									})
								}
								reader.readAsDataURL(image[0])
							} else {
								throw Error(res.statusText)
							}
						}).catch((err) => {
							console.error('Sample image save fail (catch)', err)
							this.setState({ imageError: 'Sample image save failed. Please try again.', uploadFlag: false })
						})
					}
					else {
						this.setState({ imageError: 'Image upload failed. Please try again.', uploadFlag: false })
					}
				}
				else {
					this.setState({
						imageError: 'Error reading selected file. Acceptable image file types: .jpg|.jpeg|.png',
						uploadFlag: false
					})
				}
			}
			else {
				this.setState({
					imageError: `Image size too large. Maximum size ${constants.ACCOUNT.MAX_FILE_UPLOAD_SIZE / 1000000}MB`,
					uploadFlag: false
				})
			}
		}
		catch (e) {
			console.error('error in uploading image:', e)
			this.setState({ imageError: 'Image upload failed. Please try again.' })
		}
	}

	removeImage = () => {
		const payload = {
			...this.props.sample,
			sample_type_id: this.props.sample.sample_type.id,
			update_intake_attribute: {
				key: 'sample_image_url',
				value: null
			}
		}

		cannidAPI.put(`samples/${this.props.sample.id}`, payload).then((res) => {
			if (res.status >= 200 && res.status <= 299) {
				this.setState({ image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND, uploadFlag: false, imageError: undefined })
				this.upload.current.value = "" //Wild West way to clear the dom of the image
			} else {
				throw Error(res.statusText)
			}
		}).catch((err) => {
			console.error('Sample image save fail (catch)', err)
			this.setState({ imageError: 'Sample image save failed. Please try again.', uploadFlag: true })
		})
	}

	render() {
		const intake_form = this.props.sample.intake_form || {
			strain_name: 'N/A',
			intake_number: 'N/A',
			batch_number: 'N/A',
			sample_weight: 0,
			notes: 'N/A',
			post_notes: 'N/A'
		}

		const sample = this.props.sample
		const isNotes = intake_form.notes
		const isCertus = sample.certus.sample_certus
		const isResults = sample.results && sample.results.length > 0
		const isPostNotes = sample.intake_form.post_notes

		const singleColumn = (sample.prepared_samples && !this.state.sharePage)
		const singleColumnStyle = { margin: '0 auto', width: '100%' }

		return (
			<div className='dataTable sampleInfo'>
				<Grid stackable>
					<Grid.Column width={8}>
						<Grid columns={2} stackable>
							{
								sample.import
								&& (
									<Grid.Row centered textAlign='center'>
										<Label
											style={{ cursor: 'pointer' }}
											onClick={() => this.setState({ active: !this.state.active })}
											onMouseOver={(e) => { document.getElementById('importedSampleFlag').classList.add('grey') }}
											onMouseOut={(e) => { document.getElementById('importedSampleFlag').classList.remove('grey') }}>
											<Icon id='importedSampleFlag' name='info circle' color='green' />Imported Sample
										</Label>
									</Grid.Row>
								)
							}
							<Grid.Column>
								<label>Strain/Sample Name</label>
								<span>{intake_form.strain_name}</span>
							</Grid.Column>
							<Grid.Column>
								<label>Batch Number</label>
								<span>{handleDefaultValue(intake_form.batch_number)}</span>
							</Grid.Column>
							<Grid.Column>
								<label>Test {sample.import ? 'Imported' : 'Taken'}</label>
								<span>{sample.tested_at ? dateTimeFormatter(sample.tested_at) ? dateTimeFormatter(sample.tested_at) : handleDefaultValue(null) : handleDefaultValue(null)}</span>
							</Grid.Column>
							<Grid.Column>
								<label>Sample Weight</label>
								<span>{intake_form.sample_weight !== 'NaN' ? `${intake_form.sample_weight} mg` : '---'}</span>
							</Grid.Column>
							<Grid.Column>
								<label>Organization - Tester</label>
								<span>{(sample.organization && sample.organization.name) || ''} - {(sample.tested_by && sample.tested_by.name) || ''}</span>
							</Grid.Column>
							<Grid.Column>
								<label>Material Type</label>
								<span>{sample.sample_type && sample.sample_type.name}</span>
							</Grid.Column>
							{
								singleColumn
								&& (
									<Grid.Column>
										<label>Preparation Method</label>
										{this.state.prepMethodName && <span>{this.state.prepMethodName}</span>}
										<div style={{ marginTop: '.5rem' }}>
											<PreparedSamplesInput previousPreppedSamples={sample.prepared_samples}
												addPreppedSample={() => { console.log('dud function. no editing in SampleInfo') }}
												editable={false} disabled results={sample.results} sampleId={sample.id} import={sample.import} />
										</div>
									</Grid.Column>
								)
							}
							{
								!this.state.sharePage
								&& (
									<Grid.Column>
										<div>
											<label>Report Method</label>
											<span>{(sample.test_method) && sample.test_method.name}</span>
										</div>
										{(intake_form && intake_form.submission_identification) &&
											<div style={{paddingTop: '2rem'}}>
												<label>Submission Identification &#x23;</label>
												<span>{intake_form.submission_identification}</span>
											</div>
										}
										{(intake_form && intake_form.prepared_for) &&
											<div style={{paddingTop: '2rem'}}>
												<label>Prepared For</label>
												<span style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}>
													{intake_form.prepared_for}
												</span>
											</div>
										}
									</Grid.Column>
								)
							}
							{
								(isNotes || (sample.intake_form && sample.intake_form.import_notes))
								&& (
									<Grid.Column style={(singleColumn && (!isResults || (isResults && !isPostNotes && isCertus)))
										? singleColumnStyle : {}}>
										<label>Sample Notes</label>
										{
											isNotes
											&& (
												<div>
													<span style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
														dangerouslySetInnerHTML={{ __html: this.linkify(intake_form.notes) }} />
												</div>
											)
										}
										{
											sample.intake_form && sample.intake_form.import_notes
											&& (
												<div>
													<span style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
														dangerouslySetInnerHTML={{ __html: this.linkify(sample.intake_form.import_notes) }} />
												</div>
											)
										}
									</Grid.Column>
								)
							}

							{
								((isResults && !isCertus) || (isResults && isPostNotes && isCertus))
								&& (
									<Grid.Column style={(singleColumn && !isNotes && !(sample.intake_form && sample.intake_form.import_notes))
										? singleColumnStyle : {}}>
										{
											isPostNotes
											&& (
												<div>
													<label>Additional Notes</label>
													<span style={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
														dangerouslySetInnerHTML={{ __html: this.linkify(intake_form.post_notes) }} />
												</div>
											)
										}
										{
											(this.props.editable && !isCertus)
											&& (
												<div>
													<Button onClick={(e) => this.setState({ pressed: !this.state.pressed })}
														style={{ margin: '.5em 0' }}>
														{isPostNotes ? 'Edit' : 'Create'} Additional Notes
													</Button>
													{this.state.pressed == true
														&& (
															<>
																<Form.TextArea
																	placeholder='Additional Notes'
																	className='import-notes inputter'
																	style={{ margin: '1%', padding: '1%' }}
																	value={this.state.post_notes}
																	onChange={(e) => this.createPostNote(e)}
																	/>
																<Button onClick={() => this.savePostNote(this.state.post_notes)} style={{ margin: 0 }}>
																	Save
																</Button>
															</>
														)}
												</div>
											)
										}
									</Grid.Column>
								)
							}
						</Grid>
					</Grid.Column>
					<Grid.Column width={8} verticalAlign='middle'>
						<Grid centered className='sampleImageUploadDisplay'>
							<ImageDropzone image={this.state.image} disabled={(this.state.uploadFlag || isCertus)}
								uploadImage={(files) => this.uploadImage(files)}
								imageError={(error) => this.setState({ imageError: error })}/>
						</Grid>
						{this.props.editable &&
							<Grid centered style={{ marginTop: 0 }}>
								<Grid.Column textAlign='center'>
									<input
										disabled={isCertus}
										type="file"
										accept="image/*"
										ref={this.upload}
										style={{ display: 'none' }}
										onChange={() =>{ this.uploadImage(this.upload.current.files)}}
									/>
									{this.state.imageError && <p className="error">{this.state.imageError}</p>}
									<Button icon labelPosition='left' primary={true} negative={false} size='small'
										disabled={(this.state.uploadFlag || isCertus) ? true : false}
										onClick={() => this.upload.current.click()}
										style={{margin: '0 .25em'}}>
										<Icon name={'arrow alternate circle up'} />{'Upload'}
									</Button>
									<Button icon labelPosition='left' primary={false} negative={true} size='small'
										disabled={(!this.state.uploadFlag || isCertus) ? true : false}
										onClick={() => this.removeImage()}
										style={{margin: '0 .25em'}}>
										<Icon name={'trash'} />{'Remove'}
									</Button>
								</Grid.Column>
							</Grid>
						}
					</Grid.Column>
				</Grid>
				<ImportDisclosureModal active={this.state.active} closer={() => this.setState({ active: false })} />
			</div>
		)
	}
}

export default withRouter(SampleInfo)
