import React from 'react'
import PropTypes from 'prop-types'

const iframeBaseURL = 'https://zfrmz.com/8rETNwnTioXSaF1Oxtoy'

function buildIframeURL(fullName, email, organizationName) {
	let firstName
	let lastName
	let splitName = []

	if (fullName) {
		splitName = fullName.split(' ')
	}

	if (splitName.length > 1) {
		[firstName, lastName] = splitName
	}
	else if (splitName.length === 1) {
		firstName = splitName[0]
		lastName = splitName[0]
	}

	let queryString = ''
	if (firstName && lastName && email && organizationName) {
		queryString = `?Name_First=${firstName}&Name_Last=${lastName}&Email=${email}&SingleLine6=${organizationName}`
	}

	return `${iframeBaseURL}${queryString}`
}

function Ordering({ fullName, email, organizationName }) {
	const iframeURL = buildIframeURL(fullName, email, organizationName)

	return (
		<section>
			<p>For a new product order request, complete and submit the form below. Our team will review the request and respond in an expedient manner</p>
			<iframe title='Product Ordering Form' src={iframeURL} width='100%' style={{ height: '140vh' }} />
		</section>
	)
}

Ordering.propTypes = {
	fullName: PropTypes.string,
	email: PropTypes.string,
	organizationName: PropTypes.string
}

Ordering.defaultProps = {
	fullName: undefined,
	email: undefined,
	organizationName: undefined
}

export default Ordering
