const dateFormatterUtcString = () => {
	const date = new Date()
	const year = date.getUTCFullYear()
	const month = date.getUTCMonth() + 1
	const day = date.getUTCDate()
	const hour = date.getUTCHours()
	const min = date.getUTCMinutes()
	const sec = date.getUTCSeconds()
	const dateString = `${year}/${month}/${day} ${hour}:${min}:${sec} UTC`
	return dateString
}

export default dateFormatterUtcString
