import AWS from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid'

 async function ImageUploadAWS({ bucket, accessKeyId, secretAccessKey }, file, Id) {
	const s3 = new AWS.S3({
		accessKeyId: accessKeyId,
		secretAccessKey: secretAccessKey,
		region: 'us-east-2'
	})
	let ext = file.name.split('.').pop()
	let key = `${Id}/${uuidv4()}.${ext}`

	return await s3.upload({
		Bucket: bucket,
		Key: key,
		Body: file,
		ContentType: file.type,
		ACL: 'public-read'
	})
	.promise().then((upload) => {
		return upload.key
	}).catch((error) =>{
		console.error("ImageUploadAWS", error);
		return undefined
	})
}
export default ImageUploadAWS;
