import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

const mapStoreToProps = (state) => ({
  machineState: state.machineState
})

class AppHeader extends Component {
  render() {
    return (
      <div>
        <header id="mainHeader">
          <div className="headerContent">
            {this.props.breadcrumb &&
              <Grid textAlign='left' className='breadCrumb'>
                <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                  {this.props.breadcrumb}
                </Grid.Column>
              </Grid>
            }
            <Grid centered textAlign='center'>
              <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
                {this.props.title}
              </Grid.Column>
            </Grid>
          </div>
        </header>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(AppHeader)
