import React, { Component } from 'react'
import {
  Grid,
  Message
} from 'semantic-ui-react'
class ErrorMessages extends Component {
  constructor(props) {
    super(props)
    this.state = { visible: true }
  }
  handleDismiss = () => {
    this.setState({ visible: false })
  }
  render() {
    let errorsList = Object.entries(this.props.errors)
    let messagesContent = []
    if (this.props.errors.message || (this.props.errors.status && this.props.errors.error)) {
      messagesContent.push(this.props.errors.error ? this.props.errors.error : (this.props.errors.message ? this.props.errors.message : ''))
    } if (typeof this.props.errors === 'string') {
      messagesContent.push(this.props.errors)
    }else {
      for (const [errs] of errorsList) {
        messagesContent.push(...errs)
      }
    }
    return (
      (messagesContent.length > 0) ? (
        <Grid centered className='errorMessagesGrid'>
          <Grid.Column mobile={this.props.mobile ? this.props.mobile : 16} tablet={this.props.tablet ? this.props.tablet : 16} computer={this.props.computer ? this.props.computer : 12} largeScreen={this.props.largeScreen ? this.props.largeScreen : 12}>
            {
              this.state.visible &&
              <Message error>
                {messagesContent[0]}
              </Message>
            }
          </Grid.Column>
        </Grid>
      ) : null
    )
  }
}

export default ErrorMessages
