import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import vialTypes from '../lib/vialTypes'
import PrepVial from './PrepVial'
import VialPropertyPicker from './VialPropertyPicker'
import { ReactComponent as PipetteSVG } from '../svg/micropipette.svg'
import _ from 'lodash'

class PrepMethodDiluentComposer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing_vial: {...props.vial}
    }
  }

  updateEditingVial = (e) => {
    let action = e.target.name.split('.')[1]
    console.log('action ' + action)
    if (action) {
      if (action === "reset") {
        console.log('resetting')
        this.setState({editing_vial: {...this.props.vial}})
      }
    }
    else {
      let newVial = {...this.state.editing_vial}
      newVial[e.target.name] = e.target.value
      this.setState({editing_vial: newVial})
    }
  }

  leadingZeros = (number, placesDesired) => {
    try {
      return [Array(placesDesired - String(number).length).fill("0").join(''), String(number)]
    }
    catch (err) {
      if (err instanceof RangeError)
        return ["", String(number)]
      else
        throw err
    }
  }

  render() {
    let vial = this.state.editing_vial
    let parentVial = this.props.prepMethod.prep_steps.find(v => v.id === vial.draw_from_prep_step_id)
    const [diluent0s, diluentAmount] = this.leadingZeros(vial.deposit_volume_ul, 4)
    return (
      <div className="prepMethodDiluentComposer">
        <div className="diluentProccessStep fromThisVial">
          <div className="diluentVial">
            <PrepVial vial={parentVial} />
          </div>
        </div>
        <div className="diluentProccessStep transmission">
          <div className="pipetteSVG-holder holder">
            <PipetteSVG />
          </div>
          <div className="input-holder holder">
            <span className="microliterValue"></span>
            <input type="text" onChange={this.updateEditingVial} name="deposit_volume_ul" value={vial.deposit_volume_ul} />
            <div className="bgNumber">
              <span className="leadingZeros">{diluent0s}</span><span className="userInput">{diluentAmount}</span>
            </div>
          </div>
        </div>
        <div className="diluentProccessStep toThisVial">
          <div className="vialPropertyPicker-holder">
            <VialPropertyPicker vial={vial} setVialProperties={this.updateEditingVial} />
          </div>
          <div className="diluentVial">
            <PrepVial vial={vial} />
          </div>
        </div>
        <footer>
          <button name="action.cancelVialEdit" onClick={this.props.updateEditingMethod}>cancel</button>
          <button name="action.reset" onClick={this.updateEditingVial}>reset</button>
          <button name="action.done" value={this.state.editing_vial} onClick={this.props.updateEditingMethod}>done</button>
        </footer>
      </div>
    )
  }
}

export default PrepMethodDiluentComposer
