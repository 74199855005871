import React, { Component } from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import AppHeader from './AppHeader'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import constants from '../lib/constants'
class Export extends Component {
  render() {
    const breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={formatRoute(constants.APPLICATION_ROUTE.SETTINGS.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.SETTINGS.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>Export</Breadcrumb.Section>
      </Breadcrumb>
    )
    return (
      <div>
        <AppHeader title={<h1>Export</h1>} breadcrumb={breadcrumb} />
        <section id="mainContent">
          <section className="app dark donutHolder">
            <div className="contentBlock homeBlock">
              Exporting currently unsupported.
            </div>
          </section>
        </section>
      </div>
    )
  }
}

export default Export
