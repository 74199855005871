import React from 'react'
import { Button, Form, Grid, Breadcrumb, Popup, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { userServices } from '../services/User'
import ToastMessage from '../components/Message'
import AppHeader from '../components/AppHeader'
import constants from '../lib/constants'
import passwordStrength from '../lib/passwordStrength'
import PasswordStrengthMeter from '../lib/PasswordStrengthMeter'
import ErrorMessages from './Errors/ErrorMessages'
import passwordValidity from '../lib/passwordValidity'
import { formatRoute } from 'react-router-named-routes'
class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      submitFlag: null,
      errorMessage: '',
      formErrors: {
        oldPasswordError: false,
        newPasswordError: false,
        confirmPasswordError: false,
        sameOldNewPasswordError: false,
      },
      apiErrors: {}
    }
  }
  handleChangePassword = () => {
    let formValid = true
    formValid = this.validateForm()
    if (!formValid) {
      return false
    }
    if (this.state.oldPassword === this.state.newPassword) {
      this.setState({ submitFlag: false, errorMessage: constants.CHANGE_PASSWORD.PASSWORD_REPEAT })
      return false
    }
    let configJson = {
      user: {
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword
      }
    }
    let id = this.props.id
    userServices.changePassword(id, configJson).then((res) => {
      this.setState({ submitFlag: true, apiErrors: {} })
    }).catch((e) => this.setState({ submitFlag: false, errorMessage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} }))
  }
  checkPassword = () => {
    if (!this.state.newPassword.match(constants.REG_EX.PASSWORD_REG_EX)) {
      if (this.state.newPassword.length < constants.PASSWORD.MIN_LENGTH) {
        this.setState({ errorMessage: constants.CHANGE_PASSWORD.PASSWORD_STRENGTH })
      }
      else
        this.setState({ errorMessage: constants.CHANGE_PASSWORD.PASSWORD_STRENGTH })
      return true
    }
    else {
      this.setState({ errorMessage: '' })
      return false
    }
  }
  checkConfirmPassword = () => {
    const { confirmPassword, newPassword } = this.state
    if (confirmPassword.length > 0 && confirmPassword === newPassword) {
      return false
    }
    else return true
  }
  validateForm = () => {
    let tempFormError = { ...this.state.formErrors }
    if (this.state.oldPassword === '') {
      tempFormError.oldPasswordError = true
    }
    if (this.state.oldPassword === this.state.newPassword && this.state.oldPassword !== '') {
      tempFormError.sameOldNewPasswordError = true
    }
    if (this.checkPassword(this.state.newPassword)) {
      tempFormError.newPasswordError = true
    }
    if (this.checkConfirmPassword(this.state.confirmPassword)) {
      tempFormError.confirmPasswordError = true
    }
    this.setState({
      formErrors: {
        ...tempFormError
      }
    })
    for (let key in tempFormError) {
      if (tempFormError[key] === true)
        return false
    }
    return true
  }
  isFieldValid = (field) => {
    let tempFormError = { ...this.state.formErrors }
    this.setState({ errorMessage: '' })
    if (this.state[field] === '') {
      tempFormError[`${field}Error`] = true
    }
    else {
      tempFormError[`${field}Error`] = false
    }
    this.setState({
      formErrors: { ...tempFormError }
    }, () => { this.passwordFormValidation() })
  }
  update = (e) => {
    const newState = {}
    let target = e.target
    newState[target.name] = target.value

    this.setState(newState, () => { this.isFieldValid(target.name) })
  }
  passwordFormValidation = () => {
    let tempFormError = { ...this.state.formErrors }
    let passwordErrors = passwordValidity(this.state.newPassword, this.state.confirmPassword)
    tempFormError['confirmPasswordError'] = passwordErrors.confirmPassword
    tempFormError['newPasswordError'] = passwordErrors.password
    tempFormError['sameOldNewPasswordError'] = false
    if (tempFormError['newPasswordError']) {
      this.setState({ errorMessage: constants.CHANGE_PASSWORD.PASSWORD_STRENGTH })
    }
    this.setState({
      formErrors: { ...tempFormError }
    })
  }
  render() {
    let breadcrumb
    breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={formatRoute(constants.APPLICATION_ROUTE.SETTINGS.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.SETTINGS.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={formatRoute(constants.APPLICATION_ROUTE.USER.CHANGE_PASSWORD.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.USER.CHANGE_PASSWORD.NAME}</Breadcrumb.Section></Link>
      </Breadcrumb>
    )
    let passwordStrengthCalc = passwordStrength(this.state.newPassword)
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{constants.CHANGE_PASSWORD.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <div className="form screen">
              <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
              <Form onSubmit={this.handleChangePassword}>
                {
                  this.state.submitFlag === true && <ToastMessage title='Success' description={constants.CHANGE_PASSWORD.SUCCESS} />
                }
                <Grid centered>
                  <Grid.Column mobile={16} tablet={12} largeScreen={6} computer={6}>
                    <Grid>
                      <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                        <Form.Field>
                          <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} className='required-field' label='Old Password' placeholder='Old Password' type='password' value={this.state.oldPassword} onChange={this.update} name="oldPassword" error={this.state.formErrors.oldPasswordError} />
                        </Form.Field>
                        {
                          this.state.formErrors.oldPasswordError && <p className="error">{constants.FORM.MANDATORY_FIELD}</p>
                        }
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                        <Grid>
                          <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                            <Form.Field>
                              <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} className='required-field' label={<React.Fragment><label>New Password <Popup header={constants.CHANGE_PASSWORD.PASSWORD_CRITERIA_TITLE} content={constants.CHANGE_PASSWORD.PASSWORD_STRENGTH} trigger={<Icon name='info circle' color="grey" />} /></label></React.Fragment>} placeholder='New Password' type='password' value={this.state.newPassword} onChange={this.update} name="newPassword" error={this.state.formErrors.newPasswordError || this.state.formErrors.sameOldNewPasswordError} />
                            </Form.Field>
                          </Grid.Column>
                          {
                            passwordStrengthCalc >= 0 &&
                            <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                              <PasswordStrengthMeter score={passwordStrengthCalc} />
                            </Grid.Column>
                          }
                          {
                            this.state.formErrors.newPasswordError && this.state.newPassword.length === 0 && <p className="error">{constants.FORM.MANDATORY_FIELD}</p>
                          }
                          {
                            this.state.formErrors.sameOldNewPasswordError && <p className='error'>{constants.FORM.SAME_OLD_NEW_PASSWORD}</p>
                          }
                        </Grid>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                        <Form.Field>
                          <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} className='required-field' label='Confirm Password' placeholder='Confirm Password' type='password' value={this.state.confirmPassword} onChange={this.update} name="confirmPassword" error={this.state.formErrors.confirmPasswordError} />
                        </Form.Field>
                        {
                          this.state.formErrors.confirmPasswordError && this.state.confirmPassword.length > 0 && <p className="error">{constants.CHANGE_PASSWORD.PASSWORD_MATCH}</p>
                        }
                        {
                          this.state.formErrors.confirmPasswordError && this.state.confirmPassword.length === 0 && <p className="error">{constants.FORM.MANDATORY_FIELD}</p>
                        }
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={8} largeScreen={4} computer={4} textAlign='center'>
                    <Button className='userButton' type='submit'>{constants.CHANGE_PASSWORD.CHANGE_PASSWORD_BUTTON_TITLE}</Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          </section>
        </section>
      </div>
    )
  }
}
export default ChangePassword
