import AWS from 'aws-sdk'

async function deleteS3Object({ bucket, accessKeyId, secretAccessKey, region }, key) {
	const s3 = new AWS.S3({
		accessKeyId: accessKeyId,
		secretAccessKey: secretAccessKey,
		region: region
	})

	return await s3.deleteObject({
		Bucket: bucket, 
		Key: key
	})
	.promise()
	.then((response) => {
		return response
	})
	.catch((error) =>{
		console.error('delete error', error);
		return error
	})
	
}
export default deleteS3Object
