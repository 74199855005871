function sampleQueueName(sampleQueue) {
  let name = ""
  if (sampleQueue.sample) {
    if (sampleQueue.sample.intake_form['strain_name']) {
      const sampleName = sampleQueue.sample.intake_form['strain_name']
      const batchId = sampleQueue.sample.intake_form['batch_number']
      name = `${batchId} - ${sampleName}`
    } else if (sampleQueue.sample.sample_type === "standard") {
      name = "20uL Calibration Check"
    } else {
      name = "Calibration"
    }
  } else if (sampleQueue.action) {
    name = _.capitalize(sampleQueue.action)
  }
  return name
}

export default sampleQueueName
