const constants = {
	FIELD_LENGTH: {
		MAX_64: 64,
		MAX_140: 140
	},
	HOME: {
		HEADER_TITLE: 'Home',
	},
	USER: {
		LIST: {
			HEADER_TITLE: 'Users',
			EDIT_USER: 'Edit User',
			REMOVE_USER: 'Remove User',
			STATUS: 'Status',
			DOWNLOAD_USERS_CSV: 'Download Users CSV',
			ADD_USER_BUTTON: 'Invite User',
			TOTAL_TITLE: 'Total Users',
			ACTIVATE_CONFIRMATION_HEADER: 'Activate',
			ACTIVATE_CONFIRMATION_CONTENT: 'Are You Sure Want To Activate The User?',
			DEACTIVATE_CONFIRMATION_HEADER: 'Inactivate',
			DEACTIVATE_CONFIRMATION_CONTENT: 'User Will Not Be Able To Access The Application If Account Is Inactivated.',
			DELETE_CONFIRMATION_HEADER: 'Delete',
			DELETE_CONFIRMATION_CONTENT: 'Are You Sure Want To Delete The User?',
			VIEW_USER: 'User Details',
			DELETE_BUTTON_TITLE: 'Delete',
			DELETE_AND_MIGRATE_BUTTON_TITLE: 'Migrate & Delete',
		},
		CREATE: {
			HEADER_TITLE: 'Invite User',
			CREATE_BUTTON_TITLE: 'Create',
			CREATE_REMOTE_TITLE: 'Invite',
			SUCCESS: 'User Created Successfully',
			INVITATION_SUCCESS: 'Invitation Has Been Sent To Given Email',
			CREATE_REMOTE_REINVITE_TITLE: 'Re-Invite',
			REINVITE_TIME_INTERVAL_IN_SECONDS: 60,
			ROLE_MANDATORY_FIELD: 'Please Select The Role',
			INVALID_EMAIL: 'Please enter a valid email address'
		},
		EDIT: {
			HEADER_TITLE: 'Edit User',
			EDIT_BUTTON_TITLE: 'Update',
			SUCCESS: 'User Details Updated Successfully'
		}
	},
	RESET_PASSWORD: {
		HEADER_TITLE: 'Reset Password',
		SUCCESS: 'Password Changed Successfully',
		RESET_PASSWORD_BUTTON_TITLE: 'Reset Password',
		LINK_EXPIRED: 'Link Has Expired. Please Retry',
		PASSWORD_STRENGTH: 'Password Should Be Minimum 8 Characters, Should Have Minimum 1 Alpha 1 Numeric And 1 Capitalization',
		PASSWORD_MATCH: 'Password Should Match'
	},
	FORGOT_PASSWORD: {
		HEADER_TITLE: 'Forgot Password',
		SUCCESS: 'Email Sent May Be In Your Spam Folder Please Verify And Add The Email Address To Your Contacts For Future Use',
		FORGOT_PASSWORD_BUTTON_TITLE: 'Forgot Password',
		FORGOT_PASSWORD_RESEND_BUTTON_TITLE: 'Resend',
		RESEND_INTERVAL_IN_SECONDS: 60
	},
	CHANGE_PASSWORD: {
		HEADER_TITLE: 'Change Password',
		SUCCESS: 'Password Changed Successfully',
		CHANGE_PASSWORD_BUTTON_TITLE: 'Change Password',
		PASSWORD_STRENGTH: 'Password Should Be Minimum 8 Characters, Should Have Minimum 1 Alpha 1 Numeric And 1 Capitalization',
		PASSWORD_MATCH: 'Password Should Match',
		PASSWORD_CRITERIA_TITLE: 'Password Criteria',
		PASSWORD_REPEAT: 'Passwords Should Not Be The Same'
	},
	PASSWORD: {
		MIN_LENGTH: 8
	},
	REG_EX: {
		PASSWORD_REG_EX: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
		EMAIL_REG_EX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
		PHONE_REG_EX: /^[0-9+]{10}$/
	},
	USER_SIGN_UP: {
		HEADER_TITLE: 'Sign Up',
		SIGN_UP_BUTTON_TITLE: 'Sign Up',
		SUCCESS: 'User Created Successfully',
		LINK_EXPIRED: 'Link Has Expired. Please Retry'
	},
	SUPPORT: {
		TITLE: 'Support',
		TICKET_BUTTON: {
			ICON: 'mail outline',
			CONTENT: 'Support Ticket'
		},
		ORDER_BUTTON: {
			ICON: 'add to cart',
			CONTENT: 'Ordering'
		},
		CHANGE_LOG_BUTTON: {
			ICON: 'calendar alternate outline',
			CONTENT: 'Change Log'
		},
		KNOWLEDGE_BASE_BUTTON: {
			ICON: 'file alternate outline',
			CONTENT: 'Knowledge Base'
		}
	},
	BATCH: {
		LIST: {
			HEADER_TITLE: 'Batches',
			EDIT_BATCH: 'Edit Batch',
			LIST_BATCH_TESTS: 'List Batch Tests',
			DOWNLOAD_QR_CODES_PDF: 'Download QR Codes PDF',
			DOWNLOAD_QR_CODES_CSV: 'Download QR Codes CSV',
			ADD_BATCH_BUTTON: 'Add Batch',
			TOTAL_TITLE: 'Total Batches',
			REMOVE: 'Delete Batch'
		},
		CREATE: {
			HEADER_TITLE: 'Create Batch',
			CREATE_BUTTON_TITLE: 'Create',
			GRID_TYPE_FEILD_MANDATORY: 'Please Select The Grid Type',
			BATCH_NAME_FEILD_MANDATORY: 'Please Fill The Batch Name',
			MAX_QR_LIMIT_ERROR: 'Number Of Qr Codes Cannot Be More Than',
			SEGMENT_WEIGHT_ERROR: 'Segment Weight cannot exceed Total Weight'
		},
		EDIT: {
			HEADER_TITLE: 'Edit Batch',
			EDIT_BUTTON_TITLE: 'Update',
		},
		GRID_TYPE_IMAGES: {
			ONE_ONE_ACRE: '/images/CannID_SquareGrid.png',
			FOUR_FOUR_ACRE: '/images/CannID_SquareGrid.png',
			BLOCK_NUMBER: '/images/CannID_ROW_NUMBER_GRID.png',
			ROWS: '/images/CannID_ROW_NUMBER_GRID.png',
			CENTER_PIVOT: '/images/CannID_Center_Pivot.png',
			NO_IMAGE_FOUND: '/images/no-image-found.png',
			SEGMENTS: '/images/segments_image.png'
		},
		DELETE: {
			HEADER_TITLE: 'Delete Batch',
			MODAL_CONTENT: 'Are You Sure You Want To Delete This Batch?'
		}
	},
	BATCH_TEST: {
		LIST: {
			HEADER_TITLE: 'Batch Tests',
			EDIT_BATCH_TEST: 'Edit Batch Test',
			LIST_BATCH_TEST_RESULTS: 'Batch Test Results',
			REPORT: 'Report',
			ADD_BATCH_TEST_BUTTON: 'Create Batch Test',
			TOTAL_TITLE: 'Total Batch Tests',
			BATCH_NAME: 'Batch Name',
			DOWNLOAD_PDF_REPORT: 'Download PDF',
			DOWNLOAD_CSV_RESULT: 'Download CSV'
		},
		CREATE: {
			HEADER_TITLE: 'Create Batch Test',
			CREATE_BUTTON_TITLE: 'Create',
			SUCCESS: 'Test Added Successfully',
			BATCH_NUMBER_FIELD_MANDATORY: 'Please Select The Batch',
			BATCH_NAME_FIELD_MANDATORY: 'Please Fill The Batch Number',
			QR_SCAN_MANDATORY: 'Please Scan / Select The QR Code',
			STRAIN_NAME_FIELD_MANDATORY: 'Please Fill The Strain Name / Sample Name',
			LONGITUDE_FIELD_MANDATORY: 'Please Enter Valid Longitude',
			LATITUDE_FIELD_MANDATORY: 'Please Enter Valid Latitude',
			SAMPLE_WEIGHT_FIELD_MANDATORY: 'Please Fill Valid Sample Weight',
			SAMPLE_TYPE_FIELD_MANDATORY: 'Please Select The Material Type',
			CUSTOM_FIELD_MANDATORY: 'Please Fill The Required Custom Fields',
			HARVESTED_WEIGHT_FIELD_MANDATORY: 'Please Fill Valid Harvested Weight',
			DRY_WEIGHT_FIELD_MANDATORY: 'Please Fill Valid Dry Weight',
			COMPARE_ERROR: 'Harvested Weight Should Be Greater Than Dry Weight',
			WEIGHT_ERROR: 'Please provide both As-Harvested and Dry Weight or none'
		},
		EDIT: {
			HEADER_TITLE: 'Edit Batch Test',
			EDIT_BUTTON_TITLE: 'Update',
			SUCCESS: 'Test Updated Successfully'
		},
		ALL_TEST_SUCCESS: 'All Tests Are Completed For The Selected Batch.',
		SAVE_NEXT_BUTTON_TITLE: 'Save and Next',
		START_TEST_BUTTON_TITLE: 'Start Test',
		BATCH_NOT_FOUND: 'This QR Does Not Belong To Any Batch'
	},
	SAMPLE_REPORT: {
		HEADER_TITLE: 'Results'
	},
	QC: {
		LIST: {
			HEADER_TITLE: 'Quality Control Results'
		}
	},
	SAMPLE_TEST: {
		LIST: {
			HEADER_TITLE: 'Sample Results',
			EDIT_SAMPLE_TEST: 'Edit Info',
			LIST_SAMPLE_TEST_RESULTS: 'Sample Results',
			REPORT: 'Report',
			TOTAL_TITLE: 'Total Sample Results',
			REMOVE: 'Remove',
			DOWNLOAD_PDF_REPORT: 'Download PDF',
			DOWNLOAD_CSV_RESULT: 'Download CSV',
			CERTUS_GRAY: 'Create Certus'
		},
		CREATE: {
			HEADER_TITLE: 'Create New Test',
			CREATE_BUTTON_TITLE: 'Create',
			SUCCESS: 'Test Added Successfully',
			FAIL: 'Error While Creating Sample',
			BATCH_NUMBER_FIELD_MANDATORY: 'Please Fill The Batch Number',
			BATCH_NAME_FIELD_MANDATORY: 'Please Fill The Batch',
			STRAIN_NAME_FIELD_MANDATORY: 'Please Fill The Strain Name / Sample Name',
			SAMPLE_WEIGHT_FIELD_MANDATORY: 'Please Fill Valid Sample Weight',
			SAMPLE_TYPE_FIELD_MANDATORY: 'Please Select The Material Type',
			CUSTOM_FIELD_MANDATORY: 'Please Fill The Required Custom Fields'
		},
		EDIT: {
			HEADER_TITLE: 'Edit Test',
			EDIT_BUTTON_TITLE: 'Update',
			SUCCESS: 'Test Updated Successfully',
			FAIL: 'Error While Updating Sample'
		},
		DELETE: {
			HEADER_TITLE: 'Delete Sample',
			MODAL_CONTENT: 'Are You Sure You Want To Delete Your Sample'
		},
		START_TEST_BUTTON_TITLE: 'Start Test',
		SAMPLE_WEIGHT_CONFIRMATION: 'Sample weight cannot be modified after saving.',
		SAMPLE_WEIGHT_TITLE: 'Alert!'
	},
	FORM_FIELD: {
		LIST: {
			HEADER_TITLE: 'Form Fields',
			EDIT_FORM_FIELD: 'Edit',
			ADD_FORM_FIELD_BUTTON: 'Add Form Field',
			TOTAL_TITLE: 'Total Form Fields',
			REMOVE: 'Remove',
			NO_FIELDS_FOUND: 'No Fields Found'
		},
		CREATE: {
			HEADER_TITLE: 'Add Form Field',
			CREATE_BUTTON_TITLE: 'Add',
			SUCCESS: 'Field Added Successfully'
		},
		EDIT: {
			HEADER_TITLE: 'Edit Form Field',
			EDIT_BUTTON_TITLE: 'Update',
			SUCCESS: 'Field Updated Successfully'
		},
		DELETE: {
			HEADER_TITLE: 'Delete Form Field',
			MODAL_CONTENT: 'Are You Sure You Want To Delete Form Field',
			DELETE_BUTTON_TITLE: 'Delete'
		}
	},
	FORM_TEMPLATE: {
		LIST: {
			HEADER_TITLE: 'Form Templates',
			LIST_FORM_FIELDS: 'Form Fields',
			VIEW_FORM_TEMPLATE: 'Description',
			EDIT_FORM_TEMPLATE: 'Edit',
			ADD_FORM_TEMPLATE_BUTTON: 'Create Form Template',
			TOTAL_TITLE: 'Total Form Templates',
			REMOVE: 'Remove',
			NO_DESCRIPION_FOUND: 'No Description Found'
		},
		CREATE: {
			HEADER_TITLE: 'Create Form Template',
			CREATE_BUTTON_TITLE: 'Create',
			SUCCESS: 'Form Template Added Successfully',
			FORM_FIELD_MANDATORY: 'Please Add Atleast One Form Field'
		},
		EDIT: {
			HEADER_TITLE: 'Edit Form Template',
			EDIT_BUTTON_TITLE: 'Update',
			SUCCESS: 'Form Template Updated Successfully'
		},
		DELETE: {
			HEADER_TITLE: 'Delete Form Template',
			MODAL_CONTENT: 'Are You Sure You Want To Delete Form Template'
		}
	},
	QR_LOCATION: {
		CREATE: {
			HEADER_TITLE: 'Scan QR Code',
			CREATE_BUTTON_TITLE: 'Save and Next',
			SUCCESS: 'QR Lat/Long Details Added Successfully',
			GEO_LOCATION: {
				PERMISSION_DENIED: 'User Denied The Request For Geolocation.',
				POSITION_UNAVAILABLE: 'Location Information Is Unavailable.',
				TIMEOUT: 'The Request To Get User Location Timed Out.',
				UNKNOWN_ERROR: 'An Unknown Error Occurred.'
			}
		}
	},
	ERROR: {
		SOMETHING_WENT_WRONG: 'Something Went Wrong! Please Try Again',
		API_ERRORS: 'Errors',
		REQUEST_NOT_FOUND: 'Request Not Found'
	},
	ACL: {
		BATCH_RESOURCE: {
			TITLE: 'Batch',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update',
				DELETE: 'destroy',
				EXPORT_CSV: 'index',
				DOWNLOAD_QR_CODES_PDF: 'print_pdf',
				DOWNLOAD_QR_CODES_CSV: 'qr_codes',
				CREATE: 'create',
				LIST_QR_CODES: 'index',
				LIST_QR_LOCATION: 'index',
				LIST_BATCH_TESTS: 'index'
			}
		},
		SAMPLE_RESOURCE: {
			TITLE: 'Sample',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update',
				DELETE: 'destroy',
				EXPORT_CSV: 'download_report',
				SAMPLE_RESULTS: 'sample_results',
				SHOW_REPORT: 'show',
				CREATE: 'create',
				DOWNLOAD_REPORT: 'download_report',
				DOWNLOAD_RESULT: 'results',
				MAKE_CERTUS: 'create_certus'
			}
		},
		SAMPLE_REPORT_RESOURCE: {
			TITLE: 'Sample',
			ACTIONS: {
				SHARE_REPORT: 'share',
				RUN_TEST: 'create',
				DOWNLOAD_REPORT: 'download_report',
				DOWNLOAD_RESULT: 'results'
			}
		},

		BATCH_TEST_RESOURCE: {
			TITLE: 'Sample',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update',
				DELETE: 'destroy',
				EXPORT_CSV: 'download_report',
				BATCH_TEST_RESULTS: 'sample_results',
				SHOW_REPORT: 'show',
				CREATE: 'create',
				DOWNLOAD_REPORT: 'download_report',
				DOWNLOAD_RESULT: 'results'
			}
		},
		FORM_FIELD_RESOURCE: {
			TITLE: 'FormField',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update',
				DELETE: 'destroy',
				CREATE: 'create',
				EXPORT_CSV: 'export',
			}
		},
		FORM_TEMPLATE_RESOURCE: {
			TITLE: 'FormTemplate',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update',
				DELETE: 'destroy',
				LIST_FORM_FIELDS: 'show',
				VIEW_FORM_TEMPLATE: 'show',
				EXPORT_CSV: 'export',
				CREATE: 'create'
			}
		},
		USER_RESOURCE: {
			TITLE: 'User',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update',
				DELETE: 'destroy',
				EXPORT_CSV: 'index',
				STATUS: 'update',
				VIEW_USER: 'show',
				CREATE: 'create',
				INVITE_USER: 'invite',
				CHANGE_PASSWORD: 'change_password',
				LOGOUT_OTHER_DEVICES: 'logout',
				MY_ACCOUNT: 'update'
			}
		},
		SETTING_RESOURCE: {
			TITLE: 'Sample',
			ACTIONS: {
				LIST: 'index',
				EXPORT: 'create',
				CONNECTIVITY: 'create',
				ADMINISTRATION: 'create',
				DIAGNOSTICS: 'create',
				MAINTENANCE: 'create'
			}
		},
		QR_LOCATION: {
			TITLE: 'Batch',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update'
			}
		},
		GRAPH_DASHBOARD_RESOURCE: {
			TITLE: 'AnalyticalReport',
			ACTIONS: {
				LIST: 'index',
				EDIT: 'update',
				DELETE: 'destroy',
				EXPORT_CSV: 'index',
				VIEW: 'show',
				CREATE: 'create',
				EXPORT_PDF: 'download_report',
				SHARE: 'share_pdf_report'
			}
		},
		REPORT_METHOD_RESOURCE: {
			TITLE: 'Method',
			ACTIONS: {
				LIST: 'index',
				VIEW: 'show',
				DELETE: 'destroy',
				LOOKUP: 'lookup',
				CREATE: 'create',
				EDIT: 'update',
				RESET_COLORS: 'reset_colors'
			}
		},
		CERTUS: {
			TITLE: 'Certus',
			ACTIONS: {
				VERISEAL: 'veriseal',
				VERISEAL_COA: 'veriseal-coa',
				PROJECT: 'project',
				TRANSPORTATION: 'transportation',
				CERTIFICATE: 'certificate'
			}
		}
	},
	SHARE_REPORT: {
		SEND_LINK_TO_EMAIL_BUTTON_TITLE: 'E-mail',
		SUCCESS: 'Email Has Been Sent.',
		INVALID_EMAIL: 'Please enter a valid email address'
	},
	PASSWORD_STRENGTH_METER: {
		WEAK: 'Weak',
		FAIR: 'Fair',
		GOOD: 'Good',
		STRONG: 'Strong'
	},
	GRAPH_DASHBORD: {
		LIST: {
			HEADER_TITLE: 'Analytics',
			EDIT_GRAPH: 'Edit',
			ADD_GRAPH: 'Create Report',
			TOTAL_TITLE: 'Total Reports',
			REMOVE: 'Delete',
			EXPORT_PDF: 'Download',
			SHARE: 'Share',
			NO_GRAPHS_FOUND: 'No Reports Found',
			NO_MATCHES_FOUND: 'No Matches Found'
		},
		CREATE: {
			HEADER_TITLE: 'Create Report',
			CREATE_BUTTON_TITLE: 'Create',
			SUCCESS: 'Report Added Successfully',
			NEXT_BUTTON_TITLE: 'Next',
			BACK_BUTTON_TITLE: 'Back',
			BATCH_TEST_GRAPH_SCALE_SLUG: 'batch-tests',
			COMPARE_TEST_GRAPH_SCALE_SLUG: 'compare-tests'
		},
		EDIT: {
			HEADER_TITLE: 'Edit Report',
			EDIT_BUTTON_TITLE: 'Update',
			SUCCESS: 'Report Updated Successfully'
		},
		DELETE: {
			HEADER_TITLE: 'Delete Report',
			MODAL_CONTENT: 'Are You Sure You Want To Delete Report'
		},
		GRAPH_TYPE_SLUG: {
			LINE: 'line',
			PIE: 'pie',
			BAR: 'bar',
			SCATTER: 'scatter'
		},
		GRAPH_UNIT_SLUG: {
			PERCENT: { TEXT: 'By Percent', VALUE: 'percent' },
			AMOUNT: { TEXT: 'By Amount (mg/g)', VALUE: 'amount' }
		},
		COMPARE_TYPE_SLUG: {
			BATCH_TEST: 'batch_test',
			SAMPLE_TEST: 'sample_test',
			OVERALL_TEST: 'overall_test'
		},
		LINE_CHART_RESET_ZOOM_BUTTON_TITLE: 'Reset Zoom',
		NO_PREVIEW_FOUND: '/images/no-preview-found.png',
		NO_GRAPH_DATA_FOUND: '/images/no-graph-data-found.png',
		COMPARE_BUTTON_TOOLTIP: 'Must select reports',
		SUB_SCALE_SLUG: {
			WITH_STRAIN_NAME: { TEXT: 'With Strain Name / Sample Name', VALUE: 'strain_name' },
			WITH_BATCH_NUMBER: { TEXT: 'With Batch Number', VALUE: 'batch_number' }
		}
	},
	LOGIN: {
		PASSWORD_ERROR: 'Invalid Credentials',
		EMAIL_ERROR: 'Invalid Credentials',
		SIGNATURE_EXPIRY: 'Signature Has Expired'
	},
	USER_ROLE: {
		SUPER_ADMIN_SLUG: 'superadmin',
		ORGANIZATION_VERISEAL_COA_ADMIN_SLUG: 'veriseal-coaadmin',
		ORGANIZATION_VERISEAL_ADMIN_SLUG: 'veriseal-admin',
		ORGANIZATION_ADMIN_SLUG: 'admin',
		ORGANIZATION_TESTER_SLUG: 'tester',
		ORGANIZATION_VIEWER_SLUG: 'viewer',
		ADMIN_ROLE_SLUGS: ['veriseal-coaadmin', 'veriseal-admin', 'admin']
	},
	SETTINGS: {
		EXPORT_BUTTON_TITLE: 'Export',
		CONNECTIVITY_BUTTON_TITLE: 'Connectivity',
		ADMINISTRATION_BUTTON_TITLE: 'Administration',
		DIAGNOSTICS_BUTTON_TITLE: 'Diagnostics',
		MAINTENANCE_BUTTON_TITLE: 'Maintenance',
		LOG_OUT_FROM_OTHER_DEVICES_BUTTON_TITLE: 'Logout From Other Devices'
	},
	LOGOUT: {
		OTHER_DEVICE: {
			CONFIRM: {
				HEADER_TITLE: 'Logout From Other Devices',
				MODAL_CONTENT: 'Are You Sure You Want To Logout From Other Devices?',
				SUCCESS: 'Logged Out From Other Devices Successfully'
			}
		}
	},
	FORM: {
		MANDATORY_FIELD: 'Please Fill The Field',
		SAME_OLD_NEW_PASSWORD: 'Old And New Password Cannot Be Same',
		MANDATORY_SELECT: 'Please Select The Graph Type',
		MANDATORY_DATE_FIELD: 'Please Select The Start Date And End Date'
	},
	SAMPLE_TYPE: {
		FLOWER: 'flower',
		STANDARD: 'standard',
		CALIBRATION: 'calibration',
		FLOWER_LESS_THAN_10_PRIMARY: 'flower-lt-10-primary',
		FLOWER_GREATER_THAN_10_PRIMARY: 'flower-gt-10-primary'
	},
	QC_SAMPLE_TYPES: {
		STANDARD: {
			ID: 9,
			SLUG: 'standard'
		},
		CALIBRATION: {
			ID: 10,
			SLUG: 'calibration'
		}
	},
	APPLICATION_MENU: {
		HOME: 'Home',
		CHANGE_PASSWORD: 'Change Password',
		SETTINGS: 'Settings'
	},
	APPLICATION_ROUTE: {
		HOME: {
			NAME: 'Home',
			ROUTE: '/'
		},
		BATCH: {
			LIST: {
				NAME: 'Batches',
				ROUTE: '/batch/list'
			},
			CREATE: {
				NAME: 'Create Batch',
				ROUTE: '/batch/create'
			},
			EDIT: {
				NAME: 'Edit Batch',
				ROUTE: '/batch/:id/edit'
			},
			QR_CODES: {
				NAME: 'QR Codes',
				ROUTE: '/batch/:id/qr-codes'
			}
		},
		SAMPLE: {
			LIST: {
				NAME: 'Sample Results',
				ROUTE: '/test-result/list'
			},
			CREATE: {
				NAME: 'Create New Test',
				ROUTE: '/test-result/create'
			},
			EDIT: {
				NAME: 'Edit Test Result',
				ROUTE: '/test-result/:id/edit'
			},
			REPORT: {
				NAME: 'Results',
				ROUTE: '/sample/:id/results'
			},
			SHARE_REPORT: {
				NAME: 'Share Report',
				ROUTE: '/share/report/:id'
			},
			START_TEST: {
				NAME: 'Start Test',
				ROUTE: '/samples/:id/load'
			},
			DASHBOARD: {
				NAME: 'Sample Results',
				ROUTE: '/test-result/list'
			},
			IMPORT_CSV: {
				NAME: 'Import New Results',
				ROUTE: '/samples/import'
			},
			IMPORT_CSV_MAPPER: {
				NAME: 'Import Template Mapping',
				ROUTE: '/samples/import/map'
			},
			IMPORT_TEMPLATES: {
				NAME: 'Import Templates',
				ROUTE: '/samples/import/templates'
			},
			IMPORT_TEMPLATE_EDIT: {
				NAME: 'Edit Import Template',
				ROUTE: '/samples/import/templates/:id/edit'
			},
			RAW_IMPORTED_CSV: {
				NAME: 'Raw Imported CSV',
				ROUTE: '/sample/:id/results/import'
			},
			POPULATE_COA: {
				TITLE: 'Sample',
				NAME: 'Certificate of Analysis Data',
				ROUTE: '/sample/:id/coa-data',
				ACTIONS: {
					UPDATE_COA: 'update_coa'
				}
			}
		},
		QC: {
			LIST: {
				NAME: 'Quality Control Results',
				ROUTE: '/qc/list'
			}
		},
		CERTUS:{
			LIST: {
				NAME: 'Certus List',
				ROUTE: '/certus'
			}
		},
		REPORT_METHOD: {
			LIST: {
				NAME: 'Report Methods',
				ROUTE: '/samples/report-methods'
			},
			EDIT: {
				NAME: 'Edit Report Method',
				ROUTE: '/samples/report-methods/:id/edit'
			},
			CREATE: {
				NAME: 'Create Report Method',
				ROUTE: '/samples/report-methods/create'
			}
		},
		BATCH_TEST: {
			LIST: {
				NAME: 'Batch Tests',
				ROUTE: '/batch-test/:batch_id/list'
			},
			CREATE: {
				NAME: 'Create Batch Test',
				ROUTE: '/batch-test/create'
			},
			EDIT: {
				NAME: 'Edit Batch Test',
				ROUTE: '/batch-test/:id/edit'
			},
			QR: {
				NAME: 'Print QR Codes'
			}
		},
		FORM_TEMPLATE: {
			LIST: {
				NAME: 'Form Templates',
				ROUTE: '/form-template/list'
			},
			CREATE: {
				NAME: 'Create Form Template',
				ROUTE: '/form-template/create'
			},
			EDIT: {
				NAME: 'Edit Form Template',
				ROUTE: '/form-template/:id/edit'
			}
		},
		USER: {
			LIST: {
				NAME: 'Users',
				ROUTE: '/user/list'
			},
			CREATE: {
				NAME: 'Invite User',
				ROUTE: '/user/invite'
			},
			EDIT: {
				NAME: 'Edit User',
				ROUTE: '/user/:id/edit'
			},
			CHANGE_PASSWORD: {
				NAME: 'Change Password',
				ROUTE: '/change-password'
			},
			SIGN_UP: {
				NAME: 'Sign Up',
				ROUTE: '/users/invite/:token'
			}
		},
		SUPPORT: {
			TICKET: {
				NAME: 'Support Ticket',
				ROUTE: '/support'
			},
			ORDERING: {
				NAME: 'Ordering',
				ROUTE: '/support/ordering'
			},
			CHANGE_LOG: {
				NAME: 'Change Log',
				ROUTE: '/support/changelog'
			}
		},
		ANALYTICAL_REPORT: {
			LIST: {
				NAME: 'Analytics',
				ROUTE: '/analytical-report/list'
			},
			CREATE: {
				NAME: 'Create Report',
				ROUTE: '/analytical-report/create'
			},
			EDIT: {
				NAME: 'Edit Report',
				ROUTE: '/analytical-report/:id/edit'
			},
			VIEW: {
				NAME: 'Analytics',
				ROUTE: '/analytical-report/:id/view'
			},
			COMPARE: {
				NAME: 'Compare Report',
				ROUTE: '/analytical-report/create'
			}
		},
		SCAN_QR_CODE: {
			NAME: 'Scan QR Code',
			ROUTE: '/scan-qr-code'
		},
		SETTINGS: {
			NAME: 'Settings',
			ROUTE: '/settings'
		},
		FORGOT_PASSWORD: {
			NAME: 'Forgot Password',
			ROUTE: '/forgot-password'
		},
		RESET_PASSWORD: {
			NAME: 'Reset Password',
			ROUTE: '/reset-password/:token'
		},
		LOGIN: {
			NAME: 'Login',
			ROUTE: '/login'
		},
		PAGE_NOT_FOUND: {
			NAME: '404',
			ROUTE: '/404'
		},
		ACCOUNT: {
			NAME: 'Account',
			ROUTE: '/account'
		},
		QR_REDIRECTION: {
			NAME: 'QR',
			ROUTE: '/qr/:share_key'
		},
		CERTUS_QR_REDIRECTION: {
			NAME: 'CERTUS QR',
			ROUTE: '/certus_app'
		},
		ACCESS_FORBIDDEN: {
			NAME: '403',
			ROUTE: '/403'
		}
	},
	KNOWLEDGE_BASE_LINK: 'https://support.cannid.com/portal/en/signin',
	FOOTER: {
		IONIZATION_LABS_LINK: 'https://ionizationlabs.com'
	},
	ACCOUNT: {
		HEADER_TITLE: 'Account',
		SUCCESS: 'Company Details Updated Successfully',
		IMAGE_LIMIT: 'Image size cannot exceed 10 Mb',
		MAX_FILE_UPLOAD_SIZE: 10000000 // 10 MB max
	},
	PAGE_NOT_FOUND: 'Page Not Found',
	PERMISSION_DENIED: 'You Are Not Authorized To View This Page',
	GO_TO_HOME_PAGE_BUTTON_TITLE: 'Go To Home Page',
	GO_TO_LOGIN_PAGE_BUTTON_TITLE: 'Go To Login Page',
	HEADER_TIMEZONE: 'X-Timezone',
	DEFAULT_SORT_ORDER: 'ASC'
}
export default constants
