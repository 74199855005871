import axios from '../axios'
import axiosNoToken from '../axiosNoToken'
import config from '../config'
import cannidAPI from '../cannidAPI/client'
import constants from '../lib/constants'

export const CertiServices  = {
  getTransportReportDownloadUrl,
  getOrgCertusTokenBank
}


function getTransportReportDownloadUrl(sharekey) {
  return process.env.REACT_APP_API_ROOT + "/" + `certi/${sharekey}/report/download`
}

function getOrgCertusTokenBank(orgId){
  return axios().get(process.env.REACT_APP_API_ROOT + "/" + `certi/${orgId}/bank`)
}
