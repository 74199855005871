/* eslint no-restricted-syntax: 0 */
import constants from './constants'

const QC_SAMPLE_TYPES = constants.QC_SAMPLE_TYPES

function getQCIDs() {
	const qcKeys = Object.keys(QC_SAMPLE_TYPES)
	const ids = []

	for (const key of qcKeys) {
		ids.push(QC_SAMPLE_TYPES[key].ID)
	}

	return ids
}

function getQCSlugs() {
	const qcKeys = Object.keys(QC_SAMPLE_TYPES)
	const slugs = []

	for (const key of qcKeys) {
		slugs.push(QC_SAMPLE_TYPES[key].SLUG)
	}

	return slugs
}

const isQC = {
	sample: function isQCSample(sample) {
		if (!sample.sample_type) {
			return false
		}

		if (getQCIDs().includes(sample.sample_type.id)) {
			return true
		}
		if (getQCSlugs().includes(sample.sample_type.slug)) {
			return true
		}

		return false
	},

	id: function isQCID(id) {
		return getQCIDs().includes(id)
	},

	slug: function isQCSlug(slug) {
		return getQCSlugs().includes(slug)
	}
}

export default isQC
