import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function parseBarOptions(title, categories, series) {
	return {
		chart: {
			type: 'column',
			backgroundColor: 'transparent'
		},
		title: {
			text: `<strong>${title}</strong>`
		},
		xAxis: {
			gridLineWidth: 1,
			categories,
			crosshair: true
		},
		yAxis: {
			allowDecimals: true,
			softMin: 0,
			title: false
		},
		plotOptions: {
			series: {
				animation: false
			}
		},
		tooltip: {
			formatter() {
				return `<span>${this.point.y}% from <b>${this.point.label} </b></span>`
			}
		},
		series,
		credits: {
			enabled: false
		},
		exporting: {
			enabled: false
		},
		legend: {
			enabled: true
		}
	}
}

function QCBarChart({ sample }) {
	const categories = Object.keys(sample.qc_compounds)
	return (
		<>
			<HighchartsReact highcharts={Highcharts} options={parseBarOptions('Percent Recovery', categories, sample.qc_bcd.recovery)} />
			<HighchartsReact highcharts={Highcharts} options={parseBarOptions('Percent Error', categories, sample.qc_bcd.error)} />
		</>
	)
}

QCBarChart.propTypes = {

}

export default QCBarChart
