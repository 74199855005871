import constants from './constants'
const passwordValidity = (newPassword, confirmPassword) => {
  let tempFormError = {
    password: false,
    confirmPassword: false,
  }
  if (newPassword.toString().trim() !== '' && newPassword.toString().trim().length <= confirmPassword.toString().trim().length && newPassword !== confirmPassword) {
    tempFormError['confirmPassword'] = true
  }
  if (newPassword.toString().trim().length > 0 && !newPassword.match(constants.REG_EX.PASSWORD_REG_EX)) {
    tempFormError['password'] = true
  }
  return tempFormError
}

export default passwordValidity
