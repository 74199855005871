import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import SampleInfo from '../SampleInfo'
import SampleCertus from '../Certus/SampleCertus'
import constants from '../../lib/constants'
import permissionCheck from '../../lib/permissionCheck'

function SampleSummary({ sample, finished }) {
	if (sample.result_type === 'OVER') {
		return null
	}

	return (
		(permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL) && finished === true) ? (
			<Grid centered>
				<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center' style={{padding: '1rem'}}>
					<SampleCertus sample={sample} />
				</Grid.Column>
				<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}
					textAlign='center' className='dataSampleTable'>
					<SampleInfo sample={sample} editable />
				</Grid.Column>
			</Grid>
		) : (
			<Grid centered>
				<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}
					textAlign='center' className='dataSampleTable'>
					<SampleInfo sample={sample} editable />
				</Grid.Column>
			</Grid>
		)
	)
}

SampleSummary.propTypes = {
	sample: PropTypes.object,	// eslint-disable-line react/forbid-prop-types
	finished: PropTypes.bool
}

SampleSummary.defaultProps = {
	sample: null,
	finished: false
}

export default SampleSummary
