import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import { Breadcrumb, Modal, Button } from 'semantic-ui-react'
import constants from '../../lib/constants'
import axios from '../../axios'
import pdfDownload from '../../services/pdfDownload'
import { CsvDownload } from '../../services/CsvDownloads'
import { SampleTestServices } from '../../services/SampleTest'
import reportNameFormatter from '../../lib/reportNameFormatter'
import AppHeader from '../AppHeader'
import SearchComponent from '../SearchComponent'
import QCResultsTable from './QCResultsTable/QCResultsTable'
import CertusModalQr from '../Certus/CertusModalQr'
import CertusModalCreate from '../Certus/CertusModalCreate'
import ErrorMessages from '../Errors/ErrorMessages'
import TABLE_ROW_ACTIONS from './QCResultsTable/TABLE_ROW_ACTIONS'

const pageSize = 10
const defaultSortProperty = 'id'
const SEARCH_BAR_OPTIONS = [
	{ text: 'Batch Number', value: 'batch_number' },
	{ text: 'Sample Name', value: 'strain_name' },
]

function getSampleQueue() {
	const requestURL = `${process.env.REACT_APP_API_ROOT}/sample_queues`
	return axios().get(requestURL).then((axiosResponse) => axiosResponse.data)
}

function QCResultsBreadcrumb() {
	return (
		<Breadcrumb>
			<Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}>
				<Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section>
			</Link>
			<Breadcrumb.Divider icon='right angle' />
			<Link to={formatRoute(constants.APPLICATION_ROUTE.QC.LIST.ROUTE, {})}>
				<Breadcrumb.Section>{constants.APPLICATION_ROUTE.QC.LIST.NAME}</Breadcrumb.Section>
			</Link>
		</Breadcrumb>
	)
}

class QCResults extends React.Component {
	constructor(props) {
		super(props)
		this.mounted = false
		this.currentPage = 1
		this.sort = { property: defaultSortProperty, order: 'DESC' }
		this.state = {
			loading: true,
			error: false,
			resultsData: null,
			sampleQueue: null,
			searchText: '',
			searchOptionValue: SEARCH_BAR_OPTIONS[0].value,
			deleteSampleID: null,
			certusModalSample: null,
			certusModalOpen: false
		}
	}

	componentDidMount() {
		this.mounted = true
		this.getData()
	}

	componentWillUnmount() {
		this.mounted = false
	}

	onHeaderCellClick = (headerCellConfig) => {
		if (headerCellConfig.apiName !== this.sort.property) {
			this.sort = { property: headerCellConfig.apiName, order: 'DESC' }
		}
		else if (headerCellConfig.apiName === this.sort.property) {
			if (this.sort.order === 'DESC') {
				this.sort.order = 'ASC'
			}
			else if (this.sort.order === 'ASC') {
				this.sort.property = defaultSortProperty
				this.sort.order = 'DESC'
			}
		}

		this.getData()
	}

	onRowClick = (sampleID) => {
		this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.REPORT.ROUTE, { id: sampleID }))
	}

	onRowActionClick = (rowAction, sample) => {
		if (rowAction === TABLE_ROW_ACTIONS.EDIT) {
			this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.EDIT.ROUTE, { id: sample.id }), { fromQC: true })
		}
		else if (rowAction === TABLE_ROW_ACTIONS.DOWNLOAD_PDF) {
			const fileName = `${reportNameFormatter(sample.intake_form.strain_name, sample.organization.name)}.pdf`
			pdfDownload(sample.share_key, fileName)
		}
		else if (rowAction === TABLE_ROW_ACTIONS.DOWNLOAD_CSV) {
			CsvDownload.downloadData(SampleTestServices.getSampleTestResultDownloadUrl(sample.id), sample.intake_form.batch_number)
		}
		else if (rowAction === TABLE_ROW_ACTIONS.DELETE) {
			this.setState({ deleteSampleID: sample.id })
		}
		else if (rowAction === TABLE_ROW_ACTIONS.CREATE_CERTUS || rowAction === TABLE_ROW_ACTIONS.GET_CERTUS) {
			this.setState({ certusModalSample: sample, certusModalOpen: true })
		}
	}

	onSearchTextChange = (event) => {
		this.setState({ searchText: event.target.value })
	}

	onSearchOptionChange = (event, { value }) => {
		this.setState((prevState) => {
			if (prevState.searchOptionValue !== value) {
				this.currentPage = 1
				return ({ searchOptionValue: value })
			}

			return null
		}, () => this.getData())
	}

	onPerformSearch = () => {
		this.currentPage = 1
		this.getData()
	}

	getData() {
		this.setState({ loading: true })

		this.getResultsData()
			.then((resultsData) => {
				if (this.mounted === false) {
					return null
				}

				this.setState({ resultsData })
				return getSampleQueue()
			})
			.then((sampleQueue) => {
				if (this.mounted === false) {
					return
				}

				this.setState({ sampleQueue, loading: false })
			})
			.catch(() => {
				if (this.mounted === false) {
					return
				}

				this.setState({ loading: false, error: true })
			})
	}

	getResultsData() {
		const requestConfig = {
			params: {
				qcSamples: 'true',
				startIndex: (this.currentPage * pageSize) - pageSize,
				endIndex: this.currentPage * pageSize,
				sortOrder: this.sort.order,
				sortColumn: this.sort.property
			}
		}

		const requestURL = `${process.env.REACT_APP_API_ROOT}/samples`

		const { searchText, searchOptionValue } = this.state
		if (searchText.trim() !== '' && searchText !== null) {
			requestConfig.params.searchData = searchText
			requestConfig.params.searchIn = searchOptionValue
		}

		this.setState({ loading: true })
		return axios().get(requestURL, requestConfig).then((axiosResponse) => axiosResponse.data)
	}

	onPageChange = (pageNumber) => {
		this.currentPage = pageNumber
		this.getData()
	}

	deleteSample = () => {
		SampleTestServices.deleteSample(this.state.deleteSampleID).then((res) => {
			if (this.mounted === false) {
				return
			}

			if (res && res.status === 202) {
				this.clearDeleteSample()
				this.getData()
			}
		}).catch(() => {
			if (this.mounted === false) {
				return
			}

			this.clearDeleteSample()
			this.setState({ error: true })
		})
	}

	clearDeleteSample = () => {
		this.setState({ deleteSampleID: null })
	}

	buildCertusModal() {
		const certusModalSample = this.state.certusModalSample

		if (certusModalSample) {
			if (certusModalSample.certus_id) {
				return (
					<CertusModalQr
						sample_id={certusModalSample.id}
						strain_name={certusModalSample.intake_form.strain_name}
						modalOpen={!!(this.state.certusModalSample)}
						closeModal={() => this.setState({ certusModalSample: null })}
						createFailure={() => this.setState({ certusModalSample: null, error: true })}
					/>
				)
			}

			return (
				<CertusModalCreate
					coa={certusModalSample.coa}
					sample_id={certusModalSample.id}
					current_name={certusModalSample.intake_form.strain_name}
					modalOpen={!!(this.state.certusModalSample) && this.state.certusModalOpen}
					startLoading={() => this.setState({ certusModalOpen: false })}
					closeModal={() => this.setState({ certusModalSample: null, certusModalOpen: false })}
					createSuccess={() => this.setState({ certusModalOpen: true })}
					createFailure={() => this.setState({ certusModalSample: null, error: true })}
				/>
			)
		}

		return null
	}

	buildDeleteModal() {
		const deleteSampleID = this.state.deleteSampleID

		if (deleteSampleID === null) {
			return null
		}

		return (
			<Modal size='mini' closeIcon open onClose={this.clearDeleteSample}>
				<Modal.Header>{constants.SAMPLE_TEST.DELETE.HEADER_TITLE}</Modal.Header>
				<Modal.Content>
					<p>{constants.SAMPLE_TEST.DELETE.MODAL_CONTENT}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button className='cancelButton' onClick={this.clearDeleteSample}>No</Button>
					<Button className='confirmButton' icon='checkmark' labelPosition='right' content='Yes' onClick={this.deleteSample} />
				</Modal.Actions>
			</Modal>
		)
	}

	buildSearchBar() {
		return (
			<SearchComponent
				options={SEARCH_BAR_OPTIONS}
				onSearchChange={this.onSearchTextChange}
				handleSearchResult={this.onPerformSearch}
				getSelectedDropdown={this.onSearchOptionChange}
				searchValue={this.state.searchText}
				selectedSearchDropdown={this.state.searchOptionValue}
			/>
		)
	}

	buildMainContent() {
		const { loading, error, resultsData, sampleQueue } = this.state

		if (error) {
			return <ErrorMessages errors='Something went wrong on our end. Please try again later' />
		}
		if (resultsData && sampleQueue) {
			return (
				<QCResultsTable
					resultsData={resultsData}
					sampleQueue={sampleQueue}
					currentPage={this.currentPage}
					pageSize={pageSize}
					sortProperty={this.sort.property}
					sortOrder={this.sort.order}
					onHeaderCellClick={this.onHeaderCellClick}
					onRowClick={this.onRowClick}
					onRowActionClick={this.onRowActionClick}
					onPageChange={this.onPageChange}
				/>
			)
		}
		if (!loading) {
			return <ErrorMessages errors='We were unable retrieve any samples. Please try again later' />
		}

		return null
	}

	render() {
		return (
			<div>
				<section id='mainContent' className='app light'>
					<AppHeader title={<h1>{constants.QC.LIST.HEADER_TITLE}</h1>} breadcrumb={QCResultsBreadcrumb()} />
					<section className='app light'>
						{this.buildSearchBar()}
						{this.buildMainContent()}
						{this.buildCertusModal()}
						{this.buildDeleteModal()}
					</section>
				</section>
			</div>
		)
	}
}

QCResults.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired
	}).isRequired
}

export default QCResults
