import React from 'react'
import Cards from 'react-credit-cards'
import { Button, Modal, Grid, Form, Input, Message, Icon, Segment, Checkbox, Label } from 'semantic-ui-react'
import 'react-credit-cards/es/styles-compiled.css'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import {
	formatCreditCardNumber,
	formatCVC,
	formatExpirationDate
} from '../../lib/ccValidation'
import axios from '../../axios'

class PaymentForm extends React.Component {
	constructor(props) {
		super(props)
		this.defaultState = {
			confirmedOne: false,
			number: '',
			name: '',
			expiry: '',
			cvc: '',
			issuer: '',
			focused: '',
			formData: null,
			card_errors: null,
			card_success: null,
			card_hash: false,
			fullTerms: false,
			captcha: false,
			errors: {}
		}
		this.state = this.defaultState
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			if (this.props.certusBankInfo && this.props.certusBankInfo.card) {
				this.setState({ card_hash: this.props.certusBankInfo.card })
			}
		}
	}

	handleCallback = ({ issuer }, isValid) => {
		if (isValid) {
			this.setState({ issuer })
		}
	}

	handleInputFocus = ({ target }) => {
		this.setState({
			focused: target.name
		})
	}

	handleInputChange = ({ target }) => {
		if (target.name === 'number') {
			target.value = formatCreditCardNumber(target.value)
		}
		else if (target.name === 'expiry') {
			target.value = formatExpirationDate(target.value)
		}
		else if (target.name === 'cvc') {
			target.value = formatCVC(target.value)
		}

		this.setState({ [target.name]: target.value })
	}

	getErrors = () => {
		const errors = {}
		if (Object.entries(this.state.number).length === 0) {
			errors.number = 'Credit Card cannot be blank.'
		}

		if (Object.entries(this.state.expiry).length === 0) {
			errors.expiry = 'Expiration cannot be blank.'
		}

		if (Object.entries(this.state.cvc).length === 0) {
			errors.cvc = 'CVC cannot be blank.'
		}

		if (this.state.captcha === false) {
			errors.captcha = 'Prove you are human.'
		}

		if (this.state.confirmedOne === false) {
			errors.confirmedOne = true
		}

		this.setState({ errors })
		return errors
	}

	createCreditCard = () => {
		// tokenize(seperate month and year) expry_date
		const month = this.state.expiry.split('/')[0]
		const year = this.state.expiry.split('/')[1]
		// CreditCard Info must be concealed in this manner
		// https://developer.intuit.com/app/developer/qbpayments/docs/workflows/create-tokens

		const params = {
			tokenized_form: {
				tokenize_cc_number: this.state.number,
				tokenize_cc_expmonth: month,
				tokenize_cc_expyear: year,
				tokenize_cc_cvc: this.state.cvc
			}
		}

		axios().post(`${process.env.REACT_APP_API_ROOT}/yourcards`, params).then((response) => {
			if (response.data.errors) {
				this.setState({ card_errors: response.data.errors.error })
			}
			else if (response.data) {
				this.setState({ card_success: 'Card Saved Successfully', card_errors: null }),
				location.reload()
			}
			else {
				this.setState({ card_errors: 'Something went wrong, please try again' })
			}
		})
	}

	handleSubmit = (e) => {
		const errors = this.getErrors()
		const noErrorsPresent = (Object.keys(errors).length === 0)
		if (noErrorsPresent) {
			this.createCreditCard()
			e.preventDefault()
		}
	}

	deleteCard = (orgId) => axios().delete(`${process.env.REACT_APP_API_ROOT}/yourcards/${orgId}`).then((response) => {
		location.reload()
	})

	handleDelete = (e) => {
		e.preventDefault()
		const orgId = this.props.user.organization.id
		this.deleteCard(orgId)
		this.props.closeModal()
	}

	cancelModal = () => {
		this.setState(this.defaultState)
		this.props.closeModal()
	}

	reload = (e) => {
		e.stopPropagation()
		this.props.closeModal()
	}

	captchaChange = (value) => {
		const params = { code: value }
		axios().post(`${process.env.REACT_APP_API_ROOT}/recaptcha/show`, params).then((response) => {
			if (response.data === true) {
				this.setState({ captcha: true })
			}
		})
	}

	captchaExpire = (value) => {
		this.setState({ captcha: false })
	}

	render() {
		const { name, number, expiry, cvc, focused, issuer } = this.state
		const cardEntryForm = (
			<Grid columns={2} stackable>
				<Grid.Column>
					<Cards
						number={number}
						name={name}
						expiry={expiry}
						cvc={cvc}
						focused={focused}
						callback={this.handleCallback}
					/>
				</Grid.Column>
				<Grid.Column>
					<Grid.Row>
						<Form
							id='tokenized_form' ref={(c) => (this.form = c)}
							onSubmit={this.handleSubmit}
							size='big'
							className='attached fluid segment'
						>
							<Form.Input
								type='text'
								name='name'
								className='form-control'
								placeholder='Name'
								required
								onChange={this.handleInputChange}
								onFocus={this.handleInputFocus}
							/>

							<Form.Input
								type='tel'
								name='number'
								className='form-control'
								placeholder='Card Number'
								pattern='[\d| ]{16,22}'
								required
								onChange={this.handleInputChange}
								onFocus={this.handleInputFocus}
								error={this.state.errors && this.state.errors.number && {
									content: this.state.errors.number
								}}
							/>

							<Form.Input
								type='tel'
								name='expiry'
								className='form-control'
								placeholder='Valid Thru'
								pattern='\d\d/\d\d'
								required
								onChange={this.handleInputChange}
								onFocus={this.handleInputFocus}
								error={this.state.errors && this.state.errors.expiry && {
									content: this.state.errors.expiry
								}}
							/>
							<Form.Input
								type='tel'
								name='cvc'
								className='form-control'
								placeholder='CVC'
								pattern='\d{3,4}'
								required
								onChange={this.handleInputChange}
								onFocus={this.handleInputFocus}
								error={this.state.errors && this.state.errors.cvc && {
									content: this.state.errors.cvc
								}}
							/>
							<Input type='hidden' name='issuer' value={issuer} />
						</Form>
						{this.props && this.props.certusBankInfo
							&& (
								<Message
									attached='bottom'
									warning
								>
									<Message.Header>
										Auto Reload Details
									</Message.Header>
									<Grid>
										<Grid.Row columns={2} className='topRowPaymentForm'>
											<Grid.Column className='paymentItem'>Region: </Grid.Column>
											<Grid.Column>{this.props.certusBankInfo.region} </Grid.Column>
										</Grid.Row>
										<Grid.Row columns={2} className='paymentForm'>
											<Grid.Column className='paymentItem'>Jurisdiction: </Grid.Column>
											<Grid.Column>{this.props.certusBankInfo.juri}</Grid.Column>
										</Grid.Row>
										<Grid.Row columns={2} className='paymentForm'>
											<Grid.Column className='paymentItem'>Token Price: </Grid.Column> <Grid.Column>{this.props.certusBankInfo.token_price} (USD)</Grid.Column>
										</Grid.Row>
										<Grid.Row columns={2} className='paymentForm'>
											<Grid.Column className='paymentItem'>Auto Reload Quantity: </Grid.Column> <Grid.Column> {this.props.certusBankInfo.bulk_quantity}</Grid.Column>
										</Grid.Row>
										<Grid.Row columns={2} className='paymentForm'>
											<Grid.Column className='paymentItem'>Auto Reload Total: </Grid.Column> <Grid.Column>{this.props.certusBankInfo.bundle_price} (USD)</Grid.Column>
										</Grid.Row>
										<Grid.Row textAlign='center' style={{padding: '1rem 1rem 0 1rem'}}>
											<Segment size='large' color={this.state.errors.confirmedOne ? 'red' : 'green'}>
												<Checkbox
													label='I agree to the Terms and Conditions.'
													onChange={() => this.setState({ confirmedOne: true })}
												/>
												<a
													className='import-full-terms'
													onClick={() => this.setState({ fullTerms: !this.state.fullTerms })}
													style={{cursor: 'pointer'}}
												>
													{' '}<Icon name='file alternate outline' />{this.state.fullTerms ? 'Hide Terms' : 'Full Terms'}
												</a>
												{this.state.fullTerms
													&& (
														<p>
															I authorize Green Ocean Sciences to charge the submitted credit card for the above purchase.
															I understand that upon the token bank reaching zero, the credit card will automatically be charged refilling the balance to {this.props.certusBankInfo.quantity} tokens for the price of {this.props.certusBankInfo.bundle_price} (USD).
															I understand that my information will be saved to file for future transactions on my account.
														</p>
												)}
											</Segment>
										</Grid.Row>
										<Grid.Row style={{padding: 0}}>
											<Segment basic>
												<ReCAPTCHA
													sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
													theme='light'
													onChange={this.captchaChange}
													onExpired={this.captchaExpire}
												/>
												{this.state.errors.captcha
													&& (
														<Label as='a' basic color='red' pointing>
															{this.state.errors.captcha}
														</Label>
													)}
											</Segment>

										</Grid.Row>
									</Grid>
								</Message>
							)
						}
					</Grid.Row>
					<Grid.Row>
						{this.state.card_errors
							&& (
								<Message compact negative='true' size='small'>
									{this.state.card_errors}
								</Message>
							)}
						{this.state.card_success && <Message compact success='true' size='small' color='green'>{this.state.card_success}</Message>}
					</Grid.Row>
				</Grid.Column>
			</Grid>
		)
		const cardEntryButtons = (
			<div>
				<Button
					type='submit'
					inverted
					color='green'
					onClick={this.handleSubmit}
				>
					Submit Credit Card Details
				</Button>
			</div>
		)

		const cardOnFile = (
			<Grid stackable columns={2}>
				<Grid.Column>
					<Message
						size='large'
						positive
						style={{ margin: '0 auto' }}
					>
						<Message.Header>Hidden Number</Message.Header>
						<p>
							<Icon disabled name='credit card outline' />  {this.state.card_hash}
						</p>
					</Message>
				</Grid.Column>
				{ this.props && this.props.certusBankInfo &&
					<Grid.Column>
							<p><strong>Region:</strong> {this.props.certusBankInfo.region}</p>
							<p><strong>Jurisdiction:</strong> {this.props.certusBankInfo.juri}</p>
							<p><strong>Token Price:</strong> {this.props.certusBankInfo.token_price} (USD)</p>
							<p><strong>Auto Reload Quantity:</strong> {this.props.certusBankInfo.bulk_quantity}</p>
							<p><strong>Auto Reload Total:</strong> {this.props.certusBankInfo.bundle_price} (USD)</p>
					</Grid.Column>
				}
			</Grid>
		)
		const cardOnFileButtons = (
			<div>
				<span style={{ float: 'left', lineHeight: '2.5rem' }}>
					To edit Card on File, Delete and then re-enter new Credit Card details.
				</span>
				<Button
					color='red'
					className='ui button'
					onClick={(e) => { this.handleDelete(e) }}
				>
					Delete
				</Button>
			</div>
		)

		const cardOnFileHeader = (<div>Card on File</div>)
		const cardOnEntryHeader = (<div>Enter Card Details</div>)
		let display
		let buttons
		let headerDetails
		if (this.state.card_hash) {
			display = cardOnFile
			buttons = cardOnFileButtons
			headerDetails = cardOnFileHeader
		}
		else {
			display = cardEntryForm
			buttons = cardEntryButtons
			headerDetails = cardOnEntryHeader
		}

		return (
			<Modal
				size='large'
				onOpen={() => setOpen(true)}
				open={this.props.open}
				onClose={(e) => { this.reload(e), this.cancelModal() }} closeIcon
			>
				<Modal.Header>
					<div>{headerDetails}</div>
				</Modal.Header>
				<Modal.Content>
					<Grid divided='vertically'>
						<Grid.Row>
							{display}
						</Grid.Row>
					</Grid>
				</Modal.Content>
				<Modal.Actions>
					{buttons}
				</Modal.Actions>
			</Modal>
		)
	}
}
const mapStateToProps = (state) => ({
	user: state.current_user,
	certusBankInfo: state.certusBankInfo
})

PaymentForm.propTypes = {
	certusBankInfo: PropTypes.shape({ card: PropTypes.string,
		quantity: PropTypes.number }),
	user: PropTypes.shape({
		organization: PropTypes.shape({
			id: PropTypes.number
		})
	}),
	closeModal: PropTypes.func,
	open: PropTypes.bool
}

export default connect(
	mapStateToProps
)(withRouter(PaymentForm))
