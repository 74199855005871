import React, { Component } from 'react'
import vialTypes from '../lib/vialTypes'
import classMerge from 'classnames'

class AddPreparedSample extends Component {

  constructor(props) {
    super(props)
    this.state = {
      vial_type: 'green_1ml',
      label: '',
      dilution_factor: '',
      acquire: true,
      errors: {}
    }
  }

  updatePreppedSample = (e) => {
    let value = e.target.value
    let name = e.target.name
    let stateHash = {}
    stateHash[name] = value
    this.setState(stateHash)
  }

  done = (e) => {
    this.setErrors((e) => {
      if (!Object.keys(this.state.errors).length) {
        this.props.addPreppedSample(this.state)
      }
    })
  }

  setErrors = (cb) => {
    if (this.state.dilution_factor == '') {
      this.setState({errors: {dilution_factor: "Dilution Factor Required"}}, cb)
    } 
    else if (parseFloat(this.state.dilution_factor) === 0) {
      this.setState({errors: {dilution_factor: "Dilution Factor cannot equal 0"}}, cb)
    }
    else if (isNaN(this.state.dilution_factor)) {
      this.setState({errors: {dilution_factor: "Dilution Factor must be a number"}}, cb)
    }
    else {
      this.setState({errors: {}}, cb)
    }
  }

  render() {
    let vial_types = Object.keys(vialTypes)
    let colorDom = vial_types.map((type) => {
      return <button
        key={type}
        className="setColor"
        name="vial_type"
        value={type}
        disabled={this.state.vial_type == type}
        onClick={this.updatePreppedSample}
        style={{backgroundColor: vialTypes[type].color}}
      />
    })
    return (
      <div className="addPreparedSample">
        <div className={classMerge('color', {error: this.state.errors.vial_type})}>
          {colorDom}
        </div>
        <div className={classMerge('label', {error: this.state.errors.label})}>
          <input name="label" value={this.state.label} onChange={this.updatePreppedSample} type="text" placeholder="Label" />
        </div>
        <div className={classMerge('dilutionFactor', {error: this.state.errors.dilution_factor})}>
          <input error={this.state.errors.dilution_factor} name="dilution_factor" value={this.state.dilution_factor} onChange={this.updatePreppedSample} type="text" placeholder="Dilution" />
          {this.state.errors.dilution_factor && <p>{this.state.errors.dilution_factor}</p>}
        </div>
        <div className="doneButton" onClick={this.done}>
          <button>Add</button>
        </div>
      </div>
    )
  }

}

export default AddPreparedSample
