import React  from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import constants from '../../lib/constants'
import dataToggle from '../../lib/dataToggle'
import { Grid } from 'semantic-ui-react'
class LineChartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
  }
  componentDidMount() {
  }

  prepareLineChartOptions = (data, config) => {
    let unit = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? ' %' : ''
    let unitLabel = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.TEXT : constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.TEXT
    let categories = []
    let parsedObject = []
    try {
      if (this.props.config.switchAxes)
        data = dataToggle(data)
      Object.keys(data).forEach(function (date) {
        categories.push(date)
      })
      let analytes = []
      for (let date in data) {
        analytes.push(data[date])
      }
      let analyte = Object.keys(analytes[0])
      let n = analyte.length
      let dataRows = []
      for (let i = 0; i < n; i++) {
        if (analyte[i]) {
          let dataRow = analytes.map((value) => value[analyte[i]])
          let dataRowFinal = { [analyte[i]]: dataRow }
          dataRows.push(dataRowFinal)
        }
      }
      for (let key in dataRows) {
        let dataRowKeys = Object.keys(dataRows[key])
        let dataRowValues = Object.values(dataRows[key])
        parsedObject.push({ "name": dataRowKeys[0], "data": dataRowValues[0] })
      }
    } catch (error) {
    }
    let options = {
      chart: {
        type: 'line',
        backgroundColor: 'transparent'
      },
      title: false,
      xAxis: {
        title: false,
        categories: categories
      },
      yAxis: {
        title: {
          text: unitLabel
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toFixed(2) + unit
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          let formatStr = '<span>' + this.x + '</span><br><span style="color:' + this.point.color + '">●</span> ' + this.point.series.name + ': <b>' + this.point.y.toFixed(2) + unit + '</b><br/>'
          return formatStr
        }
      },
      series: parsedObject
    }
    return options
  }
  render() {
    return (
      <React.Fragment>
        {!this.state.error &&
          <HighchartsReact
            highcharts={Highcharts}
            options={this.prepareLineChartOptions(this.props.data, this.props.config)}
          />
        }
        {this.state.error &&
          <Grid centered>
            <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12} textAlign='center'>
              <div id="runStatus">
                {constants.ERROR.SOMETHING_WENT_WRONG}
              </div>
            </Grid.Column>
          </Grid>
        }
      </React.Fragment>
    )
  }
}
export default LineChartComponent
