import React, { Component } from 'react'
import {
	Breadcrumb, Image, Label, Table, List, Grid, Icon, Menu, Popup
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import cannidAPI from '../../cannidAPI/client'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import certusLogoGreen from '../../images/certus_transparent.png'
import QrCodeDownload from './QrCodeDownload'

const QRCode = require('qrcode.react')

class ProjectCertus extends Component {
	constructor(props) {
		super(props)
		this.state = {
			certus: {},
			h1: 'VERISEAL Project',
			apiErrors: {},
			printingPdfReport: false
		}
		this.mounted = false
	}

	componentDidMount() {
		this.mounted = true
		// get individual project certus
		cannidAPI.get(`/project_certi/${this.props.match.params.id}`).then((response) => {
			if (this.mounted) {
				const certus = response.data
				this.setState({ certus, h1: certus.project_name })
			}
		}).catch((err) => {
			console.log('error!', err)
			const message = `${err}. Failed to retrieve certus.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	componentWillUnmount() {
		this.mounted = false
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/certus'>
					<Breadcrumb.Section>
						<div style={{ display: 'flex' }}>
							CERTUS
							<Icon name='registered outline' size='tiny' />
						</div>
					</Breadcrumb.Section>
				</Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>
					Project:
					{this.state.h1}
				</Breadcrumb.Section>
			</Breadcrumb>
		)

		const today = new Date()
		const projectExpiry = this.state.certus ? this.state.certus.revoked
			? <span style={{color:"red"}}>Revoked</span> : (new Date(this.state.certus.expiration_date) < today.getTime())
				? <span style={{color:"red"}}>{this.state.certus.expiration_date}</span> : this.state.certus.expiration_date : ''
		const certusStatus = this.state.certus ? this.state.certus.revoked
			? <span style={{color:"red"}}>Revoked</span> : (new Date(this.state.certus.expiration_date) < today.getTime())
				? <span style={{color:"red"}}>Expired</span> : <span>Valid</span> : ""

		return (
			<section id='mainContent' className='app light certusList'>
				<ErrorMessages errors={this.state.apiErrors} />
				<AppHeader title={<h1>{this.state.h1}</h1>} breadcrumb={breadcrumb} />

				{this.state.certus.id && (
					<div>
						<section className='certusListHeader'>
							<Grid stackable verticalAlign='middle'>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Grid columns={2} stackable verticalAlign='middle' className='descriptorContainer'>
											<Grid.Column>
												<div className='descriptorGrid'>
													<Image className='certusLogo' src={certusLogoGreen} alt='Certus Logo' />
													<h3 style={{ display: 'flex', marginTop: 0, marginLeft: '1rem' }}>VERISEAL Project</h3>
												</div>
											</Grid.Column>
											<Grid.Column>
												<div className='descriptorGrid'>
													<a className='certusScannerLink' href={process.env.REACT_APP_CERTUS_SCANNER_LINK}>
														<QRCode value={process.env.REACT_APP_CERTUS_SCANNER_LINK} size={75} />
														<Label color='green'>
															CERTUS
															<Icon
																style={{ position: 'relative', bottom: '.35rem' }}
																name='registered outline' size='tiny' />
															QR Scanner
														</Label>
													</a>
												</div>
											</Grid.Column>
										</Grid>
										<List className='detailsList'>
											<List.Item style={{ display: 'flex' }}>
												<strong style={{ display: 'flex' }}>TRACER:</strong>
												&nbsp;{this.state.certus.share_key}
											</List.Item>
											<List.Item>
												<strong>Expiration Date:</strong>
												{' '}
												{projectExpiry}
											</List.Item>
											<List.Item>
												<strong>Status:</strong>
												{' '}
												{certusStatus}
											</List.Item>
											<List.Item>
												<strong>Issued:</strong>
												{' '}
												{dateTimeFormatter(this.state.certus.created_at)}
											</List.Item>
											<List.Item>
												<strong>Created By:</strong>
												{' '}
												{this.state.certus.user.name}
											</List.Item>
											{ this.state.certus.details
												? (<List.Item>
														<strong>Description:</strong>
														{' '}
														{this.state.certus.details.project_description}
													</List.Item>
												)
												: '' }
											<List.Item>
												<strong>Project Type:</strong>
												{' '}
												{this.state.certus.project_type}
											</List.Item>
											<List.Item>
												<strong>VERISEAL Contents Count:</strong>
												{' '}
												{this.state.certus.children.length}
											</List.Item>
										</List>
									</Grid.Column>

									<Grid.Column>
										<Grid columns={2} stackable verticalAlign='middle'>
											{' '}
											<Grid.Column>
												<QRCode value={this.state.certus.qr[0]} size={200} />
												<div>
													<span>
														<small style={{ backgroundColor: 'black', color: 'white',   paddingTop: '.4em',paddingBottom: '.2em', letterSpacing: '.7em', position:'relative',  fontSize: '1.03em', fontWeight: 600 }}>
														<img width={19} height={18} style={{marginRight: '4px', marginLeft: '4px'}} src="https://cdn.chemid.com/images/chemid-logo-image.png" alt="chemid.logo"/>
														{this.state.certus.share_key}</small>
													</span>
													<p>
														<small>
															Result protected with CERTUS
															<Icon style={{ position: 'relative', bottom: '.35rem' }} name='registered outline' size='tiny' /> 
															KSI Blockchain
														</small>
													</p>
													<Popup
														content='Copied TRACER!'
														on='click'
														pinned
														trigger={
															<Menu className='sampleActionsMenu' compact={true} stackable>
																<Popup
																	content='Copy TRACER'
																	trigger={
																		<Menu.Item
																			onClick={() => { navigator.clipboard.writeText(this.state.certus.share_key) }}>
																			<Icon name='copy' style={{margin: 0}} />
																		</Menu.Item>
																	}
																/>
															</Menu>
														}
													/>
												</div>
											</Grid.Column>

											<Grid.Column>
												<QrCodeDownload tracer={this.state.certus.share_key} manifestOrProject />
											</Grid.Column>
										</Grid>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</section>

						<section className='certusSelectorTable'>
							<Table celled>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>Name</Table.HeaderCell>
										<Table.HeaderCell>VERISEAL</Table.HeaderCell>
										<Table.HeaderCell>TRACER</Table.HeaderCell>
										<Table.HeaderCell>Expiration</Table.HeaderCell>
										<Table.HeaderCell>Created Date</Table.HeaderCell>
										<Table.HeaderCell>Created By</Table.HeaderCell>
										<Table.HeaderCell>Status</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{this.state.certus.children.map((certus, index) => {
										// children of projects can only be sample certus.
										const link = `/sample/${certus.sample.id}/results`
										const expiry_date = Date.parse(certus.expiration_date)

										const status = certus.revoked
											? <Table.Cell negative>Revoked</Table.Cell>	: (today.getTime() > expiry_date)
												? <Table.Cell negative>Expired</Table.Cell>
												: <Table.Cell>Valid</Table.Cell>

										const expirationDate = certus.revoked
											? <Table.Cell negative>Revoked</Table.Cell>	: (today.getTime() > expiry_date)
												? (
													<Table.Cell negative>
														Expired:&nbsp;
														{certus.expiration_date}
													</Table.Cell>
												)
												: <Table.Cell>{certus.expiration_date}</Table.Cell>

										return (
											<Table.Row className='certusListRow' key={`certus${index}`} onClick={() => this.props.history.push(link)}>
												<Table.Cell>{certus.project_name}</Table.Cell>
												<Table.Cell>{certus.certus_type.certus_type}</Table.Cell>
												<Table.Cell>{certus.share_key}</Table.Cell>
												{expirationDate}
												<Table.Cell>{dateTimeFormatter(certus.created_at)}</Table.Cell>
												<Table.Cell>{certus.user.name}</Table.Cell>
												{status}
											</Table.Row>
										)
									})}
								</Table.Body>
							</Table>
						</section>
					</div>
				)}
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

export default connect(
	mapStateToProps
)(withRouter(ProjectCertus))
