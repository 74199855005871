import React, { Component } from 'react'
import AppHeader from './AppHeader'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { APPEND_NOTIFICATIONS_LOG } from '../actions'
import { Grid, Message, Breadcrumb, Input, Button, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ErrorMessages from './Errors/ErrorMessages'
import SampleInfo from './SampleInfo'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({
  appendNotificationsLog: (notification) => { dispatch({ type: APPEND_NOTIFICATIONS_LOG, notification: notification }) }
})

class ChemstationImport extends Component {
  constructor(props) {
    super(props)
    this.chartContainer = React.createRef()
    this.state = {
      sample: null,
      csData: {},
      filterData: {},
      filterDate: '',
      filterText: '',
      apiErrors: {},
      apiNotice: null
    }
    this.sampleId = parseInt(this.props.match.params.id, 10)
    this.resultsLink = `/sample/${this.sampleId}/results`
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)

    // get the sample from the api
    this.retrieveSample()
    
    // get the local chemstation runs
    fetch(process.env.REACT_APP_DRIVER_HTTP_ROOT + "/chemstation/runs").then((res) => {
      res.json().then((json) => {
        console.log('we have the runs ):')
        // console.log(json)
        this.setState({csData: json, filterData: json})
      })
    })
  }

  componentDidUpdate() {
    if (document.getElementsByName('filterRunsDate')[0]) {
      document.getElementsByName('filterRunsDate')[0].placeholder='Filter Date'
      document.getElementsByName('filterRunsDate')[0].autocomplete='off'
    }
  }

  retrieveSample = () => {
    const sampleRequest = new Request(process.env.REACT_APP_API_ROOT + '/samples/' + this.sampleId, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      })
    })
    fetch(sampleRequest).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response && response.statusText)
      }
      return response.json()
    }).then((sample) => {
      this.setState({sample: sample})
    })
  }

  importRunFromChemstation = (csFolder) => {
    document.body.classList.add('loading-indicator')
    fetch(process.env.REACT_APP_DRIVER_HTTP_ROOT + "/chemstation/runs/" + encodeURI(csFolder)).then((res) => {
      res.json().then((results) => {
        let runMeta = results.runMeta || {}
        let appendData = {
          csDataFolder: runMeta.data_directory,
          csDataName: runMeta.data_file_name,
          results: results.amounts,
          machineName: localStorage.getItem('machine'),
          chemstationImported: true
        }

		if (results.invalid) appendData = {...appendData, invalid: results.invalid}
        Object.keys(results.runMeta).forEach((key) => {
          if (key !== 'data_directory' && key !== 'data_file_name') {
            appendData[key] = results.runMeta[key]
          }
        })

        const request = new Request(process.env.REACT_APP_API_ROOT + '/samples/' + this.state.sample.id + '/append_results', {
          method: 'POST',
          body: JSON.stringify({ result: appendData }),
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
          })
        })

        const resultIndex = this.state.sample.results ? this.state.sample.results.length : 0
        const acquire = this.state.sample.prepared_samples.filter((prep) => {return prep.acquire === true})
        return fetch(request).then((response) => {
          document.body.classList.remove('loading-indicator')
          if (response.status < 200 || response.status >= 300) {
            throw(response)
          }
          return response.json()
        }).then((json) => {
          if (json.message && json.notification) {
            this.props.appendNotificationsLog(json.message)
          }
          if ((resultIndex + 1) === acquire.length) {
            this.props.history.push(this.resultsLink)
          } else {
            this.retrieveSample()
            this.setState({apiErrors: {}, apiNotice: json.message ? json.message : null})
            window.scrollTo(0, 0)
          }
        }).catch((error) => {
          console.log("Error while trying to append results to sample.", error.statusText)
          error.json().then((err) => {
            const preppedVial = acquire[resultIndex]
            const vialName = `${preppedVial["label"] || _.capitalize(preppedVial["vial_type"].split("_")[0]) + ' Vial'}`
            const message = err.message ? err.message : `There was an error trying to Import Chemstation Run for sample ${this.state.sample.id} - ${vialName}`
            this.props.appendNotificationsLog(message)
            this.setState({apiErrors: message, apiNotice: null})
        });
        })
      })
    })
  }

  filterCsData = (value) => {
    const filtered = {}
    Object.keys(this.state.csData).forEach((sampleFolder, index) => {
      // ignore api none/nada fillers and keep if sampleFolder matches filter value
      if (sampleFolder !== 'none' &&
        sampleFolder !== 'nada' &&
        sampleFolder.toLowerCase().includes(value)) {
          // if sampleFolder matches then keep all seq & runs
          filtered[sampleFolder] = this.state.csData[sampleFolder]
      } else {
        this.state.csData[sampleFolder].forEach((seq, seqIdx) => {
          // if seq or run folder matches, keep only that nested run
          const currentSampleFolders = Object.keys(filtered)
          if (seq.runFolder.toLowerCase().includes(value)) {
            if (currentSampleFolders.includes(sampleFolder)) {
              filtered[sampleFolder].push({...this.state.csData[sampleFolder][seqIdx]})
            } else {
              filtered[sampleFolder] = [{...this.state.csData[sampleFolder][seqIdx]}]
            }
          } else if (seq.sequenceFolder !== 'nada' && seq.sequenceFolder.toLowerCase().includes(value)) {
            if (currentSampleFolders.includes(sampleFolder)) {
              filtered[sampleFolder].push({...this.state.csData[sampleFolder][seqIdx]})
            } else {
              filtered[sampleFolder] = [{...this.state.csData[sampleFolder][seqIdx]}]
            }
          }
        })
      }
    })
    this.setState({filterData: filtered})
  }

  render() {
    const breadcrumb = (
      <Breadcrumb>
        <Link to="/"><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to="/test-result/list"><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={this.resultsLink}><Breadcrumb.Section>{this.state.sample ? this.state.sample.intake_form.strain_name : 'Sample'}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>Import Chemstation Run</Breadcrumb.Section>
      </Breadcrumb>
    )

    const sampleFolderList = Object.keys(this.state.filterData).sort()
    const noneIndex = sampleFolderList.indexOf('none')
    const nadaIndex = sampleFolderList.indexOf('nada')
    if (noneIndex > -1) {
      sampleFolderList.splice(noneIndex, 1)
      sampleFolderList.push('none')
    }
    if (nadaIndex > -1) {
      sampleFolderList.splice(nadaIndex, 1)
      sampleFolderList.push('nada')
    }

    let suggested
    const csDataItems = sampleFolderList.map((sampleFolder, index) => {

      const folderSplit = sampleFolder.split("_")
      let sampleId = folderSplit[folderSplit.length - 1]
      if (sampleId && this.sampleId == sampleId && !suggested) {
        suggested = (
          <Message color='violet' key='suggested'>
            <Message.Header>SUGGESTED: {sampleFolder}</Message.Header>
            <Grid columns='equal' divided style={{margin:'0 auto'}}>
              <Grid.Row columns='3'>
                {this.state.filterData[sampleFolder].map((seq, seqIdx) => {
                  const thePath = `${sampleFolder}/${seq.sequenceFolder}/${seq.runFolder}`
                  return (
                    <Grid.Column key={'suggested' + seq.sequenceFolder + seq.runFolder + seqIdx}>
                      <a style={{cursor: 'pointer'}} onClick={() => this.importRunFromChemstation(thePath)}>
                        <strong>({seqIdx + 1})</strong> {seq.sequenceFolder} <strong>|</strong> <strong>{seq.runFolder}</strong>
                      </a>
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          </Message>
        )
        return
      }

      if (sampleFolder !== 'none' && sampleFolder !== 'nada') {
        return (
          <Grid.Column key={sampleFolder+index}>
            <Message>
              <Message.Header style={{marginBottom: '5px'}}>{sampleFolder}</Message.Header>
              <Message.Content>
                {this.state.filterData[sampleFolder].map((seq, seqIdx) => {
                  const thePath = `${sampleFolder}/${seq.sequenceFolder}/${seq.runFolder}`
                  return (
                    <div key={seq.sequenceFolder + seq.runFolder + seqIdx} style={{paddingBottom: '5px'}}>
                      <a style={{cursor: 'pointer'}} onClick={() => this.importRunFromChemstation(thePath)}>
                        <strong>({seqIdx + 1})</strong> {seq.sequenceFolder} <strong>|</strong> <strong>{seq.runFolder}</strong>
                      </a>
                    </div>
                  )
                })}
              </Message.Content>
            </Message>
          </Grid.Column>
        )
      } else if (sampleFolder === 'none') {
        return (
          <Message key={sampleFolder+index} style={{width: '100%', margin: '1rem'}}>
            <Message.Header>NO SAMPLE GROUP FOLDER - Loose Sequences in /Data root</Message.Header>
            <Grid columns='equal' divided style={{margin:'0 auto'}}>
              <Grid.Row columns='3' style={{borderBottom:'1'}}>
                {this.state.filterData[sampleFolder].map((seq, seqIdx) => {
                  const thePath = `${seq.sequenceFolder}/${seq.runFolder}`
                  return (
                    <Grid.Column key={seq.sequenceFolder + seq.runFolder + seqIdx} style={{paddingBottom: '5px'}}>
                      <a style={{cursor: 'pointer'}} onClick={() => this.importRunFromChemstation(thePath)}>{thePath}</a>
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          </Message>
        )
      } else if (sampleFolder === 'nada') {
        return (
          <Message key={sampleFolder+index} style={{width: '100%', margin: '1rem'}}>
            <Message.Header>NO SAMPLE GROUP OR SEQUENCE FOLDER - Loose Runs in /Data root</Message.Header>
            <Grid columns='equal' divided style={{margin:'0 auto'}}>
              <Grid.Row columns='3' style={{borderBottom:'1'}}>
                {this.state.filterData[sampleFolder].map((seq, seqIdx) => {
                  const thePath = `${seq.runFolder}`
                  return (
                    <Grid.Column key={seq.sequenceFolder + seq.runFolder + seqIdx} style={{paddingBottom: '5px'}}>
                      <a style={{cursor: 'pointer'}} onClick={() => this.importRunFromChemstation(thePath)}>{thePath}</a>
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          </Message>
        )
      } else {
        console.log('err. uh, oh! how is this possible?', sampleFolder)
      }
    })

    let vialName = ''
    if (this.state.sample) {
      const resultIndex = this.state.sample.results ? this.state.sample.results.length : 0
      const preppedVial = this.state.sample.prepared_samples.filter((prep) => {return prep.acquire === true})[resultIndex]
      vialName = `${preppedVial["label"] || _.capitalize(preppedVial["vial_type"].split("_")[0]) + ' Vial'}`
    }

    const pageContent = (this.state.sample &&
      this.state.sample.results &&
      (this.state.sample.results.length >= this.state.sample.prepared_samples.filter((ps) => (ps.acquire)).length)) ? (
      <div style={{margin: '0 auto', width: '80%'}}>
        <Message warning style={{marginTop: '2rem'}}>
          <Message.Header>Results exist for all injections.</Message.Header>
          <Message.Content style={{padding: '8px'}}>
            <Link to={this.resultsLink}>View the test results for {this.state.sample ? this.state.sample.intake_form.strain_name : 'Sample'}</Link>
            </Message.Content>
        </Message>
      </div>
    ) : (
      <div style={{margin: '2rem'}}>
        {vialName && <h3><i>Select result for</i> <span style={{textDecoration: 'underline'}}>{vialName}</span></h3>}
        <div className='filterRunsContainer'>
          {!this.state.filterDate &&
            <Input
              value={this.state.filterText}
              placeholder='Filter Text'
              name='filterRunsText'
              onChange={(event, input) => {
              this.setState({filterText: input.value, filterDate: ''})
              this.filterCsData(input.value.toLowerCase())
              }}
            />
          }
          {!this.state.filterText &&
            <DatePicker
              selected={this.state.filterDate}
              name='filterRunsDate'
              maxDate={Date.now()}
              startDate={Date.now()}
              onSelect={(e, i) => {
                this.setState({filterText: '', filterDate: e})
                this.filterCsData(e.toISOString().split('T')[0])
              }}
            />
          }
          {(this.state.filterText || this.state.filterDate) &&
            <Popup content='Clear Filter'
              trigger={<Button
                icon='remove'
                color='red'
                onClick={() => {
                  this.setState({filterText: '', filterDate: '', filterData: this.state.csData})
                }}
              />} />
          }
        </div>
        {suggested}
        <Grid columns='2' style={{margin: '1rem'}}>
          {csDataItems}
        </Grid>
      </div>
    )

    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>Import Chemstation Run</h1>} breadcrumb={breadcrumb}/>
          <section className="app light">
            <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
            {this.state.apiNotice && <Message warning>{this.state.apiNotice}</Message>}
            {this.state.sample && 
              <Grid.Column largeScreen={16} computer={16} mobile={16} tablet={16} textAlign='center' className="dataSampleTable">
                <SampleInfo sample={this.state.sample} />
              </Grid.Column>
            }
            {pageContent}
          </section>
       </section>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChemstationImport))
