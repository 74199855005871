import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, Button, Icon } from 'semantic-ui-react'
import constants from '../../../lib/constants'

const headerCellsConfig = [
	{ displayName: 'Batch Number', apiName: 'batch_number', sortable: true },
	{ displayName: 'Sample Name', apiName: 'strain_name', sortable: true },
	{ displayName: 'Sample Weight', apiName: 'sample_weight', sortable: true },
	{ displayName: 'Date Created', apiName: 'created_at', sortable: true },
	{ displayName: 'Actions' }
]

const MACHINE_OFF_STATUSES = ['POWEROFF', 'disconnect']
const mapStateToProps = (state) => ({
	machineState: state.machineState
})

function QCTableHeader({ history, machineState, onHeaderCellClick, sortProperty, sortOrder }) {
	const headerCells = headerCellsConfig.map((headerCellConfig) => {
		const { displayName, apiName, sortable } = headerCellConfig

		let onClick = null
		if (sortable === true) {
			onClick = () => onHeaderCellClick(headerCellConfig)
		}

		let headerCellSorted = null
		if (apiName === sortProperty) {
			if (sortOrder === 'DESC') {
				headerCellSorted = 'descending'
			}
			else if (sortOrder === 'ASC') {
				headerCellSorted = 'ascending'
			}
		}
		return <Table.HeaderCell sorted={headerCellSorted} key={displayName} onClick={onClick}>{displayName}</Table.HeaderCell>
	})

	const onNewQCClick = () => {
		history.push(constants.APPLICATION_ROUTE.SAMPLE.CREATE.ROUTE, { fromQC: true })
	}

	return (
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell colSpan='5' textAlign='right'>
				<Button primary icon labelPosition='left' onClick={onNewQCClick}>
				<Icon name='flask' />
					New Quality Control Test
				</Button>
				</Table.HeaderCell>
			</Table.Row>
			<Table.Row>
				{headerCells}
			</Table.Row>
		</Table.Header>
	)
}

QCTableHeader.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired
	}).isRequired,
	machineState: PropTypes.shape({
		status: PropTypes.string
	}),
	onHeaderCellClick: PropTypes.func.isRequired,
	sortProperty: PropTypes.string.isRequired,
	sortOrder: PropTypes.string.isRequired
}

QCTableHeader.defaultProps = {
	machineState: null
}

export default withRouter(connect(mapStateToProps)(QCTableHeader))
