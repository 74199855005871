import React, { Component } from 'react'
import {
	Breadcrumb, Form
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import ImportDropzone from './ImportDropzone'
import ImportCsvMapperType1 from './ImportCsvMapperType1'
import ImportCsvMapperType2 from './ImportCsvMapperType2'
import ImportControlPanel from './ImportControlPanel'
import cannidAPI from '../../cannidAPI/client'

class ImportCsvMapper extends Component {
	constructor(props) {
		super(props)
		this.unClearable = {
			templateType: undefined,
			templateTypeOptions: []
		}
		this.clearable = {
			templateName: '',
			parsed: undefined,
			fileRef: undefined,
			errors: {},
			apiErrors: {}
		}
		this.state = { ...this.unClearable, ...this.clearable }
	}

	componentDidMount() {
		this.importTemplateTypes()
	}

	importTemplateTypes = () => {
		cannidAPI.get('/import_template_types').then((response) => {
			if (response.status < 200 || response.status >= 300) {
				throw new Error(response.statusText)
			}
			return response.data
		})
		.then((types) => {
			const templateType = types[0].id
			const options = types.map((type) => ({
				key: type.slug,
				text: type.name,
				value: type.id
			}))
			this.setState({ templateTypeOptions: options, templateType })
		})
		.catch((err) => {
			console.error('error!', err)
			const message = `${err}. Could not retrieve template types. Reload page to try again.`
			this.functionalError(message)
		})
	}

	functionalError = (error) => {
		this.setState({ apiErrors: error })
	}

	updateFormInput = (e) => {
		this.setState({ [e.target.name]: e.target.value, errors: {} })
	}

	updateFormSelect = (e, { name, value }) => {
		this.setState({ [name]: value, errors: {} })
	}

	mapperTypeComponent = () => {
		switch (this.state.templateType) {
			case 1:
				return <ImportCsvMapperType1
							parsedCsv={this.state.parsed}
							fileRef={this.state.fileRef}
							templateType={this.state.templateType}
							templateName={this.state.templateName}
							updateFormErrors={(errors) => this.setState({ errors: errors })}
							reset={() => this.setState(this.clearable)} />
			case 2:
				return <ImportCsvMapperType2
							parsedCsv={this.state.parsed}
							fileRef={this.state.fileRef}
							templateType={this.state.templateType}
							templateName={this.state.templateName}
							updateFormErrors={(errors) => this.setState({ errors: errors })}
							reset={() => this.setState(this.clearable)} />
			default:
				return <ErrorMessages errors='Uh oh! Template type error. Please notify support.' />
		}
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/test-result/list'><Breadcrumb.Section>Sample Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/samples/import'><Breadcrumb.Section>Import New Results</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>Map Import CSV Template</Breadcrumb.Section>
			</Breadcrumb>
		)

		return (
			<section id='mainContent' className='app light importCsvMapperPage'>
				<AppHeader title={<h1>Map Import CSV Template</h1>} breadcrumb={breadcrumb} />
				<ErrorMessages errors={this.state.apiErrors} />
				<ImportControlPanel includeTemplates />

				{this.state.parsed &&
					<section className='app light'>
						<div className='mappingForm'>
							<Form>
								<Form.Group>
									<Form.Select
										search
										selection
										label='Template Type'
										value={this.state.templateType}
										name='templateType'
										onChange={this.updateFormSelect}
										className='required-field'
										placeholder='Template Type'
										error={this.state.errors.templateType}
										options={this.state.templateTypeOptions}
									/>
									<Form.Input
										label='Template Name'
										value={this.state.templateName}
										name='templateName'
										onChange={this.updateFormInput}
										type='text'
										className='required-field'
										placeholder='Template Name'
										error={this.state.errors.templateName}
										maxLength={50}
									/>
								</Form.Group>
							</Form>
						</div>
					</section>
				}

				<div className='displayContainer'>
					{!this.state.parsed &&
						<ImportDropzone
							addParsed={(parsed, file) => this.setState({ parsed: parsed, fileRef: file })}
							functionalError={this.functionalError}
						/>
					}
					{(this.state.parsed && this.state.templateType) && this.mapperTypeComponent()}
				</div>
			</section>
		)
	}
}

export default ImportCsvMapper
