import React, { Component } from 'react'
import AppHeader from '../AppHeader'
import { 
  Breadcrumb, 
  Button, 
  Form, 
  Icon, 
  Grid, 
  Radio, 
  Image, 
  Popup, 
  Checkbox, 
  Modal 
} from 'semantic-ui-react'
import ToastMessage from '../Message'
import { Link } from 'react-router-dom'
import constants from '../../lib/constants'
import trimWhiteSpace from '../../lib/trim'
import ErrorMessages from '../Errors/ErrorMessages'
import { GraphServices } from '../../services/Graphs'
import $ from 'jquery'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import moment from 'moment'
import ChartComponent from '../Charts/ChartComponent'
import { formatRoute } from 'react-router-named-routes'
import { connect } from 'react-redux'
import ModalComponent from '../ModalComponent'
import { selectedTests } from '../../actions'
import { SampleTestServices } from '../../services/SampleTest'
import TestResults from '../SampleTests/TestResults'
import analyticalReportDataValidator from '../../lib/analyticalReportDataValidator'

const mapStateToProps = (state) => {
  return {
    selected_tests: state.selected_tests
  }
}
const mapDispatchToProps = (dispatch) => ({
  selectedTests: (tests) => { dispatch(selectedTests(tests)) }
})
const gridOptions = [
  { key: constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.VALUE, text: constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.TEXT, value: constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.VALUE },
  { key: constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE, text: constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.TEXT, value: constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE },
]
const subScaleOptions = [
  { key: constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_STRAIN_NAME.VALUE, text: constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_STRAIN_NAME.TEXT, value: constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_STRAIN_NAME.VALUE },
  { key: constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_BATCH_NUMBER.VALUE, text: constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_BATCH_NUMBER.TEXT, value: constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_BATCH_NUMBER.VALUE },
]
class CreateGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      apiErrors: {},
      reportName: '',
      selectedGraphTypeId: '',
      graphTypes: [],
      analyteTypes: [],
      graphAttributes: [],
      graphScales: [],
      startDate: '',
      stopDate: '',
      reportNameError: false,
      selectedGraphTypeIdError: false,
      step: 1,
      datesRange: '',
      selectedGraphScale: '',
      selectedGraphAttribues: [],
      chartCreated: null,
      selectedGraphScaleError: false,
      selectedGraphAttribuesError: false,
      selectedAnalyteTypeError: false,
      startDateError: false,
      stopDateError: false,
      selectedGraphTypeSlug: '',
      currentGraphData: null,
      graphLoaded: false,
      type_radio: constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.VALUE,
      modalFlag: false,
      materialType: [],
      selectedMaterial: null,
      selectedAnalyteType: 'derived',
      selectedTests: [],
      previousGraphScale: '',
      dateRange: [],
      testResultsModalFlag: false,
      selectedUnitTypeError: false,
      selectedSubScale: constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_STRAIN_NAME.VALUE,
      selectedSubScaleError: false,
      switchAxes: false
    }
  }
  get filteredGraphAttribute() {
    return this.state.graphAttributes.filter((graphAttribute) => {
      return (graphAttribute.unit === this.state.type_radio) && (graphAttribute.category === this.state.selectedAnalyteType)
    })
  }
  async componentDidMount() {
    window.addEventListener('resize', this.resize)
    await this.getGraphTypes()
    await this.getGraphAttributes()
    await this.getGraphScales()
    await this.getMaterialTypes()
    if (this.props.match.params.id) {
      await this.getGraphConfigurations()
    }
    await this.getAnalyteDropdownData()
  }
  getAnalyteDropdownData = () => {
    this.setState({
      analyteTypes: [
        {
          "key": "derived",
          "text": "Total",
          "value": 'derived'
        },
        {
          "key": 'analyte',
          "text": "Analyte",
          "value": 'analyte'
        },
      ]
    })
  }
  getMaterialTypes = async () => {
    await SampleTestServices.getAllSampleTypes().then((response) => {
      let items = response.data.data.map((item, key) => {
        let var1 = { key: item.id, text: item.name, value: item.id, slug: item.slug }
        return var1
      })
      items = items.filter(function (el) {
        return el.slug !== constants.SAMPLE_TYPE.STANDARD && el.slug !== constants.SAMPLE_TYPE.CALIBRATION
      })
      this.setState({ materialType: items, apiErrors: {} })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {} })
    })
  }
  getGraphTypes = async () => {
    await GraphServices.getGraphTypes().then((response) => {
      this.setState({ graphTypes: ( response.data && response.data.data ) || [], apiErrors: {} })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {} })
    })
  }
  getGraphAttributes = async () => {
    await GraphServices.getGraphAttributes().then((response) => {
      this.setState({ graphAttributes: ( response.data && response.data.data ) || [], apiErrors: {} })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {} })
    })
  }
  getGraphScales = async () => {
    await GraphServices.getGraphScales().then((response) => {
      let scales = response.data && response.data.data
      scales.unshift(scales.splice(scales.findIndex(item => item.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG), 1)[0])
      this.setState({ graphScales: scales || [], apiErrors: {} }, () => {
        this.prepopulateComparisionScale()
      })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {} })
    })
  }
  prepopulateComparisionScale = () => {
    if (!this.props.match.params.id && this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes && this.props.selected_tests.selectedCheckBoxes.length > 0) {
      let selectedGraphScale = this.state.graphScales.filter(value => value.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG)[0]
      this.setState({ selectedGraphScale: (selectedGraphScale && selectedGraphScale.id) || '', selectedGraphScaleError: false })
    }
  }
  getGraphDetails = () => {
    this.setState({
      currentGraphData: null,
      graphLoaded: false
    })
    let selectedGraphScaleSlug = this.state.graphScales.filter(value => value.id === this.state.selectedGraphScale)[0]
    let chart_attribute_ids = this.state.selectedGraphAttribues.filter((selectedGraphID) => {
      return this.filteredGraphAttribute.find((filteredGraph) => {
        return filteredGraph.id === selectedGraphID
      }) !== undefined
    })
    if (this.state.selectedGraphTypeId && chart_attribute_ids.length > 0 && this.state.selectedGraphScale !== '' && ((this.state.startDate !== '' && this.state.stopDate !== '') ||
      (selectedGraphScaleSlug && selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes && this.state.selectedSubScale !== ''))) {
      let batch_test = []
      if (this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes && selectedGraphScaleSlug && selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG) {
        batch_test = this.props.selected_tests.selectedCheckBoxes.map((data) => data.id)
      }
      if (this.props.match.params.id && this.state.selectedTests.length > 0)
        batch_test = this.state.selectedTests
      let requestData = {
        analytical_report: {
          name: this.state.reportName,
          chart_type_id: this.state.selectedGraphTypeId,
          chart_attribute_ids: this.state.selectedGraphAttribues.filter((selectedGraphID) => {
            return this.filteredGraphAttribute.find((filteredGraph) => {
              return filteredGraph.id === selectedGraphID
            }) !== undefined
          }),
          chart_scale_id: this.state.selectedGraphScale,
          config: {
            batch_test_ids: (selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && batch_test.length > 0) ? batch_test : null,
            filters: {
              sample_type_id: (this.state.selectedMaterial !== '' && this.state.selectedMaterial !== null) ? this.state.selectedMaterial : null
            },
            sub_scale: (selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && batch_test.length > 0) ? this.state.selectedSubScale : null,
            switch_axes: this.state.switchAxes
          },
          start_date: this.state.startDate,
          end_date: this.state.stopDate,
        }
      }
      GraphServices.getGraphDataPreview(requestData).then((response) => {
        let properResponse = analyticalReportDataValidator(response.data)
        this.setState({ currentGraphData: properResponse ? response.data : {}, apiErrors: {}, graphLoaded: true })
      }).catch((error) => {
        this.setState({ apiErrors: (error.data) ? error.data : {}, graphLoaded: true })
      })
    }
    else {
      this.setState({ currentGraphData: null })
    }
  }
  selectGraphType = (input) => {
    this.setState({
      selectedGraphTypeId: input.id,
      selectedGraphTypeIdError: false,
      selectedGraphTypeSlug: input.slug
    }, () => {
      this.prepopulateComparisionScale()
      this.goToStep(2)
      this.getGraphDetails()
    })
  }
  checkTypeValidation = () => {
    let error = false
    if (trimWhiteSpace(this.state.reportName) === '') {
      this.setState({ reportNameError: true })
      error = true
    }
    if (this.state.selectedGraphTypeId === '') {
      this.setState({ selectedGraphTypeIdError: true })
      error = true
    }
    return error
  }
  goToStep = (step) => {
    if (!this.checkTypeValidation())
      this.setState({
        step: step,
      })
  }
  resize = () => this.forceUpdate()
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  updateForm = (e) => {
    let target = e.target
    this.setState((prevState, props) => {
      let newState = $.extend({}, prevState)
      newState[target.name] = target.value
      return newState
    }, () => {
      this.isFieldValidate(target.name)
    })
  }
  isFieldValidate = (name) => {
    switch (name) {
      case 'reportName':
        this.setState({ reportNameError: false })
        break
      default:
    }
  }
  handleChange = (event) => {
    this.setState({
      startDate: event && event[0] ? moment(event[0]).format('YYYY-MM-DD') : '',
      stopDate: event && event[1] ? moment(event[1]).format('YYYY-MM-DD') : '',
      startDateError: false,
      stopDateError: false
    }, () => { this.getGraphDetails() })
  }
  handleChangeGraphAttributes = (event, { name, value, checked }) => {
    let selected = [...this.state.selectedGraphAttribues]
    if (checked === true) {
      selected.push(value)
    }
    else {
      selected = selected.filter(v => v !== value)
    }
    this.setState({
      selectedGraphAttribues: selected,
      selectedGraphAttribuesError: false
    }, () => { this.getGraphDetails() })
  }
  handleChangeGraphScales = (event, { name, value }) => {
    let selectedGraphScaleSlug = this.state.graphScales.filter(scale => scale.id === value)[0]
    this.setState({ selectedGraphScale: value, selectedGraphScaleError: false, currentGraphData: {} }, () => {
      if (selectedGraphScaleSlug && selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && (this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes && this.props.selected_tests.selectedCheckBoxes.length === 0 && this.state.selectedTests.length === 0)) {
        this.setState({ modalFlag: true })
      }
      else {
        this.setState({ modalFlag: false })
      }
      this.getGraphDetails()
    })
  }
  updateRadioType = (e, { value }) => {
    this.setState({ type_radio: value }, () => { this.getGraphDetails() })
  }
  updateSubScale = (e, { value }) => {
    this.setState({ selectedSubScale: value }, () => { this.getGraphDetails() })
  }
  isPreviewValidate = () => {
    let error = false
    let selectedGraphScaleSlug = this.state.graphScales.filter(scale => scale.id === this.state.selectedGraphScale)[0]
    let chart_attribute_ids = this.state.selectedGraphAttribues.filter((selectedGraphID) => {
      return this.filteredGraphAttribute.find((filteredGraph) => {
        return filteredGraph.id === selectedGraphID
      }) !== undefined
    })
    if (trimWhiteSpace(this.state.reportName) === '') {
      this.setState({ reportNameError: true })
      error = true
    }
    if (this.state.selectedGraphScale === '') {
      error = true
      this.setState({ selectedGraphScaleError: true })
    }
    if (chart_attribute_ids.length === 0) {
      error = true
      this.setState({ selectedGraphAttribuesError: true })
    }
    if ((this.state.startDate === '' || this.state.startDate === null) && selectedGraphScaleSlug && selectedGraphScaleSlug.slug !== constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG) {
      this.setState({ startDateError: true })
      error = true
    }
    if ((this.state.stopDate === '' || this.state.stopDate === null) && selectedGraphScaleSlug && selectedGraphScaleSlug.slug !== constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG) {
      this.setState({ stopDateError: true })
      error = true
    }
    if (this.state.type_radio === '') {
      error = true
      this.setState({ selectedUnitTypeError: true })
    }
    if (this.state.selectedSubScale === '' && selectedGraphScaleSlug && selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG) {
      error = true
      this.setState({ selectedSubScaleError: true })
    }
    return error
  }
  handleSubmit = () => {
    if (!this.isPreviewValidate()) {
      let batch_test = []
      let selectedGraphScaleSlug = this.state.graphScales.filter(scale => scale.id === this.state.selectedGraphScale)[0]
      if (this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes && selectedGraphScaleSlug && selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG) {
        batch_test = this.props.selected_tests.selectedCheckBoxes.map((data) => data.id)
      }
      if (this.props.match.params.id && this.state.selectedTests.length > 0)
        batch_test = this.state.selectedTests
      let requestData = {
        analytical_report: {
          name: this.state.reportName,
          chart_type_id: this.state.selectedGraphTypeId,
          chart_attribute_ids: this.state.selectedGraphAttribues.filter((selectedGraphID) => {
            return this.filteredGraphAttribute.find((filteredGraph) => {
              return filteredGraph.id === selectedGraphID
            }) !== undefined
          }),
          chart_scale_id: this.state.selectedGraphScale,
          config: {
            batch_test_ids: (selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && batch_test.length > 0) ? batch_test : null,
            filters: {
              sample_type_id: (this.state.selectedMaterial !== '' && this.state.selectedMaterial !== null) ? this.state.selectedMaterial : null
            },
            attribute_type: this.state.selectedAnalyteType,
            sub_scale: (selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && batch_test.length > 0) ? this.state.selectedSubScale : null,
            switch_axes: this.state.switchAxes
          },
          start_date: this.state.startDate,
          end_date: this.state.stopDate,
        }
      }
      if (this.props.match.params.id) {
        GraphServices.updateGraph(this.props.match.params.id, requestData).then((response) => {
          this.setState({ chartCreated: true, apiErrors: {} })
          this.props.selectedTests({ batch_id: '', selectedCheckBoxes: [], type: '' })
          setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ANALYTICAL_REPORT.VIEW.ROUTE, { id: response.data.id })), 1000)
        }).catch((error) => {
          this.setState({ chartCreated: false, apiErrors: (error.data) ? error.data : {} })
        })
      }
      else {
        GraphServices.createGraph(requestData).then((response) => {
          this.setState({ chartCreated: true, apiErrors: {} })
          this.props.selectedTests({ batch_id: '', selectedCheckBoxes: [], type: '' })
          setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ANALYTICAL_REPORT.VIEW.ROUTE, { id: response.data.id })), 1000)
        }).catch((error) => {
          console.log(error)
          this.setState({ chartCreated: false, apiErrors: (error.data) ? error.data.slug[0] : {} })
        })
      }
    }
  }
  async getGraphConfigurations() {
    await GraphServices.getGraphConfigurations(this.props.match.params.id).then((response) => {
      let dateRange = []
      if (response.data.start_date && response.data.start_date !== null && response.data.end_date && response.data.end_date !== null)
        dateRange = [new Date(response.data.start_date), new Date(response.data.end_date)]
      this.setState({
        step: 2,
        reportName: (response.data.name) ? (response.data.name) : '',
        selectedGraphTypeId: (response.data.chart_type.id) ? (response.data.chart_type.id) : '',
        selectedGraphTypeSlug: (response.data.chart_type.slug) ? (response.data.chart_type.slug) : '',
        startDate: (response.data.start_date && response.data.start_date !== null) ? (response.data.start_date) : '',
        stopDate: (response.data.end_date && response.data.end_date !== null) ? (response.data.end_date) : '',
        selectedGraphScale: (response.data.chart_scale.id) ? (response.data.chart_scale.id) : '',
        selectedGraphAttribues: response.data.chart_attributes.map(a => a.id),
        selectedMaterial: (response.data.config && response.data.config.filters && response.data.config.filters.sample_type_id) ? (response.data.config.filters.sample_type_id) : '',
        type_radio: response.data.chart_attributes[0] ? response.data.chart_attributes[0].unit : '',
        selectedTests: (response.data.config && response.data.config.batch_test_ids) ? response.data.config.batch_test_ids : [],
        previousGraphScale: (response.data.chart_scale.id) ? (response.data.chart_scale.id) : '',
        dateRange: dateRange,
        selectedAnalyteType: (response.data.config && response.data.config.attribute_type) ? (response.data.config.attribute_type) : '',
        selectedSubScale: (response.data.config && response.data.config.sub_scale) ? (response.data.config.sub_scale) : '',
        switchAxes: (response.data.config && response.data.config.switch_axes) ? (response.data.config.switch_axes) : false
      }, () => { this.getGraphDetails() })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {} })
    })
  }
  chooseType = () => {
    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8}>
            <Form.Field className="required-field">
              <label>Graph Type</label>
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Grid className='gridTypeImages'>
          {
            this.state.graphTypes.map((input, index) =>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8} key={input.id}>
                <Popup content={input.name} size='tiny' trigger={
                  <Image className={(input.id === this.state.selectedGraphTypeId) ? 'active' : null} key={input.id} src={input.image_url} bordered rounded centered onError={i => i.target.src = constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND} onClick={() => {
                    this.selectGraphType(input)
                  }} />
                } />
              </Grid.Column>
            )
          }
          {this.state.selectedGraphTypeIdError && <p className='error'>{constants.FORM.MANDATORY_SELECT}</p>}
        </Grid>
      </Grid.Column>
    )
  }
  checkBatch = (slug) => {
    if (slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && ((this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes && this.props.selected_tests.selectedCheckBoxes.length > 0) || (this.state.selectedTests.length > 0))) {
      return true
    } else {
      return false
    }
  }
  handlerDropdownChange = (e, { name, value }, flag) => {
    this.setState({
      selectedMaterial: value,
    }, () => this.getGraphDetails())
  }

  handlerAnalyteDropdownDataChange = (e, { name, value }, flag) => {
    this.setState({
      selectedAnalyteType: value,
    }, () => this.getGraphDetails())
  }

  handleAxisSwitch = (e, { name, value, checked }) => {
    this.setState({ switchAxes: checked }, () => {
      this.getGraphDetails()
    })
  }

  previewGraph = () => {
    let id = this.props.match.params.id
    let selectedGraphScaleSlug = this.state.graphScales.filter(scale => scale.id === this.state.selectedGraphScale)[0]
    let previousGraphScaleSlug = this.state.graphScales.filter(scale => scale.id === this.state.previousGraphScale)[0]
    let scales = this.state.graphScales
    if ((this.props.match.params.id && this.state.selectedTests.length > 0) || (!this.props.match.params.id && this.props.selected_tests.selectedCheckBoxes.length > 0)) {
    }
    else {
      scales = scales.filter(function (obj) {
        return obj.slug !== constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG
      })
    }

    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={4} largeScreen={4}>
            <div>
              <Grid>
                <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                  <Form.Select search selection clearable label='Material Type' value={this.state.selectedMaterial} options={this.state.materialType} placeholder='Material Type' name='selectedMaterial' onChange={this.handlerDropdownChange} />
                </Grid.Column>
              </Grid>
            </div>
            <div>
              <Grid>
                <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                  <Form.Select search selection label='TOTAL % - TOTAL - ANALYTE / mg/g - %'
                    className="required-field no-text-transform"
                    value={this.state.selectedAnalyteType}
                    options={this.state.analyteTypes}
                    placeholder='Total/Analyte' name='selectedAnalyteType'
                    onChange={this.handlerAnalyteDropdownDataChange} />
                </Grid.Column>
              </Grid>
              <Grid>
                {this.state.selectedAnalyteTypeError &&
                  <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
              </Grid>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                  <Form.Field className="required-field" error={this.state.selectedUnitTypeError}>
                    <label>Select type</label>
                  </Form.Field>
                </Grid.Column>
                {gridOptions.map((input, index) =>
                  <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={8}>
                    <Form.Field key={input.key}>
                      <Radio
                        label={input.text}
                        name='user_creation_radio'
                        value={input.value}
                        checked={this.state.type_radio === input.value}
                        onChange={this.updateRadioType}
                        key={input.key}
                      />
                    </Form.Field>
                  </Grid.Column>
                )}
              </Grid>
              <Grid>
                {this.state.selectedUnitTypeError && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
              </Grid>
            </div>
            <div>
              <Grid id='createReportForm'>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                  <Form.Field className="required-field" error={this.state.selectedGraphAttribuesError}>
                    <label>Attributes</label>
                  </Form.Field>
                </Grid.Column>
              </Grid>
              <Grid id='createReportForm'>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                  {this.filteredGraphAttribute.map((input, index) =>
                    <Form.Field key={input.id} error={this.state.selectedGraphAttribuesError}>
                      <Checkbox
                        label={input.name}
                        name='attributes'
                        value={input.id}
                        checked={this.state.selectedGraphAttribues.indexOf(input.id) >= 0 ? true : false}
                        onChange={this.handleChangeGraphAttributes}
                        key={input.id}
                      />
                    </Form.Field>)
                  }
                </Grid.Column>
                {this.state.selectedGraphAttribuesError && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
              </Grid>
            </div>
            <Grid id='createReportForm'>
              <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                <Form.Field className="required-field" error={this.state.selectedGraphScaleError}>
                  <label>Scale</label>
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid id='createReportForm'>
              <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                {scales.map((input, index) =>
                  <Form.Field key={input.id} error={this.state.selectedGraphScaleError}>
                    <Radio
                      label={input.name + (this.checkBatch(input.slug) ? ' (' + (this.props.match.params.id ? this.state.selectedTests.length : this.props.selected_tests.selectedCheckBoxes.length) + ')' : '')}
                      name='scales'
                      value={input.id}
                      checked={this.state.selectedGraphScale === input.id}
                      onChange={this.handleChangeGraphScales}
                      key={input.id}
                      disabled={(input.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG && previousGraphScaleSlug && previousGraphScaleSlug.slug !== input.slug && this.props.match.params.id)}
                    />
                  </Form.Field>)
                }
              </Grid.Column>
              {this.state.selectedGraphScaleError && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
            </Grid>
            {selectedGraphScaleSlug && selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG &&
              <React.Fragment>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                    <Form.Field className="required-field" error={this.state.selectedSubScaleError}>
                      <label>Sub-scale</label>
                    </Form.Field>
                  </Grid.Column>
                  {subScaleOptions.map((input, index) =>
                    <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={8}>
                      <Form.Field key={input.key}>
                        <Radio
                          label={input.text}
                          name='sub_scale'
                          value={input.value}
                          checked={this.state.selectedSubScale === input.value}
                          onChange={this.updateSubScale}
                          key={input.key}
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </Grid>
                <Grid>
                  {this.state.selectedSubScaleError && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
                </Grid>
              </React.Fragment>
            }
            {selectedGraphScaleSlug && selectedGraphScaleSlug.slug !== constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG &&
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                  <Form.Field className="required-field" error={(this.state.startDateError || this.state.stopDateError)}>
                    <label>Date Range</label>
                  </Form.Field>
                  <SemanticDatepicker onDateChange={this.handleChange} pointing="top left" type="range" maxDate={new Date()} selected={this.state.dateRange} />
                  {(this.state.startDateError || this.state.stopDateError) && <p className='error'>{constants.FORM.MANDATORY_DATE_FIELD}</p>}
                </Grid.Column>
              </Grid>}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12}>
            {this.state.currentGraphData !== null && this.state.selectedGraphTypeSlug && Object.keys(this.state.currentGraphData).length > 0 &&
              <ChartComponent type={(this.state.selectedGraphTypeSlug ? this.state.selectedGraphTypeSlug : '')} config={{ attributes: this.getConfigAttributes(), scale: this.getConfigScales(), unit: this.state.type_radio, is_compare_report: selectedGraphScaleSlug && selectedGraphScaleSlug.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG, sub_scale: this.state.selectedSubScale, switchAxes: this.state.switchAxes }} data={this.state.currentGraphData} />
            }
            {this.state.currentGraphData === null &&
              <Image size={'large'} src={constants.GRAPH_DASHBORD.NO_PREVIEW_FOUND} centered onError={i => i.target.src = constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND} />
            }
            {this.state.graphLoaded && this.state.currentGraphData !== null && Object.keys(this.state.currentGraphData).length === 0 &&
              <Image size={'large'} src={constants.GRAPH_DASHBORD.NO_GRAPH_DATA_FOUND} centered onError={i => i.target.src = constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND} />
            }
            {selectedGraphScaleSlug && selectedGraphScaleSlug.slug !== constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG &&
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} className='toggle' textAlign='right'>
                  <Form.Field>
                    <label>Toggle Scale-Series</label>
                  </Form.Field>
                  <Form.Checkbox toggle checked={this.state.switchAxes} name='swithAxes' value={this.state.switchAxes} onClick={this.handleAxisSwitch} />
                </Grid.Column>
              </Grid>
            }
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} textAlign='center'>
            <Button icon labelPosition='left' className='confirmButton' size='small'
              onClick={() => {
                this.goToStep(1)
              }}>
              <Icon name='long arrow alternate left' />{constants.GRAPH_DASHBORD.CREATE.BACK_BUTTON_TITLE}
            </Button>
            <Button icon labelPosition='left' className='confirmButton' size='small'
              onClick={() => {
                this.handleSubmit()
              }}>
              <Icon name='check' />{id ? constants.GRAPH_DASHBORD.EDIT.EDIT_BUTTON_TITLE : constants.GRAPH_DASHBORD.CREATE.CREATE_BUTTON_TITLE}
            </Button>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    )
  }
  getConfigAttributes = () => {
    let attributes = []
    if (this.state.selectedGraphAttribues) {
      let attributesFilter = this.state.graphAttributes.filter(v => this.state.selectedGraphAttribues.includes(v.id))
      attributes = attributesFilter.map(a => a.name)
    }
    return attributes
  }
  getConfigScales = () => {
    let scalesFilter = this.state.graphScales.filter(v => v.id === this.state.selectedGraphScale)
    let scales = scalesFilter.map(a => a.name)
    return [scales]
  }
  selectedDates = () => {
    let selDates = []
    if (trimWhiteSpace(this.state.startDate) !== '')
      selDates.push(new Date(this.state.startDate))
    if (trimWhiteSpace(this.state.stopDate) !== '')
      selDates.push(new Date(this.state.stopDate))
    return selDates
  }
  redirect1 = () => {
    this.props.history.push('/batch/list')
  }
  closeModal = () => {
    this.setState({ modalFlag: false })
  }
  getSelectedCheckBoxes = () => {
    if (this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes) {
      return this.props.selected_tests.selectedCheckBoxes
    }
    return []
  }
  compareReport = () => {
    this.setState({
      testResultsModalFlag: true
    })
  }
  closeTestResultsModal = () => {
    this.setState({ testResultsModalFlag: false })
  }
  reportNameOnBlur = () => {
    this.prepopulateComparisionScale()
    this.goToStep(2)
  }
  render() {
    let breadcrumb
    let id = this.props.match.params.id
    breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
      </Breadcrumb>
    )
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{id ? constants.GRAPH_DASHBORD.EDIT.HEADER_TITLE : constants.GRAPH_DASHBORD.CREATE.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <div className="form screen">
              {this.state.chartCreated === true && !id &&
                <ToastMessage title='Success' description={constants.GRAPH_DASHBORD.CREATE.SUCCESS} />}
              {this.state.chartCreated === true && id &&
                <ToastMessage title='Success' description={constants.GRAPH_DASHBORD.EDIT.SUCCESS} />}
              {this.state.apiErrors.length > 0 && <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>}
              <Form>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={16} computer={14} largeScreen={14}>
                    <Grid>
                      <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4}>
                        <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} className="required-field" label="Report Name" value={this.state.reportName} name="reportName" onChange={this.updateForm} onBlur={this.reportNameOnBlur} type="text" placeholder="Report Name" error={this.state.reportNameError} />
                        {this.state.reportNameError && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
                      </Grid.Column>
                      {this.state.step === 1 && !this.props.match.params.id &&
                        (<Grid.Column mobile={16} tablet={8} computer={6} largeScreen={4}>
                          <Form.Field>
                            <label>&nbsp;</label>
                            <Popup content={constants.GRAPH_DASHBORD.COMPARE_BUTTON_TOOLTIP} size='tiny' trigger={<Button icon labelPosition='left' className='compareReport confirmButton' size='small' disabled={this.getSelectedCheckBoxes().length > 0 ? false : false}
                              onClick={() => {
                                this.compareReport()
                              }}>
                              <Icon name='chart line' /> {constants.APPLICATION_ROUTE.ANALYTICAL_REPORT.COMPARE.NAME} ({this.getSelectedCheckBoxes().length})
                            </Button>} />
                          </Form.Field>
                        </Grid.Column>)
                      }
                      {this.state.step === 1 &&
                        this.chooseType()
                      }
                      {this.state.step === 2 &&
                        this.previewGraph()
                      }
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Form>
              <Modal id='testResultsModal' size='large' open={this.state.testResultsModalFlag} onClose={() => { this.closeTestResultsModal() }} closeIcon centered>
                <Modal.Header>{constants.SAMPLE_TEST.LIST.TOTAL_TITLE}</Modal.Header>
                <Modal.Content scrolling>
                  <TestResults />
                </Modal.Content>
                <Modal.Actions>
                  <Button className='cancelButton' onClick={() => this.closeTestResultsModal()}>Close</Button>
                  <Button className='confirmButton' icon='checkmark' labelPosition='right' content={`Continue (${this.getSelectedCheckBoxes().length})`} onClick={() => this.closeTestResultsModal()}></Button>
                </Modal.Actions>
              </Modal>
              <ModalComponent
                size='mini'
                action={true}
                openModal={this.state.modalFlag}
                onClose={this.closeModal}
                header={'Select Batch Tests'}
                content={'Redirect to select batch tests to compare'}
                handleAction={this.redirect1}
              />
            </div>
          </section>
        </section>
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateGraph)
