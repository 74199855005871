import axios from '../axios'
import config from '../config'
import constants from '../lib/constants'
export const FormTemplateService = {
  getFormTemplates,
  getFormTemplate,
  createFormTemplate,
  patchFormTemplate,
  deleteFormTemplate,
  getMasterFormTemplates,
  getMasterFormTemplatFields,
  formFieldById,
  getAllFormFieldById
}

function getFormTemplates() {
  return config.api_root + 'form-templates'
}

function getFormTemplate(id) {
  return axios().get(config.api_root + 'form-templates/' + id)
}

function createFormTemplate(requestData) {
  return axios().post('/form-templates', requestData)
}

function patchFormTemplate(id, requestData) {
  return axios().patch('/form-templates/' + id, requestData)
}

function deleteFormTemplate(id) {
  return axios().delete('/form-templates/' + id)
}

function getMasterFormTemplates() {
  return axios().get('/form-templates?no_pagination=true&sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id')
}

function getMasterFormTemplatFields(templateId) {
  return axios().get('/form-templates/' + templateId + '/form-fields?no_pagination=true&sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id')
}

function formFieldById(id) {
  return axios().get(config.api_root + 'form-templates/' + id + '/form-fields?sortOrder=' + constants.DEFAULT_SORT_ORDER + '&sortColumn=id')
}

function getAllFormFieldById(id) {
  return axios().get(config.api_root + 'form-templates/' + id + '/form-fields?no_pagination=true')
}
