import axios from 'axios'
import momentWithZone from 'moment-timezone'
import constants from './lib/constants'
axios.defaults.headers.common['Accept'] = 'application/json'
const getAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT
  })
  instance.interceptors.request.use(reqConfig => {
    document.body.classList.add('loading-indicator')
    reqConfig.headers[constants.HEADER_TIMEZONE] = momentWithZone.tz.guess()
    return reqConfig
  },
    err => Promise.reject(err),
  )
  instance.interceptors.response.use(response => {
    document.body.classList.remove('loading-indicator')
    return response
  }, err => {
    document.body.classList.remove('loading-indicator')
    return Promise.reject(err.response)
  },
  )
  return instance
}

export default getAxiosInstance
