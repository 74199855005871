import axios from '../axios'
import config from '../config'
export const FormFieldServices = {
  getFormFields,
  getFormField,
  createFormFields,
  patchFormFields,
  deleteFormFields,
  getFormFieldsDataGrid,
  getAllFormFields
};

function getFormFieldsDataGrid() {
  return (config.api_root + 'form-fields')
}

function getFormFields() {
  return axios().get(config.api_root + 'form-fields')
}

function getFormField(id) {
  return axios().get(config.api_root + 'form-fields/' + id)
}

function createFormFields(requestData) {
  return axios().post('/form-fields', requestData)
}

function patchFormFields(id, requestData) {
  return axios().patch('/form-fields/' + id, requestData)
}

function deleteFormFields(id) {
  return axios().delete('/form-fields/' + id)
}

function getAllFormFields() {
  return axios().get(config.api_root + 'form-fields?no_pagination=true')
}
