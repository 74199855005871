import React, { Component } from 'react'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { CsvDownload } from '../../services/CsvDownloads'
class DownloadExcel extends Component {
  downloadExcel = () => {
    CsvDownload.downloadData(this.props.url, this.props.fileName);
  }
  render() {
    return (
      <Popup content={'Download CSV'} size='tiny' trigger={
        <Button className='exportButton reportButton' icon onClick={this.downloadExcel} floated='right' size='small'>
          <Icon name='download' />
        </Button>
      } />
    )
  }
}
export default DownloadExcel
