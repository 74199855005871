import React, { Component } from 'react'

class StatusNode extends Component {
  render() {
    return (
      <div className="statusNode">
        <span className={`status ${this.props.nodestatus}`}></span>
        <span className="statusLabel">{this.props.label}</span>
      </div>
    )
  }
}

export default StatusNode
