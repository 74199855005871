import React, { Component } from 'react'
import TreeFollow from './TreeFollow'
import drawArrows from '../lib/drawArrows'

class PrepMethodDiluentFlow extends Component {
  constructor(props) {
    super(props)
    this.chartHolderRef = React.createRef()
    this.rows = this.getRows(props.prepMethod.prep_steps)
    this.columns = this.getColumns(props.prepMethod.prep_steps)
  }
  getRows = (vials) => {
    let parentVialIds = vials.map((v) => (v.draw_from_prep_step_id)).filter(Boolean)
    parentVialIds.sort((a,b) => (a-b))
    let mostParentsFound = 1
    let currentParentCount = 1
    for (let i = 0; i < parentVialIds.length - 1; i++) {
      // if the next value is the same as current, increment
      // our current parent count score
      if (parentVialIds[i] === parentVialIds[i+1]) {
        currentParentCount++
        if (currentParentCount > mostParentsFound) {
          mostParentsFound = currentParentCount
        }
      }
      // if the next value is different, see if we've set a
      // new score, and clear our running count
      else {
        currentParentCount = 1
      }
    }
    return mostParentsFound
  }
  getColumns = (vials) => {
    let parentVialIds = vials.map((v) => (v.draw_from_prep_step_id)).filter(Boolean)
    parentVialIds.sort((a,b) => (a-b))
    let mostUniqueIds = parentVialIds.reduce((acc, val, i) => ( val === parentVialIds[i+1] ? acc : acc + 1 ), 0)
    return mostUniqueIds
  }
  nodesRendered = () => {
    this.setVialCoords()
  }
  getCoordsForEl = (vialEl) => {
    return [0, 0]
  }
  setVialCoords = () => {
    // 80 charachters be damned !
    // let toFromIds = this.props.prepMethod.prep_steps.map((vial) => (vial.draw_from_prep_step_id ? [`#prepMethod-${this.props.prepMethod.id}-vial-${vial.draw_from_prep_step_id}`, `#prepMethod-${this.props.prepMethod.id}-vial-${vial.id}`] : undefined)).filter(Boolean)
    // drawArrows(`#prepMethod-${this.props.prepMethod.id}-arrowsHolder`, toFromIds)
  }
  render() {
    const baseNode = this.props.prepMethod.prep_steps.find((v) => (!v.draw_from_prep_step_id))
    return (
      <div className="prepMethodDiluentFlow" ref={this.chartHolderRef}>
        <div id={`#prepMethod-${this.props.prepMethod.id}-arrowsHolder`} className="arrowsHolder">
        </div>
        <TreeFollow
          renderCallback={this.nodesRendered}
          currentNode={baseNode}
          allNodes={this.props.prepMethod.prep_steps}
          depth={0}
          idPrefix={this.props.prepMethod.id}
          editingVialId={this.props.editingVialId}
          nodeClicked={this.props.setEditingVial}
        />
      </div>
    )
  }
}

export default PrepMethodDiluentFlow
