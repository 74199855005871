import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import Routes from './Routes'
import SharableReport from './components/SharableReport'
import './App'
import { formatRoute } from 'react-router-named-routes'
import constants from './lib/constants'

class App extends Component {
  render() {
    let routes = <Route path={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})} component={Routes} />
    if (/\/share/.test(window.location.pathname)) {
      routes = <Route path={constants.APPLICATION_ROUTE.SAMPLE.SHARE_REPORT.ROUTE} component={SharableReport} />
    }

    return (
      <Router>
        <div className="App" style={ (localStorage.getItem('access_token') && isMobile) ? { marginTop: '80px' } : {} }>
          {routes}
        </div>
      </Router>
    )
  }
}

export default App
