import React, { Component } from 'react'
import { Input, Button, Modal, Header, Segment, Label } from 'semantic-ui-react'
import constants from '../lib/constants'
import { SampleTestServices } from '../services/SampleTest'
import { formatRoute } from 'react-router-named-routes'
class ShareReportModal extends Component {
  constructor(props) {
    super(props)
    this.shareLink = process.env.REACT_APP_SELF_REFERENCE_ROOT + '/' + formatRoute(constants.APPLICATION_ROUTE.SAMPLE.SHARE_REPORT.ROUTE, { id: props.sample.share_key }).substring(1)
    this.closeMaybe = this.closeMaybe.bind(this)
    this.copyReportLink = this.copyReportLink.bind(this)
    this.copyCoaLink = this.copyCoaLink.bind(this)
    this.state = {
      reportCopied: false,
      coaCopied: false,
      emailSent: false,
      email: '',
      formErrors: {
        emailError: false
      },
      message: ''
    }
  }
  copyReportLink() {
    this.setState({ emailSent: false, message: '', coaCopied: false })
    document.getElementById('copyReportTarget').select()
    document.execCommand('copy')
    this.setState({ reportCopied: true })
  }
  copyCoaLink() {
    this.setState({ emailSent: false, message: '', reportCopied: false })
    document.getElementById('copyCoaTarget').select()
    document.execCommand('copy')
    this.setState({ coaCopied: true })
  }
  closeMaybe(e) {
    this.props.close()
  }
  handleInputChange = (e) => {
    let target = e.target
    let newState = { ...this.state }
    newState[target.name] = target.value
    this.setState(newState, () => {
      this.isfieldValid(target.name)
    })
  }
  isfieldValid = (field) => {
    let tempFormError = { ...this.state.formErrors }
    if (this.state[field] === '') {
      tempFormError[`${field}Error`] = true
    }
    else {
      tempFormError[`${field}Error`] = false
    }
    this.setState({
      formErrors: { ...tempFormError }
    })
  }
  checkEmail = (email) => {
    let re = constants.REG_EX.EMAIL_REG_EX
    return !re.test(String(email).toLowerCase())
  }
  validateForm = () => {
    let tempFormError = { ...this.state.formErrors }
    if (this.checkEmail(this.state.email)) {
      tempFormError.emailError = true
    }
    this.setState({
      formErrors: {
        ...tempFormError
      }
    })
    for (let key in tempFormError) {
      if (tempFormError[key] === true)
        return false
    }
    return true
  }
  emaiShareLink = () => {
    let formIsValid = this.validateForm()
    this.setState({ emailSent: true, message: 'Sending...', reportCopied: false, coaCopied: false })
    if (!formIsValid) {
      this.setState({emailSent: false, message: ''})
      return false
    }
    let requestBody = {
      email: this.state.email,
      link: this.shareLink
    }
    let id = this.props.sample.id
    SampleTestServices.sendEmailReportLink(requestBody, id).then((res) => {
      this.setState({ emailSent: true, message: constants.SHARE_REPORT.SUCCESS })
    }).catch((e) => {
      this.setState({ emailSent: false, message: constants.ERROR.SOMETHING_WENT_WRONG })
    })
  }
  render() {
    let shareReport
    let sendEmail
    let shareCoa
    shareCoa = (
      <Segment>
        <Label attached='top'>
          Certificate of Analysis
        </Label>
        <Input fluid
          action={<Button className='reportButton' disabled={this.state.coaCopied} labelPosition='right' icon='copy' content={!this.state.coaCopied ? 'Copy' : 'Copied'} onClick={this.copyCoaLink} />}
          value={this.props.coaUrl}
          id="copyCoaTarget"
        />
      </Segment>
    )
    shareReport = (
      <Segment>
        <Label attached='top'>
          Web Report
        </Label>
        <Input fluid
          action={<Button className='reportButton' disabled={this.state.reportCopied} labelPosition='right' icon='copy' content={!this.state.reportCopied ? 'Copy' : 'Copied'} onClick={this.copyReportLink} />}
          value={this.shareLink}
          id="copyReportTarget"
        />
      </Segment>
    )
    sendEmail = (
      <Segment>
        <Label attached='top'>
          Email Report
        </Label>
        <Input fluid
          action={<Button className='reportButton' labelPosition='right' icon='mail' content={constants.SHARE_REPORT.SEND_LINK_TO_EMAIL_BUTTON_TITLE} onClick={this.emaiShareLink} />}
          value={this.state.email}
          type='text'
          className='required-field'
          placeholder='E-mail'
          onChange={this.handleInputChange}
          name='email'
        />
      </Segment>
    )
    return (
      <Modal size='small' open={true} onClose={(e) => { this.closeMaybe(e) }
      } closeIcon>
        <Header icon='share' content='Share Results' />
        <Modal.Content id="share-modal">
          {this.props.coaUrl && shareCoa}
          {shareReport}
          {sendEmail}
          {this.state.emailSent && this.state.message && <p className='success'>{this.state.message}</p>}
          {this.state.emailSent === false && this.state.message && <p className='error'>{this.state.message}</p>}
          {this.state.formErrors.emailError && <p className='error'>{constants.SHARE_REPORT.INVALID_EMAIL}</p>}
        </Modal.Content>
      </Modal>
    )
  }
}
export default ShareReportModal
