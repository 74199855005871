import React from 'react'
import { Message } from 'semantic-ui-react'

const ToastMessage = (props) => (
  <Message className={(props.title.toLowerCase() === 'success' ? 'positive' : 'negative')}>
    <p>{props.description}</p>
  </Message>
)

export default ToastMessage
