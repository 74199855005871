import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import constants from '../../lib/constants'
import { Grid } from 'semantic-ui-react'
class ComparisionBarChartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false
    }
  }
  componentDidMount() {
  }
  prepareCompareLineChartOptions = (data, config) => {
    let unit = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? ' %' : ''
    let unitLabel = config.unit === constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.VALUE ? constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.PERCENT.TEXT : constants.GRAPH_DASHBORD.GRAPH_UNIT_SLUG.AMOUNT.TEXT
    let subscale = config.sub_scale === constants.GRAPH_DASHBORD.SUB_SCALE_SLUG.WITH_STRAIN_NAME.VALUE ? 'Strain' : 'Batch'
    let dates = Object.keys(data)
    let datesLength = dates.length
    let categories = []
    let finalSeries = []
    let colors = ['#EF8748', '#FADA32', '#5631A2', '#162487', '#A43B9A', '#8D38A5', '#8D38A5', '#B83C89', '#CC4778', '#F3A135', '#F7BB33', '#E97158', '#DB5C68', '#7236A8']
    try {
      categories = dates
      for (let i = 0; i < datesLength; i++) {
        let dateItems = data[dates[i]]
        for (let j = 0; j < dateItems.length; j++) {
          let attributes = Object.keys(dateItems[j].data)
          for (let k = 0; k < attributes.length; k++) {
            let index = finalSeries.findIndex(x => x.stack === dateItems[j].name + '||' + dateItems[j].id && x.name === attributes[k])
            if (index < 0) {
              let stack_data = [...Array(datesLength)].map(x => 0)
              stack_data[i] = dateItems[j].data[attributes[k]]
              finalSeries.push({
                showInLegend: false,
                stack: dateItems[j].name + '||' + dateItems[j].id,
                name: attributes[k],
                data: stack_data,
                color: colors[k]
              })
            }
            else {
              finalSeries[index].data[i] = dateItems[j].data[attributes[k]]
              finalSeries[index].color = colors[k]
            }
          }
        }
      }
    } catch (error) {

    }
    let options = {
      chart: {
        type: 'column',
        backgroundColor: 'transparent'
      },
      title: false,
      xAxis: {
        categories: categories,
        labels: {
          rotation: 0
        }
      },
      yAxis: {
        title: {
          text: unitLabel
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            rotation: 0,
            formatter: function () {
              return this.y.toFixed(2) + unit
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          let stack = this.series.userOptions.stack.split('||')
          let formatStr = '<span>' + this.x + '</span><br><span style="color:' + this.point.color + '">●</span> ' + subscale + ': <b>' + stack[0] + '<br><span style="color:' + this.point.color + '">●</span> ' + this.point.series.name + ': <b>' + this.point.y.toFixed(2) + unit + '</b><br/>'
          return formatStr
        }
      },
      series: finalSeries
    }
    return options
  }
  render() {
    return (
      <React.Fragment>
        {!this.state.error &&
          <HighchartsReact
            highcharts={Highcharts}
            options={this.prepareCompareLineChartOptions(this.props.data, this.props.config)}
          />
        }
        {this.state.error &&
          <Grid centered>
            <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12} textAlign='center'>
              <div id="runStatus">
                {constants.ERROR.SOMETHING_WENT_WRONG}
              </div>
            </Grid.Column>
          </Grid>
        }
      </React.Fragment>
    )
  }
}
export default ComparisionBarChartComponent
