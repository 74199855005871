import decodeJWT from './decodeJWT'
const permissionCheck = (entity, action) => {
  let access = false
  let decodedResponse = decodeJWT(localStorage.getItem('access_token'))
  let permission = decodedResponse.permissions ? decodedResponse.permissions : {}
  //In permission tables of backend; using action and subject_class( as 'this.entity'). Returns true. ex where entity[action] == sample["edit"]
  for (let key in permission) {
    if (key === entity) {
      if (permission[key].includes(action)) {
        access = true
      }
    }
  }
  return access
}
export default permissionCheck
