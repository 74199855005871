import { createStore } from 'redux'
import rootReducer from './reducers'
import { applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { loadState, saveState } from './localStorage'
import throttle from 'lodash/throttle'
import initialState from '../src/lib/InitialState'
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedState = loadState()
let state = { ...initialState, ...persistedState }
if (!localStorage.getItem('access_token')) {
  localStorage.setItem('state', undefined)
  state = undefined
}
const configureStore = () => {
  const store = createStore(
    rootReducer,
    state,
    composeEnhancer(applyMiddleware(thunk)),
  )
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer)
    })
  }
  store.subscribe(throttle(() => {
    saveState({
      selected_tests: store.getState().selected_tests,
    })
  }, 1000))
  return store
}
export default configureStore
