import AWS from 'aws-sdk'

async function invokeLambda({accessKeyId, secretAccessKey, location, funcName, event}){
    const lambda = new AWS.Lambda({
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: location
	
    })

    const params = {
        FunctionName: funcName,
        InvocationType: "RequestResponse",
        Payload: event
      };

   return await lambda.invoke(params).promise().then((response,error)=>{
    if(error){
        console.log(error)
    } else {
        return response
    }
    
   })
}

export default invokeLambda