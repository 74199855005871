import React, { Component } from 'react'
import { Grid, Button, Modal, Label, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import cannidAPI from '../../cannidAPI/client'

let QRCode = require('qrcode.react')

class CertusModalQr extends Component {
	constructor(props) {
		super(props)
		this.state = {
			certus: null
		}
	}

	componentDidMount () {
		// expects props to include either, 1) certus obj for sample or 2) sample_id for retrieving certus
		if (!this.state.certus && this.props.sample_id) {
			this.getCerti()
		} else {
			this.setState({certus: this.props.certus})
		}
	}

	getCerti = () => {
		cannidAPI.get('/samples/' + this.props.sample_id).then((response) => {
			this.setState({certus: response.data.certus})
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. VERISEAL retrieval error. Reload the page and try again.`
			this.props.createFailure(message)
		})
	}

	render(){
		const display = !this.state.certus ? (
			<Grid>
				<Grid.Column>
					<p>Retrieving VERISEAL Associations...</p>
				</Grid.Column>
			</Grid>
		) : (
			(this.state.certus && (this.state.certus.project_certi.length > 0 || this.state.certus.transportation_certi.length > 0))
			? 	<Grid columns='equal' className='associatedCertiGrid' divided>
					{this.state.certus.project_certi.length > 0 &&
						<Grid className="qrGrid" columns={2} textAlign="center">
							<Grid.Row>
								<Label color='green'>VERISEAL Projects</Label>
							</Grid.Row>
							<Grid.Row columns={this.state.certus.project_certi.length > 1 ? 2 : 1}>
								{this.state.certus.project_certi.map((certus, index) => {
									return (
										<Grid.Column  floated="left" key={`project${index}`} className="qrItem">
											<QRCode value={certus.qr[0]} size={150} />
											<br /><a href={`/certus/project/${certus.id}`}> {certus.project_name}</a>
											<br /><strong>TRACER:</strong> {certus.share_key}
										</Grid.Column>
									)
								})}
							</Grid.Row>
						</Grid>
					}
					{this.state.certus.transportation_certi.length > 0 &&
						<Grid className="qrGrid" columns={2} textAlign="center">
							<Grid.Row>
								<Label color='purple'>VERISEAL Transportation Manifests</Label>
							</Grid.Row>
							<Grid.Row columns={this.state.certus.transportation_certi.length > 1 ? 2 : 1}>
								{this.state.certus.transportation_certi.map((certus, index) => {
									return (
										<Grid.Column floated="left" key={`transportation${index}`} className="qrItem">
											<QRCode value={certus.qr[0]} size={150} />
											<br /><a href={`/certus/transportation/${certus.id}`}> {certus.project_name}</a>
											<br /><strong>TRACER:</strong> {certus.share_key}
										</Grid.Column>
									)
								})}
							</Grid.Row>
						</Grid>
					}
				</Grid>
			: "This sample does not belong to any VERISEAL Projects or VERISEAL Transportation Manifests"
		)

		return (
			<Modal className='certusConfirmation'
				size='large'
				open={this.props.modalOpen}
				onClose={() => this.props.closeModal()} closeIcon>
				<Modal.Header>
					<Grid>
						<Grid.Column floated='left' width={12} verticalAlign='middle'>
							{this.state.certus && `VERISEAL Associations: ${this.state.certus.sample_certus.project_name}`}
						</Grid.Column>
						<Grid.Column floated='right' width={4} textAlign='right'>
						<a className="certusScannerLink" href={process.env.REACT_APP_CERTUS_SCANNER_LINK}>
							<QRCode value={process.env.REACT_APP_CERTUS_SCANNER_LINK} size={60} />
							<Label color='green' style={{textAlign:'center'}}>
								CERTUS<Icon style={{position: 'relative', bottom: '.35rem'}} name='registered outline' size='tiny'/>QR Scanner
							</Label>
						</a>
						</Grid.Column>
					</Grid>
				</Modal.Header>
				<Modal.Content scrolling>
					{display}
				</Modal.Content>
				<Modal.Actions>
					<Button className='confirmButton' onClick={() => this.props.closeModal()}>Close</Button>
				</Modal.Actions>
			</Modal>
		)
	}
}

export default withRouter(CertusModalQr)
