import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import constants from '../lib/constants'
import { formatRoute } from 'react-router-named-routes'
import dateTimeFormatter from '../lib/dateTimeFormatter.js'
class ResultsWidget extends Component {
  goToResult(resultId) {
    this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.REPORT.ROUTE, { id: resultId }))
  }

  render() {
  
    let rows = []
    rows = this.props.samples.map((sample) => {
      return (
        <Table.Row key={sample.id} onClick={() => { this.goToResult(sample.id) }}>
          <Table.Cell>{(sample.intake_form && sample.intake_form.batch_number) ? sample.intake_form.batch_number : ''}</Table.Cell>
          <Table.Cell>{(sample.intake_form && sample.intake_form.strain_name) ? sample.intake_form.strain_name : ''}</Table.Cell>
          <Table.Cell>{dateTimeFormatter(sample.created_at)}</Table.Cell>
          <Table.Cell>{sample.user.name}</Table.Cell>
        </Table.Row>
      )
    })
    return (
      <div className="sampleWidget contentBlock homeBlock blockTable">
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Batch Number</Table.HeaderCell>
              <Table.HeaderCell>Strain/Sample Name</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Tester</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows}
            {rows.length === 0 && (<Table.Row colSpan='4' textAlign='center'>
              <Table.Cell colSpan='4'>No samples have been run</Table.Cell>
            </Table.Row>)}
          </Table.Body>
          {rows.length > 0 && <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='4'>
                <Link to={formatRoute(constants.APPLICATION_ROUTE.SAMPLE.LIST.ROUTE, {})}>See Previous Results</Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>}
        </Table>
      </div>
    )
  }
}

export default withRouter(ResultsWidget)
