const trimWhiteSpace = (value) => {
  switch (typeof value) {
    case 'string':
      return value.trim()
    case 'number':
      return Number(value.toString().trim())
    default:
      return value
  }
}

export default trimWhiteSpace
