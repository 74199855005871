import React, { useState } from 'react'
import { Table, Button, Icon, Checkbox, Segment, List, Header, Grid, Label } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import randomHexColor from 'random-hex-color'

function ParsedCsvDisplay(props) {
	const [showMapping, toggleMapping] = useState(false)
	const mappedColors = {}

	let display = (
		<Table celled compact size='small' className='csvTable'>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell />
					{Object.keys(props.parsed[0]).map((field) => { // eslint-disable-line
						return <Table.HeaderCell key={field}>{field}</Table.HeaderCell>
					})}
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{props.parsed.map((row, rowIndex) => (
					<Table.Row
						key={`row${rowIndex}`} // eslint-disable-line
						className='mappingRow'
					>
						<Table.Cell className='rowNumber'>{rowIndex}</Table.Cell>
						{Object.keys(row).map((cellKey) => <Table.Cell key={cellKey} className='mappingCell'>{row[cellKey]}</Table.Cell>)}
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	)

	if (props.mappingTemplate && showMapping) {
		Object.values(props.mappingTemplate.rows).forEach((value) => { mappedColors[value] = randomHexColor() })
		Object.values(props.mappingTemplate.metadata).forEach((value) => { mappedColors[value] = randomHexColor() })
		Object.values(props.mappingTemplate.analytes).forEach((value) => {
			if (!props.batchTemplate) {
				mappedColors[value] = randomHexColor()
			}
			else {
				mappedColors[value['Name']] = randomHexColor()
				mappedColors[value['Concentration %']] = randomHexColor()
				if (value['Units']) mappedColors[value['Units']] = randomHexColor()
			}
		})
		
		display = (
			<Table celled compact size='small' className='csvTable'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						{Object.keys(props.parsed[0]).map((field) => { // eslint-disable-line
							return <Table.HeaderCell key={field}>{field}</Table.HeaderCell>
						})}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{props.parsed.map((row, rowIndex) => (
						<Table.Row
							key={`row${rowIndex}`} // eslint-disable-line
							className='mappingRow'
							style={
								mappedColors[`${rowIndex}`]
									? { border: `2px solid ${mappedColors[rowIndex]}` }
									: { border: 'initial' }
							}
						>
							<Table.Cell className='rowNumber'>{rowIndex}</Table.Cell>
							{Object.keys(row).map((cellKey) => {
								const mappedColor = mappedColors[`${rowIndex},${cellKey}`]
									? { background: mappedColors[`${rowIndex},${cellKey}`] }
									: { background: 'initial' }
								return <Table.Cell key={cellKey} className='mappingCell' style={mappedColor}>{row[cellKey]}</Table.Cell>
							})}
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		)
	}

	return (
		<div className='parsedCsvContainer'>
			{((props.sourceFile && props.sourceFilename) || props.mappingTemplate) &&
				<div className='csvTools'>
					{(props.sourceFile && props.sourceFilename)
						&& (
							<Button
								icon labelPosition='left' className='blue'
								onClick={() => {
									const link = document.createElement('a')
									link.href = props.sourceFile
									link.setAttribute('download', props.sourceFilename)
									document.body.appendChild(link)
									link.click()
								}}
							>
								<Icon name='download' />Download CSV: {props.sourceFilename}
							</Button>
						)}
					{props.mappingTemplate
						&& (
							<Checkbox
								toggle
								checked={showMapping}
								label={showMapping ? 'Hide Map' : 'Show Map'}
								onClick={() => toggleMapping(!showMapping)}
								style={{ margin: '0 1.5em 0 1.5em' }}
							/>
						)}
				</div>
			}
			<Grid>
				{showMapping
					&& (
						<Grid.Column width={3}>
							<Segment>
								<List divided selection>
									<Header as='h5'>
										<strong>Header Rows</strong>
									</Header>
									{Object.keys(props.mappingTemplate.rows).map((row) => (
										<List.Item key={row}>
											<Label style={{ border: `2px solid ${mappedColors[props.mappingTemplate.rows[row]]}`,
												backgroundColor: 'white',
												color: 'black' }}
											>
												{row}
											</Label>
										</List.Item>
									))}
								</List>
							</Segment>
							<Segment>
								<List divided selection>
									<Header as='h5'>
										<strong>Analyte Headers</strong>
									</Header>
									{Object.keys(props.mappingTemplate.analytes).map((header) => {
										return !props.batchTemplate ? (
											<List.Item key={header}>
												<Label style={{
													background: `${mappedColors[props.mappingTemplate.analytes[header]]}`,
													color: 'black'
												}}
												>
													{header}
												</Label>
											</List.Item>
										) : (
											<List.Item key={header}>
												<Label style={{
													background: `${mappedColors[props.mappingTemplate.analytes[header]['Name']]}`,
													color: 'black'
												}}
												>
													{header}
												</Label>
												<Label style={{
													background: `${mappedColors[props.mappingTemplate.analytes[header]['Concentration %']]}`,
													color: 'black'
												}}
												>Conc. %</Label>
												{props.mappingTemplate.analytes[header]['Units'] &&
													<Label style={{
														background: `${mappedColors[props.mappingTemplate.analytes[header]['Units']]}`,
														color: 'black'
													}}
													>Units</Label>
												}
											</List.Item>
										)
									})}
								</List>
							</Segment>
							<Segment>
								<List divided selection>
									<Header as='h5'>
										<strong>Metadata Cells</strong>
									</Header>
									{Object.keys(props.mappingTemplate.metadata).map((meta) => (
										<List.Item key={meta}>
											<Label style={{
												background: `${mappedColors[props.mappingTemplate.metadata[meta]]}`,
												color: 'black'
											}}
											>
												{meta}
											</Label>
										</List.Item>
									))}
								</List>
							</Segment>
						</Grid.Column>
					)}
				<Grid.Column width={showMapping ? 13 : 16}>{display}</Grid.Column>
			</Grid>

		</div>
	)
}

ParsedCsvDisplay.propTypes = {
	parsed: PropTypes.array.isRequired,
	mappingTemplate: PropTypes.objectOf(PropTypes.shape({
		rows: PropTypes.objectOf(PropTypes.shape({})),
		analytes: PropTypes.objectOf(PropTypes.shape({})),
		metadata: PropTypes.objectOf(PropTypes.shape({}))
	}))
}.isRequired

export default ParsedCsvDisplay
