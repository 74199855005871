import React from 'react'
import PropTypes from 'prop-types'
import constants from '../../lib/constants'

const iframeBaseURL = 'https://forms.zohopublic.com/jtsprockett/form/CannIDSupportTicketRequest/formperma/AwixY3w6GTZHARTrQ8bpG3PRAvE0oeVDXu_zjRgGjU8'

function buildIframeURL(fullName, email, organizationName) {
	let firstName
	let lastName
	let splitName = []

	if (fullName) {
		splitName = fullName.split(' ')
	}

	if (splitName.length > 1) {
		[firstName, lastName] = splitName
	}
	else if (splitName.length === 1) {
		firstName = splitName[0]
		lastName = splitName[0]
	}

	let queryString = ''
	if (firstName && lastName && email && organizationName) {
		queryString = `?Name_First=${firstName}&Name_Last=${lastName}&Email=${email}&Radio=Email&SingleLine1=${organizationName}`
	}

	return `${iframeBaseURL}${queryString}`
}

function SubmitTicket({ fullName, email, organizationName }) {
	const iframeURL = buildIframeURL(fullName, email, organizationName)

	return (
		<section>
			<p>
				{'For technical support, to leave a suggestion, or make a comment, please '}
				<a href='mailto:clientservices@ionizationlabs.com'>email client services</a>
				, or complete and submit the form below.
				{' '}
				<br />
				Our team will review the request and respond in an expedient manner.
			</p>
			<p>
				{'Manage your tickets and explore the documentation at the '}
				<a href={constants.KNOWLEDGE_BASE_LINK} target='_blank' rel='noreferrer'>Knowledge Base</a>
			</p>
			<iframe title='Support Ticket Submission Form' src={iframeURL} width='100%' style={{ height: '120vh' }} />
		</section>
	)
}

SubmitTicket.propTypes = {
	fullName: PropTypes.string,
	email: PropTypes.string,
	organizationName: PropTypes.string
}

SubmitTicket.defaultProps = {
	fullName: undefined,
	email: undefined,
	organizationName: undefined
}

export default SubmitTicket
