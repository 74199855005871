import React  from 'react'
import { Button, Form, Grid, Segment, Header, Popup, Icon } from 'semantic-ui-react'
import { userServices } from '../services/User'
import ToastMessage from '../components/Message'
import constants from '../lib/constants'
import ErrorMessages from './Errors/ErrorMessages'
import passwordStrength from '../lib/passwordStrength'
import PasswordStrengthMeter from '../lib/PasswordStrengthMeter'
import passwordValidity from '../lib/passwordValidity'
import { formatRoute } from 'react-router-named-routes'
class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      formErrors: {
        passwordError: false,
        confirmPasswordError: false
      },
      errorMessage: '',
      submitFlag: null,
      isLinkValid: true,
      apiErrors: {}
    }
  }
  componentDidMount() {
    let token = this.props.match.params.token
    userServices.linkValidity(token).then((res) => {
      this.setState({
        apiErrors: {}
      })
    }).catch((e) => {
      this.setState({
        isLinkValid: false,
        apiErrors: (e.data) ? e.data : {}
      })
    })
  }
  handleResetPassword = () => {
    let formValid = true
    formValid = this.validateForm()
    if (!formValid) {
      return false
    }
    let configJson = {
      user: {
        password: this.state.password,
      }
    }
    let token = this.props.match.params.token
    userServices.resetPassword(configJson, token).then((res) => {
      this.setState({ submitFlag: true, apiErrors: {} })
      setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})), 1000)
    }).catch((e) => {
      this.setState({ submitFlag: false, errorMessage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} })
    }
    )
  }
  checkPassword = () => {
    if (!this.state.password.match(constants.REG_EX.PASSWORD_REG_EX)) {
      if (this.state.password.length < constants.PASSWORD.MIN_LENGTH) {
        this.setState({ errorMessage: constants.RESET_PASSWORD.PASSWORD_STRENGTH })
      }
      else
        this.setState({ errorMessage: constants.RESET_PASSWORD.PASSWORD_STRENGTH })
      return true
    }
    else {
      this.setState({ errorMessage: '' })
      return false
    }
  }
  checkConfirmPassword = () => {
    const { confirmPassword, password } = this.state
    if (confirmPassword.length > 0 && confirmPassword === password) {
      return false
    }
    return true
  }
  validateForm = () => {
    let tempFormError = { ...this.state.formErrors }
    if (this.checkPassword(this.state.password)) {
      tempFormError.passwordError = true
    }
    if (this.checkConfirmPassword(this.state.confirmPassword)) {
      tempFormError.confirmPasswordError = true
    }
    this.setState({
      formErrors: {
        ...tempFormError
      }
    })
    for (let key in tempFormError) {
      if (tempFormError[key] === true)
        return false
    }
    return true
  }
  isFieldValid = (field) => {
    let tempFormError = { ...this.state.formErrors }
    this.setState({ errorMessage: '' })
    if (this.state[field] === '') {
      tempFormError[`${field}Error`] = true
    }
    else {
      tempFormError[`${field}Error`] = false
    }
    this.setState({
      formErrors: { ...tempFormError }
    }, () => { this.passwordFormValidation() })
  }
  update = (e) => {
    const newState = {}
    let target = e.target
    newState[target.name] = target.value
    this.setState(newState, () => {
      this.isFieldValid(target.name)
    })
  }
  passwordFormValidation = () => {
    let tempFormError = { ...this.state.formErrors }
    let passwordErrors = passwordValidity(this.state.password, this.state.confirmPassword)
    tempFormError['confirmPasswordError'] = passwordErrors.confirmPassword
    tempFormError['passwordError'] = passwordErrors.password
    if (tempFormError['passwordError']) {
      this.setState({ errorMessage: constants.CHANGE_PASSWORD.PASSWORD_STRENGTH })
    }
    this.setState({
      formErrors: { ...tempFormError }
    })
  }
  render() {
    let passwordStrengthCalc = passwordStrength(this.state.password)
    return (
      <Grid id="resetPassword" textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Header as='h2' className='headerColor' textAlign='center'>
            {constants.RESET_PASSWORD.HEADER_TITLE}
          </Header>
          <ErrorMessages errors={this.state.apiErrors} mobile={16} tablet={16} computer={16} largeScreen={16}></ErrorMessages>
          <Form size='large' onSubmit={this.handleResetPassword}>
            <Segment stacked className='segmentPadding'>
              {
                this.state.submitFlag === true && <ToastMessage title='Success' description={constants.RESET_PASSWORD.SUCCESS} />
              }
              <Grid>
                <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                  <Form.Field>
                    <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} className='required-field' label={<React.Fragment><label>Password <Popup header={constants.CHANGE_PASSWORD.PASSWORD_CRITERIA_TITLE} content={constants.CHANGE_PASSWORD.PASSWORD_STRENGTH} trigger={<Icon name='info circle' color="grey" />} /></label></React.Fragment>} placeholder='Password' type='password' value={this.state.password} onChange={this.update} name="password" ref={this.passwordRef} error={this.state.formErrors.passwordError} />
                  </Form.Field>
                </Grid.Column>
                {this.state.password &&
                  <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                    <PasswordStrengthMeter score={passwordStrengthCalc} />
                  </Grid.Column>
                }
                {
                  this.state.formErrors.passwordError && this.state.password.length === 0 && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>
                }
                <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                  <Form.Field>
                    <Form.Input  maxLength={constants.FIELD_LENGTH.MAX_64} className='required-field' label='Confirm Password' placeholder='Confirm Password' type='password' value={this.state.confirmPassword} onChange={this.update} name="confirmPassword" ref={this.passwordRef} error={this.state.formErrors.confirmPasswordError} />
                  </Form.Field>
                  {
                    this.state.formErrors.confirmPasswordError && this.state.confirmPassword.length > 0 && <p className='error'>{constants.CHANGE_PASSWORD.PASSWORD_MATCH}</p>
                  }
                  {
                    this.state.formErrors.confirmPasswordError && this.state.confirmPassword.length === 0 && <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>
                  }
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16}>
                  <Button className='confirmButton' type='submit' disabled={!this.state.isLinkValid}>{constants.RESET_PASSWORD.RESET_PASSWORD_BUTTON_TITLE}</Button>
                </Grid.Column>
              </Grid>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
}
export default ResetPassword
