import React, { Component } from 'react'
import { Button, Grid, Image, Message, Icon } from 'semantic-ui-react'
import AppHeader from './AppHeader'
import { Link } from 'react-router-dom'
import SampleInfo from './SampleInfo'
import sample from '../data/sample'
import SampleProcessor from '../lib/SampleProcessor'
import ConditionalRender from './ConditionalRender'
import ChromatogramLineChart from './Charts/ChromatogramLineChart'
import ReportResultsTable from './SampleTests/ReportResultsTable'
import QCReportResultsTable from './SampleTests/QCReportResultsTable'
import BarChartComponent from './Charts/BarChartComponent'
import QCBarChart from './Charts/QCBarChart'
import PieChartComponent from './Charts/PieChartComponent'
import DerivedCompoundsTotals from './SampleTests/DerivedCompoundsTotals'
import axiosNoToken from '../axiosNoToken'
import { SampleTestServices } from '../services/SampleTest'
import Footer from './Footer'
import SharedHeader from './SharedHeader'
import cannLogo from '../images/cann-id.png'
import print from 'print-js'
import $ from 'jquery'
import reportNameFormatter from '../lib/reportNameFormatter'
import handleDefaultValue from '../lib/handleDefaultValue'
import isQC from '../lib/isQC';
import { isMobile } from 'react-device-detect'

import certusLogoGreen from '../images/certus_transparent.png'

let QRCode = require('qrcode.react')
class Sample extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sample: {
        sample_name: 'loading'
      },
      share: false,
      printingPdfReport: false,
      donut_inner_text: {
        top: 'THC',
        bottom: ''
      }
    }
    this.sampleProcessor = new SampleProcessor(sample)
    this.sampleId = this.props.match.params.id
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    SampleTestServices.getSampleShare(this.sampleId).then((response) => {
      let sample = response.data
      const dbSampleProcessor = new SampleProcessor(sample)
      const resState = dbSampleProcessor.hasResults() ? { isResultsExist: true } : {}

      this.setState({
        ...resState,
        sample,
        donut_inner_text: {
          top: 'THC',
          bottom: (sample.dominant_analyte) ? sample.dominant_analyte : ''
        }
      })
    }).catch((error) => {
		console.error(error)
		this.setState({sample: {sample_error: `Sample Error: ${error.status} ${error.statusText}`}})
	})

    //Override Ctrl+P to print manually
    $(document).bind("keyup keydown", function (e) {
      if (e.ctrlKey && e.keyCode === 80) {
        this.printReport()
        return false
      }
    }.bind(this))
  }

  printReport = () => {
    if (!this.state.printingPdfReport) {
      this.setState({ printingPdfReport: true })
      axiosNoToken().get(SampleTestServices.getPdfReportDownloadUrl(this.sampleId), { responseType: 'blob' })
        .then((response) => {
          if (response.status === 200) {
            try {
              if (isMobile) {
                if (!navigator.userAgent.match('CriOS') && !navigator.userAgent.toLowerCase().indexOf("android") > -1)
                  print(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
                else
                  window.open(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
              }
              else
                print(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
            } catch (e) {
            }
          }
        })
        .finally(() => {
          this.setState({ printingPdfReport: false })
        })
    }
  }

  resize = () => this.forceUpdate()
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  downloadReport() {
    axiosNoToken().get(SampleTestServices.getPdfReportDownloadUrl(this.sampleId)).then((response) => {
      if (response.status === 200) {
        if (navigator.userAgent.match('CriOS')) {
          var reader = new FileReader()
          var out = new Blob([response.data], { type: 'application/pdf' })
          reader.onload = function (e) {
            window.location.href = reader.result
          }
          reader.readAsDataURL(out)
        }
        else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${reportNameFormatter(handleDefaultValue(this.state.sample.intake_form.batch_number), this.state.sample.intake_form.strain_name, this.state.sample.organization.name)}.pdf`)
          document.body.appendChild(link)
          link.click()
        }
      }
    })
  }

  render() {
    const sample = this.state.sample

	if (sample.sample_name && sample.sample_name === 'loading') {
		return <Message style={{margin: '3em'}}>Loading...</Message>
	}
	if (sample.sample_error) {
		return <Message error style={{margin: '3em'}}>{this.state.sample.sample_error}</Message>
	}

    const jitSampleProcessor = new SampleProcessor(sample)
	const sampleIsQC = isQC.sample(sample)

	let barchartToRender
	let tableToRender

	if (sampleIsQC) {
		barchartToRender = <QCBarChart sample={sample} />
		tableToRender = <QCReportResultsTable sample={sample} />
	}
	else {
		barchartToRender = <BarChartComponent barchart={sample.barchart} elution_order={sample.elution_order}/>
		tableToRender = <ReportResultsTable sample={sample} />
	}

    let adminPanel
    if (window.location.hash.indexOf('admin') > -1) {
      adminPanel = (
        <section className="app dark">
          <div className="contentBlock">
            <h1>Admin Actions</h1>
            <Link to={`/samples/${parseInt(this.props.match.params.id, 10)}/import`}><Button className='confirmButton'>Import from Chemstation</Button></Link>
          </div>
        </section>
      )
    }
    let results, concentrationMessage
    if (sample && jitSampleProcessor.finished()) {
      if (jitSampleProcessor.sample.result_type === "CLOSE" || jitSampleProcessor.sample.result_type === "EDGING") {
        concentrationMessage = (
          <Grid centered>
            <Grid.Column mobile={16} tablet={16} largeScreen={12} computer={12} textAlign='center' className='sample_report'>
              <div id="runStatus">
                Due to the extremely high cannabinoid counts detected within your sample, the results displayed are close estimates that fall within state and federal guidelines.
              </div>
            </Grid.Column>
          </Grid>
        )
      }
      if (jitSampleProcessor.sample.result_type === "OVER") {
        concentrationMessage = (
          <Grid centered>
            <Grid.Column mobile={16} tablet={16} largeScreen={12} computer={12} textAlign='center' className='sample_report'>
              <div id="runStatus">
                Cann-ID has detected an error in this sample preparation. Please click the following link and follow the instructions to remake the sample. <a href="https://www.evernote.com/l/Aoo53S-3qJhC8LCO2g2ywILg_z5O_SvdoiI" target="_blank">Sample Preparation Checklist</a>
              </div>
            </Grid.Column>
          </Grid>
        )
        results = concentrationMessage
      } else {
        const chartsAndTotals = Object.keys(sample.derived_compounds).length > 0 ? (
          <React.Fragment>
				<ConditionalRender condition={!sampleIsQC}>
					<Grid.Column tablet={16} mobile={16} largeScreen={8} computer={8} textAlign='center'>
						<PieChartComponent data={sample}/>
						<div id="legend-item-container"></div>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} largeScreen={8} computer={8} textAlign='center' verticalAlign='middle'>
						<DerivedCompoundsTotals derivedCompounds={this.state.sample.derived_compounds} />
					</Grid.Column>
				</ConditionalRender>
			<Grid.Column tablet={16} mobile={16} largeScreen={16} computer={16} textAlign='center'>
				{barchartToRender}
			</Grid.Column>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid.Column tablet={16} mobile={16} largeScreen={8} computer={8} textAlign='center'>
              <PieChartComponent data={sample}/>
              <div id="legend-item-container"></div>
            </Grid.Column>
            <Grid.Column tablet={16} mobile={16} largeScreen={8} computer={8} textAlign='center'>
              <BarChartComponent barchart={this.state.sample.barchart} elution_order={this.state.sample.elution_order}/>
            </Grid.Column>
          </React.Fragment>
        )

        results = (
          <div>
            {!jitSampleProcessor.hasResults() &&
              <div className="theBadNews">
                No cannabinoids detected. Machine may be out of calibration or product may not have cannabinoids.
              </div>
            }
            <Grid centered>
              {concentrationMessage && <Grid.Column tablet={16} mobile={16} largeScreen={16} computer={16}>
                {concentrationMessage}
              </Grid.Column>}
              {chartsAndTotals}
              <Grid.Column tablet={16} mobile={16} largeScreen={16} computer={16} textAlign='center' className='sample_report'>
                <section className="app light sample_report">
                  <ChromatogramLineChart openOnLoad {...this.state}/>
                </section>
              </Grid.Column>
              <Grid.Column tablet={16} mobile={16} largeScreen={16} computer={16} textAlign='center'>
                <section className="app light tablita">
					{tableToRender}
                </section>
              </Grid.Column>
            </Grid>
          </div>
        )
      }
    }

    return (
      <div>
        <SharedHeader />
        <section id="mainContent" className="app light">
          <Grid centered className="sample_report">
            <Grid.Column mobile={16} tablet={16} largeScreen={3} computer={3} textAlign={window.screen.width < 800 ? 'center' : 'left'}>
              <Grid>
                <Grid.Column mobile={8} tablet={8} largeScreen={8} computer={8} textAlign='center' verticalAlign='middle'>
                  <img alt='CannID Logo' src={cannLogo} className='shareReportCannIdLogo' style={{ maxWidth: '250px', width: '100%', hspace: '10px' }} />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} largeScreen={8} computer={8} textAlign='center' >
                  <QRCode value={process.env.REACT_APP_SELF_REFERENCE_ROOT + '/qr/' + sample.share_key} size={100} />
                  <p>Cann-ID Report</p>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} largeScreen={8} computer={8} textAlign={window.screen.width < 800 ? 'center' : 'left'}>
              {(sample.certus && sample.certus.sample_certus) &&
                <div style={{border:'1px solid black',borderRadius:'1rem',backgroundColor:'#f3f3f3',padding:'8px 6px 8px 6px'}}>
                  <Grid textAlign='center' verticalAlign='middle'>
                    <Grid.Column mobile={8} tablet={8} largeScreen={5} computer={5}>
                      <a href={process.env.REACT_APP_CERTUS_SCANNER_LINK}>
                        <QRCode value={process.env.REACT_APP_CERTUS_SCANNER_LINK} size={100} />
                        <p>
							<strong>
								CERTUS
								<Icon
									style={{ position: 'relative', bottom: '.35rem' }}
									name='registered outline' size='tiny' />
								QR Scanner
							</strong>
							<br />
							<span>(Open 1st)</span>
						</p>
                      </a>
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} largeScreen={5} computer={5} style={{paddingTop: '10px'}}>
                      <Image style={{width: '100%', maxWidth: '145px', margin: '0 auto'}} src={certusLogoGreen} alt="Certus Logo"/>
                      <p style={{color:'#4d9851'}}><strong>VERISEAL<br/>{sample.certus.sample_certus.certus_type.certus_type}</strong></p>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} largeScreen={6} computer={6}>
                      <QRCode value={sample.certus.sample_certus.qr[0]} size={200} />
                    </Grid.Column>
                  </Grid>
                </div>
              }
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} largeScreen={4} computer={4} textAlign='center'>
              <Grid centered>
                <Grid.Column mobile={8} tablet={8} largeScreen={8} computer={8} textAlign='center' >
                  <img alt='Organization Logo' style={{ border: '1px solid black', width: '100%', maxWidth: '145px', hspace: '10px', margin: '0 auto'}} src={sample.organization && sample.organization.logo} />
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} largeScreen={8} computer={8} textAlign={window.screen.width < 800 ? 'center' : 'left'} >
                  <span id='sharedCompanyInfo'><b>{sample.organization && sample.organization.name}</b><br />
                    {sample.organization && sample.organization.address} <br />
                    {sample.organization && sample.organization.contact_no}<br />
                    <a href="mailto:#">{sample.organization && sample.organization.email}</a>
                  </span>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid centered className="sample_report" style={{marginBottom:'10px'}}>
            <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
              <Button className="confirmButton" icon='print' disabled={this.state.printingPdfReport} loading={this.state.printingPdfReport} labelPosition='left' content='Print' onClick={(e) => { e.stopPropagation(); this.printReport() }}></Button>
            </Grid.Column>
          </Grid>
          <section className="app light">
            <AppHeader title={<h1>Cann-ID Results</h1>} />
            <Grid centered className="sample_report">
              <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center' className='dataSampleTable'>
                <SampleInfo sample={sample} />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
                {results}
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
                {adminPanel}
              </Grid.Column>
            </Grid>
          </section>
        </section>
        <Footer />
      </div>
    )
  }
}

export default Sample
