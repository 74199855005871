import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Segment, Header } from 'semantic-ui-react'

function AttachmentUploader(props) {
	const [error, setError] = useState(null)

	const onDrop = useCallback(acceptedFiles => {
		console.log('dropped file:', acceptedFiles)
		// new drop, clear the errors
		setError(null)

		if (acceptedFiles[0]) {
			props.handleFile(acceptedFiles[0])
		}
		else {
			setError('Error getting file.')
		}
	})

	const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})

	return (
		<div className='dropZone' {...getRootProps()} style={{padding: '0', margin: '20px 10px'}}>
			<Segment inverted color='grey'>
				<Header icon size='small' style={{margin: '0'}}>
					<img src='https://cdn.chemid.com/images/dragon_drop/dragon_drop_head_chemid_479x479.png' alt='ChemID Dragon Drop'
						className='dragonDrop'
						style={{marginBottom: '1em'}} />
					<br />
					{!isDragActive ? <span>Upload or <i>Dragon Drop</i> the file here</span> : 'Drop to upload!'}
					{error && <div className='zone inactive error'>{error}</div>}
				</Header>
				<input {...getInputProps()} />
			</Segment>
		</div>
	)
}

export default AttachmentUploader
