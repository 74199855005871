import React from 'react'
class Timer extends React.Component {
  render() {
    let minutes = Math.floor(this.props.time / 60)
    let seconds = this.props.time - minutes * 60
    return (
      <p>{(String(minutes).length === 1 ? '0' + minutes : minutes)}:{(String(seconds).length === 1 ? '0' + seconds : seconds)}</p>
    )
  }
}
export default Timer
