const vialTypes = {
  green_1ml: {
    name: "green 1ml",
    color: "rgba(0, 255, 0, 1)",
    volume_ul: 1000
  },
  blue_1ml: {
    name: "blue 1ml",
    color: "rgba(0, 0, 255, 1)",
    volume_ul: 1000
  },
  yellow_1ml: {
    name: "yellow 1ml",
    color: "rgba(255, 255, 0, 1)",
    volume_ul: 1000
  },
  red_1ml: {
    name: "red 1ml",
    color: "rgba(255, 0, 0, 1)",
    volume_ul: 1000
  }
}

export default vialTypes
