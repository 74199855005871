import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import PrepVial from './PrepVial'
import PrepMethodDiluentFlow from './PrepMethodDiluentFlow'
import PrepMethodDiluentComposer from './PrepMethodDiluentComposer'
import _ from 'lodash'

class PrepMethodEditTile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing_vial_id: null,
      editing_method: _.cloneDeep(props.prepMethod)
    }
  }

  setEditingVial = (vial_id) => {
    this.setState({editing_vial_id: vial_id})
  }

  calculateErrors = () => {
    console.log('check now or forever hold your peace')
  }

  updateEditingMethod = (e) => {
    const vialPropertyKey = e.target.name
    const newValue = e.target.value
    let action = vialPropertyKey.split('.')[1]
    if (action) {
      if (action === "reset") {
        this.setState({editing_method: _.cloneDeep(this.props.prepMethod)})
        console.log('reset')
      }
      else if (action === "cancelVialEdit") {
        this.setState({editing_vial_id: null})
      }
      else if (action === "done") {
        this.calculateErrors()
        this.setState({
          editing_vial_id: null
        })
        console.log('done')
      }
    } else {
      this.setState((prevState, props) => {
        let new_editing_method = {...prevState.editing_method}
        new_editing_method[e.target.name] = e.target.value
        return {editing_method: new_editing_method}
        console.log('updatey?')
      })
    }
  }

  render() {
    let composerDom
    if (this.state.editing_vial_id) {
      const editingVial = this.state.editing_method.prep_steps.find((v) => v.id === this.state.editing_vial_id)
      composerDom = <PrepMethodDiluentComposer
        prepMethod={this.state.editing_method}
        vial={editingVial}
        updateEditingMethod={this.updateEditingMethod}
      />
    }
    return (
      <div className="prepMethodTile prepMethodEditTile">
        <section className="header">
          <h3>
            <input type="text" value={this.state.editing_method.name} name="name" onChange={this.updateEditingMethod} />
          </h3>
          <div className="actions">
            <button onClick={this.props.cancel}>save</button>
            <button onClick={this.props.cancel}>cancel</button>
          </div>
        </section>
        <section className="content">
          <PrepMethodDiluentFlow prepMethod={this.state.editing_method} setEditingVial={this.setEditingVial} editingVialId={this.state.editing_vial_id} />
          {composerDom}
        </section>
      </div>
    )
  }
}

export default PrepMethodEditTile
