import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'

import { connect } from 'react-redux'
import StatusNode from './StatusNode'

const mapStateToProps = (state) => {
	const { machineState } = state
	return { machineState }
}

class SystemStatus extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.readyCallback) {
			if (this.props.machineState.status === 'PRERUN' && prevProps.machineState.status !== 'PRERUN') {
				this.props.readyCallback()
			}
		}
	}

	render() {
		return (
			<div className='systemStatus'>
				<h3>System Status</h3>
				<Grid padded>
					<Grid.Column mobile={16} tablet={12} largeScreen={8} computer={8}>
						<Grid>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} className='systemStatusSteps'>
								<StatusNode nodestatus='READY' label='Injector Position' />
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} className='systemStatusSteps'>
								<StatusNode nodestatus={this.props.machineState.pumpstate} label='Pressure' />
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} className='systemStatusSteps'>
								<StatusNode nodestatus='READY' label='Calibration' />
							</Grid.Column>
						</Grid>
					</Grid.Column>
					<Grid.Column mobile={16} tablet={12} largeScreen={8} computer={8}>
						<Grid>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} className='systemStatusSteps'>
								<StatusNode nodestatus={this.props.machineState.tempstate} label='Temperature' />
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} className='systemStatusSteps'>
								<StatusNode nodestatus={this.props.machineState.detectorstate} label='Detector' />
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} className='systemStatusSteps'>
								<StatusNode nodestatus='READY' label='Supply Estimates' />
							</Grid.Column>
						</Grid>
					</Grid.Column>
				</Grid>
			</div>
		)
	}
}

export default connect(mapStateToProps)(SystemStatus)
