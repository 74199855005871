import React, { Component } from 'react'
import { Table, Grid } from 'semantic-ui-react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { SampleTestServices } from '../../services/SampleTest'
import DynamicTableComponent from '../DynamicTableComponent'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import constants from '../../lib/constants'
import axios from '../../axios'
import logoutSession from '../../lib/logoutSession'
import checkDynamicTableActions from '../../lib/checkDynamicTableActions'
import { selectedTests } from '../../actions'
import reportNameFormatter from '../../lib/reportNameFormatter'

const tableHeader = [
	{ id: 'check', title: '', sortable: false, type: 'checkbox' },
	{ id: 'batch_number', title: 'Batch Number', sortable: false, type: 'src' },
	{ id: 'strain_name', title: 'Strain/Sample Name', sortable: false, type: 'src' },
	{ id: 'sample_weight', title: 'Sample Weight', sortable: false, type: 'src' },
	{ id: 'created_at', title: 'Date Created', sortable: true, type: 'src' },
	{ id: 'updated_at', title: 'Date Updated', sortable: true, type: 'src' }
]
const options = [
	{ key: 'batch_number', text: 'Batch Number', value: 'batch_number' },
	{ key: 'strain_name', text: 'Strain/Sample Name ', value: 'strain_name' },
	{ key: 'sample_weight', text: 'Sample weight ', value: 'sample_weight' }
]
const mapStateToProps = (state) => ({
	selected_tests: state.selected_tests,
	current_user: state.current_user
})
const mapDispatchToProps = (dispatch) => ({
	selectedTests: (tests) => { dispatch(selectedTests(tests)) }
})
class TestResults extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableData: [],
			count: 0,
			selectedSearchDropdown: 'batch_number',
			sampleFlag: false,
			sampleTestResultFlag: false,
			apiErrors: {},
			selectedCheckBoxes: []
		}
	}

	resize = () => this.forceUpdate()

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize)
	}

	getTableData = (res) => {
		const response = get(res, 'data.data', false) || []
		const count = get(res, 'data.meta.total_count', false) || 0
		const tableData = response.map((data) => ({
			id: data.id,
			intake_number: data.intake_form ? data.intake_form.intake_number : 'NA',
			batch_number: data.intake_form ? data.intake_form.batch_number : 'NA',
			strain_name: data.intake_form ? data.intake_form.strain_name : 'NA',
			sample_weight: data.intake_form && data.intake_form.sample_weight !== 'NaN' ? data.intake_form.sample_weight : 'NA',
			share_key: data.share_key ? data.share_key : 'NA',
			created_at: dateTimeFormatter(data.created_at),
			updated_at: dateTimeFormatter(data.updated_at),
			organization_name: data.organization ? data.organization.name : 'NA',
			isResult: !data.results
		}))
		this.setState({
			tableData,
			count,
			initialCount: count,
			initialData: tableData
		})
	}

	getFilteredData = (data) => {
		const arrayList = get(data, 'data.data', false) || []
		const count = get(data, 'data.meta.total_count', false) || 0
		const tableData = arrayList.map((data) => ({
			id: data.id,
			intake_number: data.intake_form ? data.intake_form.intake_number : 'NA',
			batch_number: data.intake_form ? data.intake_form.batch_number : 'NA',
			strain_name: data.intake_form ? data.intake_form.strain_name : 'NA',
			sample_weight: data.intake_form && data.intake_form.sample_weight !== 'NaN' ? data.intake_form.sample_weight : 'NA',
			share_key: data.share_key ? data.share_key : 'NA',
			created_at: dateTimeFormatter(data.created_at),
			updated_at: dateTimeFormatter(data.updated_at),
			organization_name: data.organization ? data.organization.name : 'NA',
			isResult: !data.results
		}))
		this.setState({ tableData, count })
	}

	sortByColumn = (tableData) => {
		this.setState({ tableData })
	}

	rowAction = (action, data, e) => {
	}

	removeSample = (id) => {
		SampleTestServices.deleteSample(id).then((res) => {
			if (res && res.status === 202) {
				const tableData = [...this.state.tableData].filter((data) => data.id !== id)
				this.setState({ sampleFlag: false, tableData, count: this.state.count - 1, apiErrors: {} })
			}
		}).catch((error) => {
			this.setState({ apiErrors: (error.data) ? error.data : {} })
		})
	}

	getSelectedDropdown = (e, { value }) => {
		this.setState({ selectedSearchDropdown: value, tableData: this.state.initialData, count: this.state.initialCount })
	}

	closeModal = () => {
		this.setState({ sampleFlag: false, sampleTestResultFlag: false })
	}

	downloadReport = (data) => {
		axios().get(SampleTestServices.getPdfReportDownloadUrl(data.share_key), { responseType: 'blob' })
			.then((response) => {
				if (response.status === 200) {
					if (navigator.userAgent.match('CriOS')) {
						const reader = new FileReader()
						const out = new Blob([response.data], { type: 'application/pdf' })
						reader.onload = function (e) {
							window.location.href = reader.result
						}
						reader.readAsDataURL(out)
					}
					else {
						const url = window.URL.createObjectURL(new Blob([response.data]))
						const link = document.createElement('a')
						link.href = url
						link.setAttribute('download', `${reportNameFormatter(data.batch_number, data.strain_name, data.organization_name)}.pdf`)
						document.body.appendChild(link)
						link.click()
					}
					this.setState({ apiErrors: {} })
				}
			}).catch((error) => {
				if (error.status === 401 || error.data.message === constants.LOGIN.SIGNATURE_EXPIRY) {
					logoutSession()
				}
				this.setState({ apiErrors: (error.data) ? error.data : {} })
			})
	}

	componentDidMount() {
		window.addEventListener('resize', this.resize)
		this.props.selectedTests({ batch_id: this.props.selected_tests.batch_id, selectedCheckBoxes: get(this, 'props.selected_tests.selectedCheckBoxes', false) || [], type: constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.OVERALL_TEST })
	}

	checkBoxHandler = (e, data, row) => {
		e.stopPropagation()
		let selectedCheckBoxes = get(this, 'props.selected_tests.selectedCheckBoxes', false) || []
		if (this.props.selected_tests.type && this.props.selected_tests.type !== constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.OVERALL_TEST) {
			selectedCheckBoxes = []
		}
		if (data.checked) {
			selectedCheckBoxes.push({ id: row.id })
		}
		else {
			const index = selectedCheckBoxes.findIndex((x) => x.id === row.id)
			if (index >= 0) selectedCheckBoxes.splice(index, 1)
		}
		this.props.selectedTests({ selectedCheckBoxes, batch_id: '', type: constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.OVERALL_TEST })
	}

	checkBoxHandlerAll = (e, data, rows) => {
		e.stopPropagation()
		const selectedCheckBoxes = get(this, 'props.selected_tests.selectedCheckBoxes', false) || []
		rows.map((row) => {
			if (data.checked) {
				const index = selectedCheckBoxes.findIndex((x) => x.id === row.id)
				if (index < 0) {
					selectedCheckBoxes.push({ id: row.id })
				}
			}
			else {
				const index = selectedCheckBoxes.findIndex((x) => x.id === row.id)
				if (index >= 0) {
					selectedCheckBoxes.splice(index, 1)
				}
			}
			return null
		})
		this.props.selectedTests({ selectedCheckBoxes, batch_id: '', type: constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.OVERALL_TEST })
	}

	getSelectedCheckBoxes = () => {
		if (this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes) {
			return this.props.selected_tests.selectedCheckBoxes
		}
		return []
	}

	render() {
		const showActions = checkDynamicTableActions(tableHeader, constants.ACL.SAMPLE_RESOURCE.TITLE)
		const id = get(this, 'props.current_user.organization.id', false) || ''
		return (
			<div>
				<DynamicTableComponent
					entity={constants.ACL.SAMPLE_RESOURCE.TITLE}
					action={constants.ACL.SAMPLE_RESOURCE.ACTIONS.LIST}
					tableHeader={tableHeader}
					options={options}
					baseUrl={SampleTestServices.getAllTestResults(id)}
					rowData={this.state.tableData}
					initialData={this.state.initialData}
					selectedSearchDropdown={this.state.selectedSearchDropdown}
					getFilteredData={this.getFilteredData}
					getSelectedDropdown={this.getSelectedDropdown}
					colSpan={tableHeader.length - (showActions ? 0 : 1)}
					sortByColumn={this.sortByColumn}
					count={this.state.count}
					getData={this.getTableData}
					actionItem
					countTitle={constants.SAMPLE_TEST.LIST.TOTAL_TITLE}
					rowAction={this.rowAction}
					showActions={showActions}
					isCheckBoxRequired
					checkBoxHandler={this.checkBoxHandler}
					checkBoxHandlerAll={this.checkBoxHandlerAll}
					selectedCheckBoxes={this.getSelectedCheckBoxes()}
				>
					<Table.Header fullWidth>
						<Table.Row>
							<Table.HeaderCell colSpan={tableHeader.length - (showActions ? 0 : 1)}>
								<Grid centered>
									<Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8}>
										{constants.SAMPLE_TEST.LIST.TOTAL_TITLE}: {this.state.count}
									</Grid.Column>
									<Grid.Column mobile={16} tablet={8} largeScreen={8} computer={8} />
								</Grid>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
				</DynamicTableComponent>
			</div>
		)
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(TestResults)
