import React, { Component } from 'react'
import { Grid, Button, Image, Label, Icon, Popup, Menu, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ErrorMessages from '../Errors/ErrorMessages'
import certusLogoGreen from '../../images/certus_transparent.png'
import certusLogoGray from '../../images/certus_transparent_grayed.png'
import CertusModalCreate from './CertusModalCreate.js'
import CertusModalQr from './CertusModalQr.js'
import QrCodeDownload from './QrCodeDownload'

let QRCode = require('qrcode.react')
class SampleCertus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      certus: this.props.sample.certus,
      modalOpen: false,
      loading: false,
      apiErrors: {}
    }
  }

  render() {
    let certusStatus
    let certusExpiry

    if (this.state.certus.sample_certus) {
      const today = new Date()
      let parsedCertusDate = Date.parse(this.state.certus.sample_certus.expiration_date)
      certusStatus = this.state.certus ?
        this.state.certus.sample_certus.revoked ?
        <span style={{color:"red"}}> Revoked</span> :
        (parsedCertusDate < today.getTime()) ?
          <span style={{color:"red"}}> Expired</span> :
          <span> Valid</span>
        :
        ""
      // let certusExpiration = this.state.certus.sample_certus.revoked ? "Revoked" :
      certusExpiry = this.state.certus ?
        this.state.certus.sample_certus.revoked ?
        <span style={{color:"red"}}> Revoked</span> :
          (parsedCertusDate > today.getTime()) ?
            this.state.certus.sample_certus.expiration_date :
            <span style={{color:"red"}}> {this.state.certus.sample_certus.expiration_date} </span>
        :
        ""
    }

    const certusDisplay = this.state.certus.sample_certus ? (
		<Grid columns='equal' verticalAlign='middle' textAlign='center' stackable className="displayGrid">
			<Grid.Column>
				<Grid.Row>
					<Grid.Column>
						<a className="certusScannerLink" href={process.env.REACT_APP_CERTUS_SCANNER_LINK}>
							<QRCode value={process.env.REACT_APP_CERTUS_SCANNER_LINK} size={75} />
							<Label color='green' style={{textAlign:'center'}}>CERTUS<Icon style={{position: 'relative', bottom: '.35rem'}} name='registered outline' size='tiny'/>QR Scanner</Label>
						</a>
					</Grid.Column>
				</Grid.Row>
			</Grid.Column>
			<Grid.Column>
				<Grid.Row>
					<div className="descriptorGrid" onClick={() => this.setState({modalOpen: true})}>
						<Grid stackable className="certusLogoContainer">
							<Grid.Column textAlign='center' verticalAlign='middle' width={6} style={{paddingLeft: '.5rem', paddingRight: '0'}}>
								<Image className="certusLogo" src={certusLogoGreen} alt="Certus Logo"/>
							</Grid.Column>
							<Grid.Column textAlign='center' verticalAlign='middle' width={10} style={{paddingLeft: '0', paddingRight: '.5rem'}}>
								<h2>
									VERISEAL
									<br/>
									{this.state.certus.sample_certus.certus_type.certus_type}
								</h2>
								<div style={{textTransform: 'capitalize', paddingTop: '5px'}}>
									<strong>Expiration Date: </strong>{certusExpiry}
								</div>
								<div style={{textTransform: 'capitalize', paddingTop: '5px' }}>
									<strong>Status:</strong>{certusStatus}
								</div>
							</Grid.Column>
						</Grid>
					</div>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column style={{marginTop: '1rem'}}>
						<QrCodeDownload tracer={this.state.certus.sample_certus.share_key} sampleCertus />
					</Grid.Column>
				</Grid.Row>
			</Grid.Column>
			<Grid.Column>
				<Grid.Row>
					
					<Grid.Column>
						<QRCode value={this.state.certus.sample_certus.qr[0]} size={230} />
						<div>
							<span>
								<small style={{ backgroundColor: 'black', color: 'white', paddingTop: '.4em',paddingBottom: '.2em', letterSpacing: '.9em', position:'relative',  fontSize: '1.03em', fontWeight: 600 }}>
								<img width={19} height={18} style={{marginRight: '6px', marginLeft: '6px'}} src="https://cdn.chemid.com/images/chemid-logo-image.png" alt="chemid.logo"/>
								{this.state.certus.sample_certus.share_key}</small>
							</span>
							<p>
								<small>
									Result protected with CERTUS
									<Icon style={{ position: 'relative', bottom: '.35rem' }} name='registered outline' size='tiny' /> 
									KSI Blockchain
								</small>
							</p>
							<Popup
								content='Copied TRACER!'
								on='click'
								pinned
								trigger={
									<Menu className='sampleActionsMenu' compact={true} stackable>
										<Popup
											content='Copy TRACER'
											trigger={
												<Menu.Item
													onClick={() => {
														navigator.clipboard.writeText(this.state.certus.sample_certus.share_key) }
														}>
													<Icon name='copy' style={{margin: 0}} />
												</Menu.Item>
											}
										/>
									</Menu>
								}
							/>
						</div>
						</Grid.Column>
				</Grid.Row>
			</Grid.Column>
		</Grid>
    ) : (
		<Grid columns='equal' verticalAlign='middle' textAlign='center' stackable className="createGrid">
			<Grid.Column>
				<Image className="certusLogo" src={certusLogoGray} alt="Certus Logo" style={{margin:'0 auto'}}/>
			</Grid.Column>
			<Grid.Column>
				{this.state.loading ? (
					<Loader active={this.state.loading} size='big' inline='centered'>
						<small>
							Writing sample result data to the 
							CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> 
							KSI Blockchain. This may take several seconds...
						</small>
					</Loader>
				) : (
					<div>
						<Button inverted color='green' onClick={() => this.setState({modalOpen: true, apiErrors: {}})} disabled={this.props.certusBankInfo && this.props.certusBankInfo.quantity == 0}>
							Create VERISEAL
						</Button>
						<p>
							<small>
								Protect result by writing to the CERTUS
								<Icon style={{ position: 'relative', bottom: '.35rem' }} name='registered outline' size='tiny' /> 
								KSI Blockchain
							</small>
						</p>
					</div>
				)}
			</Grid.Column>
		</Grid>
    )

    const modal = this.state.certus.sample_certus ? (
		<CertusModalQr
			certus={this.state.certus}
			strain_name={this.props.sample.intake_form.strain_name}
			modalOpen={this.state.modalOpen}
			closeModal={()=> this.setState({modalOpen:false})}
			createFailure={(message) => this.setState({apiErrors: message, modalOpen: false, loading: false})}
		/>
    ) : (
		<CertusModalCreate
			coa={this.props.sample.coa}
			sample_id={this.props.sample.id}
			current_name={this.props.sample.intake_form.strain_name}
			modalOpen={this.state.modalOpen}
			closeModal={()=> this.setState({modalOpen:false})}
			startLoading={()=> this.setState({modalOpen:false, loading:true})}
			createSuccess={() => this.setState({modalOpen:true, loading: false})}
			createFailure={(message) => this.setState({apiErrors: message, modalOpen: false, loading: false})}
		/>
    )
    return (
		<section className="sampleCertus">
			<ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
			{certusDisplay}
			{modal}
		</section>
    )
  }
}

const mapStateToProps = state => ({
	user: state.current_user,
	certusBankInfo: state.certusBankInfo
});

export default connect(
  	mapStateToProps
)(withRouter(SampleCertus))
