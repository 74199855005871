import React  from 'react'
import { Table, Grid } from 'semantic-ui-react'
import { SampleTestServices } from '../../services/SampleTest'
import DownloadExcel from '../Download/DownloadExcel'
class SampleTestResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SampleTestResult: [],
    }
  }
  componentDidMount() {
    SampleTestServices.getSampleResult(this.props.id).then((response) => {
      const res = response.data.results;
      const array = [];
      for (let key in res) {
        res[key] = { ...res[key], name: key, mgPerGram: '-' }
        array.push(res[key]);
      }
      this.setState({ SampleTestResult: array })
    })
  }
  openModal = () => {
    this.setState({ openModal: true })
  }
  handleClose = () => {
    this.setState({ openModal: false })
  }
  render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column id="sampleTestResult" mobile={16} tablet={16} computer={16} largeScreen={16}>
            <DownloadExcel url={SampleTestServices.getSampleTestResultDownloadUrl(this.props.id)} fileName={'Sample-Test-Results'} title={'Export'} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Peak Area</Table.HeaderCell>
                  <Table.HeaderCell>Percent</Table.HeaderCell>
                  <Table.HeaderCell>Mg/Gram</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.SampleTestResult.map((data) => {
                  return (
                    <Table.Row key={data.name}>
                      <Table.Cell>{data.name}</Table.Cell>
                      <Table.Cell>{data.amount}</Table.Cell>
                      <Table.Cell>{data.peak_area}</Table.Cell>
                      <Table.Cell>{data.percent}</Table.Cell>
                      <Table.Cell>{data.amount}</Table.Cell>
                    </Table.Row>
                  )
                })
                }
                {(!this.state.SampleTestResult || this.state.SampleTestResult.length === 0) &&
                  <Table.Row colSpan={5} textAlign='center'>
                    <Table.Cell colSpan={5}>No Results Found</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

export default SampleTestResult
