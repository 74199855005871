import React from 'react'
import PropTypes from 'prop-types'
import { Table, Popup, Button, Icon } from 'semantic-ui-react'
import { ReactComponent as CertusLogoSVG } from '../../../images/certus_check.svg'
import samplePropType from './samplePropType'
import handleDefaultValue from '../../../lib/handleDefaultValue'
import dateTimeFormatter from '../../../lib/dateTimeFormatter'
import TABLE_ROW_ACTIONS from './TABLE_ROW_ACTIONS'

function ActionButton({ popupContent, buttonContent, icon, color, disabled, onClick, className }) {
	const button = (
		<Button size='medium' icon={icon} color={color} disabled={disabled} onClick={onClick} className={`dynamicTableActionButton ${className}`}>
			{buttonContent}
		</Button>
	)

	return <Popup content={popupContent} trigger={button} />
}

ActionButton.propTypes = {
	popupContent: PropTypes.node.isRequired,
	buttonContent: PropTypes.node,
	icon: PropTypes.string,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string
}

ActionButton.defaultProps = {
	buttonContent: null,
	icon: null,
	color: null
}

ActionButton.defaultProps = {
	icon: null,
	disabled: false,
	className: null
}

function buildCertusActionButtonProps(sampleResult, onRowActionClick) {
	const sampleIsFinished = (sampleResult.finished || sampleResult.import)
	const existingCertus = !!(sampleResult.certus_id)

	let popupText = 'Create VERISEAL'
	let color = null
	let className = 'icon'

	if (existingCertus) {
		popupText = 'VERISEAL Associations'
		color = 'green'
		className += ' certusLogoWhite'
	}

	const disabled = !sampleIsFinished
	const buttonContent = <Icon><CertusLogoSVG /></Icon>
	const popupContent = (
		<span>
			{popupText}
		</span>
	)

	const onClick = (event) => {
		event.stopPropagation()
		if (existingCertus) {
			onRowActionClick(TABLE_ROW_ACTIONS.GET_CERTUS, sampleResult)
		}
		else if (!disabled) {
			onRowActionClick(TABLE_ROW_ACTIONS.CREATE_CERTUS, sampleResult)
		}
	}

	return {
		popupContent, buttonContent, color, disabled, onClick, className
	}
}

function stopPropagationWrapper(fn, ...args) {
	return (event) => {
		event.stopPropagation()
		fn(...args)
	}
}

function QCTableRow({ sampleResult, inQueue, onRowClick, onRowActionClick }) {
	const intakeForm = sampleResult.intake_form || {}
	let sampleWeight = handleDefaultValue(intakeForm.sample_weight)

	if (sampleWeight !== 'NA') {
		sampleWeight = `${sampleWeight} mg`
	}

	const deleteDisabled = !!(sampleResult.certus_id) || inQueue

	const actionButtons = []
	actionButtons.push(<ActionButton key={1} popupContent='Edit' icon='edit' color='blue' onClick={stopPropagationWrapper(onRowActionClick, TABLE_ROW_ACTIONS.EDIT, sampleResult)} />)
	actionButtons.push(<ActionButton key={2} popupContent='Download PDF' icon='file pdf' color='green' disabled={!sampleResult.finished} onClick={stopPropagationWrapper(onRowActionClick, TABLE_ROW_ACTIONS.DOWNLOAD_PDF, sampleResult)} />)
	actionButtons.push(<ActionButton key={3} popupContent='Download CSV' icon='file excel' color='green' disabled={!sampleResult.finished} onClick={stopPropagationWrapper(onRowActionClick, TABLE_ROW_ACTIONS.DOWNLOAD_CSV, sampleResult)} />)
	actionButtons.push(<ActionButton key={4} popupContent='Delete' icon='remove' color='red' disabled={deleteDisabled} onClick={stopPropagationWrapper(onRowActionClick, TABLE_ROW_ACTIONS.DELETE, sampleResult)} />)
	actionButtons.push(<ActionButton key={5} {...buildCertusActionButtonProps(sampleResult, onRowActionClick)} />)

	return (
		<Table.Row onClick={() => onRowClick(sampleResult.id)}>
			<Table.Cell>{handleDefaultValue(intakeForm.batch_number)}</Table.Cell>
			<Table.Cell>{handleDefaultValue(intakeForm.strain_name)}</Table.Cell>
			<Table.Cell>{sampleWeight}</Table.Cell>
			<Table.Cell>{dateTimeFormatter(sampleResult.created_at)}</Table.Cell>
			<Table.Cell><div className='qcResultsTable__actionsContainer'>{actionButtons}</div></Table.Cell>
		</Table.Row>
	)
}

QCTableRow.propTypes = {
	sampleResult: samplePropType.isRequired,
	inQueue: PropTypes.bool.isRequired,
	onRowClick: PropTypes.func.isRequired,
	onRowActionClick: PropTypes.func.isRequired
}

export default QCTableRow
