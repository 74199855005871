import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import vialTypes from '../lib/vialTypes'

class PrepVial extends Component {
  render() {
    let vialProperties = vialTypes[this.props.vial.vial_type]
    return (
      <div className="prepMethodVial">
        {this.props.children}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.37 103.5">
          <g id="Layer_2" data-name="Layer 2">
            <g id="vial">
              <path fill="green" id="vial_contents" d="M2.43,38v58.5s-.15,4.13,3.3,4.46l39.77,0c3.45-.34,3.3-4.46,3.3-4.46V38"/>
              <path strokeWidth="3pt" stroke="black" fill="transparent" id="vial_body" d="M11.58,18.92c-.07,6.68-4.43,7.84-4.43,7.84A4.86,4.86,0,0,0,2.5,31V96s-.15,4.59,3.3,5l39.77,0c3.45-.37,3.3-5,3.3-5V31a4.86,4.86,0,0,0-4.65-4.27s-4.35-1.16-4.43-7.84"/>
              <g id="cap">
                <rect fill={vialProperties.color} stroke="black" strokeWidth="2pt" x="2.19" y="1.5" width="47" height="15.91"/>
                <path d="M47.69,3V15.91h-44V3h44m3-3H.69V18.91h50V0Z"/>
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }
}

export default PrepVial
