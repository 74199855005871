import fabric from '../../lib/fabric'
import vialTypes from '../../lib/vialTypes'

const VIAL_COLORS = {
  default: 'rgba(255, 255, 255, 0.1)',
  completed: 'green',
  highlight: 'white',
  calibration: 'brown',
  standard: 'green',
  wash: 'aqua'
}

const Vial = fabric.util.createClass(fabric.Circle, {
  initialize: function(params, vialNumber, vialWithSample, text) {
    this.vialWithSample = vialWithSample
    this.vialNumber = parseInt(vialNumber)
    this.isCurrent = false
    this.text = text
    this.selectable = false
    if (vialWithSample) {
      let locations = vialWithSample.sample.locations
      this.place = locations.indexOf(parseInt(vialNumber))
    }
    this.callSuper('initialize', params)
    this.calcProperties()
  },

  updateData: function(vialWithSample, isCurrent) {
    this.vialWithSample = vialWithSample
    this.isCurrent = isCurrent
    this.calcProperties()
  },

  calcProperties: function() {
    let color, label
    if (this.vialWithSample && this.vialWithSample.vial) {
      this.place = this.vialWithSample.sample.locations.indexOf(this.vialNumber)
      const sample_type_slug = this.vialWithSample.sample.sample_type
      const vialProperties = vialTypes[this.vialWithSample.vial.prepared_sample.vial_type]
      if (sample_type_slug === "calibration") {
        const calLabels = "ABCDE"
        label = calLabels[this.place]
        color = VIAL_COLORS['calibration']
      } else if (sample_type_slug === "standard") {
        label = "✓"
      } else if (vialProperties) {
        color = vialProperties.color
        label = this.vialWithSample.vial.prepared_sample.label
        label = label ? label[0] : ""
      } else {
        label = "hm"
      }
      if (this.vialWithSample.vial.completed) {
        color = VIAL_COLORS['completed']
        label = "✓"
      }
    } else {
      color = VIAL_COLORS['default']
      label = ""
    }

    if (this.vialNumber > 90 && this.vialNumber <= 92) {
      label = "W"
      color = VIAL_COLORS['wash']
    }
    if (this.isCurrent) {
      color = VIAL_COLORS['highlight']
    }

    this.text.set('text', label)
    this.set('fill', color)
  }
})

export default Vial
