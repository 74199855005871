import React, { Component } from 'react'
import { Button, Icon, Progress } from 'semantic-ui-react'

function AutosamplerPlayButton(props) {
  let playButton

  // error state
  if (props.machineState && props.machineState.status === "ERROR") {
    playButton = (<Button icon labelPosition="left" className="error" onClick={() => {props.doAction('preprun')}}>
      <Icon name="wrench" size="large" />
      Clear
    </Button>)
  }

  // sleep complete. ready to warmup and test
  else if (props.machineState
    && props.machineState.method === process.env.REACT_APP_SLEEP_METHOD+'.M'
    && props.machineState.status == 'NOTREADY') {
     playButton = (<Button icon labelPosition="left" onClick={() => {props.doAction('warmup')}}>
       <Icon name="play" size="large"/>
         Wake\Warm Up  🔥
       </Button>
     )
   }

  // warmup running
  else if (props.machineState
    && props.machineState.method === process.env.REACT_APP_WARMUP_METHOD+'.M'
    && ['PRERUN', 'RUN', 'NOTREADY'].indexOf(props.machineState.status) > -1) {
    playButton = (<Button icon labelPosition="left"  disabled>
        <Icon loading name="spinner" size="large" />
        Warming
      </Button>
    )
  }

  // sleep running
  else if (props.machineState
   && props.machineState.method === process.env.REACT_APP_SLEEP_METHOD+'.M'
   && (props.machineState.status == 'RUNNING' || props.machineState.status == 'RUN' || props.machineState.status == 'PRERUN')) {
    playButton = (<Button icon labelPosition="left" disabled>
        <Icon loading name="spinner" size="large"/>
        Going to Sleep
      </Button>
    )
  }

  // run method handling
  else if (props.machineState && props.machineState.method === process.env.REACT_APP_RUN_METHOD+'.M') {
    // running
    if (props.machineState.sequenceRunning) {
      playButton = (<Button icon labelPosition="left" disabled>
          <Icon loading name="spinner" size="large" />
          Running
        </Button>
      )
    } else {
      // not running
      if (props.sampleQueues && props.sampleQueues.length >= 1 && props.taskToRun) {
        // stuff in queue and something still to run
        const first = props.sampleQueues[0]
        const buttonText = (
          first.sample.id === props.taskToRun.sample_id &&
          first.locations[0] === props.taskToRun.location
        ) ? 'Begin' : 'Resume'
        playButton = (<Button icon labelPosition="left" onClick={() => {props.doAction('start')}}>
          <Icon name="play" size="large" />
            {buttonText} Run
          </Button>)
      } else if (props.sampleQueues && props.sampleQueues.length >= 1 && !props.taskToRun) {
        // stuff in queue but nothing left to run
        playButton = (<Button icon labelPosition="left" disabled>
          <Icon name="play" size="large" />
            Load More Samples
          </Button>)
      } else {
        playButton = (<Button icon labelPosition="left" disabled>
          <Icon name="play" size="large" />
            Queue Empty
          </Button>)
      }
    }
  }
 
  // catch-all for safety. 
  // shouldn't be possible if logic above is correct and chemstation enabled cannid method
  else {
    playButton = (<Button icon labelPosition="left" onClick={() => {props.doAction('start')}}>
        <Icon name="play" size="large" />
        Start
      </Button>)
  }

  return (
    <div className="autosamplerPlayButton actionButton">
      {playButton}
    </div>
  )
}

export default AutosamplerPlayButton
