import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import * as csv from 'csvtojson'
import { Segment, Header } from 'semantic-ui-react'
import standardizeParsedCsv from '../../lib/standardizeParsedCsv'

function ImportDropzone(props) {
	const onDrop = useCallback((acceptedFiles) => {
		console.warn('dropped file:', acceptedFiles[0])
		console.warn('Deets', acceptedFiles[0].name, acceptedFiles[0].type)
		if (acceptedFiles[0] && acceptedFiles[0].name.match(/.*\.(csv|CSV)$/)) {
			const reader = new FileReader()
			reader.onabort = () => props.functionalError('Error: File reading was aborted.')
			reader.onerror = (e) => props.functionalError(`Error reading the file. ${e}`)
			reader.onload = () => {
				const text = reader.result
				csv({ noheader: true }).fromString(text).then((row) => {
					const standardized = standardizeParsedCsv(row)
					props.addParsed(standardized, acceptedFiles[0])
					props.functionalError({})
				})
			}
			reader.readAsText(acceptedFiles[0])
		}
		else {
			props.functionalError('File type must be CSV.')
		}
	})

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<div className='dropZone' {...getRootProps()}>
			<Segment placeholder className='dropZoneSegment'>
				<Header icon style={{marginBottom: 0}}>
					<img src='https://cdn.chemid.com/images/dragon_drop/dragon_drop_head_chemid_479x479.png'
						alt='ChemID Dragon Drop'
						className='dragonDrop'
						style={{marginBottom: '.5em'}} />
					<br />
					{!isDragActive ? <span>Upload or <i>Dragon Drop</i> an example test result CSV Report here</span> : 'Drop to upload!'}
				</Header>
				<input {...getInputProps()} />
			</Segment>
		</div>
	)
}

ImportDropzone.propTypes = {
	addParsed: PropTypes.func.isRequired,
	functionalError: PropTypes.func.isRequired
}.isRequired

export default ImportDropzone
