import React from 'react'
import { useSelector } from 'react-redux'
import { Icon, Button, Popup, Menu } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'

function HeaderUser(props) {
  const user = useSelector((state) => (state.current_user))
  if (user) {

    const userDetails = (
      <div className="headerUser-info">
        <div className="headerUser-name">
          <Icon name="user" />{props.chemidUser && <img width={15} height={15} style={{ marginBottom: '-2px' }} src='https://cdn.chemid.com/images/chemid-logo-image.png' alt='chemid.logo' />} {user.name}
        </div>
        <div className="headerUser-organization">
          <Icon name="sitemap" /> {user.organization.name} | {user.role.name}
        </div>
      </div>
    )
  
    const userDisplay = isMobile ? (
      <div className="headerUser">
        <Menu compact inverted size='large'>
          <Popup content={userDetails} position='bottom right' trigger={<Menu.Item><Icon name='user' size='large' style={{margin: 0}} /></Menu.Item>} />
          <Menu.Item onClick={() => {localStorage.removeItem('access_token'); window.location.reload()}}>
            <Icon name='log out' size='large' style={{margin: 0}} />
          </Menu.Item>
        </Menu>
      </div>
    ) : (
      <div className="headerUser">
        {userDetails}
        <Button onClick={() => {localStorage.removeItem('access_token'); localStorage.setItem('driverPolling', 0); window.location.reload()}}>
          Logout
        </Button>
      </div>
    )

    return userDisplay
  } else {
    return (
      <div className="headerUser">
        loading...
      </div>
    )
  }
}

export default HeaderUser
