import {
  UPDATE_MACHINE_STATE,
  UPDATE_CHROM,
  SET_CURRENT_USER,
  GET_SELECTED_TESTS,
  SET_TIMER,
  UPDATE_SAMPLE_QUEUES,
  TOGGLE_SIDEBAR_MENU,
  TOGGLE_SAMPLE_QUEUES,
  APPEND_NOTIFICATIONS_LOG,
  CLEAR_NOTIFICATIONS_LOG,
  START_DRIVER_POLLING,
  STOP_DRIVER_POLLING,
  SET_CURRENT_CERTUS_BANK
} from '../actions'

import initialState from '../lib/InitialState'

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MACHINE_STATE:
      return Object.assign({}, state, { machineState: action.machineState })
    case UPDATE_CHROM:
      return Object.assign({}, state, { currentChrom: [...state.currentChrom, action.newCoords] })
    case "TOGGLE_PAUSE":
      console.log('toggle pause')
      localStorage.setItem('queuePaused', !state.queuePaused ? 1 : 0)
      return Object.assign({}, state, {queuePaused: !state.queuePaused})
    case "PAUSE_QUEUE":
      localStorage.setItem('queuePaused', 1)
      console.log('pause q')
      return Object.assign({}, state, {queuePaused: true})
    case "RESUME_QUEUE":
      console.log('resume q')
      localStorage.setItem('queuePaused', 0)
      return Object.assign({}, state, {queuePaused: false})
    case "CLEAR_RUN":
    case "UPDATE_RESULTS":
      return Object.assign({}, state, { results: action.results })
    case UPDATE_SAMPLE_QUEUES:
      return Object.assign({}, state, { sampleQueues: action.sampleQueues })
    case SET_CURRENT_USER:
      return Object.assign({}, state, { current_user: action.user })
    case TOGGLE_SAMPLE_QUEUES:
      const newShowState = !state.showQueue
      if (newShowState)
        document.body.classList.add('queueOpen');
      else
        document.body.classList.remove('queueOpen');
      return Object.assign({}, state, { showQueue: newShowState })
    case TOGGLE_SIDEBAR_MENU:
      return Object.assign({}, state, { showSidebarMenu: !state.showSidebarMenu })
    case SET_TIMER:
      {
        return Object.assign({}, state, { timer: action.timer, timerSeconds: action.timer.seconds })
      }
    case GET_SELECTED_TESTS:
      {
        return (
          Object.assign({}, state, { selected_tests: { ...action.selectedTests } })
        )
      }
    case APPEND_NOTIFICATIONS_LOG:
      const logAppended = [...state.notificationsLog, ...[`${action.notification} [${new Date().toString()}]`]]
      return Object.assign({}, state, {notificationsLog: logAppended})
    case CLEAR_NOTIFICATIONS_LOG:
      return Object.assign({}, state, {notificationsLog: []})
    case START_DRIVER_POLLING:
      localStorage.setItem('driverPolling', 1)
      return Object.assign({}, state, {driverPolling: true})
    case STOP_DRIVER_POLLING:
      localStorage.setItem('driverPolling', 0)
      localStorage.setItem('queuePaused', 1)
      return Object.assign({}, state, {driverPolling: false, queuePaused: true})
      //return {...state, driverPolling:false, queuePaused: false}
    case SET_CURRENT_CERTUS_BANK:
      return Object.assign({}, state, {certusBankInfo: action.certusBankInfo})
	case 'SET_CHEMID_USER':
		sessionStorage.setItem('CHEMID_USER', JSON.stringify(action.chemidUser))
		return Object.assign({}, state, {chemidUser: action.chemidUser})
	case 'SET_CHEMID_ENTITY':
		sessionStorage.setItem('CHEMID_ENTITY', JSON.stringify(action.chemidEntity))
		return Object.assign({}, state, {chemidEntity: action.chemidEntity})
	case 'LOGOUT_CHEMID':
		sessionStorage.removeItem('CHEMID_USER')
		sessionStorage.removeItem('CHEMID_ENTITY')
		return Object.assign({}, state, {chemidUser: null, chemidEntity: null})
    default:
      return state
  }
}

export default rootReducer
