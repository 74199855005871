const analyticalReportDataValidator = (data) => {
  try {
    let categories = data ? Object.keys(data) : []
    let series = categories[0] ? Object.keys(data[categories[0]]) : []
    return categories.length > 0 && series.length > 0
  } catch (error) {
    return false
  }
}

export default analyticalReportDataValidator
