import React from 'react'
import axios from 'axios'
import { Button } from 'semantic-ui-react'
import ReactDOM from 'react-dom'
import decodeJWT from './lib/decodeJWT'
import tokenValidity from './lib/tokenValidity'
import constants from './lib/constants'
import { formatRoute } from 'react-router-named-routes'
import momentWithZone from 'moment-timezone'
axios.defaults.headers.common['Accept'] = 'application/json'
window.appVersion = process.env.REACT_APP_VERSION
const getAxiosInstance = (options) => {
  const instance = axios.create({
	...options
  })
  instance.interceptors.request.use((reqConfig) => {
    document.body.classList.add('loading-indicator')
    if (localStorage.getItem('access_token')) {
      reqConfig.headers.authorization = 'Bearer ' + localStorage.getItem('access_token')
      reqConfig.headers[constants.HEADER_TIMEZONE] = momentWithZone.tz.guess()
    }
    if (localStorage.getItem('machine')) {
      reqConfig.headers["HPLC-Laptop-Hostname"] = localStorage.getItem('machine')
    }
    if (window.appVersion) {
      reqConfig.headers.appversion = window.appVersion
    }
    if (reqConfig.method === 'get') {
      reqConfig.url = reqConfig.url + (reqConfig.url.indexOf("?") < 0 ? '?' : '&') + 'timestamp=' + new Date().getTime()
    }
    return reqConfig
  },
    err => Promise.reject(err),
  )
  instance.interceptors.response.use(response => {
    let res = decodeJWT(localStorage.getItem('access_token'))
    let tokenValidityCheck = tokenValidity(res.exp)
    if (tokenValidityCheck.isTokenValidityApproachingExpiry === true && tokenValidityCheck.duration > 0 && !response.config.__isRetryRequest) {
      return axios.post(process.env.REACT_APP_API_ROOT + '/resend/token', { "token": localStorage.getItem('access_token') }, {})
        .then((responseNew) => {
          localStorage.setItem('access_token', responseNew.data.access_token)
          response.config.__isRetryRequest = true
          response.config.headers.authorization = 'Bearer ' + responseNew.data.access_token
          document.body.classList.remove('loading-indicator')
          return axios(response.config)
        })
        .catch((res) => {
          document.body.classList.remove('loading-indicator')
          localStorage.removeItem('access_token')
          window.location.href = formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        })
    }
    else {
      document.body.classList.remove('loading-indicator')
      if (tokenValidityCheck.duration > 0) {
        return response
      }
      else {
        localStorage.removeItem('access_token')
        window.location.href = formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
      }
    }
  }, err => {
    document.body.classList.remove('loading-indicator')
    if (err.response.status === 401 || err.response.data.message === constants.LOGIN.SIGNATURE_EXPIRY) {
      localStorage.removeItem('access_token')
      if (window.location.pathname !== "/login")
        window.location.href = formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
    } else if (err.response.status === 426) {
      const handleRetry = () => {
        document.getElementById("modal").style.display = "none"
        const oldVersion = window.appversion
        window.appVersion = null
        delete err.config.headers.appversion
        setTimeout(() => {
          window.appVersion = oldVersion
        }, 1800000)
      }
      const resendAndReload = () => {
        console.log('try to upgrade react version')
        window.appVersion = null
        delete err.config.headers.appversion
        window.location.reload(true)
      }
      ReactDOM.render(
        <div>
          <h1>New Version Available</h1>
          <h2>A new version of the software is available please click OK to load or Cancel to continue</h2>
          <Button primary onClick={resendAndReload}>
          OK
          </Button>
          <Button color='red' onClick={handleRetry} >
          Cancel
          </Button>
        </div>, document.getElementById('modal'))
      document.getElementById("modal").style.display = "block"
      return err.response
    }
    return Promise.reject(err.response)
  },
  )
  return instance
}

export default getAxiosInstance
