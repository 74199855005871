import axiosNoToken from '../axiosNoToken'
import axios from '../axios'
import axiosExternal from '../axiosExternal'
export const LoginServices = {
  doLogin,
  getRole,
  getOrganisations,
  getOfficerOrganizations,
  updateOrganisation,
  userInfo,
  logout,
  getAccountInfo,
  checkValidQR,
  chemidLogin,
  chemidLogout,
  chemidEntity
}
function doLogin(requestData) {
  return axiosNoToken().post(process.env.REACT_APP_API_ROOT + '/auth/login', requestData)
}
function getRole(requestData) {
  return axiosNoToken().post(process.env.REACT_APP_API_ROOT + '/auth/check-user', requestData)
}
function getOrganisations(access_token) {
  return axios().get(process.env.REACT_APP_API_ROOT + '/admin/organizations?no_pagination=true&sortOrder=ASC&sortColumn=id&searchIn=is_active&searchData=true', {
    headers: { 'authorization': 'Bearer ' + access_token, 'accept': 'application/json' }
  })
}
function getOfficerOrganizations(access_token, org_id) {
  return axios().get(process.env.REACT_APP_API_ROOT + `/admin/organization_officers/privates?organization_id=${org_id}`, {
    headers: { 'authorization': 'Bearer ' + access_token, 'accept': 'application/json' }
  })
}
function updateOrganisation(id, requestData) {
  return axios().patch(process.env.REACT_APP_API_ROOT + '/organizations/' + id, requestData)
}
function userInfo(id) {
  return axios().get(process.env.REACT_APP_API_ROOT + '/users/' + id)
}
function logout(session_id) {
  return axios().post(process.env.REACT_APP_API_ROOT + '/auth/logout', { "session_id": session_id })
}
function getAccountInfo() {
  return axios().get(process.env.REACT_APP_API_ROOT + '/me')
}
function checkValidQR(share_key) {
  return axiosNoToken().get('samples/' + share_key + '/details')
}
function chemidLogin(requestData) {
	return axiosExternal({ withCredentials: true }).post(process.env.REACT_APP_CHEMID_API_ROOT + '/user/login', requestData)
}
function chemidLogout() {
	return axiosExternal({ withCredentials: true }).post(process.env.REACT_APP_CHEMID_API_ROOT + '/user/logout')
}
function chemidEntity() {
	return axiosExternal({ withCredentials: true }).get(process.env.REACT_APP_CHEMID_API_ROOT + '/entity/authorized')
}
