import React  from 'react'
import { Button, Form, Grid, Segment, Header } from 'semantic-ui-react'
import { userServices } from '../services/User'
import ToastMessage from '../components/Message'
import constants from '../lib/constants'
import trimWhiteSpace from '../lib/trim'
import ErrorMessages from './Errors/ErrorMessages'
import Timer from '../lib/Timer'
import { formatRoute } from 'react-router-named-routes'

class ForgotPassowrd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      submitFlag: null,
      emailError: false,
      apiErrors: {},
      userInvited: null,
      time: constants.FORGOT_PASSWORD.RESEND_INTERVAL_IN_SECONDS
    }
  }
  handleForgotPassword = () => {
    let formValid = true
    formValid = this.validateForm()
    if (!formValid) {
      return false
    }
    let requestData = {
      user: {
        email: trimWhiteSpace(this.state.email),
      }
    }
    userServices.requestResetPassword(requestData).then((res) => {
      this.setTimer()
      this.setState({ userInvited: true, submitFlag: true, apiErrors: {} })
    }).catch((e) => {
      this.setState({ userInvited: false, submitFlag: false, errorMesage: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (e.data) ? e.data : {} })
    }
    )
  }
  checkEmail = (email) => {
    var re = constants.REG_EX.EMAIL_REG_EX
    return !re.test(String(email).toLowerCase())
  }
  validateForm = () => {
    if (this.checkEmail(this.state.email)) {
      this.setState({ emailError: true })
      return false
    }
    this.setState({ emailError: false })
    return true
  }
  update = (e) => {
    if (this.state.userInvited) {
      clearInterval(this.timer)
      this.setState({ time: constants.FORGOT_PASSWORD.RESEND_INTERVAL_IN_SECONDS, userInvited: false })
    }
    const newState = {}
    newState[e.target.name] = e.target.value
    this.setState(newState, () => {
      if (this.state.email === '') {
        this.setState({ emailError: true })
      }
      else {
        this.setState({ emailError: false })
      }
    })
  }
  intervalTimer = () => {
    this.setState({ time: this.state.time - 1 }, () => {
      if (this.state.time < 1) {
        clearInterval(this.timer)
      }
    })
  }
  setTimer = () => {
    this.setState({ time: constants.FORGOT_PASSWORD.RESEND_INTERVAL_IN_SECONDS }, () => {
      clearInterval(this.timer)
      this.timer = setInterval(this.intervalTimer, 1000)
    })
  }
  render() {
    return (
      <Grid id="passwordResetForm" textAlign='center' verticalAlign='middle'>
        <Grid.Column id="passwordColumn">
          <Header as='h2' className='headerColor' textAlign='center'>
            {constants.FORGOT_PASSWORD.HEADER_TITLE}
          </Header>
          <ErrorMessages errors={this.state.apiErrors} mobile={16} tablet={16} computer={16} largeScreen={16}></ErrorMessages>
          <Form size='large' onSubmit={this.handleForgotPassword}>
            <Segment stacked className='segmentPadding'>
              {
                this.state.submitFlag === true && <ToastMessage title='Success' description={constants.FORGOT_PASSWORD.SUCCESS} />
              }
              <Form.Field>
                <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label='Email' placeholder='Email' value={this.state.email} onChange={this.update} name="email" className='required-field' error={this.state.emailError}></Form.Input>
                {this.state.emailError && <p className="error"> Please provide the valid email</p>}
              </Form.Field>
              <Button className='userButton' type='submit' disabled={this.state.userInvited && this.state.time > 0}>{(this.state.userInvited) ? constants.FORGOT_PASSWORD.FORGOT_PASSWORD_RESEND_BUTTON_TITLE : constants.FORGOT_PASSWORD.FORGOT_PASSWORD_BUTTON_TITLE}</Button>
              <Button className='userButton' type='button' onClick={() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {}))}>{'Login'}</Button>
              {this.state.userInvited && this.state.time > 0 && <React.Fragment><br /><Timer time={this.state.time} /></React.Fragment>}
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
}
export default ForgotPassowrd
