import React, { Component } from 'react'
import AppHeader from '../AppHeader'
import { Breadcrumb, 
  Button, 
  Form, 
  Icon, 
  Grid, 
  Menu, 
  Input, 
  Image, 
  Segment, 
  Modal, 
  Header, 
  Popup 
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import constants from '../../lib/constants'
import trimWhiteSpace from '../../lib/trim'
import ErrorMessages from '../Errors/ErrorMessages'
import Acl from '../ACL/Acl'
import { GraphServices } from '../../services/Graphs'
import $ from 'jquery'
import ChartComponent from '../Charts/ChartComponent'
import axios from '../../axios'
import { formatRoute } from 'react-router-named-routes'
import logoutSession from '../../lib/logoutSession'
import analyticalReportDataValidator from '../../lib/analyticalReportDataValidator'
class ViewDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      apiErrors: {},
      deleteGraphFlag: false,
      currentGraphId: '',
      totalGraphsOriginal: null,
      searhValue: '',
      currentGraphName: '',
      currentGraphConfiguration: {},
      currentGraphData: null,
      graphLoaded: false,
      email: '',
      emailSent: false,
      message: '',
      shareReportFlag: false,
      formErrors: {
        emailError: false
      }
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.getGraphsList()
  }
  getGraphsList() {
    GraphServices.getAllGraphs().then((result) => {
      this.setState({ totalGraphsOriginal: result.data.data, apiErrors: {} })
      if (this.state.currentGraphId === '' && result.data.data.length > 0) {
        let responeData = result.data.data
        if (this.props.match.params.id && responeData.filter(v => v.id === parseInt(this.props.match.params.id))[0])
          this.renderGraph(responeData.filter(v => v.id === parseInt(this.props.match.params.id))[0])
        else
          this.renderGraph(responeData[0])
      }
    }).catch((error) => {
      this.setState({ totalGraphsOriginal: [], apiErrors: (error.data) ? error.data : {} })
    })
  }
  resize = () => this.forceUpdate()
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  deleteGraph = (id) => {
    GraphServices.deleteGraph(id).then((response) => {
      this.setState({ deleteGraphFlag: false, apiErrors: {}, currentGraphId: '' })
      this.getGraphsList()
    }).catch((error) => {
      this.setState({ deleteGraphFlag: false, apiErrors: (error.data) ? error.data : {} })
    })
  }
  openModal = () => {
    this.setState({ deleteGraphFlag: true, currentGraphId: this.state.currentGraphId })
  }
  closeModal = () => {
    this.setState({ deleteGraphFlag: false })
  }
  search = (item) => {
    return item['name'].toLowerCase().indexOf(trimWhiteSpace(this.state.searhValue.toLowerCase())) !== -1
  }
  updateForm = (e) => {
    let target = e.target
    this.setState((prevState, props) => {
      let newState = $.extend({}, prevState)
      newState[target.name] = target.value
      return newState
    }, () => { })
  }
  graphTypeIcon = (type) => {
    let icon
    switch (type) {
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.LINE:
        icon = 'chart line'
        break
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.PIE:
        icon = 'chart pie'
        break
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.BAR:
        icon = 'chart bar'
        break
      case constants.GRAPH_DASHBORD.GRAPH_TYPE_SLUG.SCATTER:
        icon = 'chart area'
        break
      default:
        icon = 'chart line'
        break
    }
    return icon
  }
  renderGraph = (input) => {
    this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ANALYTICAL_REPORT.VIEW.ROUTE, { id: input.id }))
    this.setState({
      currentGraphId: input.id,
      currentGraphName: input.name,
      currentGraphData: {},
      currentGraphConfiguration: {},
      graphLoaded: false
    })
    GraphServices.getGraphConfigurations(input.id).then((response) => {
      this.setState({ currentGraphConfiguration: response.data, apiErrors: {} })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {} })
    })
    GraphServices.getGraphData(input.id).then((response) => {
      let properResponse = analyticalReportDataValidator(response.data)
      this.setState({ currentGraphData: properResponse ? response.data : {}, apiErrors: {}, graphLoaded: true })
    }).catch((error) => {
      this.setState({ apiErrors: (error.data) ? error.data : {}, graphLoaded: true })
    })
  }
  getConfigAttributes = () => {
    let attributes = this.state.currentGraphConfiguration.chart_attributes.map((attr) => attr.name)
    return attributes
  }
  getUnit = () => {
    let unit = this.state.currentGraphConfiguration.chart_attributes && this.state.currentGraphConfiguration.chart_attributes[0] && this.state.currentGraphConfiguration.chart_attributes[0].unit ? this.state.currentGraphConfiguration.chart_attributes[0].unit : ''
    return unit
  }
  getConfigScales = () => {
    let scales = this.state.currentGraphConfiguration.chart_scale.name
    return [scales]
  }
  downloadReport = (id) => {
    axios().get(GraphServices.getPdfReportDownloadUrl(id), { responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ apiErrors: {} })
          if (navigator.userAgent.match('CriOS')) {
            var reader = new FileReader()
            var out = new Blob([response.data], { type: 'application/pdf' })
            reader.onload = function (e) {
              window.location.href = reader.result
            }
            reader.readAsDataURL(out)
          }
          else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${( this.state.currentGraphName ? this.state.currentGraphName : 'NA' ) + '-' + new Date().toDateString()}.pdf`)
            document.body.appendChild(link)
            link.click()
          }
        }
      }).catch((error) => {
        if (error.status === 401 || error.data.message === constants.LOGIN.SIGNATURE_EXPIRY) {
          logoutSession()
        }
        this.setState({ apiErrors: (error.status === 404) ? { download: [constants.ERROR.REQUEST_NOT_FOUND] } : { download: [((error.statusText) ? error.statusText : {})] } })
      })
  }
  handleInputChange = (e) => {
    let target = e.target
    let newState = { ...this.state }
    newState[target.name] = target.value
    this.setState(newState, () => {
      this.isfieldValid(target.name)
    })
  }
  isfieldValid = (field) => {
    let tempFormError = { ...this.state.formErrors }
    if (this.state[field] === '') {
      tempFormError[`${field}Error`] = true
    }
    else {
      tempFormError[`${field}Error`] = false
    }
    this.setState({
      formErrors: { ...tempFormError }
    })
  }
  checkEmail = (email) => {
    let re = constants.REG_EX.EMAIL_REG_EX
    return !re.test(String(email).toLowerCase())
  }
  validateForm = () => {
    let tempFormError = { ...this.state.formErrors }
    if (this.checkEmail(this.state.email)) {
      tempFormError.emailError = true
    }
    this.setState({
      formErrors: {
        ...tempFormError
      }
    })
    for (let key in tempFormError) {
      if (tempFormError[key] === true)
        return false
    }
    return true
  }
  emaiShareLink = () => {
    let formIsValid = this.validateForm()
    this.setState({ emailSent: false, message: '' })
    if (!formIsValid) {
      return false
    }
    let requestBody = {
      email: this.state.email
    }
    GraphServices.sendEmailReportPDF(requestBody, this.state.currentGraphId).then((res) => {
      this.setState({ emailSent: true, message: constants.SHARE_REPORT.SUCCESS, apiErrors: {} })
    }).catch((error) => {
      this.setState({ emailSent: false, message: constants.ERROR.SOMETHING_WENT_WRONG, apiErrors: (error.data) ? error.data : {} })
    })
  }
  openShareModal = () => {
    this.setState({
      email: '',
      emailSent: false,
      message: '',
      apiErrors: {},
      shareReportFlag: true
    })
  }
  closeModalShareReport = () => {
    this.setState({
      email: '',
      emailSent: false,
      message: '',
      apiErrors: {},
      shareReportFlag: false
    })
  }
  render() {
    let breadcrumb
    let graphs = []
    breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
      </Breadcrumb>
    )
    if (trimWhiteSpace(this.state.searhValue).length === 0) {
      graphs = this.state.totalGraphsOriginal
    }
    else if (trimWhiteSpace(this.state.searhValue).length > 0 && this.state.totalGraphsOriginal.filter(this.search).length > 0) {
      graphs = this.state.totalGraphsOriginal.filter(this.search)
    }
    let sendEmail = (<Input fluid
      action={<Button color='green' labelPosition='right' icon='mail' content={constants.SHARE_REPORT.SEND_LINK_TO_EMAIL_BUTTON_TITLE} onClick={this.emaiShareLink} />}
      actionPosition='right'
      value={this.state.email}
      type='text'
      className='required-field'
      placeholder='E-mail'
      onChange={this.handleInputChange}
      name='email'
    />)
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{constants.GRAPH_DASHBORD.LIST.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <div className="form screen">
              <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
              <Form>
                {this.state.totalGraphsOriginal !== null && this.state.totalGraphsOriginal.length > 0 &&
                  <Grid>
                    <Grid.Column mobile={16} tablet={6} computer={4} largeScreen={4}>
                      <Menu vertical fluid>
                        <Menu.Item>
                          <Input icon='search' placeholder='Search' name='searhValue' value={this.state.searhValue} onChange={this.updateForm} />
                        </Menu.Item>
                      </Menu>
                      <Segment id="veiwDashboard">
                        <Menu vertical fluid>
                          {
                            graphs.map((input, index) =>
                              <Menu.Item name={input.name} key={index} active={input.id === this.state.currentGraphId ? true : false} className={'chart_menu_item'} onClick={() => this.renderGraph(input)}>
                                <Icon name={this.graphTypeIcon(input.chart_type.slug)} />
                                {input.name}
                              </Menu.Item>
                            )
                          }
                          {
                            trimWhiteSpace(this.state.searhValue).length > 0 && this.state.totalGraphsOriginal.filter(this.search).length === 0 &&
                            <Menu.Item key={0}>
                              {constants.GRAPH_DASHBORD.LIST.NO_MATCHES_FOUND}
                            </Menu.Item>
                          }
                        </Menu>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={10} computer={12} largeScreen={12}>
                      <Grid>
                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                          <Grid>
                            <Grid.Column mobile={16} tablet={10} computer={10} largeScreen={12} textAlign='left'>
                              <h2 className={'headerContent'}>{this.state.currentGraphName}</h2>
                            </Grid.Column>
                            {this.state.graphLoaded && this.state.currentGraphData !== null && Object.keys(this.state.currentGraphData).length > 0 && this.state.currentGraphConfiguration.chart_type &&
                              <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={4}>
                                <Acl entity={constants.ACL.GRAPH_DASHBOARD_RESOURCE.TITLE} action={constants.ACL.GRAPH_DASHBOARD_RESOURCE.ACTIONS.EXPORT_PDF}>
                                  <Popup content={constants.GRAPH_DASHBORD.LIST.EXPORT_PDF} size='tiny' trigger={
                                    <Button floated={window.screen.width < 768 ? 'none' : 'right'} icon={'download'} color={'black'} className={'dynamicTableActionButton'} onClick={(e) => this.downloadReport(this.state.currentGraphId)} />
                                  } />
                                </Acl>
                                <Acl entity={constants.ACL.GRAPH_DASHBOARD_RESOURCE.TITLE} action={constants.ACL.GRAPH_DASHBOARD_RESOURCE.ACTIONS.SHARE}>
                                  <Popup content={constants.GRAPH_DASHBORD.LIST.SHARE} size='tiny' trigger={
                                    <Button floated={window.screen.width < 768 ? 'none' : 'right'} icon={'share alternate'} color={'black'} className={'dynamicTableActionButton'} onClick={(e) => this.openShareModal()} />
                                  } />
                                </Acl>
                              </Grid.Column>
                            }
                          </Grid>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                          {this.state.graphLoaded && this.state.currentGraphData !== null && Object.keys(this.state.currentGraphData).length > 0 && this.state.currentGraphConfiguration.chart_type &&
                            <ChartComponent type={(this.state.currentGraphConfiguration.chart_type.slug ? this.state.currentGraphConfiguration.chart_type.slug : 'pie')} config={{ attributes: this.getConfigAttributes(), scale: this.getConfigScales(), is_compare_report: this.state.currentGraphConfiguration && this.state.currentGraphConfiguration.chart_scale && this.state.currentGraphConfiguration.chart_scale.slug === constants.GRAPH_DASHBORD.CREATE.COMPARE_TEST_GRAPH_SCALE_SLUG, unit: this.getUnit(), sub_scale: this.state.currentGraphConfiguration.config && this.state.currentGraphConfiguration.config.sub_scale ? this.state.currentGraphConfiguration.config.sub_scale : '', switchAxes: this.state.currentGraphConfiguration.config && this.state.currentGraphConfiguration.config.switch_axes ? this.state.currentGraphConfiguration.config.switch_axes : '' }} data={this.state.currentGraphData} />
                          }
                          {this.state.graphLoaded && this.state.currentGraphData !== null && Object.keys(this.state.currentGraphData).length === 0 &&
                            <Image size={'large'} src={constants.GRAPH_DASHBORD.NO_GRAPH_DATA_FOUND} centered onError={i => i.target.src = constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND} />
                          }
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    {this.state.graphLoaded &&
                      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
                        <Acl entity={constants.ACL.GRAPH_DASHBOARD_RESOURCE.TITLE} action={constants.ACL.GRAPH_DASHBOARD_RESOURCE.ACTIONS.EDIT}>
                          <Button icon labelPosition='left' className='confirmButton' size='small'
                            onClick={() => {
                              this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.ANALYTICAL_REPORT.EDIT.ROUTE, { id: this.state.currentGraphId }))
                            }}>
                            <Icon name='edit' />{constants.GRAPH_DASHBORD.LIST.EDIT_GRAPH}
                          </Button>
                        </Acl>
                        <Acl entity={constants.ACL.GRAPH_DASHBOARD_RESOURCE.TITLE} action={constants.ACL.GRAPH_DASHBOARD_RESOURCE.ACTIONS.DELETE}>
                          <Button icon labelPosition='left' className='confirmButton' size='small'
                            onClick={this.openModal}>
                            <Icon name='trash' />{constants.GRAPH_DASHBORD.LIST.REMOVE}
                          </Button>
                        </Acl>
                      </Grid.Column>
                    }
                  </Grid>
                }
                {this.state.totalGraphsOriginal !== null && this.state.totalGraphsOriginal.length === 0 &&
                  <Grid centered>
                    <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12} textAlign='center'>
                      <div id="runStatus">
                        {constants.GRAPH_DASHBORD.LIST.NO_GRAPHS_FOUND}
                      </div>
                    </Grid.Column>
                  </Grid>
                }
              </Form>
            </div>
            <Modal size='mini' open={this.state.deleteGraphFlag} onClose={() => { this.closeModal() }} closeIcon>
              <Modal.Header>{constants.GRAPH_DASHBORD.DELETE.HEADER_TITLE}</Modal.Header>
              <Modal.Content>
                <p>{constants.GRAPH_DASHBORD.DELETE.MODAL_CONTENT}</p>
              </Modal.Content>
              <Modal.Actions>
                <Button className='cancelButton' onClick={() => this.closeModal()}>No</Button>
                <Button className='confirmButton' icon='checkmark' labelPosition='right' content='Yes' onClick={() => this.deleteGraph(this.state.currentGraphId)}></Button>
              </Modal.Actions>
            </Modal>
            <Modal id="share-modal" size='tiny' open={this.state.shareReportFlag} onClose={(e) => { this.closeModalShareReport(e) }
            } closeIcon>
              <Header icon='share' content='Share Report' />
              <Modal.Content>
                {sendEmail}
                {this.state.emailSent && this.state.message && <p className='success'>{this.state.message}</p>}
                {this.state.emailSent === false && this.state.message && <p className='error'>{this.state.message}</p>}
                {this.state.formErrors.emailError && <p className='error'>{constants.SHARE_REPORT.INVALID_EMAIL}</p>}
              </Modal.Content>
            </Modal>
          </section>
        </section>
      </div>
    )
  }
}
export default ViewDashboard
