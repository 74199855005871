import React, { Component } from 'react'
import {
	Breadcrumb, Grid, Form, Button, Modal, Loader, Header, Icon, Label, Table, Checkbox
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImageUploadAWS from '../../lib/ImageUploadAWS'
import ImageDropzone from '../../lib/ImageDropzone'
import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import cannidAPI from '../../cannidAPI/client'
import constants from '../../lib/constants'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import CertusSelectorTable from './CertusSelectorTable'
import PreviousCertus from './PreviousCertus'
import { getOrgCertusTokenBank } from '../../actions'

class TransportationCertusCreate extends Component {
	constructor(props) {
		super(props)
		this.upload = React.createRef()

		this.state = {
			meta: {},
			certi: [],
			modalSuccess: false,
			confirmOpen: false,
			loading: false,
			selected_certi: [],
			image: [constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND],
			uploadFlag: false,
			imageError: undefined,
			preFilledId: false,
			imageKey: undefined,
			form: {
				manifest_name: '',
				description: ''
			},
			manifest: {
				source: '',
				destination: '',
				transport_company_name: '',
				driver_name: '',
				driver_license: '',
				vehicle_vin: '',
				vehicle_license_plate: ''
			},
			manifest_optional: {
				bonded_carrier_name: '',
				bonded_carrier_permit: ''
			},
			tracer: undefined,
			org: {},
			newId: 0,
			errors: {},
			apiErrors: {},
			certusID: undefined,
			linkPrevious: false
		}
		this.mounted = false

		this.awsConf = {
			bucket: process.env.REACT_APP_CERTUS_IMAGES_BUCKET,
			accessKeyId: process.env.REACT_APP_CERTUS_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_CERTUS_SECRET_ACCESS_KEY
		}
	}

	componentDidMount() {
		this.getCertiRecords(1)
	}

	componentWillUnmount() {
		this.mounted = false
	}

	getCertiRecords = (page) => {
		this.mounted = true
		// get all org sample certi
		cannidAPI.get(`/certi/transportable?page=${page}`).then((response) => {
			if (this.mounted) {
				this.setState({
					certi: response.data.data,
					meta: response.data.meta,
					org: response.data.org,
					tracer: response.data.tracer
				})
			}
		}).catch((err) => {
			console.error('error!', err)
			const message = `${err}. Failed to retrieve sample test and project certi.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	updateForm = (e) => {
		this.setState((prevState) => {
			const form = prevState.form
			form[e.target.name] = e.target.value
			return { form }
		})
	}

	updateManifest = (e) => {
		this.setState((prevState) => {
			const manifest = prevState.manifest
			manifest[e.target.name] = e.target.value
			return { manifest }
		})
	}

	updateManifestOptional = (e) => {
		this.setState((prevState) => {
			const manifest_optional = prevState.manifest_optional
			manifest_optional[e.target.name] = e.target.value
			return { manifest_optional }
		})
	}

	uploadImage = async (image) => {
		try {
			const reader = new FileReader()
			if (image[0].size < constants.ACCOUNT.MAX_FILE_UPLOAD_SIZE) {
				if (image[0].name.match(/.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
					const awsUrl = await ImageUploadAWS(this.awsConf, image[0], this.state.tracer)
					if (awsUrl) {
						reader.onload = (e) => {
							this.setState({
								image: [e.target.result],
								uploadFlag: true,
								imageError: undefined,
								imageKey: awsUrl
							})
						}
						reader.readAsDataURL(image[0])
					}
					else {
						this.setState({ imageError: 'Image upload error. Please try again.' })
					}
				}
				else {
					this.setState({
						imageError: 'Error reading selected file. Acceptable image file types: .jpg|.jpeg|.png',
						uploadFlag: false
					})
				}
			}
			else {
				this.setState({ imageError: constants.ACCOUNT.IMAGE_LIMIT, uploadFlag: false })
			}
		}
		catch (e) {
			console.error('error in uploading image:', e)
		}
	}

	removeImage = () => {
		if (this.state.preFilledId) {
			this.setState({ preFilledId: false })
		}
		this.setState({
			image: constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND,
			uploadFlag: false,
			imageError: undefined,
			imageKey: undefined
		})
		this.upload.current.value = '' // Wild West way to clear the dom of the image
	}

	getErrors = () => {
		const errors = {}

		Object.keys(this.state.form).forEach((key) => {
			if (this.state.form[key].trim() === '') {
				const prettyName = key.split('_')
					.map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
					.join(' ')
				errors[key] = `Must include ${prettyName}`
			}
		})
		Object.keys(this.state.manifest).forEach((key) => {
			if (this.state.manifest[key].trim() === '') {
				const prettyName = key.split('_')
					.map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
					.join(' ')
				errors[key] = `Must include ${prettyName}`
			}
		})
		if (this.state.selected_certi.length < 1) {
			errors.selected_certi = 'Must select 1 or more VERISEAL to be part of the VERISEAL Transportation Manifest.'
		}

		// commented out to temporarily disable driver's license image as required
		// if (!this.state.imageKey) {
		// 	errors.driver_id = 'Must include a Drivers ID.'
		// }
		return errors
	}

	getConfirmation = () => {
		this.setState({ errors: {} })
		if (!Object.keys(this.getErrors()).length) {
			this.setState({ confirmOpen: true })
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	createTransportationCertus = () => {
		const selectedCerti = this.state.selected_certi.map((selected) => selected.batch_id)
		const optional = { ...this.state.manifest_optional }
		Object.keys(optional).forEach((key) => {
			if (optional[key].trim() === '') {
				delete optional[key]
			}
		})

		const params = {
			name: this.state.form.manifest_name.trim(),
			description: this.state.form.description.trim(),
			children: selectedCerti.join(),
			manifest: { ...this.state.manifest, ...optional },
			imageKey: this.state.imageKey,
			tracer: this.state.tracer
		}

		if (this.state.certusID) params['previous'] = this.state.certusID

		document.body.classList.add('loading-indicator')

		cannidAPI.post('/transportation_certi', params).then((response) => {
			document.body.classList.remove('loading-indicator')
			this.setState({
				confirmOpen: true, loading: false, modalSuccess: true, newId: response.data.id
			})
			this.props.getOrgCertusTokenBank()
		}).catch((err) => {
			console.error('error!', err)
			document.body.classList.remove('loading-indicator')
			const spec = err.response.data.message ? `${err.response.data.message} ` : ''
			const message = `${err}. ${spec}Certus creation error. Reload the page and try again.`
			this.setState({
				apiErrors: message, confirmOpen: false, loading: false, modalSuccess: false
			})
			this.props.getOrgCertusTokenBank()
		})
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/certus'>
					<Breadcrumb.Section>
						<div style={{ display: 'flex' }}>
							CERTUS
							<Icon name='registered outline' size='tiny' />
						</div>
					</Breadcrumb.Section>
				</Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>Create VERISEAL Transportation Manifest</Breadcrumb.Section>
			</Breadcrumb>
		)

		const modal = this.state.modalSuccess ? (
			<Modal
				className='certusConfirmation'
				size='large'
				open={this.state.confirmOpen}
				onClose={() => this.setState(
					{ confirmOpen: false, loading: false },
					this.props.history.push(`/certus/transportation/${this.state.newId}`)
				)}
				closeIcon
			>
				<Modal.Header>CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> Success!</Modal.Header>
				<Modal.Content scrolling>
					<div>
						<p>
							VERISEAL Transportation Manifest successfully written to the 
							CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> KSI Blockchain.
							<br/><strong>Notice: It may take up to a minute for the QR code scan functionality to propogate.</strong>
						</p>
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button
						className='confirmButton'
						icon='checkmark'
						labelPosition='right'
						content='OK'
						onClick={() => this.setState(
							{ confirmOpen: false, loading: false },
							this.props.history.push(`/certus/transportation/${this.state.newId}`)
						)}
					/>
				</Modal.Actions>
			</Modal>
		) : (
			<Modal
				className='certusConfirmation'
				size='large'
				open={this.state.confirmOpen}
				onClose={() => this.setState({ confirmOpen: false })}
				closeIcon
			>
				<Modal.Header>Create VERISEAL Transportation Manifest</Modal.Header>
				<Modal.Content scrolling>
					<div className='certusSelectorTable' style={{marginTop: 0, marginBottom: '1rem'}}>
						<Checkbox toggle
							label={
								<label>
									<strong>Link a Previous VERISEAL (Optional)</strong>
								</label>
							}
							onChange={() => this.setState(prevState => ({ linkPrevious: !prevState.linkPrevious, certusID: undefined }))} />
					</div>
					{ this.state.linkPrevious &&
						<PreviousCertus
							certusType='manifest'
							setSelection={ (value) => this.setState({ certusID: value }) }
							current_name={this.state.form.manifest_name} />
					}
					{ ((this.state.linkPrevious && this.state.certusID)
						|| (!this.state.linkPrevious && !this.state.certusID)) &&
						<section>
							<p style={{marginTop: '1rem'}}>
							Are you sure you want to write this VERISEAL Transportation Manifest to the immutable 
							CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> KSI Blockchain?
							</p>
						</section>
					}
				</Modal.Content>
				{ ((this.state.linkPrevious && this.state.certusID)
					|| (!this.state.linkPrevious && !this.state.certusID)) &&
					<Modal.Actions>
						<Button className='cancelButton' onClick={() => this.setState({ confirmOpen: false })}>No</Button>
						<Button
							className='confirmButton'
							icon='checkmark'
							labelPosition='right'
							content='Yes'
							onClick={() => this.setState({ confirmOpen: false, loading: true }, this.createTransportationCertus)}
						/>
					</Modal.Actions>
				}
			</Modal>
		)

		const display = this.state.loading ? (
			<Loader active={this.state.loading} size='big' inline='centered'>
				<small>
					Writing VERISEAL Transportation Manifest to the CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> 
					KSI Blockchain. This may take several seconds...
				</small>
			</Loader>
		) : (
			<section className='createCertusForm'>
				<Form className='transportationForm'>
					<Grid columns={3} divided>
						<Grid.Row>
							<Grid.Column width={5}>
								<Label attached='top left'>Issuing Organization</Label>
								<Table basic='very'>
									<Table.Body>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Name:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.name}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Address:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.address}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Phone Number:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.contact_no}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className='manifestFormCell'>Email:</Table.Cell>
											<Table.Cell className='manifestFormCell'>{this.state.org.email}</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</Grid.Column>
							<Grid.Column textAlign='left' width={6}>
								<Label attached='top left'>Driver Details</Label>
								<div className='mani-top-label'>
									<Form.Input
										label="Driver's Name"
										value={this.state.manifest.driver_name}
										name='driver_name'
										onChange={this.updateManifest}
										type='text'
										className='required-field'
										placeholder="Driver's Name"
										error={this.state.errors.driver_name}
									/>
								</div>
								<Form.Input
									label="Driver's License Number"
									value={this.state.manifest.driver_license}
									name='driver_license'
									onChange={this.updateManifest}
									type='text'
									className='required-field'
									placeholder="Driver's License Number"
									error={this.state.errors.driver_license}
								/>
								<Form.Input
									label="Vehicle's VIN"
									value={this.state.manifest.vehicle_vin}
									name='vehicle_vin'
									onChange={this.updateManifest}
									type='text'
									className='required-field'
									placeholder="Vehicle's VIN"
									error={this.state.errors.vehicle_vin}
								/>
								<Form.Input
									label="Vehicle's License Plate"
									value={this.state.manifest.vehicle_license_plate}
									name='vehicle_license_plate'
									onChange={this.updateManifest}
									type='text'
									className='required-field'
									placeholder="Vehicle's License Plate"
									error={this.state.errors.vehicle_license_plate}
								/>
							</Grid.Column>
							<Grid.Column textAlign='left' width={5}>
								<Label attached='top left'>Route Details</Label>
								<div className='mani-top-label'>
									<Form.Input
										label='Source'
										value={this.state.manifest.source}
										name='source'
										onChange={this.updateManifest}
										type='text'
										className='required-field'
										placeholder='Detailed source information including company and address'
										error={this.state.errors.source}
									/>
								</div>
								<Form.Input
									label='Destination'
									value={this.state.manifest.destination}
									name='destination'
									onChange={this.updateManifest}
									type='text'
									className='required-field'
									placeholder='Detailed destination information including company and address'
									error={this.state.errors.destination}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={5}>
								<Label attached='top left'>Transportation Manifest Details</Label>
								<div className='mani-top-label'>
									<Form.Input
										label='Name'
										value={this.state.form.manifest_name}
										name='manifest_name'
										onChange={this.updateForm}
										type='text'
										className='required-field'
										placeholder='Name'
										error={this.state.errors.manifest_name}
										maxLength={50}
									/>
								</div>
								<Form.Input
									label='Description'
									value={this.state.form.description}
									name='description'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Description'
									error={this.state.errors.description}
								/>
								<Form.Input
									label='Transport Company Name'
									value={this.state.manifest.transport_company_name}
									name='transport_company_name'
									onChange={this.updateManifest}
									type='text'
									className='required-field'
									placeholder='Company Name'
									error={this.state.errors.transport_company_name}
								/>
							</Grid.Column>

							<Grid.Column textAlign='left' width={6}>
								<Label attached='top left'>Drivers License</Label>
								<Grid centered style={{ paddingTop: '15px' }}>
									<ImageDropzone
										image={this.state.image} disabled={(this.state.uploadFlag)}
										uploadImage={(files) => this.uploadImage(files)}
										imageError={(error) => this.setState({ imageError: error })}
									/>
								</Grid>
								<Grid centered>
									<Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='center'>
										<input
											type='file'
											accept='image/*'
											ref={this.upload}
											id='myAccountLogoForm'
											onChange={() => { this.uploadImage(this.upload.current.files) }}
										/>
										{this.state.imageError && <p className='error'>{this.state.imageError}</p>}
										<div id='mani-image-buttons'>
											<Button
												icon
												labelPosition='left'
												primary
												negative={false}
												size='small'
												disabled={(this.state.uploadFlag || this.state.certus)}
												onClick={() => this.upload.current.click()}
											>
												<Icon name='arrow alternate circle up' />
												Upload
											</Button>
											<Button
												icon
												labelPosition='left'
												primary={false}
												negative
												size='small'
												disabled={(!this.state.uploadFlag || this.state.certus)}
												onClick={() => this.removeImage()}
											>
												<Icon name='trash' />
												Remove
											</Button>
										</div>
										{this.state.errors.driver_id && <ErrorMessages errors={this.state.errors.driver_id} />}
									</Grid.Column>
								</Grid>
							</Grid.Column>

							<Grid.Column textAlign='left' width={5}>
								<Label attached='top left'>Bonding Details</Label>
								<div className='mani-top-label'>
									<Form.Input
										label='Bonded Carrier Name'
										value={this.state.manifest.bonded_carrier_name}
										name='bonded_carrier_name'
										onChange={this.updateManifestOptional}
										type='text'
										placeholder='Bonded Carrier Name'
										error={this.state.errors.bonded_carrier_name}
									/>
								</div>
								<Form.Input
									label='Bonded Carrier Permit'
									value={this.state.manifest.bonded_carrier_permit}
									name='bonded_carrier_permit'
									onChange={this.updateManifestOptional}
									type='text'
									placeholder='Bonded Carrier Permit'
									error={this.state.errors.bonded_carrier_permit}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Header as='h5' textAlign='right'>
						<span style={{ color: '#db2828' }}>*</span>
						{' '}
						Required
					</Header>

					{this.state.errors.selected_certi && <ErrorMessages errors={this.state.errors.selected_certi} />}

					{this.state.selected_certi.length > 0 && (
						<Grid className='selectedCertusList' columns={2} padded textAlign='left'>
							{this.state.selected_certi.map((certus) => (
								<Grid.Column key={certus.id}>
									{certus.certus_type.certus_type}
									:
									{' '}
									<strong>{certus.project_name}</strong>
									{' '}
									|
									{' '}
									{dateTimeFormatter(certus.created_at)}
								</Grid.Column>
							))}
						</Grid>
					)}
					{this.state.selected_certi.length > 0 && (
						<p>
							<strong>
								Total:
								{this.state.selected_certi.length}
							</strong>
						</p>
					)}
					<Button
						type='submit'
						inverted
						color='green'
						onClick={this.getConfirmation}
						loading={this.state.loading}
					>
						Create VERISEAL Transportation Manifest
					</Button>
				</Form>

				{this.state.certi.length > 0 && (
					<CertusSelectorTable
						sampleCerti={false}
						certusRows={this.state.certi}
						meta={this.state.meta}
						getCertiRecords={(page) => this.getCertiRecords(page)}
						jumpPage={(page) => { this.setState({ meta: { ...this.state.meta, currentPageNo: page } }) }}
						updateSelected={(updated) => { this.setState({ selected_certi: updated, errors: {} }) }}
					/>
				)}
			</section>
		)

		return (
			<section id='mainContent' className='app light certusCreate'>
				<ErrorMessages errors={this.state.apiErrors} />
				<AppHeader title={<h1>Create VERISEAL Transportation Manifest</h1>} breadcrumb={breadcrumb} />

				<p>
					Certify the transportation of VERISEAL Certificate of Analysis, Chemical Reports, and Projects by creating a VERISEAL 
					Transportation Manifest. The VERISEAL Transportation Manifest secures the physical mobility on the 
					CERTUS<Icon style={{ verticalAlign: 'top' }} name='registered outline' size='tiny' /> blockchain.
				</p>

				{display}

				{modal}
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

const mapDispatchToProps = (dispatch) => ({
	getOrgCertusTokenBank: () => { dispatch(getOrgCertusTokenBank()) }
})

TransportationCertusCreate.propTypes = {
	history: PropTypes.shape({
		length: PropTypes.number.isRequired,
		push: PropTypes.func.isRequired
	}).isRequired
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(withRouter(TransportationCertusCreate))
