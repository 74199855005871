import React, { Component } from 'react'
import PreparedSample from './PreparedSample'
import AddPreparedSample from './AddPreparedSample'
import { withRouter } from 'react-router-dom'

class PreparedSamplesInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      adding: false,
      acquiredPreparedSample: this.props.previousPreppedSamples.filter((prep) => {return prep.acquire === true}) // downsize prepped to only the acquired for index referencing
    }
  }

  toggleAdding = () => {
    this.setState({adding: !this.state.adding})
  }

  render() {
    const previouslyPreppedSamplesDOM = this.props.previousPreppedSamples.map((pps, index) => {
      let link
      // lookup result in results array based on the acquire prepped vials index (should match results array index)
      // if we have results, and this vial is acquired, and result exists for this vial; then create link
      if (this.props.results &&
        this.state.acquiredPreparedSample.indexOf(pps) >= 0 &&
        this.props.results[this.state.acquiredPreparedSample.indexOf(pps)]) {
          link = `/sample/${this.props.sampleId}/vial/${this.state.acquiredPreparedSample.indexOf(pps) + 1}` // add 1 for beauty
      }
      return (
        <div className="preppedVialInput" key={`${pps.vial_type}-${pps.label}-${pps.dilution_factor}-${index}`}>
          <div className={link ? "vial vialLink" : "vial"}
            onClick={() => {if (link) {this.props.history.push(link)}}}>
            <PreparedSample vial={pps} hideAcquire={this.props.hideAcquire} />
          </div>
        </div>
      )
    })
    const addButton = (
      <div className="addButton" onClick={this.toggleAdding}>
        + add
      </div>
    )
    const addForm = <AddPreparedSample addPreppedSample={(preppedSample) => {this.toggleAdding(); this.props.addPreppedSample(preppedSample)}} />
    return (
      <div className="preparedSamplesInput">
        {previouslyPreppedSamplesDOM}
        {(this.props.editable && !this.state.adding && !this.props.disabled) && addButton}
        {this.state.adding && addForm}
      </div>
    )
  }
}

export default withRouter(PreparedSamplesInput)
