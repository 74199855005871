import React, { Component } from 'react'
import { Button, Icon, Progress } from 'semantic-ui-react'

function AutosamplerStopButton(props) {

  let stopButton

  // error state
  if (props.machineState && props.machineState.status === "ERROR") {
    // stopButton = (<Button icon labelPosition="left"  disabled>
    //   <Icon name="stop" size="large" />
    //   Error!
    // </Button>)
    stopButton = undefined
  }

  //slept state, instrument on
  else if (props.machineState
    && props.machineState.status == 'NOTREADY'
    && props.machineState.method === process.env.REACT_APP_SLEEP_METHOD+'.M' ){
    stopButton = (<Button icon labelPosition="left" disabled>
        <Icon name="stop" size="large" />
        Sleeping
      </Button>
    )
  }

  // warming up as we speak
  else if (props.machineState
    && props.machineState.method === process.env.REACT_APP_WARMUP_METHOD+'.M'
    && ['PRERUN', 'RUN', 'NOTREADY'].indexOf(props.machineState.status) > -1) {
    stopButton = (<Button icon labelPosition="left"  onClick={() => {props.doAction('stopMethod', 'warming')}}>
        <Icon name="stop" size="large" />
        Stop Warming
      </Button>
    )
  }
  else if (props.machineState && props.machineState.method === process.env.REACT_APP_SLEEP_METHOD+'.M') {
    stopButton = (<Button icon labelPosition="left" onClick={() => {props.doAction('stopMethod', 'sleeping')}} >
        <Icon name="stop" size="large" />
        Stop Sleeping
      </Button>
    )
  }
  else if (props.machineState && props.machineState.sequenceRunning) { //Sequence Running
      stopButton = (<Button icon labelPosition="left" onClick={() => {props.doAction('abort')}}>
          <Icon name="stop" size="large" />
          Stop Run
        </Button>
      )
    if (props.machineState && !props.machineState.pausing) {
    } else {
      // "pausing" button
      stopButton = (<Button icon labelPosition="left" disabled>
          <Icon name="stop" size="large" />
          Sleep
        </Button>
      )
    }

  // no sleepy while paused
  } else if (props.machineState && props.machineState.sequencePaused) {
    stopButton = (<Button icon labelPosition="left" disabled>
      <Icon name="stop" size="large" />
      Paused
    </Button>)
  }

  // idle state
  else if (props.machineState && props.machineState.method === process.env.REACT_APP_RUN_METHOD+'.M') {
    stopButton = (<Button icon labelPosition="left"  onClick={() => {props.doAction('sleep')}}>
        <Icon name="stop" size="large" />
        Sleep Instrument💤
      </Button>
    )
  }

  // catch-all for safety. 
  // shouldn't be possible if logic above is correct and chemstation enabled cannid method
  else {
    stopButton = (<Button icon labelPosition="left" disabled>
        <Icon name="stop" size="large" />
        Stop-ish?
      </Button>
    )
  }

  return stopButton ? (
    <div className="autosamplerStopButton actionButton">
      {stopButton}
    </div>
  ) : ""
}

export default AutosamplerStopButton
