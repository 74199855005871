import React from 'react'
import { Button, Grid, Header } from 'semantic-ui-react'
import constants from '../lib/constants'
class PageNotAuthorized extends React.Component {
  render() {
    return (
      <Grid className='redirects' textAlign='center' verticalAlign='middle'>
        <Grid.Column>
          <Header as='h2' className='headerColor' textAlign='center'>
            {constants.PERMISSION_DENIED}
          </Header>
          <Button positive onClick={() => {
            this.props.history.replace('/')
          }}>{(localStorage.getItem('access_token') ? constants.GO_TO_HOME_PAGE_BUTTON_TITLE : constants.GO_TO_LOGIN_PAGE_BUTTON_TITLE)}</Button>
        </Grid.Column>
      </Grid>
    )
  }
}

export default PageNotAuthorized
