import React, { Component } from 'react'
import {
	Breadcrumb, Grid, Form, Button, Modal, Loader, Header, Icon
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AppHeader from '../AppHeader'
import ErrorMessages from '../Errors/ErrorMessages'
import cannidAPI from '../../cannidAPI/client'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import CertusSelectorTable from './CertusSelectorTable'
import { getOrgCertusTokenBank } from '../../actions'

class ProjectCertusCreate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			meta: {},
			certi: [],
			modalSuccess: false,
			confirmOpen: false,
			loading: false,
			selected_samples: [],
			form: { project_name: '', description: '' },
			newId: 0,
			project_options: [],
			projectType: undefined,
			errors: {},
			apiErrors: {}
		}
		this.mounted = false
	}

	componentDidMount() {
		this.getCertiRecords(1)
	}

	componentWillUnmount() {
		this.mounted = false
	}

	getCertiRecords = (page) => {
		this.mounted = true
		// get list of certi records, all types
		cannidAPI.get(`/sample_certi?page=${page}`).then((response) => {
			const project_options = response.data.project_type.map((x) => ({ key: x.id, text: x.project_type, value: x.id }))
			if (this.mounted) {
				this.setState({
					certi: response.data.data,
					meta: response.data.meta,
					project_options
				})
			}
		}).catch((err) => {
			console.log('error!', err)
			const message = `${err}. Failed to retrieve sample test certi.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	updateForm = (e) => {
		const form = this.state.form
		form[e.target.name] = e.target.value
		this.setState({ form })
	}

	getErrors = () => {
		const errors = {}
		if (!this.state.form.project_name) {
			errors.project_name = 'Must include Project Name.'
		}
		if (!this.state.form.description) {
			errors.description = 'Must include a Project Description.'
		}
		if (this.state.selected_samples.length <= 1) {
			errors.selected_samples = 'Must select 2 or more VERISEAL to be part of the VERISEAL Project.'
		}
		if (!this.state.projectType) {
			errors.selected_project = 'Must select a Project Type.'
		}
		return errors
	}

	getConfirmation = () => {
		this.setState({ errors: {} })
		if (!Object.keys(this.getErrors()).length) {
			this.setState({ confirmOpen: true, apiErrors: {} })
		}
		else {
			this.setState({ errors: this.getErrors() })
		}
	}

	createProjectCertus = () => {
		const selectedSamples = this.state.selected_samples.map((selected, index) => selected.batch_id)

		const params = {
			name: this.state.form.project_name.trim(),
			description: this.state.form.description.trim(),
			children: selectedSamples.join(),
			projectType: this.state.projectType
		}

		document.body.classList.add('loading-indicator')

		cannidAPI.post('/project_certi', params).then((response) => {
			console.log('success', response)
			document.body.classList.remove('loading-indicator')
			this.setState({
				confirmOpen: true, loading: false, modalSuccess: true, newId: response.data.id
			})
			this.props.getOrgCertusTokenBank()
		}).catch((err) => {
			console.log('error!', err)
			document.body.classList.remove('loading-indicator')
			const spec = err.response.data.message ? `${err.response.data.message} ` : ''
			const message = `${err}. ${spec}Certus creation error. Reload the page and try again.`
			this.setState({
				apiErrors: message, confirmOpen: false, loading: false, modalSuccess: false
			})
			this.props.getOrgCertusTokenBank()
		})
	}

	render() {
		const breadcrumb = (
			<Breadcrumb>
				<Link to='/'><Breadcrumb.Section>Home</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to='/certus'>
					<Breadcrumb.Section>
						<div style={{ display: 'flex' }}>
							CERTUS
							<Icon name='registered outline' size='tiny' />
						</div>
					</Breadcrumb.Section>
				</Link>
				<Breadcrumb.Divider icon='right angle' />
				<Breadcrumb.Section>Create VERISEAL Project</Breadcrumb.Section>
			</Breadcrumb>
		)

		const modal = this.state.modalSuccess ? (
			<Modal
				className='certusConfirmation'
				size='large'
				open={this.state.confirmOpen}
				onClose={() => this.setState({ confirmOpen: false, loading: false },
					 this.props.history.push(`/certus/project/${this.state.newId}`))}
				closeIcon
			>
				<Modal.Header>CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> Success!</Modal.Header>
				<Modal.Content scrolling>
					<div>
						<p>
							VERISEAL Project successfully written to the 
							CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> KSI Blockchain.
							<br/><strong>Notice: It may take up to a minute for the QR code scan functionality to propogate.</strong>
						</p>
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button
						className='confirmButton'
						icon='checkmark'
						labelPosition='right'
						content='OK'
						onClick={() => this.setState({ confirmOpen: false, loading: false },
							this.props.history.push(`/certus/project/${this.state.newId}`))}
					/>
				</Modal.Actions>
			</Modal>
		) : (
			<Modal
				className='certusConfirmation'
				size='large'
				open={this.state.confirmOpen}
				onClose={() => this.setState({ confirmOpen: false })}
				closeIcon
			>
				<Modal.Header>Create VERISEAL Project</Modal.Header>
				<Modal.Content scrolling>
					<section>
						<p style={{marginTop: '1rem'}}>
							Are you sure you want to write this VERISEAL Project to the immutable 
							CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> KSI Blockchain?
						</p>
					</section>
				</Modal.Content>
				<Modal.Actions>
					<Button className='cancelButton' onClick={() => this.setState({ confirmOpen: false })}>No</Button>
					<Button
						className='confirmButton'
						icon='checkmark'
						labelPosition='right'
						content='Yes'
						onClick={() => this.setState({ confirmOpen: false, loading: true }, this.createProjectCertus)}
					/>
				</Modal.Actions>
			</Modal>
		)

		const display = this.state.loading ? (
			<Loader active={this.state.loading} size='big' inline='centered'>
				<small>
					Writing VERISEAL Project to the CERTUS<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> 
					KSI Blockchain. This may take several seconds...
				</small>
			</Loader>
		) : (
			<section className='createCertusForm'>
				<Form className='projectForm'>
					<Grid>
						<Grid.Row centered columns={3}>
							<Grid.Column width={3}>
								<Form.Input
									label='Project Name'
									value={this.state.form.project_name}
									name='project_name'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Project Name'
									error={this.state.errors.project_name}
									maxLength={50}
								/>
							</Grid.Column>
							<Grid.Column width={6}>
								<Form.Input
									label='Project Description'
									value={this.state.form.description}
									name='description'
									onChange={this.updateForm}
									type='text'
									className='required-field'
									placeholder='Project Description'
									error={this.state.errors.description}
								/>
							</Grid.Column>
							<Grid.Column width={4}>
								<Form.Select
									label='Project Type'
									options={this.state.project_options}
									placeholder='Project Type'
									error={this.state.errors.selected_project}
									onChange={(event, data) => { this.setState({ projectType: data.value }) }}
									className='required-field'
								/>
								<Grid.Row>
									<Header as='h5' textAlign='right'>
										<span style={{ color: '#db2828' }}>*</span>
										{' '}
										Required
									</Header>
								</Grid.Row>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					{this.state.errors.selected_samples && <ErrorMessages errors={this.state.errors.selected_samples} />}
					{this.state.selected_samples.length > 0 && (
						<Grid className='selectedCertusList' columns={2} padded textAlign='left'>
							{this.state.selected_samples.map((certus, index) => (
								<Grid.Column key={index}>
									{certus.certus_type.certus_type}
									:
									{' '}
									<strong>{certus.project_name}</strong>
									{' '}
									|
									{' '}
									{dateTimeFormatter(certus.created_at)}
								</Grid.Column>
							))}
						</Grid>
            		)}
					{this.state.selected_samples.length > 0 && (
						<p><strong>Total:{this.state.selected_samples.length}</strong></p>
            		)}
					<Button
						type='submit'
						inverted
						color='green'
						onClick={this.getConfirmation}
						loading={this.state.loading}
					>
						<div style={{ display: 'flex' }}>
							Create VERISEAL Project
						</div>
					</Button>
				</Form>

				{this.state.certi.length > 0 && (
					<CertusSelectorTable
						sampleCerti={false} // can be enabled for projects since all listed certi are samples
						certusRows={this.state.certi}
						meta={this.state.meta}
						getCertiRecords={(page) => this.getCertiRecords(page)}
						jumpPage={(page) => { this.setState({ meta: { ...this.state.meta, currentPageNo: page } }) }}
						updateSelected={(updated) => { this.setState({ selected_samples: updated, errors: {} }) }}
					/>
				)}
			</section>
		)

		return (
			<section id='mainContent' className='app light certusCreate'>
				<ErrorMessages errors={this.state.apiErrors} />
				<AppHeader
					title={(
						<h1 style={{ display: 'flex', justifyContent: 'center' }}>
							Create VERISEAL Project
						</h1>
					)}
					breadcrumb={breadcrumb}
				/>

				<p>
					Certify the relationship of multiple VERISEAL Certificate of Analysis and/or VERISEAL Chemical Reports 
					by creating a VERISEAL Project. The VERISEAL Project secures the relationship on the CERTUS
					<Icon style={{ verticalAlign: 'top', margin: 0 }} name='registered outline' size='tiny' /> blockchain.
				</p>

				{display}

				{modal}
			</section>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.current_user
})

const mapDispatchToProps = (dispatch) => ({
	getOrgCertusTokenBank: () => { dispatch(getOrgCertusTokenBank()) }
})

export default connect(
	mapStateToProps, mapDispatchToProps
)(withRouter(ProjectCertusCreate))
