import React, { Component } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import CertusModalActions from './CertusModalActions'

class CertusActionButtons extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apiErrors: {},
			modalSampleId: null,
			modalStrainName: null,
			certusId: null,
			modalOpen: false,
			buttonClicked: null
		}
	}

	handleChildClick = (e, type) => {
		e.stopPropagation();
		this.setState({
			modalOpen: true,
			buttonClicked: type
		})
	}

	render() {
		const certusbuttons = (
			<div>
				<CertusModalActions
					certus={this.props.certus}
					modalOpen={this.state.modalOpen}
					buttonClicked={this.state.buttonClicked}
					closeModal={()=> this.setState({modalOpen:false})}
					openModal={() => this.setState({modalOpen:true})} />
				{this.props.certus.expired ?
					<Popup content="Renew VERISEAL" position='top right'
						trigger={
							<Button icon="calendar plus outline"
								onClick={ (e) => {this.handleChildClick(e,"Renew");}} >
							</Button> 
						} /> :
					<Popup content="Set VERISEAL Expire Date" position='top right'
						trigger={
							<Button icon="calendar times"
								onClick={ (e) => {this.handleChildClick(e,"Expire");}}>
							</Button>
						} />
				}
				<Popup content="Revoke VERISEAL" position='top right'
					trigger={
						<Button icon="ban"
						onClick={ (e) => {this.handleChildClick(e,"Revoke");}}>
						</Button>
					} />
			</div>
		)

		return (certusbuttons)
	}
}
export default CertusActionButtons
