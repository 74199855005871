import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import { Segment, Header } from 'semantic-ui-react'
import constants from './constants'

function ImageDropzone(props) {
	const noImageFound = constants.BATCH.GRID_TYPE_IMAGES.NO_IMAGE_FOUND
	const imageSrc = props.image ? props.image : noImageFound

	const onDrop = useCallback((acceptedFiles) => {
		console.warn('dropped files:', acceptedFiles)
		if (acceptedFiles[0] && acceptedFiles[0].name.match(/.*\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
			props.uploadImage(acceptedFiles)
		}
		else {
			props.imageError('Error reading selected file. Acceptable image file types: .jpg|.jpeg|.png')
		}
	})

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop, 
		disabled: props.disabled
	})

	return (
		<div className='imageDropZone' {...getRootProps()}
			style={props.disabled ? {} : { cursor: 'pointer' }}
			disabled={props.disabled}>
			{!isDragActive
				? <img src={imageSrc} />
				: (
					<Segment placeholder>
						<Header icon style={{marginBottom: 0}}>
							<img src='https://cdn.chemid.com/images/dragon_drop/dragon_drop_head_chemid_479x479.png'
								alt='ChemID Dragon Drop'
								className='dragonDrop'
								style={{marginBottom: '.5em'}} />
							<br />
							Drop to upload!
						</Header>
					</Segment>
				)
			}
			<input {...getInputProps()} disabled={props.disabled}/>
		</div>
	)
}

ImageDropzone.propTypes = {
	image: PropTypes.string.isRequired,
	uploadImage: PropTypes.func.isRequired,
	imageError: PropTypes.func.isRequired,
	disabled: PropTypes.bool
}.isRequired

export default ImageDropzone
