import base64 from './urlSafeBase64'

export const pathEncoderS3 = (result) => {
    // grab unique data folder path within \Data
    var df = result.csDataFolder?.match(/(?:Data\\)(.*)/i)?.[1]
    
    // result csDataFolder should be \Data root followed by sequence folder or the combo sampleFolder & sequence folder
    // although, empty string csDataFolder is acceptable for .D run result folders loose in the \Data root
    // so we'll create a flag to handle empty string so that logic doesn't return false due to empty string
    const dfEmpty = (Object.keys(result).includes('csDataFolder') && (result.csDataFolder === '' || df === ''))

    // format slashes
    if (df && df.includes("\\")) {
        df = df.replace(/\\/g, "/")
    }
    var dn = result.csDataName
    var mn = result.machineName

    // if the pieces exist we rebuild the encoding, otherwise return null
    if ((df || dfEmpty) && dn && mn) {
        if (df && dn) {
            var chroma_key = df+"/"+dn;
        } else if ((dn && !df) || (dn && dfEmpty)){
            var chroma_key = dn;
        } else if (df && !dn){
            var chroma_key = df;
        }

        // man inject: csDataFolder
        var chroma_id = base64.urlEncode(`${mn},,${chroma_key}`) // if data folder concat data name with 
        // console.log('encoding', `${mn},,${chroma_key}`, chroma_id)  
        return chroma_id
    } else {
        return null
    }
}

export const showChromes = (results) => {
    let show = false
    if (results && results.length > 0) {
        results.forEach((result) => {
            const encodable = pathEncoderS3(result)
            if (result.chromeUrl || encodable) {
                show = true
            }
        })
    }
    return show
}