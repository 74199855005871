import React, { Component } from 'react'
import { Icon, Grid } from 'semantic-ui-react'
import classnames from 'classnames'
import sampleQueueName from '../../lib/sampleQueueName'

class SampleQueueList extends Component {
  render() {
    let i = 0
    let oneIsCompleted = false
    let vialsDom
    let allResults
    let noResultsAndNotCurrent
    let currentSampleButIdle
    if (this.props.sample_queue.locations) {
      vialsDom = this.props.sample_queue.locations.map((vialKey) => {
        let vialColor = 'grey'
        let completion_icon
        if (parseInt(vialKey) === parseInt(this.props.currentVialLocation))
          vialColor = 'white'
        if (this.props.sample_queue.vials && this.props.sample_queue.vials.length) {
          let vial = this.props.sample_queue.vials.find((vial) => (parseInt(vial.location) === parseInt(vialKey)))
          if (vial && vial.completed) {
            vialColor = parseInt(vialKey) === parseInt(this.props.currentVialLocation) ? 'white': '#008000'
            completion_icon = <Icon name={'check'} style={{color: vialColor}} />
            oneIsCompleted = true
          }
        }
        i++
        return (
          <em key={vialKey} onMouseEnter={() => { this.props.setCurrentVialLocation(vialKey) }} onMouseLeave={() => { this.props.setCurrentVialLocation(undefined) }}>  
            {completion_icon}
            <Icon name={'flask'} style={{color: vialColor}} />
          </em>
        )
      })

      allResults = this.props.sample_queue.vials.filter(v => v.completed).length === this.props.sample_queue.vials.length
      noResultsAndNotCurrent = (this.props.sample_queue.vials.filter(v => v.completed).length === 0 &&
        this.props.currentTask &&
        !this.props.sample_queue.locations.includes(this.props.currentTask.location)
      )
      currentSampleButIdle = (this.props.sample_queue.vials.filter(v => v.completed).length < this.props.sample_queue.vials.length &&
        this.props.currentTask &&
        this.props.sample_queue.locations.includes(this.props.currentTask.location) &&
        (this.props.machineState.method === process.env.REACT_APP_RUN_METHOD + '.M' || this.props.machineState.method === 'RUN.M') &&
        this.props.machineState.status === 'PRERUN' &&
        !this.props.machineState.sequenceRunning &&
        this.props.queuePaused
      )
    }

    let queueClasses = classnames('sampleQueueList', {noExey: oneIsCompleted})
    return (
      <div className={queueClasses}>
        <Grid className="queueInfo" columns="equal" stackable>
          <Grid.Column textAlign="left" className="sampleName">
            <span>{sampleQueueName(this.props.sample_queue)}</span>
            {(allResults ||
              noResultsAndNotCurrent ||
              currentSampleButIdle ||
              (this.props.machineState.method !== process.env.REACT_APP_RUN_METHOD + '.M' && this.props.machineState.method !== 'RUN.M') ||
              !this.props.sample_queue.locations) &&
              <span><Icon style={{cursor: "pointer"}} onClick={() => {this.props.removeSampleFromQueue(this.props.sample_queue)}} name='close' /></span>
            }
          </Grid.Column>
          <Grid.Column textAlign="right" className="vialIcons">{vialsDom}</Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default SampleQueueList
