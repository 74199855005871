import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import PrepVialCannId from './PrepVialCannId'
import FancyVolume from './FancyVolume'

class PrepMethodCannIdTile extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const prepSteps = this.props.prepMethod.prep_steps.reverse()
    const diluentFlow = prepSteps.map((prep, index) => {
      const opacity = 1 - (index * .4)
      return (
        <Grid.Column key={prep.id} className="vialContainer">
          <div>
            <PrepVialCannId vial={prep} index={index} fillOpacity={opacity} />
          </div>
          {prepSteps[index + 1] &&
            <div className="aliquotInfo">
              <div className="depositVolume">
                <FancyVolume ul={prepSteps[index + 1].deposit_volume_ul} />
              </div>
              <div className="transitionText">
                ➢
              </div>
              <div className="fillVolume">
                <FancyVolume ul={prepSteps[index + 1].fill_volume_ul} />
              </div>
            </div>
          }
        </Grid.Column>
      )
    })

    return (
      <div className="prepMethodCannIdTile">
        <section className="header">
          <h3>{this.props.prepMethod.name}</h3>
        </section>
        <section className="prepMethodDiluentFlow">
          <Grid stackable columns='equal'>{diluentFlow}</Grid>
        </section>
      </div>
    )
  }
}

export default PrepMethodCannIdTile
