import axios from 'axios'

let requestHeaders = {}

if (localStorage.getItem('access_token')) {
	requestHeaders['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
}

if (localStorage.getItem('machine')) {
	requestHeaders['HPLC-Laptop-Hostname'] = localStorage.getItem('machine')
}

const client = axios.create({
	baseURL: process.env.REACT_APP_API_ROOT + '/',
	headers: requestHeaders,
	transformRequest: [(data, headers) => {
		// if initial load and instrument wasn't previously connected, the machine name won't be in local storage and the Routes.js
		// file (where all sample queue refreshes are requested from) won't know the machine until a reload happens. therefore, we
		// re-check if the machine hostname is present and then apply it to the request headers if it now exists
		if (!headers['HPLC-Laptop-Hostname'] && localStorage.getItem('machine')) {
			headers['HPLC-Laptop-Hostname'] = localStorage.getItem('machine')
		}

		return data
	}, ...axios.defaults.transformRequest]
})

export default client
