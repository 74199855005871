// takes parsed csv json output from csvtojson package and standardizes
// row lengths so they all match the longest row (handles missing cells)
const standardizeParsedCsv = (row) => {
	// check column count for each row so we can find longest
	let longestRowKeys = []
	row.map(r => {
		if (Object.keys(r).length > longestRowKeys.length) longestRowKeys = Object.keys(r)
	})
	// then standardize making all equal length
	return row.map(r => {
		if (Object.keys(r).length < longestRowKeys.length) {
			let newRow = {...r}
			longestRowKeys.forEach(k => {
				if (!Object.keys(newRow).includes(k)) {
					newRow[k] = ''
				}
			})
			return newRow
		}
		else {
			return r
		}
	})
}
export default standardizeParsedCsv