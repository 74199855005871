const sample = {
  sample_weight: '100g',
  results: [
    {
      results: [{
        compound: "cbd",
        amount: 0.53,
        peak_area: 250
      },
      {
        compound: "cbg",
        amount: 2.23,
        peak_area: 300
      },
      {
        compound: "cbda",
        amount: 0.9,
        peak_area: 300
      }],
      chrom: []
    },
    {
      results: [{
        compound: "thc",
        amount: 0.38,
        peak_area: 300
      },
      {
        compound: "thca",
        amount: 0.5,
        peak_area: 300
      },
      {
        compound: "thcb",
        amount: 0.9,
        peak_area: 300
      },
      {
        compound: "cbd",
        amount: 0.23,
        peak_area: 300
      }],
      chrom: []
    }
  ]
}

export default sample
