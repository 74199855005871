import React, { Component } from 'react'
import { Icon, Checkbox, Input, Header, Table, Menu, Grid } from 'semantic-ui-react'
import cannidAPI from '../../cannidAPI/client'

export default class PreviousCertus extends Component {
	constructor(props) {
		super(props)
		this.initialState = {
			search: '',
			confirmedOne: false,
			confirmedTwo: false,
			selectedOption: undefined,
			selectedId: undefined,
			share_key: undefined,
			options: [],
			count: 0,
			total_count: 0,
			offset: 0
		}
		this.state = {
			...this.initialState,
			latest: []
		}
		this.mounted = false
	}

	componentDidUpdate() {
		this.state.options
		if (this.state.confirmedOne == false && this.state.confirmedTwo == true) {
			this.clearState(true)
		}
	}

	componentWillUnmount() {
		this.mounted = false
	}

	componentDidMount() {
		this.mounted = true
		this.getRecent(10)
	}

	clearState = (clearInput) => {
		this.setState(prevState => {
			return ({
				...this.initialState,
				options: prevState.latest,
				count: prevState.latest.length,
				total_count: prevState.latest.length
			})
		})
		this.props.setSelection(undefined)
		if (clearInput) document.getElementById('previousSearchInput').value = ''
	}

	getRecent = (count) => {
		const manifest = this.props.certusType === 'manifest' ? true : false

		cannidAPI.post(`certi/previous_certi_recent`, {
			count: count,
			manifest: manifest
		})
		.then((res) => {
			if (res.status >= 200 && res.status <= 299 && this.mounted) {
				this.setState({
					latest: res.data.certi_list,
					options: res.data.certi_list,
					count: res.data.certi_list.length,
					total_count: res.data.certi_list.length
				})
			}
		})
		.catch( (err) => {
			console.error(`VERISEAL Search Error: ${err}`)
			const message = `${err}. Failed to retrieve VERISEAL list.`
			if (this.mounted) {
				this.setState({ apiErrors: message })
			}
		})
	}

	updateSearch = (event) => {
		event.preventDefault()
		// 3 char minimum in search
		if (event.target.value.length >= 3) {
			this.fetchCertusList(event.target.value)
			this.setState({
				search: event.target.value,
				offset: 0,
				selectedId: undefined,
				share_key: undefined
			})
		}
		else {
			this.clearState()
		}
	}

	fetchCertusList (search, offset = 0) {
		const manifest = this.props.certusType === 'manifest' ? true : false

		cannidAPI.post(`certi/previous_certi`, {
			input: search.toUpperCase(),
			offset: offset,
			manifest: manifest
		})
		.then((res) => {
			if (res.status >= 200 && res.status <= 299) {
				this.setState({
					options: res.data.certi_list,
					count: res.data.count,
					total_count: res.data.total_count
				})
			}
		})
		.catch( (err) => {
			console.error(`VERISEAL Search Error: ${err}`)
		})
	}

	toggleSelected = (data, certusId, shareKey) => {
		const selectedOption = this.state.options.filter(o => o.id === certusId)[0]
		const selection = data.checked
			? { selectedId: certusId, share_key: shareKey, selectedOption: selectedOption }
			: { selectedId: undefined, share_key: undefined, selectedOption: undefined }
		this.setState(selection)
	}

	toggleConfirmOne(data) {
		data.checked ? this.setState({ confirmedOne: data.checked, confirmedTwo: false }) : this.clearState(true)
	}

	toggleConfirmTwo(data) {
		this.setState({ confirmedTwo: data.checked })
		data.checked ? this.props.setSelection(this.state.selectedId) : this.props.setSelection(undefined)
	}

	render() {

		let previousCertiTable = this.state.options.length === 0 ?
			<div style={{ padding: '2rem' }}><p>No results found. Please alter the search.</p></div> :
			<section className='certusSelectorTable'>
				<Table celled>
					<Table.Header>
						<Table.Row className='certusListRow'>
							<Table.HeaderCell>Selected</Table.HeaderCell>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>VERISEAL</Table.HeaderCell>
							<Table.HeaderCell>TRACER</Table.HeaderCell>
							<Table.HeaderCell>Expiration</Table.HeaderCell>
							<Table.HeaderCell>Created Date</Table.HeaderCell>
							<Table.HeaderCell>Created By</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{this.state.options.map((certus, index) => {
							return (
								<Table.Row id={'certus'+certus.id} key={'certus'+certus.id}
									className={certus.id === this.state.selectedId ? 'selected-certus-row' : ''}>
									<Table.Cell >
										<Checkbox toggle
											onChange={(e, data) => this.toggleSelected(data, certus.id, certus.share_key)}
											checked={certus.id === this.state.selectedId ? true : false} />
									</Table.Cell>
									<Table.Cell>{certus.project_name}</Table.Cell>
									<Table.Cell>{certus.certus_type.certus_type}</Table.Cell>
									<Table.Cell>{certus.share_key}</Table.Cell>
									<Table.Cell>{certus.expiration_date}</Table.Cell>
									<Table.Cell>{certus.created_at}</Table.Cell>
									<Table.Cell>{certus.user.name}</Table.Cell>
								</Table.Row>
							)
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell colSpan='7' verticalAlign='middle'>
								<Menu floated='right' pagination>
									<Menu.Item as='a' disabled={this.state.offset === 0}
										onClick={() => {
											this.fetchCertusList(this.state.search, this.state.offset - 10)
											this.setState(prevState => ({ offset: prevState.offset - 10 }))
										}}>
										<Icon name='chevron left' size='small' style={{margin: 0}} />
									</Menu.Item>
									<Menu.Item as='a' disabled={(this.state.offset + this.state.count) === this.state.total_count}
										onClick={() => {
											this.fetchCertusList(this.state.search, this.state.offset + 10)
											this.setState(prevState => ({ offset: prevState.offset + 10 }))
										}}>
										<Icon name='chevron right' size='small' style={{margin: 0}} />
									</Menu.Item>
								</Menu>
								<div style={{float: 'left', lineHeight: '2.5rem'}}>
									{ this.state.search.length < 3
										? 'Showing 10 Most Recent'
										: `Showing ${this.state.offset + 1} - ${this.state.offset + this.state.count} of ${this.state.total_count}`
									}
								</div>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</section>

		if (this.state.confirmedOne && this.state.selectedId) {
			const certus = this.state.selectedOption
			previousCertiTable = (
				<section className='certusSelectorTable'>
					<Table celled>
						<Table.Header>
							<Table.Row className='certusListRow'>
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell>VERISEAL</Table.HeaderCell>
								<Table.HeaderCell>TRACER</Table.HeaderCell>
								<Table.HeaderCell>Expiration</Table.HeaderCell>
								<Table.HeaderCell>Created Date</Table.HeaderCell>
								<Table.HeaderCell>Created By</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row id={'certus'+certus.id} key={'certus'+certus.id}
								className='selected-certus-row'>
								<Table.Cell>{certus.project_name}</Table.Cell>
								<Table.Cell>{certus.certus_type.certus_type}</Table.Cell>
								<Table.Cell>{certus.share_key}</Table.Cell>
								<Table.Cell>{certus.expiration_date}</Table.Cell>
								<Table.Cell>{certus.created_at}</Table.Cell>
								<Table.Cell>{certus.user.name}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</section>
			)
		}

		return (
			<div>
				<Header>Previous VERISEAL</Header>
				<p>
					Select a Previous VERISEAL to link as a source for this VERISEAL being created.
				</p>
				<div>
					<Input icon='search' type='text' minLength='3' id='previousSearchInput'
						onChange={this.updateSearch}
						label='Search'
						placeholder='3 Character Minimum...'
						style={{minWidth: '40%'}} />
					{ this.state.share_key &&
						<div style={{float: 'right', lineHeight: '2.5rem'}}>
							SELECTED: <strong style={{color: '#4d9851'}}>{this.state.share_key}</strong>
						</div>
					}
				</div>
	
				{ previousCertiTable }

				{ this.state.selectedId != null && 
					<div style={{margin: '1rem 0'}}>
						<Checkbox
							label='I have previewed the data being uploaded for the new VERISEAL and confirm it is accurate to the best of my knowledge.'
							checked={this.state.confirmedOne}
							onChange={(event, data) => this.toggleConfirmOne(data)}
						/>
					</div>
				}

				{ this.state.confirmedOne == true &&
					<div style={{margin: '1rem 0'}}>
						<Checkbox
							label={
								<label>
									I understand that I have selected Previous VERISEAL TRACER <strong>{this.state.share_key}</strong> to be 
									linked and associated with the VERISEAL of <strong>{this.props.current_name}</strong>. Once I link the 
									VERISEAL, I cannot make changes and will need to start over in order to make changes.
								</label>
							}
							checked={this.state.confirmedTwo}
							onChange={(event, data) => this.toggleConfirmTwo(data)}
						/>
					</div>
				}
			</div>
		)
	}
}
