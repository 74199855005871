import React, { Component } from 'react'
import AppHeader from '../AppHeader'
import { 
  Breadcrumb, 
  Button, 
  Form, 
  Table,
  Icon,
  Popup,
  Modal 
} from 'semantic-ui-react'
import ToastMessage from '../Message'
import { Link } from 'react-router-dom'
import Grid from 'semantic-ui-react/dist/es/collections/Grid/Grid'
import { FormTemplateService } from '../../services/FormTemplates'
import constants from '../../lib/constants'
import trimWhiteSpace from '../../lib/trim'
import ErrorMessages from '../Errors/ErrorMessages'
import CreateFormField from '../../components/FormFields/CreateFormField'
import { formatRoute } from 'react-router-named-routes'
class CreateFormTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formTemplate: {
        id: '',
        name: '',
        description: '',
        fields: [],
        selectedFields: {},
        formTemplateFields: []
      },
      formErrors: {
        name: false,
        fields: false
      },
      addFormTemplateFlag: true,
      formTemplateCreated: null,
      formTemplateUpdated: null,
      errorMessages: [],
      apiErrors: {},
      openFormFieldModal: false,
      modalData: {},
      modalActionType: null,
      modalDataIndex: null,
      removeFormFieldFlag: false
    }
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      FormTemplateService.getFormTemplate(this.props.match.params.id).then((res) => {
        let tempState = { ...this.state.formTemplate }
        tempState.id = res.data.id
        tempState.name = res.data.name
        tempState.description = res.data.description
        let existingFields = []
        let arrayList = res.data.form_fields
        for (let i = 0; i < arrayList.length; i++) {
          existingFields.push({
            "id": arrayList[i].id,
            "name": arrayList[i].name,
            "type": arrayList[i].type.slug,
            "values": arrayList[i].options.map((data) => { return data.name }),
            "validations": [
              {
                "is_required": arrayList[i].is_required
              }
            ]
          })
        }
        tempState.formTemplateFields = existingFields
        this.setState({
          formTemplate: tempState,
          apiErrors: {}
        })
      }).catch((error) => {
        this.setState({
          apiErrors: (error.data) ? error.data : {}
        })
      })
    }
  }
  validateForm = () => {
    let tempFormError = { ...this.state.formErrors }
    let formFieldExists = false
    if (this.state.formTemplate.formTemplateFields.length > 0) {
      formFieldExists = true
    }
    if (!this.state.formTemplate.name.length) {
      tempFormError.name = true
    }
    if (!formFieldExists) {
      tempFormError.fields = true
    }
    this.setState({ formErrors: tempFormError })
    return formFieldExists && (this.state.formTemplate.name.length !== 0)
  }
  addFormTemplate = () => {
    let formIsValid = this.validateForm()
    if (!formIsValid) {
      return false
    }
    let tempSelectedFormFields = []
    for (let key in this.state.formTemplate.selectedFields) {
      if (this.state.formTemplate.selectedFields[key]) {
        tempSelectedFormFields.push(key)
      }
    }
    let requestBody = {
      "form_template": {
        "name": trimWhiteSpace(this.state.formTemplate.name),
        "description": trimWhiteSpace(this.state.formTemplate.description),
        "form_fields": this.state.formTemplate.formTemplateFields
      }
    }
    if (this.state.formTemplate.id === '') {
      FormTemplateService.createFormTemplate(requestBody).then(res => {
        setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.LIST.ROUTE, {})), 1000);
        this.setState({ formTemplateCreated: true, errorMessages: [], apiErrors: {} })
      }).catch((error) => {
        this.setState({
          apiErrors: (error.data) ? error.data : {}
        })
        if (error && error.data && error.data.slug) {
          this.setState({
            errorMessages: error.data.slug,
            formTemplateCreated: false
          })
        }
      })
    } else if (parseInt(this.state.formTemplate.id) > 0) {
      FormTemplateService.patchFormTemplate(this.props.match.params.id, requestBody).then(res => {
        setTimeout(() => this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.LIST.ROUTE, {})), 1000);
        this.setState({ formTemplateUpdated: true, errorMessages: [], apiErrors: {} })
      }).catch((error) => {
        this.setState({
          apiErrors: (error.data) ? error.data : {}
        })
        if (error && error.data && error.data.slug) {
          this.setState({
            errorMessages: error.data.slug,
            formTemplateUpdated: false
          })
        }
      })
    }
  }
  updateFormTemplateForm = (e, formField) => {
    let tempFormError = { ...this.state.formErrors }
    tempFormError.name = false
    this.setState({ formErrors: tempFormError })
    let selected = {
      ...this.state.formTemplate,
      [e.target.name]: e.target.value
    }
    this.setState({
      formTemplate: selected
    })
  }
  handleChange = (e, { name, value, checked }) => {
    let selected = {
      ...this.state.formTemplate.selectedFields,
      [value]: checked
    }
    let tempFormError = { ...this.state.formErrors }
    tempFormError.fields = false
    this.setState({ formErrors: tempFormError })
    this.setState({
      formTemplate: {
        ...this.state.formTemplate,
        selectedFields: { ...selected }
      }
    })
  }
  addFormField = (e, type, index) => {
    let data = {}
    let modalIndex = null
    if (type === 'add' || type === 'edit') {
      if (type === 'edit') {
        data = this.state.formTemplate.formTemplateFields[index]
        modalIndex = index
      }
      this.setState({ openFormFieldModal: true, modalActionType: type, modalData: data, modalIndex: modalIndex })
    }
    else {
      modalIndex = index
      this.setState({ removeFormFieldFlag: true, modalActionType: type, modalData: data, modalIndex: modalIndex })
    }
  }
  removeFormField = () => {
    let existingFields = this.state.formTemplate.formTemplateFields
    existingFields.splice(this.state.modalIndex, 1)
    let tempFormError = { ...this.state.formErrors }
    tempFormError.fields = false
    this.setState({
      formTemplate: {
        ...this.state.formTemplate,
        formTemplateFields: existingFields
      },
      formErrors: tempFormError
    }, () => { this.setState({ removeFormFieldFlag: false }) })
  }
  closeFormFieldModal = (e) => {
    this.setState({ openFormFieldModal: false })
  }
  handleSaveFormField = (e, data, type, index) => {
    let existingFields = this.state.formTemplate.formTemplateFields
    let tempFormError = { ...this.state.formErrors }
    tempFormError.fields = false
    if (type === 'add')
      existingFields.push(data)
    else
      existingFields[index] = data
    this.setState({
      formTemplate: {
        ...this.state.formTemplate,
        formTemplateFields: existingFields
      },
      formErrors: tempFormError
    }, () => { this.setState({ openFormFieldModal: false }) })
  }
  closeModalRemoveFormField = () => {
    this.setState({
      removeFormFieldFlag: false
    })
  }
  render() {
    let breadcrumb
    let id = this.props.match.params.id
    breadcrumb = (
      <Breadcrumb>
        <Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.LIST.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.FORM_TEMPLATE.LIST.NAME}</Breadcrumb.Section></Link>
        <Breadcrumb.Divider icon="right angle" />
        <Link to={id ? formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.EDIT.ROUTE, { id: id }) : formatRoute(constants.APPLICATION_ROUTE.FORM_TEMPLATE.CREATE.ROUTE, {})}><Breadcrumb.Section>{id ? constants.APPLICATION_ROUTE.FORM_TEMPLATE.EDIT.NAME : constants.APPLICATION_ROUTE.FORM_TEMPLATE.CREATE.NAME}</Breadcrumb.Section></Link>
      </Breadcrumb>
    )
    return (
      <div>
        <section id="mainContent" className="app light">
          <AppHeader title={<h1>{id ? constants.FORM_TEMPLATE.EDIT.HEADER_TITLE : constants.FORM_TEMPLATE.CREATE.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />
          <section className="app light">
            <div className="form screen">
              {this.state.formTemplateCreated === true &&
                <ToastMessage title='Success' description={constants.FORM_TEMPLATE.CREATE.SUCCESS} />}
              {this.state.formTemplateUpdated === true &&
                <ToastMessage title='Success' description={constants.FORM_TEMPLATE.EDIT.SUCCESS} />}
              <ErrorMessages errors={this.state.apiErrors}></ErrorMessages>
              <Form>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={12} largeScreen={8} computer={8}>
                    <Form.Input maxLength={constants.FIELD_LENGTH.MAX_64} label="Form Template Name" value={this.state.formTemplate.name}
                      name="name" onChange={this.updateFormTemplateForm} type="text"
                      placeholder="Form Template Name" error={this.state.formErrors.name} className="required-field" />
                    {this.state.formErrors.name &&
                      <p className='error'>{constants.FORM.MANDATORY_FIELD}</p>}
                  </Grid.Column>
                </Grid>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={12} largeScreen={8} computer={8}>
                    <Form.TextArea label="Form Template Description" value={this.state.formTemplate.description}
                      name="description" onChange={this.updateFormTemplateForm}
                      placeholder="Form Template Description" error={this.state.formErrors.description} />
                  </Grid.Column>
                </Grid>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={12} largeScreen={8} computer={8}>
                    <Form.Field style={{ fontWeight: 'bold', color: 'black' }} className="required-field">
                      <label>Form Fields</label>
                    </Form.Field>
                    <Grid centered>
                      <Grid.Column mobile={16} tablet={16} largeScreen={16} computer={16} textAlign='right'>
                        <Button type='button' color='blue' onClick={(e) => { this.addFormField(e, 'add', 0) }}>{constants.FORM_FIELD.CREATE.CREATE_BUTTON_TITLE}</Button>
                      </Grid.Column>
                    </Grid>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Required</Table.HeaderCell>
                          <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.formTemplate.formTemplateFields.map((data, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>{data.name}</Table.Cell>
                              <Table.Cell style={{ textTransform: 'capitalize' }}>{data.type} {data.type === 'dropdown' ? <Popup
                                content={data.values.join(', ')}
                                key={'values'}
                                header={'Values'}
                                trigger={<Icon name='info circle' color="grey" />}
                              /> : ''}</Table.Cell>
                              <Table.Cell>{data.validations[0].is_required ? 'Yes' : 'No'}</Table.Cell>
                              <Table.Cell>
                                <Popup content={constants.FORM_FIELD.EDIT.EDIT_BUTTON_TITLE} size='tiny' trigger={
                                  <Button icon={'pencil'} color={'blue'} onClick={(e) => {
                                    this.addFormField(e, 'edit', index)
                                  }} className={'dynamicTableActionButton'}
                                  />
                                } />
                                <Popup content={constants.FORM_FIELD.DELETE.DELETE_BUTTON_TITLE} size='tiny' trigger={
                                  <Button icon={'trash'} color={'red'} onClick={(e) => {
                                    this.addFormField(e, 'remove', index)
                                  }} className={'dynamicTableActionButton'}
                                  />
                                } />
                              </Table.Cell>
                            </Table.Row>
                          )
                        })
                        }
                        {
                          (this.state.formTemplate.formTemplateFields.length === 0) &&
                          <Table.Row colSpan={4} textAlign='center'>
                            <Table.Cell colSpan={4}>{constants.FORM_FIELD.LIST.NO_FIELDS_FOUND}</Table.Cell>
                          </Table.Row>
                        }
                      </Table.Body>
                    </Table>
                    {
                      this.state.formErrors.fields &&
                      <p className='error'>{constants.FORM_TEMPLATE.CREATE.FORM_FIELD_MANDATORY}</p>
                    }
                  </Grid.Column>
                </Grid>
                <Grid centered>
                  <Grid.Column mobile={16} tablet={8} largeScreen={4} computer={4} textAlign='center'>
                    <Button className='blue' type="submit"
                      onClick={this.addFormTemplate}>{id ? constants.FORM_TEMPLATE.EDIT.EDIT_BUTTON_TITLE : constants.FORM_TEMPLATE.CREATE.CREATE_BUTTON_TITLE}</Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
            {this.state.openFormFieldModal && <CreateFormField data={this.state.modalData} type={this.state.modalActionType} index={this.state.modalIndex} closeModal={this.closeFormFieldModal} handleSave={this.handleSaveFormField} />}
          </section>
        </section>
        <Modal size='mini' open={this.state.removeFormFieldFlag} onClose={() => { this.closeModalRemoveFormField() }} closeIcon>
          <Modal.Header>{constants.FORM_FIELD.DELETE.HEADER_TITLE}</Modal.Header>
          <Modal.Content>
            <p>{constants.FORM_FIELD.DELETE.MODAL_CONTENT}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive icon='checkmark' labelPosition='right' content='Yes' onClick={() => this.removeFormField()}></Button>
            <Button negative onClick={() => this.closeModalRemoveFormField()}>No</Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default CreateFormTemplate
