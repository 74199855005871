import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
const ModalComponent = (props) => (
  <Modal size={props.size}
    open={props.openModal} onClose={() => {
      props.onClose()
    }} closeIcon>
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content scrolling>
      <p>{props.content}</p>
    </Modal.Content>
    {props.action && <Modal.Actions>
      <Button className='cancelButton' onClick={() => props.onClose()}>No</Button>
      <Button className='confirmButton' icon='checkmark' labelPosition='right' content='Yes'
        onClick={() => props.handleAction(props.id)}></Button>
    </Modal.Actions>}
  </Modal>
)
export default ModalComponent
