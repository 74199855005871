const dataToggle = (json) => {
  let ret = {}
  try {
    for (let key in json) {
      for (let childKey in json[key]) {
        ret[childKey] = { ...ret[childKey], [key]: json[key][childKey] }
      }
    }
  } catch (error) {
  }
  return ret
}

export default dataToggle
