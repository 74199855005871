import React, { Component } from 'react'
import { Accordion, Icon, Message } from 'semantic-ui-react'
import $ from 'jquery'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsData from 'highcharts/modules/data';
import HighchartsExporting from 'highcharts/modules/exporting';
import iconv from 'iconv-lite'
import { pathEncoderS3 , showChromes } from '../../lib/pathEncoderS3'

HighchartsData(Highcharts)
HighchartsExporting(Highcharts)

class ChromatogramLineChart extends Component {
	constructor(props) {
		super(props)

		let show = showChromes(props.sample.results)
		this.state = {
			activeIndex: false,
			show: show,
			chart_data: false,
			chart_error: false
		}
	}

	componentDidMount() {
		if (this.props.openOnLoad) {
			this.handleClick()
		}
	}

  renderGraph = () => {
    this.props?.sample?.results?.forEach((item, index) =>{
      var chroma
      const chromeUrl = this.props.sample?.results?.[index]?.chromeUrl

      if (chromeUrl) {
        chroma = encodeURI(chromeUrl)
      } else if (this.props.sample?.results?.[index]?.chromeUploadError) {
        console.log('error for', index)
        $(`#chroma_csv .a${index}`).text('ERROR!')
        this.setState({ chart_error: true })
      } else {
        const chroma_id = pathEncoderS3(item)
        if (chroma_id) {
          chroma = `https://${process.env.REACT_APP_AWS_CHROME_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${chroma_id}/signal1.csv`
        } else {
          $(`#chroma_csv .a${index}`).text('ERROR!')
          this.setState({ chart_error: true })
        }
      }

      if (chroma) {
        fetch(chroma)
          .then(res => {
            if (res.status >= 200 && res.status <= 299) {
              return res
            } else {
              console.log('fetch bad resp status', res)
              throw Error(res.statusText)
            }
          })
          .then(res => res.arrayBuffer())
          .then(arrayBuffer => {
            const buff = Buffer.from(arrayBuffer)
            // test if newlines exist to determine encoding utf8 or utf16
            const utf16 = iconv.decode(buff, 'ucs2').toString()
            if (utf16.split('\r\n').length === 1) {
              const utf8 = iconv.decode(buff, 'utf8').toString()
              return utf8
            } else {
              return utf16
            }
          })
          .then(text => {
            $(`#chroma_csv .a${index}`).text(`Retention,Intensity\n${text}`)
            this.setState({ chart_data: true })
          })
          .catch(err => {
            console.log('fetch catch err', err)
            $(`#chroma_csv .a${index}`).text('ERROR!')
            this.setState({ chart_error: true })
          });
      }
    });
  }

  handleClick = () => {
    this.setState({ activeIndex: !this.state.activeIndex })
    this.renderGraph(this.props)
  }

  render() {
    const activeIndex = this.state.activeIndex
    const chart_data = this.state.chart_data
    var view = ""
    var chart = ""

    if (this.props.sample.prepared_samples && (chart_data || this.state.chart_error)){
      // array of 41 undefined positions which will be mapped via tickPositions
      const ticks = Array.from(Array(parseInt(1200/30+1)))
      const acquiredPreparedSample = this.props.sample.prepared_samples.filter((prep) => {return prep.acquire === true})
      chart = this.props?.sample?.results?.map((item, index) => {
        if (acquiredPreparedSample[index] && document.querySelector(`#chroma_csv .a${index}`) && document.querySelector(`#chroma_csv .a${index}`).innerHTML) {
			let titleText = this.props.sample.intake_form.strain_name
			if (acquiredPreparedSample[index]["label"]) {
				titleText = `${acquiredPreparedSample[index]["label"]}`
			}
			else if (!this.props.sample.import) {
				titleText = `${_.capitalize(acquiredPreparedSample[index]["vial_type"].split("_")[0]) + ' Vial'}`
			}
          
          if (document.querySelector(`#chroma_csv .a${index}`).innerHTML === 'ERROR!') {
            const content = item.chromeUploadError ? `Error retrieving Chromatogram: "${item.chromeUploadError}"` : "Error retrieving Chromatogram"
            return (
              <div key={index}>
                <p className="highcharts-title" style={{color:"#333333",fontSize:"18px",fill:"#333333"}}>{titleText}</p>
                <Message error style={{maxWidth:"80%",margin:"0 auto"}}>{content}</Message>
              </div>
            )
          } else {
            return <HighchartsReact
              key={index}
              highcharts={Highcharts}
              options={{
                credits: {
                  enabled: false
                },
                chart: {
                  backgroundColor: 'transparent',
                  zoomType: 'xy'
                },
                title: {
                  text: titleText
                },
                subtitle: {
                  text: 'Raw chroma data'
                },
                yAxis: [{ // Primary yAxis
                  labels: {
                    format: '{value}',
                  },
                  title: {
                    text: 'Intensity',
                  }
                }],
                xAxis: [{ // Primary yAxis
                  tickPositions: ticks.map((e,i)=>{ return item.chromeRtUnits === 'Minutes' ? .5 * i : 30 * i }),
                  labels: {
                    formatter: function() {
                      return item.chromeRtUnits === 'Minutes' ? this.value : this.value / 60
                    }
                  },
                  title: {
                    text: 'Retention Time, Minutes',
                  }
                }],
                data: {
                  csv: document.querySelector(`#chroma_csv .a${index}`).innerHTML
                },
                plotOptions: {
                  series: {
                    marker: {
                      enabled: false
                    }
                  }
                },
                tooltip: {
                  formatter: function () {
                    let rt = item.chromeRtUnits === 'Minutes' ? this.x : this.x / 60
                    let cr = this.point.color
                    let nm = this.point.series.name
                    let int = this.point.y
                    let str = `● Retention Time: <b>${rt.toFixed(3)}</b> min
                      <br/>
                      <span style="color:${cr}">●</span> ${nm}: <b>${int.toFixed(3)}</b>
                      <br/>`
                    return str
                  }
                },
                exporting: {
                  enabled: false
                }
              }}
            />
          }
          
        }
      })
    };

    if (this.state.show){
      view = <Accordion className='accordian'>
                <pre id="chroma_csv" style={{display:'none'}}>
                  <pre className="a0"></pre>
                  <pre className="a1"></pre>
                  <pre className="a2"></pre>
                  <pre className="a3"></pre>
                  <pre className="a4"></pre>
                  <pre className="a5"></pre>
                </pre>
                <Accordion.Title
                  className={activeIndex ? 'accordian-title' : 'accordian-inactive'}
                  active={activeIndex}
                  index={0}
                  onClick={this.handleClick}>
                  <Icon name='dropdown' />
                  Chromatogram
                </Accordion.Title>
                <Accordion.Content active={activeIndex}>
                  {chart}
                </Accordion.Content>
              </Accordion>
    }

    return view
  }
}

export default ChromatogramLineChart
