import React  from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { FormTemplateService } from '../../services/FormTemplates'
import ModalComponent from '../ModalComponent'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
class ListFormFieldsByTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formFieldById: [],
      openModal: false,
      options: []
    }
  }
  componentDidMount() {
    FormTemplateService.getAllFormFieldById(this.props.id).then((response) => {
      const res = response.data.data
      const array = res.map((data) => {
        return {
          id: data.id,
          name: data.name,
          createdOn: dateTimeFormatter(data.created_at),
          updatedOn: dateTimeFormatter(data.updated_at),
          type: data.type && data.type.name,
          options: data.options || []
        }
      })
      this.setState({ formFieldById: array })
    })
  }
  openModal = () => {
    this.setState({ openModal: true })
  }
  handleClose = () => {
    this.setState({ openModal: false })
  }
  render() {
    return (
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Date Updated</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.formFieldById.map((data, index) => {
              return (
                <Table.Row key={data.id}>
                  <Table.Cell>{data.name}</Table.Cell>
                  <Table.Cell>{data.type}</Table.Cell>
                  <Table.Cell>{data.createdOn}</Table.Cell>
                  <Table.Cell>{data.updatedOn}</Table.Cell>
                  <Table.Cell>
                    {data.type === 'Dropdown' ? <Icon name={'eye'} onClick={() => {
                      this.openModal()
                      this.setState({ options: data.options })
                    }} title={'view option'} /> : 'NA'}</Table.Cell>
                </Table.Row>
              )
            })
            }
            {
              (!this.state.formFieldById || this.state.formFieldById.length === 0) &&
              <Table.Row colSpan={5} textAlign='center'>
                <Table.Cell colSpan={5}>No Fields Found</Table.Cell>
              </Table.Row>
            }
          </Table.Body>
        </Table>
        <ModalComponent
          size='mini'
          action={false}
          openModal={this.state.openModal}
          onClose={this.handleClose}
          header={'Possible values'}
          content={<ul>
            {this.state.options.map((data) => {
              return (
                <li>{data.name}</li>
              )
            })}
          </ul>}
        />
      </div>
    )
  }
}
export default ListFormFieldsByTemplate
