import React, { Component } from 'react'
import { Icon } from 'semantic-ui-react'
import vialTypes from '../lib/vialTypes'

class VialPropertyPicker extends Component {
  render() {
    let vial_types = Object.keys(vialTypes)
    let colorDom = vial_types.map((type) => {
      return <button
        className="setColor"
        name="vial_type"
        value={type}
        onClick={this.props.setVialProperties}
        style={{backgroundColor: vialTypes[type].color}}
      />
    })
    return (
      <div className="vialPropertyPicker">
        <div className="vialPropertyPicker-colors">
          {colorDom}
        </div>
        <div className="vialPropertyPicker-fillVolumeUl">
          <input type="text" onChange={this.props.setVialProperties} name="fill_volume_ul" value={this.props.vial.fill_volume_ul} />
        </div>
      </div>
    )
  }
}

export default VialPropertyPicker
