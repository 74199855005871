import React, { Component } from 'react'
import { Breadcrumb, Button, Icon, Modal, Table, Grid, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { formatRoute } from 'react-router-named-routes'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import AppHeader from '../AppHeader'
import { SampleTestServices } from '../../services/SampleTest'
import DynamicTableComponent from '../DynamicTableComponent'
import ModalComponent from '../ModalComponent'
import SampleTestResult from './SampleTestResult'
import DownloadExcel from '../Download/DownloadExcel'
import dateTimeFormatter from '../../lib/dateTimeFormatter'
import constants from '../../lib/constants'
import Acl from '../ACL/Acl'
import axios from '../../axios'
import ErrorMessages from '../Errors/ErrorMessages'
import logoutSession from '../../lib/logoutSession'
import checkDynamicTableActions from '../../lib/checkDynamicTableActions'
import permissionCheck from '../../lib/permissionCheck'
import { selectedTests, updateSampleQueues } from '../../actions'
import reportNameFormatter from '../../lib/reportNameFormatter'
import decodeJWT from '../../lib/decodeJWT'
import { CsvDownload } from '../../services/CsvDownloads'
import handleDefaultValue from '../../lib/handleDefaultValue'
import cannidAPI from '../../cannidAPI/client'
import ImportDisclosureModal from '../Import/ImportDisclosureModal'
import CertusTokenBank from '../Certus/CertusTokenBank'

const options = [
	{ key: 'batch_number', text: 'Batch Number', value: 'batch_number' },
	{ key: 'strain_name', text: 'Strain/Sample Name ', value: 'strain_name' },
	{ key: 'sample_weight', text: 'Sample Weight (mg)', value: 'sample_weight' }
]
const entireRowAction = { title: constants.SAMPLE_TEST.LIST.REPORT, action: constants.ACL.SAMPLE_RESOURCE.ACTIONS.SHOW_REPORT }
const mapStateToProps = (state) => ({
	selected_tests: state.selected_tests,
	sampleQueues: state.sampleQueues,
	machineState: state.machineState,
	showQueue: state.showQueue,
	current_user: state.current_user,
	certusBankInfo: state.certusBankInfo
})
const mapDispatchToProps = (dispatch) => ({
	selectedTests: (tests) => { dispatch(selectedTests(tests)) },
	updateSampleQueues: (sampleQueues) => {
		dispatch(updateSampleQueues(sampleQueues))
	}
})
class SampleTests extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tableData: [],
			count: 0,
			selectedSearchDropdown: 'batch_number',
			sampleFlag: false,
			sampleTestResultFlag: false,
			apiErrors: {},
			selectedCheckBoxes: [],
			role: '',
			importModal: false,
	  queryParams: ''
		}
	}

	resize = () => this.forceUpdate()

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize)
	}

	fetchQueues = () => {
		cannidAPI.get('/sample_queues').then((res) => {
			this.props.updateSampleQueues(res.data)
		})
	}

	getTableData = (res) => {
		const response = get(res, 'data.data', false) || []
		const count = get(res, 'data.meta.total_count', false) || 0
		const tableData = response.map((data) => {
			const imported = data.import ? (
				<Icon
					name='info circle' color='green'
					onClick={() => this.setState({ importModal: !this.state.importModal })}
					onMouseOver={(e) => { e.target.classList.add('grey') }}
					onMouseOut={(e) => { e.target.classList.remove('grey') }}
				/>
			) : ''
			const isCalibrationStandard = (data.sample_type && (data.sample_type.slug === constants.SAMPLE_TYPE.CALIBRATION || data.sample_type.slug === constants.SAMPLE_TYPE.STANDARD))
			return {
				id: data.id,
				intake_number: data.intake_form ? handleDefaultValue(data.intake_form.intake_number) : handleDefaultValue(null),
				batch_number: data.intake_form ? handleDefaultValue(data.intake_form.batch_number) : handleDefaultValue(null),
				strain_name: data.intake_form ? handleDefaultValue(data.intake_form.strain_name) : handleDefaultValue(null),
				sample_weight: data.intake_form && data.intake_form.sample_weight !== 'NaN' ? handleDefaultValue(data.intake_form.sample_weight) : handleDefaultValue(null),
				share_key: handleDefaultValue(data.share_key),
				created_at: dateTimeFormatter(data.created_at),
				updated_at: dateTimeFormatter(data.updated_at),
				organization_name: data.organization ? handleDefaultValue(data.organization.name) : handleDefaultValue(null),
				isResult: !data.finished,
				isEditAllowed: (!constants.USER_ROLE.ADMIN_ROLE_SLUGS.includes(this.state.role) ? ((data.intake_form && data.intake_form.sample_weight !== null && data.intake_form.sample_weight !== '' && data.intake_form.sample_weight !== 'NaN') ? true : isCalibrationStandard) : isCalibrationStandard),
				import: imported,
				finished: !!(data.finished || data.import), // cannot add sample to queue if results present or it was imported
				certus_id: data.certus_id,
				strain_name: data.intake_form.strain_name,
				coa: data.coa
			}
		})
		this.setState({
			tableData,
			count,
			initialCount: count,
			initialData: tableData
		})
	}

	getFilteredData = (data) => {
		const arrayList = get(data, 'data.data', false) || []
		const count = get(data, 'data.meta.total_count', false) || 0
		const tableData = arrayList.map((data) => {
			const imported = data.import ? (
				<Icon
					name='info circle' color='green'
					onClick={() => this.setState({ importModal: !this.state.importModal })}
					onMouseOver={(e) => { e.target.classList.add('grey') }}
					onMouseOut={(e) => { e.target.classList.remove('grey') }}
				/>
			) : ''
			const isCalibrationStandard = (data.sample_type && (data.sample_type.slug === constants.SAMPLE_TYPE.CALIBRATION || data.sample_type.slug === constants.SAMPLE_TYPE.STANDARD))
			return {
				id: data.id,
				intake_number: data.intake_form ? handleDefaultValue(data.intake_form.intake_number) : handleDefaultValue(null),
				batch_number: data.intake_form ? handleDefaultValue(data.intake_form.batch_number) : handleDefaultValue(null),
				strain_name: data.intake_form ? handleDefaultValue(data.intake_form.strain_name) : handleDefaultValue(null),
				sample_weight: data.intake_form && data.intake_form.sample_weight !== 'NaN' ? handleDefaultValue(data.intake_form.sample_weight) : handleDefaultValue(null),
				share_key: handleDefaultValue(data.share_key),
				created_at: dateTimeFormatter(data.created_at),
				updated_at: dateTimeFormatter(data.updated_at),
				organization_name: data.organization ? handleDefaultValue(data.organization.name) : handleDefaultValue(null),
				isResult: !data.finished,
				isEditAllowed: (!constants.USER_ROLE.ADMIN_ROLE_SLUGS.includes(this.state.role) ? ((data.intake_form && data.intake_form.sample_weight !== null && data.intake_form.sample_weight !== '' && data.intake_form.sample_weight !== 'NaN') ? true : isCalibrationStandard) : isCalibrationStandard),
				import: imported,
				finished: !!(data.finished || data.import), // cannot add sample to queue if results present or it was imported
				certus_id: data.certus_id,
				strain_name: data.intake_form.strain_name,
				coa: data.coa
			}
		})
		this.setState({ tableData, count })
	}

	sortByColumn = (tableData) => {
		this.setState({ tableData })
	}

	rowAction = (action, data, e) => {
		if (e.dispatchConfig) e.stopPropagation()
		if (action === constants.SAMPLE_TEST.LIST.EDIT_SAMPLE_TEST) this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.EDIT.ROUTE, { id: data.id }))
		if (action === constants.SAMPLE_TEST.LIST.REMOVE) {
			this.setState({ sampleFlag: true, sampleId: data.id })
		}
		if (action === constants.SAMPLE_TEST.LIST.LIST_SAMPLE_TEST_RESULTS) {
			this.setState({ sampleTestResultFlag: true, sampleId: data.id })
		}
		if (action === constants.SAMPLE_TEST.LIST.REPORT) {
			if (permissionCheck(constants.ACL.SAMPLE_RESOURCE.TITLE, constants.ACL.SAMPLE_RESOURCE.ACTIONS.SHOW_REPORT)) this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.REPORT.ROUTE, { id: data.id }))
		}
		if (action === constants.SAMPLE_TEST.LIST.DOWNLOAD_PDF_REPORT) {
			this.downloadReport(data)
		}
		if (action === constants.SAMPLE_TEST.LIST.DOWNLOAD_CSV_RESULT) {
			this.downloadResult(data)
		}
		if (action === 'Add To Queue') {
			this.addToSampleQueue(data)
		}
	}

	removeSample = (id) => {
		SampleTestServices.deleteSample(id).then((res) => {
			if (res && res.status === 202) {
				const tableData = [...this.state.tableData].filter((data) => data.id !== id)
				this.setState({ sampleFlag: false, tableData, count: this.state.count - 1, apiErrors: {} }, () => window.location.reload())
			}
		}).catch((error) => {
			this.setState({ apiErrors: (error.data) ? error.data : {} })
		})
	}

	getSelectedDropdown = (e, { value }) => {
		this.setState({ selectedSearchDropdown: value, tableData: this.state.initialData, count: this.state.initialCount })
	}

	closeModal = () => {
		this.setState({ sampleFlag: false, sampleTestResultFlag: false })
	}

	addToSampleQueue = (data) => {
		console.log('we are getting this:', data)
		cannidAPI.post('/sample_queues/', {
			sample: data,
			actual_role: this.props.current_user.actual_role.slug,
			assumed_org: this.props.current_user.organization.id
		})
			.then((response) => {
				if (response.status === 200) {
					this.setState({ apiErrors: {} })
					this.fetchQueues()
				}
			}).catch((error) => {
				console.log(error)
				this.setState({ apiErrors: (error.response.data.error) ? error.response.data.error : {} })
			})
		return true
	}

	downloadReport = (data) => {
		axios().get(SampleTestServices.getPdfReportDownloadUrl(data.share_key), { responseType: 'blob' })
			.then((response) => {
				if (response.status === 200) {
					this.setState({ apiErrors: {} })
					if (navigator.userAgent.match('CriOS')) {
						const reader = new FileReader()
						const out = new Blob([response.data], { type: 'application/pdf' })
						reader.onload = function (e) {
							window.location.href = reader.result
						}
						reader.readAsDataURL(out)
					}
					else {
						const url = window.URL.createObjectURL(new Blob([response.data]))
						const link = document.createElement('a')
						link.href = url
						link.setAttribute('download', `${reportNameFormatter(handleDefaultValue(data.batch_number), data.strain_name, data.organization_name)}.pdf`)
						document.body.appendChild(link)
						link.click()
					}
				}
			}).catch((error) => {
				if (error.status === 401 || error.data.message === constants.LOGIN.SIGNATURE_EXPIRY) {
					logoutSession()
				}
				this.setState({ apiErrors: (error.data) ? error.data : {} })
			})
	}

	downloadResult = (data) => {
		CsvDownload.downloadData(SampleTestServices.getSampleTestResultDownloadUrl(data.id), handleDefaultValue(data.batch_number))
	}

	componentDidMount() {
		window.addEventListener('resize', this.resize)
		if (this.props.selected_tests && this.props.selected_tests.type !== constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.SAMPLE_TEST) this.props.selectedTests({ batch_id: '', selectedCheckBoxes: [], type: constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.SAMPLE_TEST })
		const decodedResponse = decodeJWT(localStorage.getItem('access_token'))
		this.setState({ role: (decodedResponse.role.slug) })
	}

	checkBoxHandler = (e, data, row) => {
		e.stopPropagation()
		let selectedCheckBoxes = get(this, 'props.selected_tests.selectedCheckBoxes', false) || []
		if (this.props.selected_tests && this.props.selected_tests.type && this.props.selected_tests.type !== constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.SAMPLE_TEST) {
			selectedCheckBoxes = []
		}
		if (data.checked) {
			selectedCheckBoxes.push({ id: row.id })
		}
		else {
			const index = selectedCheckBoxes.findIndex((x) => x.id === row.id)
			if (index >= 0) selectedCheckBoxes.splice(index, 1)
		}
		this.props.selectedTests({ selectedCheckBoxes, batch_id: '', type: constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.SAMPLE_TEST })
	}

	checkBoxHandlerAll = (e, data, rows) => {
		e.stopPropagation()
		const selectedCheckBoxes = get(this, 'props.selected_tests.selectedCheckBoxes', false) || []
		rows.map((row) => {
			if (data.checked && row.isResult === false) {
				const index = selectedCheckBoxes.findIndex((x) => x.id === row.id)
				if (index < 0) {
					selectedCheckBoxes.push({ id: row.id })
				}
			}
			else {
				const index = selectedCheckBoxes.findIndex((x) => x.id === row.id)
				if (index >= 0) {
					selectedCheckBoxes.splice(index, 1)
				}
			}
			return selectedCheckBoxes
		})
		this.props.selectedTests({ selectedCheckBoxes, batch_id: '', type: constants.GRAPH_DASHBORD.COMPARE_TYPE_SLUG.SAMPLE_TEST })
	}

	getSelectedCheckBoxes = () => {
		if (this.props.selected_tests && this.props.selected_tests.selectedCheckBoxes) {
			return this.props.selected_tests.selectedCheckBoxes
		}
		return []
	}

	render() {
		const machineOnline = (this.props.machineState && Object.keys(this.props.machineState).length > 0 && !['POWEROFF', 'disconnect'].includes(this.props.machineState.status))

		let breadcrumb
		breadcrumb = (
			<Breadcrumb>
				<Link to={formatRoute(constants.APPLICATION_ROUTE.HOME.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.HOME.NAME}</Breadcrumb.Section></Link>
				<Breadcrumb.Divider icon='right angle' />
				<Link to={formatRoute(constants.APPLICATION_ROUTE.SAMPLE.LIST.ROUTE, {})}><Breadcrumb.Section>{constants.APPLICATION_ROUTE.SAMPLE.LIST.NAME}</Breadcrumb.Section></Link>
			</Breadcrumb>

		)

		const tableHeader = [
			{ id: 'batch_number', title: 'Batch Number', sortable: false, type: 'src', import_flag: true },
			{ id: 'strain_name', title: 'Strain/Sample Name', sortable: false, type: 'src' },
			{ id: 'sample_weight', title: 'Sample Weight (mg)', sortable: false, type: 'src' },
			{ id: 'created_at', title: 'Date Created', sortable: true, type: 'src' },
			{
				id: 'action_item',
				title: 'Actions',
				sortable: false,
				actionItems: [
					{ title: constants.SAMPLE_TEST.LIST.EDIT_SAMPLE_TEST, color: 'blue', style: { marginRight: '10px' }, icon: 'edit', action: constants.ACL.SAMPLE_RESOURCE.ACTIONS.EDIT, disableClass: 'isEditAllowed' },
					{ title: constants.SAMPLE_TEST.LIST.DOWNLOAD_PDF_REPORT, color: 'green', style: { marginRight: '10px' }, icon: 'file pdf outline', action: constants.ACL.SAMPLE_RESOURCE.ACTIONS.DOWNLOAD_REPORT, disableClass: 'isResult' },
					{ title: constants.SAMPLE_TEST.LIST.DOWNLOAD_CSV_RESULT, color: 'green', style: { marginRight: '10px' }, icon: 'file excel outline', action: constants.ACL.SAMPLE_RESOURCE.ACTIONS.DOWNLOAD_RESULT, disableClass: 'isResult' },
					{ title: constants.SAMPLE_TEST.LIST.REMOVE, style: { marginRight: '10px' }, color: 'red', icon: 'remove', action: constants.ACL.SAMPLE_RESOURCE.ACTIONS.DELETE }
				],
				type: 'action'
			}
		]
		if (machineOnline) {
			tableHeader[tableHeader.length - 1].actionItems.push({ title: 'Add To Queue', style: { marginRight: '10px' }, color: 'green', icon: 'add', action: 'add_to_queue' })
		}
		if (permissionCheck(constants.ACL.CERTUS.TITLE, constants.ACL.CERTUS.ACTIONS.VERISEAL)) {
			tableHeader[tableHeader.length - 1].actionItems.push({ title: constants.SAMPLE_TEST.LIST.CERTUS_GRAY, style: { marginRight: '10px' }, color: 'grey', icon: 'qrcode', action: 'certus' })
		}

		const showActions = checkDynamicTableActions(tableHeader, constants.ACL.SAMPLE_RESOURCE.TITLE)
		const idsInQueue = this.props.sampleQueues.map((q) => ((q.sample && parseInt(q.sample.id) || (q.action === 'sleep' && q.action)))).filter((q) => q)
		return (
			<div>
				<section id='mainContent' className='app light'>

					<AppHeader title={<h1>{constants.SAMPLE_TEST.LIST.HEADER_TITLE}</h1>} breadcrumb={breadcrumb} />

					<section className='app light'>
						<ErrorMessages errors={this.state.apiErrors} />
						
						<DynamicTableComponent
							entity={constants.ACL.SAMPLE_RESOURCE.TITLE}
							action={constants.ACL.SAMPLE_RESOURCE.ACTIONS.LIST}
							tableHeader={tableHeader}
							options={options}
							baseUrl={SampleTestServices.getSampleTests([constants.SAMPLE_TYPE.CALIBRATION], [])}
							rowData={this.state.tableData}
							initialData={this.state.initialData}
							selectedSearchDropdown={this.state.selectedSearchDropdown}
							getFilteredData={this.getFilteredData}
							getSelectedDropdown={this.getSelectedDropdown}
							colSpan={tableHeader.length - (showActions ? 0 : 1) - (permissionCheck(constants.ACL.GRAPH_DASHBOARD_RESOURCE.TITLE, constants.ACL.GRAPH_DASHBOARD_RESOURCE.ACTIONS.CREATE) === false ? 1 : 0)}
							sortByColumn={this.sortByColumn}
							count={this.state.count}
							getData={this.getTableData}
							actionItem
							rowKey='id'
							countTitle={constants.SAMPLE_TEST.LIST.TOTAL_TITLE}
							rowAction={this.rowAction}
							entireRowAction={entireRowAction}
							showActions={showActions}
							isCheckBoxRequired={permissionCheck(constants.ACL.GRAPH_DASHBOARD_RESOURCE.TITLE, constants.ACL.GRAPH_DASHBOARD_RESOURCE.ACTIONS.CREATE)}
							idsInQueue={idsInQueue}
							showQueue={this.props.showQueue}
							machineState={this.props.machineState}
							propagateQuery={(queryParams) => this.setState({ queryParams })}
							certusTokenBankPanel={true}
							certusTokenBank={this.props.certusBankInfo}
						>
							<Table.Header fullWidth>
								<Table.Row>
									<Table.HeaderCell style={{ cursor: 'initial' }} colSpan={tableHeader.length - (showActions ? 0 : 1) - (permissionCheck(constants.ACL.GRAPH_DASHBOARD_RESOURCE.TITLE, constants.ACL.GRAPH_DASHBOARD_RESOURCE.ACTIONS.CREATE) === false ? 1 : 0)}>
										<Grid centered>
											<Grid.Column mobile={16} tablet={6} largeScreen={6} computer={6}>
												{constants.SAMPLE_TEST.LIST.TOTAL_TITLE}: {this.state.count}
											</Grid.Column>
											<Grid.Column mobile={16} tablet={10} largeScreen={10} computer={10} className='dataTableHeaderButtons'>
												<Acl entity={constants.ACL.SAMPLE_RESOURCE.TITLE} action={constants.ACL.SAMPLE_RESOURCE.ACTIONS.EXPORT_CSV}>
													<DownloadExcel url={SampleTestServices.getBulkCsvResultsDownloadUrl([constants.SAMPLE_TYPE.CALIBRATION], [], this.state.queryParams)} fileName='CannID-Results' />
												</Acl>
												<Acl entity={constants.ACL.SAMPLE_RESOURCE.TITLE} action={constants.ACL.SAMPLE_RESOURCE.ACTIONS.CREATE}>
													<Button
														icon labelPosition='left' className={window.screen.width < 768 ? 'confirmButton' : 'right floated confirmButton'} size='small'
														onClick={() => {
															this.props.history.push(formatRoute(constants.APPLICATION_ROUTE.SAMPLE.CREATE.ROUTE, {}))
														}}
													>
														<Icon name='user' />Create New Test
													</Button>
												</Acl>
												<Acl entity={constants.ACL.SAMPLE_RESOURCE.TITLE} action={constants.ACL.SAMPLE_RESOURCE.ACTIONS.CREATE}>
													<Button
														icon labelPosition='left' className={window.screen.width < 768 ? 'blue' : 'right floated blue'} size='small'
														onClick={() => {
															this.props.history.push('/samples/import')
														}}
													>
														<Icon name='add' />Import New Results
													</Button>
												</Acl>
												<Acl entity={constants.ACL.SAMPLE_RESOURCE.TITLE} action={constants.ACL.SAMPLE_RESOURCE.ACTIONS.EDIT}>
													<Button
														icon labelPosition='left' className={window.screen.width < 768 ? 'blue' : 'right floated blue'} size='small'
														onClick={() => {
															this.props.history.push('/samples/report-methods')
														}}
													>
														<Icon name='edit' />Report Methods
													</Button>
												</Acl>
											</Grid.Column>
										</Grid>
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
						</DynamicTableComponent>
						<Modal size='mini' open={this.state.sampleFlag} onClose={() => { this.closeModal() }} closeIcon>
							<Modal.Header>{constants.SAMPLE_TEST.DELETE.HEADER_TITLE}</Modal.Header>
							<Modal.Content>
								<p>{constants.SAMPLE_TEST.DELETE.MODAL_CONTENT}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button className='cancelButton' onClick={() => this.closeModal()}>No</Button>
								<Button className='confirmButton' icon='checkmark' labelPosition='right' content='Yes' onClick={() => this.removeSample(this.state.sampleId)} />
							</Modal.Actions>
						</Modal>
						<ModalComponent
							size='large'
							action={false}
							openModal={this.state.sampleTestResultFlag}
							onClose={this.closeModal}
							header={constants.SAMPLE_TEST.LIST.LIST_SAMPLE_TEST_RESULTS}
							content={<SampleTestResult history={this.props.history} id={this.state.sampleId} onClose={this.closeModal} />}
						/>
						<ImportDisclosureModal active={this.state.importModal} closer={() => this.setState({ importModal: false })} />
					</section>
				</section>
			</div>
		)
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SampleTests)
