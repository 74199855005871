import React, { Component } from 'react'
import { Icon, Menu, Dropdown, Image } from 'semantic-ui-react'
import getAxiosInstance from '../../axios'
import certusLogoWhite from '../../images/certus_transparent_white.png'
import print from 'print-js'

export default class QrCodeDownload extends Component {
	constructor(props){
		super(props)
		this.state = {
			loading: false,
			downloadPdf:false,
			downloadingPdfReport: false,
			printingPdfReport: false,
			apiErrors: {}
		}
	}

  	certusDownloader = (tracer, endpoint) => {
    	this.setState({ downloadingPdfReport: true, apiErrors: {} })
        getAxiosInstance().get(`${process.env.REACT_APP_API_ROOT}/certi/download/${endpoint}/${tracer}`, { responseType: 'blob' })
        .then((response) => {
			if (response.status === 200) {
				if (navigator.userAgent.match('CriOS')) {
					var reader = new FileReader()
					reader.fileName = `VERISEAL_${endpoint.toUpperCase()}_${tracer}.pdf` //pdf name
					var out = new Blob([response.data], { type: 'application/pdf' })
					reader.onload = function (e) {
						window.location.href = reader.result // opens readable results in the pdf url
					}
					reader.readAsDataURL(out)
				}
				else {
					const url = window.URL.createObjectURL(new Blob([response.data])) 
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `VERISEAL_${endpoint.toUpperCase()}_${tracer}.pdf`)
					document.body.appendChild(link) //creates a link
					link.click()
				}
				this.setState({ downloadingPdfReport: false })
			}
      })
      .catch((error) => {
        if (error) {
        	console.error(error)
        }
        this.setState({ downloadingPdfReport: false })
      })
	}
        
  	certusPrinter(tracer, endpoint) {
        this.setState({ printingPdfReport: true, apiErrors: {} })
        getAxiosInstance().get(`${process.env.REACT_APP_API_ROOT}/certi/download/${endpoint}/${tracer}`, { responseType: 'blob' })
        .then((response) => {
            if (response.status === 200) {
				try {
					if (!navigator.userAgent.match('CriOS') && !navigator.userAgent.toLowerCase().indexOf('android') > -1) {
						print(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
					}
					else {
						print(window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })))
					}
					}
				catch (e) {
					console.error('error', e)
				}
            }
        })
        .finally(() => {
            this.setState({ printingPdfReport: false })
        })
    }

render() {
    return (
        <Menu className='sampleActionsMenu' compact stackable>
			<Dropdown item trigger={<span><Image style={{width: '22px', display: 'initial'}} src={certusLogoWhite} alt="Certus Logo"/> Download</span>}>
				<Dropdown.Menu>
					{this.props.sampleCertus && 
						<Dropdown.Item text={<span>VERISEAL Summary Report</span>} icon='file pdf' 
							onClick={() => this.certusDownloader(this.props.tracer, 'summary')} />
					}
					{this.props.manifestOrProject && 
						<Dropdown.Item text={<span>VERISEAL Report</span>} icon='file pdf' 
							onClick={() => this.certusDownloader(this.props.tracer, 'report')} />
					}
					{this.props.originCertus && 
						<Dropdown.Item text={<span>VERISEAL Certificate</span>} icon='file pdf' 
							onClick={() => this.certusDownloader(this.props.tracer, 'origin')} />
					}
					<Dropdown.Item text={<span>VERISEAL QR</span>} icon='qrcode'
						onClick={() => this.certusDownloader(this.props.tracer, 'qr')} />
				</Dropdown.Menu>
			</Dropdown>
			<Dropdown item trigger={<span><Image style={{width: '22px', display: 'initial'}} src={certusLogoWhite} alt="Certus Logo"/> Print</span>}>
				<Dropdown.Menu>
					{this.props.sampleCertus && 
						<Dropdown.Item text={<span>VERISEAL Summary Report</span>} icon='file pdf'
							onClick={(e) => {e.stopPropagation(); this.certusPrinter(this.props.tracer, 'summary')}} />
					}
					{this.props.manifestOrProject && 
						<Dropdown.Item text={<span>VERISEAL Report</span>} icon='file pdf'
							onClick={(e) => {e.stopPropagation(); this.certusPrinter(this.props.tracer, 'report')}} />
					}
					{this.props.originCertus && 
						<Dropdown.Item text={<span>VERISEAL Certificate</span>} icon='file pdf'
							onClick={(e) => {e.stopPropagation(); this.certusPrinter(this.props.tracer, 'origin')}} />
					}
					<Dropdown.Item text={<span>VERISEAL QR</span>} icon='qrcode'
						onClick={(e) => {e.stopPropagation(); this.certusPrinter(this.props.tracer, 'qr')}} />
				</Dropdown.Menu>
			</Dropdown>
        </Menu>
    )
  }
}
