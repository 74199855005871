import React from 'react'

const FancyVolume = (props) => {
  let value, className
  if (props.ul % 1000 === 0) {
    className = "milliliterValue"
    value = props.ul / 1000
  } else {
    className = "microliterValue"
    value = props.ul
  }
  return (
    <span className={className}>
      {value}
    </span>
  )
}

export default FancyVolume
